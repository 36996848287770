import { useQuery } from 'react-query';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';

import { SubscriptionProvider } from '../../../../contexts/subscription.context';
import { useOrganisationForm } from '../../../../hooks/useOrganisation';
import { ROLES } from '../../../../utils/constants';
import { CustomLoader } from '../../../basic/CustomeLoader/CustomLoader';
import AnalyticsLogs from './AnalyticsLogs/AnalyticsLogs.component';
import Navi from './navi/Navi.component';
import Risk from './risk/Risk.component';
import Trends from './Trends/Trends.component';
import VeritcalTabLayout from './VeritcalTabLayout.component';
import './style.css';

const AnalyticsPage = () => {
    const { path } = useRouteMatch();
    const { id } = useParams<{ id: string }>();
    const { getUserPermission } = useOrganisationForm();

    const { data: userPermit, isLoading: userPermitLoading } = useQuery(['getUserPermission', id], () => {
        try {
            return getUserPermission(id as string);
        } catch (e) {
            console.error(e);
        }
    });

    if (userPermitLoading) return <CustomLoader />;

    return (
        <div className=' flex main_navi'>
            <SubscriptionProvider>
                <VeritcalTabLayout
                    tabs={[
                        // { title: 'Overview', path: `/organisation/${id}/analytics/` },
                        { title: 'Trends', path: `/organisation/${id}/analytics/Trends` },
                        { title: 'Risk', path: `/organisation/${id}/analytics/risk` },
                        {
                            title: 'Navi',
                            path: `/organisation/${id}/analytics/navi`,
                            hidden: userPermit !== ROLES.Global,
                        },
                        { title: 'Logs', path: `/organisation/${id}/analytics/Logs` },
                    ]}
                />

                <div className='analytics-right'>
                    <Switch>
                        {/* <Route exact path={[`${path}/`]} component={Analytics} /> */}
                        <Route exact path={`${path}/Navi`} component={Navi} />
                        <Route exact path={`${path}/Trends`} component={Trends} />
                        <Route exact path={`${path}/risk`} component={Risk} />
                        <Route exact path={`${path}/Logs`} component={AnalyticsLogs} />
                    </Switch>
                </div>
            </SubscriptionProvider>
        </div>
    );
};

export default AnalyticsPage;
