export const formatAMPM = (date: Date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();
    const strTime = hours + ':' + minutesStr + ' ' + ampm;
    return strTime;
};

const getMonth = (monthNumber: number): string => {
    switch (monthNumber) {
        case 0:
            return 'Jan';
        case 1:
            return 'Feb';
        case 2:
            return 'Mar';
        case 3:
            return 'Apr';
        case 4:
            return 'May';
        case 5:
            return 'Jun';
        case 6:
            return 'Jul';
        case 7:
            return 'Aug';
        case 8:
            return 'Sep';
        case 9:
            return 'Oct';
        case 10:
            return 'Nov';
        case 11:
            return 'Dec';
        default:
            return '';
    }
};

export const timestampToDate = (timestamp: number) => {
    const date = new Date(timestamp);
    const month = getMonth(date.getMonth());
    const year = date.getFullYear();
    const day = date.getDate();

    return `${month} ${day}, ${year}`;
};
