import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { ReactComponent as FileUploadOutlinedIcon } from '../../../assets/icons/icon-file-upload.svg';
import { MAX_ORGANISATION_LOGO_SIZE } from '../../../utils/constants';
import { getDocumentUrl } from '../../../utils/getDocumentUrl';
import { isFileValidation } from '../../../utils/validation';
import { IconAlert } from '../../basic/IconAlert.component';

const OrganisationLogo = ({
    documnetId,
    onChange,
}: {
    documnetId?: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
    const logoInputRef = useRef<HTMLInputElement | null>(null);
    const [preview, setPreview] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (documnetId) {
            const document = getDocumentUrl(Number(documnetId));
            setPreview(document);
        }
    }, [documnetId]);

    const handleOpenFilePrompt = () => {
        if (logoInputRef.current) {
            logoInputRef.current.value = '';
            logoInputRef.current.click();
        }
    };

    const handleLogoUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            const validation = isFileValidation(file, MAX_ORGANISATION_LOGO_SIZE);
            if (validation) {
                setError(validation);
                setTimeout(() => {
                    setError(null);
                }, 5000);
                return;
            }
            setPreview(URL.createObjectURL(file));
            onChange(e);
        }
    };

    return (
        <div className='flex flex-col justify-center items-center space-y-4'>
            {preview ? (
                <div>
                    <div className='flex flex-col justify-center space-y-2 items-center bg-white w-24 h-24 border border-blue-dark border-opacity-7'>
                        <img className='w-20 h-20 object-contain' src={preview} alt='Organisation Logo' />
                    </div>
                    <button className='text-xs text-blue-ryb font-medium' onClick={() => setPreview(null)}>
                        Clear
                    </button>
                </div>
            ) : (
                <div
                    className='flex flex-col justify-center space-y-2 items-center bg-white w-24 h-24 border border-blue-dark border-opacity-10 cursor-pointer'
                    onClick={() => handleOpenFilePrompt()}
                >
                    <FileUploadOutlinedIcon className='w-3 h-3' fill='#0014FF' />
                    <input
                        ref={logoInputRef}
                        accept='image/*'
                        onChange={handleLogoUpload}
                        className='hidden'
                        type='file'
                    />
                    <p className='font-medium text-xs text-center text-blue-ryb'>Upload a Logo</p>
                </div>
            )}

            {/* <p className='text-xs text-darker text-opacity-75'>Logo larger than 5 MB are not supported.</p> */}
            {error && <IconAlert message={error} />}
        </div>
    );
};

export default OrganisationLogo;
