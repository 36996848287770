import { getCategoryIcon } from '../../../../../../utils/categoryUnicode';

export const ChangeResponseType = {
    Positive: 'Positive',
    Negative: 'Negative',
};

const ChangeResponse = ({
    type,
    posneg,
    setPosNeg,
    handleActionChange,
}: {
    type: string;
    posneg: boolean;
    setPosNeg: (value: boolean) => void;
    handleActionChange: () => void;
}) => {
    const handleChange = () => {
        setPosNeg(!posneg);
        handleActionChange();
    };
    return (
        <div className='flex'>
            <button className='change_response_btn' onClick={handleChange}>
                <div className='flex items-center justify-center change-response'>
                    <p>{getCategoryIcon('Cross')}</p>
                    {type === 'Positive' ? (
                        <p>{getCategoryIcon('Thumbs Up')}</p>
                    ) : (
                        <p>{getCategoryIcon('Thumbs Down')}</p>
                    )}
                </div>
                <div className='response_tooltip_box'>
                    {type === 'Positive' ? <p>Set as False Positive</p> : <p>Set as False Negative</p>}
                </div>
            </button>
        </div>
    );
};

export default ChangeResponse;
