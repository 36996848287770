import Cookies from 'js-cookie';

import history from '../history';
import { store } from '../store';
import { handleLogout, handleRenewToken, handleTokenExpiry } from './chromeExtension';

const ENV_BASE_URL = process.env.REACT_APP_BACK_END_URL || 'http://localhost:3000';
const BASE_URL = ENV_BASE_URL[ENV_BASE_URL.length - 1] === '/' ? ENV_BASE_URL.slice(0, -1) : ENV_BASE_URL;

const logout = () => {
    localStorage.removeItem('jwt');
    Cookies.remove('bberry-token');
    Cookies.remove('bberry-user');
    handleLogout();
};

export const getFullUrl = (path: string) => {
    return BASE_URL + (path[0] === '/' ? '' : '/') + path;
};

const UNAUTHORIZED = 401;
const NOT_FOUND = 404;
const INTERNAL_SERVER_ERROR = 500;

export const api = async (url: string, method: string, headers: RequestInit['headers'], body?: string | FormData) => {
    const bearer = localStorage.getItem('jwt') ?? undefined;
    const fullUrl = getFullUrl(url);
    if (bearer) {
        const decodedToken = JSON.parse(atob(bearer.split('.')[1]));
        if (decodedToken.exp * 1000 < Date.now()) {
            console.log('token expired');
            handleTokenExpiry();
            logout();
            history.push('/sign-in');
        }
    }

    const res = await fetch(fullUrl, {
        method,
        headers: {
            ...headers,
            ...(bearer ? { Authorization: `Bearer ${bearer}` } : {}),
        },
        body,
    });

    //5xx error status
    if (res.status === 503) {
        history.push(`/error/${res.status}`);
        return;
    }

    //4xx error status
    if (res.status === NOT_FOUND) {
        history.push(`/error/${res.status}`);
        return;
    }

    const resJson = await res.json();
    if (res.ok) {
        if (history.location.pathname == '/' || history.location.pathname == '/sign-in') {
            history.push('/home');
        }
        return Promise.resolve(resJson);
    } else {
        // renew the token
        if (
            res.status === UNAUTHORIZED &&
            store.user &&
            store.user.userData?.email !== null &&
            store.user.userData?.id !== null &&
            !history.location.pathname.includes('/b/')
        ) {
            console.log('Renewing token... network.ts');
            const body = {
                email: store.user.userData?.email,
                userId: store.user.userData?.id,
                userType: store.user.userData?.userType,
            };
            console.log('body', body);
            if (body) {
                const res = await fetch(getFullUrl('/auth/renew-pub-token'), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body),
                });
                const resJson = await res.json();
                console.log('resJson', resJson);
                if (res.ok) {
                    store.user.setToken(resJson.access_token);
                    handleRenewToken(resJson.access_token);
                    window.location.reload();
                }
            }
            // exits out of the condition
            return;
        }
        if (res.status === UNAUTHORIZED && !history.location.pathname.includes('/b/')) {
            const pathname = history.location.pathname;
            logout();
            if (pathname.match(/\/sign-in(-client)?/)) return;
            const queryParams = `expired=true&redirect=${pathname}`;
            history.push(`/sign-in?${queryParams}`);
            return Promise.reject();
        } else return Promise.reject(new Error(resJson.message ? resJson.message : resJson.error));
    }
};

export const post = (
    url: string,
    params: Record<string, unknown> = {},
    headers: RequestInit['headers'] = {
        'Content-Type': 'application/json',
    },
) => api(url, 'POST', headers, JSON.stringify(params));

export const postFile = (url: string, formData: FormData, headers: RequestInit['headers'] = {}) =>
    api(url, 'POST', headers, formData);

export const get = (url: string, headers: RequestInit['headers'] = {}) => api(url, 'GET', headers);

export const put = (
    url: string,
    params: Record<string, unknown> = {},
    headers: RequestInit['headers'] = { 'Content-Type': 'application/json' },
) => api(url, 'PUT', headers, JSON.stringify(params));

export const patch = (
    url: string,
    params: Record<string, unknown> = {},
    headers: RequestInit['headers'] = { 'Content-Type': 'application/json' },
) => api(url, 'PATCH', headers, JSON.stringify(params));

export const del = (url: string, headers: RequestInit['headers'] = {}) => api(url, 'DELETE', headers, '');
