import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Link, useLocation } from 'react-router-dom';

import useMediaQuery from '../../../hooks/useMediaQuery';
import { SIZES } from '../../../utils/constants';

type TabsElement = {
    title: string;
    path: string;
    exactPath?: string[];
    tour?: string;
};
type Props = {
    tabs: (TabsElement | null)[];
};

const TabsLayout: React.FC<Props> = ({ tabs }) => {
    const { pathname } = useLocation();
    const [value, setValue] = React.useState(pathname);
    const isMobile = useMediaQuery(SIZES.MOBILE_WIDTH);

    useEffect(() => {
        setValue(pathname);
    }, [pathname]);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <Stack direction='row' className='flex w-full items-center md:justify-center mb-6 md:mt-6'>
            <Box sx={{ borderBottom: '1px solid #0F10440D', width: isMobile ? '100%' : 'fit-content' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    key={value}
                    TabIndicatorProps={{ style: { background: '#373BBA' } }}
                >
                    {tabs &&
                        tabs.map((element, index) => {
                            if (!element) return;
                            return (
                                <Tab
                                    key={index}
                                    label={element.title}
                                    value={element.path}
                                    to={element.path}
                                    component={Link}
                                    sx={{
                                        textTransform: 'none',
                                        fontFamily: 'Poppins',
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        marginInline: '8px',
                                        padding: 0,
                                        color: '#0F1044BF',
                                        '&.MuiTab-root.Mui-selected': {
                                            color: '#373BBA',
                                            fontWeight: '500',
                                        },
                                    }}
                                    data-tour={element.tour}
                                />
                            );
                        })}
                </Tabs>
            </Box>
        </Stack>
    );
};

export default TabsLayout;
