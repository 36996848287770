import React from 'react';

import clsx from 'clsx';

export const selectVariant = (color: string) => {
    if (color === 'orange') return 'orange';
    if (color === 'teal') return 'teal';
    if (color === 'lightBlue') return 'lightBlue';
    if (color === 'pink') return 'pink';
    if (color === 'blue') return 'blue';
    if (color === 'black') return 'black';
};

export const logoVariants = {
    workSpace: 'bg-warning-500 text-orange-dark',
    space: 'bg-primary-400 text-blue-dark',
    orange: 'bg-spaces-100 text-spaces-orange',
    teal: 'bg-spaces-200 text-spaces-teal',
    lightBlue: 'bg-spaces-300 text-spaces-light-blue',
    blue: 'bg-spaces-400 text-spaces-blue',
    pink: 'bg-spaces-500 text-spaces-pink',
    black: 'bg-spaces-600 text-spaces-black',
};

export const textVariants = {
    workSpace: 'bg-white text-orange-dark',
    space: 'bg-white text-blue-dark',
    orange: 'bg-white text-spaces-orange',
    teal: 'bg-white text-spaces-teal',
    lightBlue: 'bg-white text-spaces-light-blue',
    blue: 'bg-white text-spaces-blue',
    pink: 'bg-white text-spaces-pink',
    black: 'bg-white text-spaces-black',
};

type props = {
    className?: string;
    title?: string;
    variant?: keyof typeof logoVariants;
    textColor?: keyof typeof textVariants;
    size?: string;
    borderColor?: string;
    selected?: boolean;
    preview?: string;
    organization?: boolean;
    imgSize?: number;
};

const SpaceLogo: React.FC<props> = ({
    className,
    variant,
    title = 'M',
    size = '',
    textColor,
    borderColor,
    selected,
    preview,
    organization,
    imgSize,
}) => {
    return (
        <div className={clsx(className, 'flex items-center justify-center')}>
            {preview && preview !== '' ? (
                <img
                    src={preview}
                    className={clsx(imgSize ? `w-${imgSize} h-${imgSize}` : 'w-4 h-4', 'bg-cover rounded-md')}
                    alt='logo'
                />
            ) : (
                <div
                    className={clsx(
                        'rounded md:rounded-lg md:border-2  flex item-center justify-center',
                        selected && 'bg-gray-100',
                        variant && logoVariants[variant],
                        textColor && textVariants[textColor],
                        className,
                        borderColor ? borderColor : 'border-white',
                        organization && 'bg-primary-darker bg-opacity-3',
                    )}
                >
                    {!organization ? (
                        <p
                            className={clsx(
                                'leading-none text-center font-medium self-center capitalize',
                                size ? size : 'text-[8px] md:text-sm',
                            )}
                        >
                            {title}
                        </p>
                    ) : (
                        <p className='text-xs text-center self-center text-darker text-opacity-75 font-normal'>logo</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default SpaceLogo;
