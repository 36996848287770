import { Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';

import { ReactComponent as PlusIcon } from '../../../../assets/icons/icon-plus.svg';
import { CardResponse } from '../../../../store/UserStore';
import PaymentMethodCard from '../../../admin/paymentMethods/PaymentMethodCard';
import { Button } from '../../../basic/Button.component';

const PaymentMethod = ({ cards }: { cards?: CardResponse[] | null }) => {
    return (
        <div className='flex flex-col space-y-4 text-sm text-darker text-opacity-75 w-full'>
            <div className='w-full'>
                <div className='flex items-center justify-between w-full mb-2'>
                    <h1 className='font-semibold'>Payment Method</h1>
                    <Link to={`add-payment`}>
                        <Button className='space-x-2 h-10' size='oval' variant='blueRYB'>
                            <PlusIcon width={10} height={10} fill='white' />
                            <p>Add payment Method</p>
                        </Button>
                    </Link>
                </div>
                {/* <p className='font-normal'>Update your payment methods to cover any subscription.</p> */}
                <div className='space-y-6 mt-6'>
                    {!cards ? (
                        <Skeleton
                            variant='rectangular'
                            sx={{ borderRadius: '16px', maxWidth: '924px', marginInline: 'auto' }}
                            height={78}
                            width='100%'
                        />
                    ) : (
                        cards.map(({ card, isDefault, isExpired }) => (
                            <PaymentMethodCard key={card.id} card={card} isDefault={isDefault} isExpired={isExpired} />
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default PaymentMethod;
