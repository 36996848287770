import * as React from 'react';

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

export default function MoodSlider({
    userMoodValue,
    handleUserMood,
}: {
    userMoodValue: number;
    handleUserMood: (value: number) => void;
}) {
    const [value, setValue] = React.useState<number>(userMoodValue);

    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number);
        handleUserMood(newValue as number);
    };

    const marks = [{ value: 0 }, { value: 25 }, { value: 50 }, { value: 75 }, { value: 100 }];

    return (
        <Box sx={{ width: 100 }}>
            <Slider
                aria-label='Volume'
                value={value}
                onChange={handleChange}
                min={0}
                max={100}
                step={null}
                marks={marks}
                sx={{
                    margin: '8px',
                    padding: 0,
                    '& .MuiSlider-thumb': {
                        color: '#60F4C8',
                        boxShadow: 'none',
                        width: 12,
                        height: 12,
                    },
                    '& .MuiSlider-track': {
                        color: '#60F4C8',
                    },
                    '& .MuiSlider-rail': {
                        color: 'rgba(96, 244, 200, 0.1)',
                        height: 4,
                    },
                    '& .MuiSlider-thumb:hover': {
                        boxShadow: 'none',
                    },
                    // '& .MuiSlider-thumb:active': {
                    //     boxShadow: 'none',
                    // },
                    // '& .MuiSlider-thumb:focus': {
                    //     boxShadow: 'none',
                    // },
                    '& .MuiSlider-mark': {
                        display: 'none',
                    },
                }}
            />
        </Box>
    );
}
