import { MouseEventHandler } from 'react';

import clsx from 'clsx';

import { ReactComponent as CheckIcon } from '../../assets/icons/icon-check-filled.svg';

export type CheckboxProps = {
    label?: string;
    onClick?: MouseEventHandler<HTMLInputElement>;
    className?: string;
    checked?: boolean;
    custom?: boolean;
    rounded?: boolean;
    disabled?: boolean;
};
export const Checkbox = ({ label, onClick, checked, custom, rounded, disabled }: CheckboxProps) => {
    return (
        <div className=''>
            <div className='flex items-center ' onClick={onClick}>
                <input
                    type='checkbox'
                    className={clsx('opacity-0 absolute h-4 w-4', disabled ? 'cursor-not-allowed' : 'cursor-pointer')}
                />
                <div
                    className={clsx(
                        'border border-blue-dark border-opacity-15',
                        'w-4 h-4 flex justify-center items-center focus-within:border-purple',
                        checked ? 'bg-blue' : 'bg-white',
                        rounded ? 'rounded-full' : 'rounded',
                        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                    )}
                >
                    {checked && <CheckIcon fill='white' />}
                </div>
                {label && (
                    <label
                        className={clsx(
                            'ml-2 text-darker text-opacity-75',
                            custom ? 'select-none text-sm' : 'select-none text-sm',
                            disabled && 'cursor-not-allowed',
                        )}
                    >
                        {label}
                    </label>
                )}
            </div>
        </div>
    );
};
