// Sole Trader, Limited Company, Partnership, LLP
export const ONE_DRIVE_FILE = 'oneDriveFile';
export const ARCHIVE_STATUS = 'archived';
export const businessTypes = {
    LLP: 'LLP',
    SOLE_TRADER: 'Sole Trader',
    LIMITED_COMPANY: 'Limited Company',
    PARTENERSHIP: 'Partnership',
} as const;
export type BusinessType = keyof typeof businessTypes;

export const businessTypeList = Object.keys(businessTypes).map((key) => ({
    value: key,
    label: businessTypes[key as BusinessType],
}));

export const ENTERPRISE = 'ENTERPRISE';
export const BLUEBERRY = 'BLUEBERRY';
export const BLUEBERRY_PRO = 'BLUEBERRY_PRO';
export const BUSINESS = 'BUSINESS';

export const PLAN_NAMES = {
    Free: 'LITE',
    Paid: 'PRO',
};

export const SUBSCRIPTION_TYPE = {
    MONTHLY: 'monthly',
    YEARLY: 'yearly',
};

export const SIZES = {
    MOBILE_WIDTH: 768,
    TABLET_WIDTH: 1024,
};

export const ROLES = {
    Global: 'Global Admin',
    Steward: 'Steward',
    Member: 'Member',
    Architect: 'Architect',
    Treasurer: 'Treasurer',
    Invited: 'Invited',
};

export const MAX_FILE_SIZE = 52428800; // 50MB
export const MAX_PROFILE_PICTURE_SIZE = 10485760; // 10MB
export const MAX_HEADER_PICTURE_SIZE = 2097152; // 2MB
export const MAX_SPACE_ICON_SIZE = 1048576; // 1MB
export const MAX_ORGANISATION_LOGO_SIZE = 5242880; // 5MB

export const industryTypes = {
    Education: ['Education Management', 'E-Learning', 'Higher Education', 'Primary/Secondary Education', 'Research'],
    Design: ['Architecture & Planning', 'Design', 'Graphic Design'],
    'Corporate Services': [
        'Accounting',
        'Business Supplies & Equipment',
        'Environmental Services',
        'Events Services',
        'Executive Office',
        'Facilities Services',
        'Human Resources',
        'Information Services',
        'Management Consulting',
        'Outsourcing/Offshoring',
        'Professional Training & Coaching',
        'Security & Investigations',
        'Staffing & Recruiting',
    ],
    Retail: ['Retail', 'Supermarkets', 'Wholesale'],
    Manufacturing: [
        'Automotive',
        'Aviation & Aerospace',
        'Chemicals',
        'Defense & Space',
        'Electrical & Electronic Manufacturing',
        'Food Production',
        'Glass, Ceramics & Concrete',
        'Industrial Automation',
        'Machinery',
        'Mechanical or Industrial Engineering',
        'Packaging & Containers',
        'Paper & Forest Products',
        'Plastics',
        'Railroad Manufacture',
        'Renewables & Environment',
        'Shipbuilding',
    ],
    Finance: [
        'Banking',
        'Capital Markets',
        'Financial Services',
        'Insurance',
        'Investment Banking',
        'Investment Management',
        'Wealth & Asset Management',
        'Venture Capital & Private Equity',
    ],
    'Recreation & Travel': [
        'Airlines/Aviation',
        'Gambling & Casinos',
        'Hospitality',
        'Leisure, Travel & Tourism',
        'Restaurants',
        'Recreational Facilities & Services',
        'Sports',
    ],
    Arts: ['Arts & Crafts', 'Fine Art', 'Performing Arts', 'Photography'],
    'Health Care': [
        'Biotechnology',
        'Hospital & Health Care',
        'Medical Device',
        'Medical Practice',
        'Mental Health Care',
        'Pharmaceuticals',
        'Veterinary',
    ],
    'Hardware & Networking': [
        'Computer Hardware',
        'Computer Networking',
        'Nanotechnologie',
        'Semiconductors',
        'Telecommunications',
        'Wireless',
    ],
    'Real Estate': ['Commercial Real Estate', 'Real Estate'],
    Legal: ['Alternative Dispute Resolution', 'Law Practice', 'Legal Services'],
    'Consumer Goods': [
        'Apparel & Fashion',
        'Consumer Electronics',
        'Consumer Goods',
        'Consumer Services',
        'Cosmetics',
        'Food & Beverages',
        'Furniture',
        'Luxury Goods & Jewelry',
        'Sporting Goods',
    ],
    'Media & Communications': [
        'Market Research',
        'Marketing & Advertising',
        'Newspapers',
        'Online Media',
        'Printing',
        'Public Relations & Communications',
        'Publishing',
        'Translation & Localization',
        'Writing & Editing',
    ],
    Nonprofit: [
        'Civic & Social Organization',
        'Fundraising',
        'Individual & Family Services',
        'International Trade & Development',
        'Libraries',
        'Museums & Institutions',
        'Non-Profit Organization Management',
        'Philanthropy',
        'Program Development',
        'Religious Institutions',
        'Think Tanks',
    ],
    'Software, Data & IT Services': [
        'Computer & Network Security',
        'Computer Software',
        'Information Technology & Services',
        'Data Science',
        'Aritificial Intelligence',
        'Internet',
    ],
    'Transportation & Logistics': [
        'Import & Export',
        'Logistics & Supply Chain',
        'Maritime',
        'Package/Freight Delivery',
        'Transportation/Trucking/Railroad',
        'Warehousing',
    ],
    Entertainment: [
        'Animation',
        'Broadcast Media',
        'Computer Games',
        'Entertainment',
        'Media Production',
        'Mobile Games',
        'Motion Pictures & Film',
        'Music',
    ],
    'Wellness & Fitness': ['Alternative Medicine', 'Nutrition', 'Health, Wellness & Fitness'],
    'Public Administration': [
        'Government Administration',
        'Government Relations',
        'International Affairs',
        'Judiciary',
        'Legislative Office',
        'Political Organization',
        'Public Policy',
    ],
};

export type IndustryType = keyof typeof industryTypes;

export const industryTypeList = Object.keys(industryTypes).map((key) => ({
    value: industryTypes[key as IndustryType],
    label: key,
}));

export type CategoryType = {
    id?: number;
    name: string;
    thread: boolean;
    type: string;
};

export const Categories: CategoryType[] = [
    {
        name: 'Agriculture',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Anthropology',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Art',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Astronomy',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Biology',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Business',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Chemistry',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Computer Science',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Economics',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Education',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Engineering',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Environmental Sciences',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Geography',
        thread: false,
        type: 'Category',
    },
    {
        name: 'History',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Law',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Linguistics',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Literature',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Mathematics',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Medicine',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Philosophy',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Physics',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Political Science',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Psychology',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Public Health',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Social Sciences',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Sports',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Technology',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Theology',
        thread: false,
        type: 'Category',
    },
    {
        name: 'Veterinary Science',
        thread: false,
        type: 'Category',
    },
];

export const countryList = ['United-Kingdom' /* 'France', 'Germany', 'Italy', 'Spain', 'Japan' */];

export const getRolesByUserPermission = (permission: string, tier?: string) => {
    if (tier === 'SHIELD') {
        return [ROLES.Member];
    }
    if (permission === ROLES.Global) {
        return [ROLES.Global, ROLES.Steward, ROLES.Architect, ROLES.Member, ROLES.Treasurer];
    } else {
        return [ROLES.Architect, ROLES.Member];
    }
};
