import { makeAutoObservable } from 'mobx';

export class SignInStore {
    forgotPasswordEmail: string | null = null;
    constructor() {
        makeAutoObservable(this);
    }
    updateForgotPasswordEmail = (email: string | null): void => {
        this.forgotPasswordEmail = email;
    };
}
