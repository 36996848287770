import { useState } from 'react';

import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
    StripeCardCvcElementChangeEvent,
    StripeCardExpiryElementChangeEvent,
    StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';

import { appInsights } from '../AppInsights';
import { useServices } from '../components/ServiceProvider';
import { useStore } from '../store';
import { useForm } from '../utils/useForm';
import { required } from '../utils/validation';

type StripeValidationType =
    | StripeCardExpiryElementChangeEvent
    | StripeCardNumberElementChangeEvent
    | StripeCardCvcElementChangeEvent;

export const usePaymentMethods = (cardId?: string) => {
    const history = useHistory();
    const services = useServices();
    const { payments, organisation } = services;
    const elements = useElements();
    const stripe = useStripe();
    const store = useStore();
    const email = store.user.userData?.email;
    const [validElements, setValidElements] = useState({
        card: false,
        cvv: false,
        expiration: false,
    });
    const cardElement = elements?.getElement(CardNumberElement);
    

    const [defaultMethod, setDefaultMethod] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [cardTypeCheck, setCardTypeCheck] = useState(true);
    const [paymentId, setPaymentId] = useState<string | null>(null);
    const { subscriptions } = useStore();
    const [cardPayment, setCardPayment] = useState<string | null>(null);
    const currentPaymentMethod = store.user.getPaymentMethods()?.find((el) => el.card.id == cardId);
    const { fields, isValid } = useForm({
        fields: {
            name: { validation: required },
        },
    });

    const handleStripeElementChange = (event: StripeValidationType, type: string) => {
        if (event.complete) {
            setValidElements((elements) => {
                return {
                    ...elements,
                    [type]: true,
                };
            });
        } else {
            setValidElements((elements) => {
                return {
                    ...elements,
                    [type]: false,
                };
            });
        }
    };

    const createPaymentMethod = async (path?: string, onboarding?: boolean, type?: string) => {
        if (!stripe || !elements) {
            console.error(`Stripe isn't loaded`);
            return false;
        }
        if (cardElement) {
            setLoading(true);
            const { error } = await stripe.createToken(cardElement);
            if (error) {
                setError('Error: ' + error.message);
                setLoading(false);
                return false;
            }
            const paymentMethodRes = await stripe.createPaymentMethod({
                type: 'card',
                billing_details: { email, name: fields.name.value },
                card: cardElement,
            });

            if (paymentMethodRes.paymentMethod?.id && email) {
                await payments.addPaymentMethod({
                    email,
                    payment_method: paymentMethodRes.paymentMethod?.id,
                    defaultMethod,
                });
                const index = store.user.paymentMethods?.findIndex((pm) => pm.isDefault);
                store.user.updatePaymentMethodStatus(index);
                store.user.addPaymentMethod({
                    card: paymentMethodRes.paymentMethod,
                    isDefault: defaultMethod,
                    isExpired: false,
                });
                if (onboarding) {
                    try {
                        setError('');
                        await payments.createOrUpdateSubcriptionTF({
                            payment_method: paymentMethodRes.paymentMethod?.id,
                            type: type,
                            product_name: 'AICKO GROWTH',
                        });
                        const newSubscription = await payments.getCurrentSubscription();
                        subscriptions.setAickoCurrentSubscription(newSubscription);
                    } catch (err) {
                        if (err instanceof Error) {
                            setError(err.message);
                            appInsights.trackException({ exception: err });
                        } else
                            setError(
                                'Your payment was not successfully processed. Please try again or contact our support.',
                            );
                        setLoading(false);
                    }
                }
                setCardPayment(paymentMethodRes.paymentMethod?.id);
                setLoading(false);
                if (path) history.push(path);
                else history.goBack();
            }
            return true;
        }
        return false;
    };

    const deletePaymentMethod = async (paymentMethod: string) => {
        if (window.confirm('Are you sure you wish to delete this card?')) {
            if (email) {
                await payments.removePaymentMethod(paymentMethod);

                store.user.deletePaymentMethod(paymentMethod);
            }
        }
    };

    const setPaymentMethodAsDefault = async (paymentMethod: string) => {
        if (window.confirm('Make this card your default one?')) {
            if (email) {
                await payments.setPaymentMethodAsDefault(paymentMethod);
                // update store here
            }
        }
    };

    //organisaton default payment method
    const setOrganisationDefaultPayment = async (paymentMethod: string, id: string) => {
        if (window.confirm('Make this card your default one?')) {
            if (email) {
                await organisation.setDefaultPaymentMethod(id, paymentMethod);
            }
        }
    };

    const updatePaymentMethod = async (methodId: string | undefined) => {
        if (stripe && email && cardElement && methodId) {
            setLoading(true);
            const { error } = await stripe.createToken(cardElement);
            if (error) {
                setError('Error: ' + error.message);
                setLoading(false);
                return false;
            }
            const paymentMethodRes = await stripe.createPaymentMethod({
                type: 'card',
                billing_details: {
                    email,
                    name: fields.name.value,
                },
                card: cardElement,
            });
            if (paymentMethodRes.paymentMethod?.id) {
                try {
                    const newMethod = await payments.updatePaymentMethod({
                        email,
                        current_payment_method: methodId,
                        new_payment_method: paymentMethodRes.paymentMethod?.id,
                        defaultMethod,
                    });
                    store.user.deletePaymentMethod(methodId);
                    store.user.addPaymentMethod(newMethod);
                    history.goBack();
                } catch (err) {
                    console.log(err);
                    setLoading(false);
                }
            }
        }
        setLoading(false);
    };

    const createOrganisationPaymentMethod = async (id: string) => {
        try {
            setError(null);
            if (!stripe || !elements) {
                console.error(`Stripe isn't loaded`);
                return false;
            }
            if (!isValid()) throw new Error('Name on card cannot be empty, please enter a valid name');
            if (cardElement) {
                setLoading(true);
                const { error } = await stripe.createToken(cardElement);
                if (error) {
                    setError('Error: ' + error.message);
                    setLoading(false);
                    return false;
                }
                const paymentMethodRes = await stripe.createPaymentMethod({
                    type: 'card',
                    billing_details: { email, name: fields.name.value },
                    card: cardElement,
                });
                if (paymentMethodRes.paymentMethod?.id && email) {
                    await organisation.addPaymentMethod(
                        {
                            email,
                            payment_method: paymentMethodRes.paymentMethod?.id,
                            defaultMethod,
                        },
                        id,
                    );
                    setLoading(false);
                    history.goBack();
                }
            }
            return false;
        } catch (err) {
            if (err instanceof Error) setError(err.message);
            setLoading(false);
            return false;
        }
    };

    const updateOrganisationPaymentMethod = async (paymentId: string, id: string) => {
        try {
            if (!isValid()) throw new Error('Name on card cannot be empty, please enter a valid name');
            if (stripe && email && cardElement && paymentId) {
                setLoading(true);
                const { error } = await stripe.createToken(cardElement);
                if (error) {
                    setError('Error: ' + error.message);
                    setLoading(false);
                    return false;
                }
                const paymentMethodRes = await stripe.createPaymentMethod({
                    type: 'card',
                    billing_details: {
                        email,
                        name: fields.name.value,
                    },
                    card: cardElement,
                });
                if (paymentMethodRes.paymentMethod?.id) {
                    try {
                        const newMethod = await organisation.updatePaymentMethod(
                            {
                                email,
                                current_payment_method: paymentId,
                                new_payment_method: paymentMethodRes.paymentMethod?.id,
                                defaultMethod,
                            },
                            id,
                        );
                        history.goBack();
                    } catch (err) {
                        console.log(err);
                        setLoading(false);
                    }
                }
            }
            setLoading(false);
        } catch (err) {
            if (err instanceof Error) setError(err.message);
            setLoading(false);
            return false;
        }
    };

    const deleteOrganisationPaymentMethod = async (paymentMethod: string, id: string) => {
        if (window.confirm('Are you sure you wish to delete this card?')) {
            if (email) {
                await organisation.deletePaymentMethod(id, paymentMethod);
            }
        }
    };

    return {
        fields,
        createPaymentMethod,
        currentPaymentMethod,
        deletePaymentMethod,
        defaultMethod,
        setDefaultMethod,
        updatePaymentMethod,
        setPaymentMethodAsDefault,
        cardPayment,
        loading,
        error,
        setError,
        cardTypeCheck,
        setCardTypeCheck,
        paymentId,
        handleStripeElementChange,
        validStripeElements: validElements.card && validElements.cvv && validElements.expiration,
        createOrganisationPaymentMethod,
        updateOrganisationPaymentMethod,
        setOrganisationDefaultPayment,
        deleteOrganisationPaymentMethod,
    };
};
