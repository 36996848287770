/* eslint-disable @typescript-eslint/no-explicit-any */
import { MetaMarkerItem, Nuances, ThumbData } from '../../types';
// compare data after eva publish
const isEqualNuancesData = (a: any, b: any): boolean => {
    if (typeof a !== typeof b) return false;
    if (typeof a !== 'object' || a === null || b === null) return a === b;
    if (Array.isArray(a)) {
        if (a.length !== b.length) return false;
        for (let i = 0; i < a.length; i++) {
            if (!isEqualNuancesData(a[i], b[i])) return false;
        }
        return true;
    }
    const aKeys: string[] = Object.keys(a);
    const bKeys: string[] = Object.keys(b);
    if (aKeys.length !== bKeys.length) return false;
    for (const key of aKeys) {
        if (!isEqualNuancesData(a[key], b[key])) return false;
    }
    return true;
};

export const compareNuanceData = (data1: Nuances[], data2: Nuances[]): boolean => {
    return isEqualNuancesData(data1, data2);
};

// compare data eva create , update , but not a publish

const isEqual = (a: any, b: any): boolean => {
    if (typeof a !== typeof b) return false;
    if (typeof a !== 'object' || a === null || b === null) return a === b;
    if (Array.isArray(a)) {
        if (a.length !== b.length) return false;
        for (let i = 0; i < a.length; i++) {
            if (!isEqual(a[i], b[i])) return false;
        }
        return true;
    }
    const aKeys: string[] = Object.keys(a);
    const bKeys: string[] = Object.keys(b);
    if (aKeys.length !== bKeys.length) return false;
    for (const key of aKeys) {
        if (!isEqual(a[key], b[key])) return false;
    }
    return true;
};

export const compareMetaMarkers = (data1: MetaMarkerItem[], data2: MetaMarkerItem[]): boolean => {
    if (data1?.length !== data2?.length) return false;

    for (let i = 0; i < data1.length; i++) {
        const marker1 = data1[i];
        const marker2 = data2[i];

        if (
            marker1.triggerNumber !== marker2.triggerNumber ||
            marker1.triggerState !== marker2.triggerState ||
            marker1.color !== marker2.color ||
            marker1.metaMarker !== marker2.metaMarker ||
            marker1.weight !== marker2.weight
        ) {
            return false;
        }

        if (!isEqual(marker1.nuances, marker2.nuances)) {
            return false;
        }
    }

    return true;
};

// compare data before submit eva data open a conform moda
// Function to compare specific fields (value and titles) in ThumbData objects
const deepCompareObject = (obj1: ThumbData, obj2: ThumbData): boolean => {
    // Compare the value fields
    if (obj1.value !== obj2.value) {
        return false;
    }
    // Compare the titles arrays
    if (obj1.titles.length !== obj2.titles.length) {
        return false;
    }
    for (let i = 0; i < obj1.titles.length; i++) {
        if (obj1.titles[i] !== obj2.titles[i]) {
            return false;
        }
    }
    // If both value and titles match, the objects are considered equal
    return true;
};

// Function to deeply compare two arrays of ThumbData objects in an order-insensitive manner
export const deepCompareData = (data1: ThumbData[], data2: ThumbData[]): boolean => {
    // Helper function to compare titles arrays irrespective of order
    const compareTitles = (titles1: string[], titles2: string[]): boolean => {
        if (titles1.length !== titles2.length) return false;
        const sortedTitles1 = [...titles1].sort();
        const sortedTitles2 = [...titles2].sort();
        return sortedTitles1.every((title, index) => title === sortedTitles2[index]);
    };

    // If the lengths of arrays differ, they are not equal
    if (data1?.length !== data2?.length) return false;
    // Sort arrays by value for consistent comparison
    const sortedData1 = [...data1].sort((a, b) => a.value - b.value);
    const sortedData2 = [...data2].sort((a, b) => a.value - b.value);
    // Compare each item in the sorted arrays
    return sortedData1.every((item, index) => {
        const otherItem = sortedData2[index];
        return item.value === otherItem.value && compareTitles(item.titles, otherItem.titles);
    });
};

// convert data api to local state key

export function convertToThumbData(inputValues: Nuances[]): ThumbData[] {
    // Initialize an object to accumulate data based on threshold
    const thresholdDataMap: { [key: number]: { value: number; titles: string[] } } = {};
    // Initialize an array to keep track of the order of thresholds
    const order: number[] = [];
    // Iterate over inputValues array
    inputValues?.forEach((inputValue) => {
        const { nuance, threshold } = inputValue;
        // Check if threshold already exists in the map
        if (thresholdDataMap[threshold]) {
            // If threshold exists, push nuance to titles array
            thresholdDataMap[threshold].titles.push(nuance);
        } else {
            // If threshold doesn't exist, create a new entry and add to order
            thresholdDataMap[threshold] = { value: threshold, titles: [nuance] };
            order.push(threshold);
        }
    });
    // Convert the accumulated data into ThumbData array based on order
    const thumbDataArray: ThumbData[] = order.map((threshold) => thresholdDataMap[threshold]);
    return thumbDataArray;
}

export const formatDateToDDMMYYYY = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JS
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};
// camalcase format 
export const formatCamelCase = (str: string) => {
    return str
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between camelCase words
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Handle cases like 'XMLHttpRequest'
        .replace(/([a-z])([0-9])/g, '$1 $2') // Handle digits
        .replace(/([0-9])([a-zA-Z])/g, '$1 $2') // Handle digits
        .replace(/^\w/, (c) => c.toUpperCase()); // Capitalize the first letter
};
