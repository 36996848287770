import { store } from '../store';
import { SpaceInfo } from '../store/SpacesStore';

export const CHROME_EXTENSION_ID = 'kdhkbpbefpefnbjchphilloecbkmohdm';
export const CHROME_EXTENSION_URL =
    'https://chromewebstore.google.com/detail/blueberry/kdhkbpbefpefnbjchphilloecbkmohdm';

export const handleLogin = (token: string) => {
    try {
        if (!chrome.runtime) {
            return;
        }

        if (!CHROME_EXTENSION_ID) {
            return;
        }
        chrome.runtime.sendMessage(CHROME_EXTENSION_ID, { action: 'login', data: token }, function (response) {
            // Handle the response from the extension if needed
            console.log('Message sent to extension:', response);
        });
    } catch (e) {
        console.log('error in handleLogin', e);
    }
};

export const handleLogout = () => {
    try {
        if (!chrome.runtime) {
            return;
        }

        if (!CHROME_EXTENSION_ID) {
            return;
        }
        chrome.runtime.sendMessage(CHROME_EXTENSION_ID, { action: 'logout' }, function (response) {
            // Handle the response from the extension if needed
            console.log('Message sent to extension:', response);
        });
    } catch (e) {
        console.log('error in handleLogout', e);
    }
};

export const handleRenewToken = (token: string) => {
    try {
        if (!chrome.runtime) {
            return;
        }

        if (!CHROME_EXTENSION_ID) {
            return;
        }
        chrome.runtime.sendMessage(CHROME_EXTENSION_ID, { action: 'renew-token', data: token }, function (response) {
            // Handle the response from the extension if needed
            console.log('Message sent to extension:', response);
        });
    } catch (e) {
        console.log('error in handleRenewToken', e);
    }
};

export const handleTokenExpiry = () => {
    if (!chrome.runtime) {
        return;
    }

    if (!CHROME_EXTENSION_ID) {
        return;
    }
    chrome.runtime.sendMessage(CHROME_EXTENSION_ID, { action: 'token-expired' }, function (response) {
        // Handle the response from the extension if needed
        console.log('Message sent to extension:', response);
    });
};

export const handleSignUp = (token: string) => {
    try {
        if (!chrome.runtime) {
            return;
        }

        if (!CHROME_EXTENSION_ID) {
            return;
        }
        chrome.runtime.sendMessage(CHROME_EXTENSION_ID, { action: 'signup', data: token }, function (response) {
            // Handle the response from the extension if needed
            console.log('Message sent to extension:', response);
        });
    } catch (e) {
        console.log('error in handleSignUp', e);
    }
};

export const handleMessage = (event: { data: { extensionInstalled: undefined } }) => {
    try {
        if (event.data && event.data.extensionInstalled !== undefined) {
            const isExtensionInstalled = event.data.extensionInstalled;
            if (isExtensionInstalled) {
                store.setExtensionStatus(true);
                localStorage.setItem('bberry-extension', 'true');
            } else {
                store.setExtensionStatus(false);
                localStorage.setItem('bberry-extension', 'false');
            }
        }
    } catch (e) {
        console.log('error in handleMessage', e);
    }
};

export const handleNewSpace = (space: SpaceInfo) => {
    try {
        const newSpace = {
            spaceId: space.spaceId,
            spaceName: space.spaceName,
            spaceIcon: space.spaceIcon,
            spaceColor: space.spaceColor,
        };
        window.postMessage({ type: 'NEW_SPACE_ADDED', space: newSpace }, '*');
    } catch (e) {
        console.log('error in handleNewSpace', e);
    }
};

export const handleSpaceUpdated = (space: SpaceInfo) => {
    try {
        const updatedSpace = {
            spaceId: space.spaceId,
            spaceName: space.spaceName,
            spaceIcon: space.spaceIcon,
            spaceColor: space.spaceColor,
        };
        window.postMessage({ type: 'SPACE_UPDATED', space: updatedSpace }, '*');
    } catch (e) {
        console.log('error in handleSpaceUpdated', e);
    }
};

export const handleSpaceDeleted = (spaceId: string) => {
    try {
        window.postMessage({ type: 'SPACE_DELETED', spaceId: spaceId }, '*');
    } catch (e) {
        console.log('error in handleSpaceDeleted', e);
    }
};

//new organisation is created
export const handleNewOrganisation = (spaceId: string) => {
    try {
        window.postMessage({ type: 'NEW_ORGANISATION_ADDED', spaceId: spaceId }, '*');
    } catch (e) {
        console.log('error in handleNewOrganisation', e);
    }
};

// when member is added to organisation
export const handleOrganisationInvite = (spaceId: string) => {
    try {
        window.postMessage({ type: 'ORGANISATION_INVITE', spaceId: spaceId }, '*');
    } catch (e) {
        console.log('error in handleOrganisationInvite', e);
    }
};

// when eva is created
export const handleEvaCreated = (spaceId: string) => {
    try {
        window.postMessage({ type: 'EVA_CREATED', spaceId: spaceId }, '*');
    } catch (e) {
        console.log('error in handleEvaCreated', e);
    }
};

//when eva is updated
export const handleEvaUpdated = (spaceId: string) => {
    try {
        window.postMessage({ type: 'EVA_UPDATED', spaceId: spaceId }, '*');
    } catch (e) {
        console.log('error in handleEvaUpdated', e);
    }
};

export const handleExtensionInstalled = () => {
    window.postMessage({ type: 'IS_EXTENSION_INSTALLED' }, '*');
    try {
        if (!chrome.runtime) {
            store.setExtensionStatus(false);
            localStorage.setItem('bberry-extension', 'false');
        }
    } catch (e) {
        console.log('error in handleExtensionInstalled', e);
        store.setExtensionStatus(false);
    }
};
