import { useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import { useParams } from 'react-router-dom';

import { ReactComponent as CalendarIcon } from '../../../../assets/icons/icon-one-to-one.svg';
import { useOrganisationForm } from '../../../../hooks/useOrganisation';
import { Transaction } from '../../../../store/OrganisationStore';
import { CardResponse } from '../../../../store/UserStore';
import { generateAndDownloadPdf } from '../../../../utils/generateAndDownloadPdf';
import { BillingSummary, Summary } from '../../../admin/billing/BillingSummary.component';
import { Button } from '../../../basic/Button.component';
import FormSelect from '../../../profile/Form/FormSelect';
import './datepicker.css';

const dateRangeList = ['Current Month', 'Last month', 'Last 12 month', 'Last 2 Years', 'Last 5 years', 'All', 'Custom'];

const OragnisationBillingSummary = ({ cards }: { cards?: CardResponse[] | null }) => {
    const [dateRange, setDateRange] = useState<string>('');
    const [loadingDownload, setLoadingDownload] = useState<boolean>();
    const [selectedDateFrom, setSelectedDateFrom] = useState<Date>(new Date());
    const [selectedDateTo, setSelectedDateTo] = useState<Date>(new Date());
    const [invoiceDate, setInvoiceDate] = useState<Date>(new Date());
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const { getInvoices } = useOrganisationForm();
    const { id: organisationId } = useParams<{ id: string }>();

    useEffect(() => {
        const fetchOrganisationInvoices = async () => {
            const data = await getInvoices(organisationId);
            if (data) {
                setTransactions(data);
            }
        };
        fetchOrganisationInvoices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisationId]);

    useEffect(() => {
        switch (dateRange) {
            case 'Current Month': {
                setSelectedDateFrom(new Date(invoiceDate.getFullYear(), invoiceDate.getMonth(), 1));
                setSelectedDateTo(invoiceDate);
                break;
            }
            case 'Last month': {
                setSelectedDateFrom(new Date(invoiceDate.getFullYear(), invoiceDate.getMonth() - 1, 1));
                setSelectedDateTo(new Date(invoiceDate.getFullYear(), invoiceDate.getMonth(), 0));
                break;
            }
            case 'Last 12 month': {
                setSelectedDateFrom(new Date(invoiceDate.getFullYear() - 1, invoiceDate.getMonth(), 1));
                setSelectedDateTo(new Date(invoiceDate.getFullYear(), invoiceDate.getMonth(), 0));
                break;
            }
            case 'Last 2 Years': {
                setSelectedDateFrom(new Date(invoiceDate.getFullYear() - 2, invoiceDate.getMonth(), 1));
                setSelectedDateTo(new Date(invoiceDate.getFullYear(), invoiceDate.getMonth(), 0));
                break;
            }
            case 'Last 5 years': {
                setSelectedDateFrom(new Date(invoiceDate.getFullYear() - 5, invoiceDate.getMonth(), 1));
                setSelectedDateTo(new Date(invoiceDate.getFullYear(), invoiceDate.getMonth(), 0));
                break;
            }
            case 'All': {
                // Determine the start date as the earliest date among all transactions
                const earliestDate = transactions.reduce((minDate, transaction) => {
                    const transactionDate = dayjs(transaction.date);
                    return transactionDate.isBefore(minDate) ? transactionDate : minDate;
                }, dayjs()); // Initialize with the current date

                setSelectedDateFrom(earliestDate.toDate());
                setSelectedDateTo(invoiceDate);
                break;
            }
        }
    }, [dateRange, invoiceDate, transactions]);

    const getSummary = (): Summary => {
        return {
            id: '#b_EVA-' + dayjs(invoiceDate).format('MMMYYYY'),
            date: invoiceDate,
            periodStartDate: selectedDateFrom,
            periodEndDate: selectedDateTo,
            transactions: transactions.filter(
                (transaction) =>
                    (dayjs(transaction.date).isAfter(selectedDateFrom) ||
                        dayjs(transaction.date).isSame(selectedDateFrom)) &&
                    (dayjs(transaction.date).isBefore(selectedDateTo) ||
                        dayjs(transaction.date).isSame(selectedDateTo)),
            ),
            //todo transaction payment method
            paymentMethod: {
                method: 'Credit Card',
                type: cards && cards[0] && cards[0].card.card ? cards[0].card.card.brand : 'Visa',
                cardNumber:
                    '**** **** **** ' + (cards && cards[0] && cards[0].card.card ? cards[0].card.card.last4 : '0000'),
            },
        };
    };

    const downloadSummary = async () => {
        setInvoiceDate(new Date());
        setLoadingDownload(true);
        await generateAndDownloadPdf(
            <BillingSummary summary={getSummary()} />,
            'Summary_' +
                dayjs(selectedDateFrom).format('DDMMMYYYY-') +
                dayjs(selectedDateTo).format('DDMMMYYYY') +
                '.pdf',
        );
        setLoadingDownload(false);
    };

    return (
        <div className='bg-white border border-blue-dark border-opacity-10 p-4 rounded-2xl mb-10 mt-10'>
            <p className='text-sm font-semibold mb-4 text-darker text-opacity-75'>Monthly Summary</p>
            <div className='w-full flex items-end justify-between'>
                <div className='flex items-center space-x-5'>
                    <FormSelect
                        label='Date Range'
                        entries={dateRangeList}
                        value={dateRange}
                        onChange={(e) => setDateRange(e)}
                        edit
                        placeholder='-Select-'
                        className='w-44'
                    />
                    {dateRange === 'Custom' && (
                        <div className='text-sm font-medium text-darker text-opacity-75 flex space-x-5'>
                            <div className='relative'>
                                <p className='mb-2'>From</p>
                                <DatePicker
                                    selected={selectedDateFrom}
                                    dateFormat='dd/MM/yyyy'
                                    maxDate={new Date()}
                                    onChange={(date) => setSelectedDateFrom(date as Date)}
                                    wrapperClassName='datepicker-wrapper-round'
                                    className='h-[38px] w-full rounded-full px-4 cursor-pointer'
                                />
                                <CalendarIcon
                                    className='absolute w-3 h-3 opacity-75 bottom-[14px] right-[14px]'
                                    fill='#0F1044'
                                />
                            </div>
                            <div className='relative'>
                                <p className='mb-2'>To</p>
                                <DatePicker
                                    selected={selectedDateTo}
                                    dateFormat='dd/MM/yyyy'
                                    minDate={selectedDateFrom}
                                    maxDate={new Date()}
                                    onChange={(date) => setSelectedDateTo(date as Date)}
                                    wrapperClassName='datepicker-wrapper-round'
                                    className='h-[38px] w-full rounded-full px-4 cursor-pointer'
                                />
                                <CalendarIcon
                                    className='absolute w-3 h-3 opacity-75 bottom-[14px] right-[14px]'
                                    fill='#0F1044'
                                />
                            </div>
                        </div>
                    )}
                </div>
                <Button
                    variant='outlinedBlue'
                    size='oval'
                    onClick={downloadSummary}
                    disabled={!dateRange || !selectedDateFrom || !selectedDateTo}
                    className={`w-24 flex items-center justify-center ${!dateRange ? 'opacity-50' : ''}`}
                >
                    {loadingDownload ? <CircularProgress size={16} sx={{ color: 'white' }} /> : 'Download'}
                </Button>
            </div>
        </div>
    );
};

export default OragnisationBillingSummary;
