interface AnimatedCircleProps {
    isThinking?: boolean;
    evaluateMode?: boolean;
}
export const AnimatedCircle: React.FC<AnimatedCircleProps> = ({ isThinking, evaluateMode }) => {
    return (
        <>
            {evaluateMode ? (
                <div className='evaluateMode_img'>
                    <div className={`${isThinking ? "evaluateMode_orbit thinking" : "evaluateMode_orbit" } `}  />
                </div>
            ) : (
                <div className={`${isThinking ? 'thinking' : ''} neon-circle idle  `} />
            )}
        </>
    );
};
