import * as React from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

interface SkeletonProps {
    background?: 'primary' | 'secondary' | 'white';
    height?: string
}

export const SkeletonLoading: React.FC<SkeletonProps> = ({ background, height }) => {
    const skeletonStyle: React.CSSProperties = {};

    // Apply color styles based on the provided color prop
    switch (background) {
        case 'primary':
            skeletonStyle.background = '#373bba';
            break;
        case 'secondary':
            skeletonStyle.background = '#5a44ff';
            break;
        case 'white':
            skeletonStyle.background = '#ffffff1a';
            break;
        default:
            skeletonStyle.background = '#ffffff1a';
    }

    // Apply height style if height prop is provided
    if (height) {
        skeletonStyle.height = height;
        skeletonStyle.transform = 'scale(1)'
    }

    return (
        <Box >
            <Skeleton variant='text' style={skeletonStyle} />
        </Box>
    )
}