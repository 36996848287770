import { SUBSCRIPTION_TYPE } from "../../../../utils/constants";

const OrganisationSubscription = ({
    type,
    pricingName,
    description,
    price,
    onClick,
    buttonName = 'Change Tier',
}: {
    type: string | undefined;
    pricingName: string | undefined;
    description: string | undefined;
    price: number;
    onClick: () => void;
    buttonName?: string;
}) => {
    return (
        <div className='bg-primary-darker bg-opacity-3 mt-10 px-6 py-4 rounded-2xl border border-blue-dark border-opacity-10'>
            <div className='flex justify-between items-center'>
                <div>
                    <p className='font-semibold text-sm capitalize text-darker text-opacity-75'>
                        {type === SUBSCRIPTION_TYPE.YEARLY ? 'Annual' : type} Tier{' '}
                        <span className='uppercase font-bold'>{pricingName}</span>
                    </p>
                    <p className='text-xs text-darker text-opacity-50'>{description}</p>
                </div>
                <div>
                    <span className='flex items-center space-x-1'>
                        <p className='font-semibold text-xl text-darker text-opacity-75'>
                            £{type === SUBSCRIPTION_TYPE.MONTHLY ? price : Math.round(price / 12)}
                        </p>
                        <p className='text-xxs text-darker text-opacity-50'>/org/mo</p>
                    </span>
                    {type === SUBSCRIPTION_TYPE.YEARLY && <p className='text-xxs text-darker text-opacity-35'>Annual Billing</p>}
                </div>
            </div>
            <button
                className='w-full mt-4 border p-2 rounded-full border-blue-dark border-opacity-10'
                onClick={onClick}
            >
                <p className='text-sm text-darker text-opacity-75 font-medium'>{buttonName}</p>
            </button>
        </div>
    );
};

export default OrganisationSubscription;
