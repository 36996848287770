import dayjs from 'dayjs';

import { ReactComponent as PopoutIcon } from '../../assets/blueberry/icon-popout.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/icon-cross-fill.svg';
import { useSession } from '../../hooks/useSession';
import { Session } from '../../store/SessionStore';
import { getDocumentUrl } from '../../utils/getDocumentUrl';
import HorizontalSeparator from '../basic/HorizontalSeparator';
import { protocolTypeIcon } from '../organisation/copilotOrganisation/eva/components/ProtocolTypeList';
import DomainIcon from './DomainIcon.component';

const SessionDomainCard = ({
    data,
    showDomainIcon = false,
    handleDeleteSession,
}: {
    data: Session[];
    showDomainIcon?: boolean;
    handleDeleteSession?: (domain: string, id: number) => void;
}) => {
    const { deleteSession } = useSession();
    return (
        <div className='w-full space-y-2'>
            <div className='space-y-4 text-darker text-opacity-75 text-sm'>
                <div
                    className='bg-white rounded-lg'
                    style={{
                        border: '1px solid rgba(90, 68, 255, 0.10)',
                    }}
                >
                    {data.map(
                        (item, index) =>
                            item.hint && (
                                <div key={index}>
                                    <div className='group space-y-2 p-4'>
                                        <div className='relative flex space-x-4'>
                                            <div className='w-full text-sm text-darker text-opacity-50'>
                                                <div className='flex text-darker text-opacity-75 font-medium justify-between'>
                                                    <div className='underline-custom'>{item.tabTitle}</div>
                                                    <div className='text-xs flex justify-end space-x-2 text-darker text-opacity-50 mr-5'>
                                                        <p className='whitespace-nowrap'>
                                                            {dayjs(item.date).format('DD/MM/YY')}
                                                        </p>
                                                        <p className='whitespace-nowrap'>
                                                            {dayjs(item.date).format('h:mm A')}
                                                        </p>
                                                        <div
                                                            className='mt-0.5 cursor-pointer'
                                                            onClick={() => window.open(item.sessionUrl, '_blank')}
                                                        >
                                                            <PopoutIcon className='w-2.5 h-2.5' />
                                                        </div>
                                                    </div>
                                                </div>
                                                {item.hint && (
                                                    <div className='flex items-center space-x-2 mt-2'>
                                                        {item.protocolType && (
                                                            <img
                                                                src={protocolTypeIcon(item.protocolType)}
                                                                className='w-6 h-6'
                                                                alt='hint'
                                                            />
                                                        )}
                                                        <div className='nsa-wrapper'>
                                                            <div className='flex nsa-container space-x-2'>
                                                                <div className='flex w-4 h-4 items-center justify-center border border-blue-dark border-opacity-10 rounded-sm bg-primary-darker bg-opacity-5'>
                                                                    {item.spaceLogo && item.spaceLogo != '' ? (
                                                                        <img
                                                                            src={getDocumentUrl(+item.spaceLogo)}
                                                                            className='w-3 h-3'
                                                                            alt='logo'
                                                                        />
                                                                    ) : (
                                                                        <p className='text-xxs'>logo</p>
                                                                    )}
                                                                </div>
                                                                <p>{item.hint}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {handleDeleteSession && (
                                                <div
                                                    className='right-0 absolute group-hover:opacity-100 opacity-0 cursor-pointer mt-0.5'
                                                    onClick={() => {
                                                        deleteSession(item.id);
                                                        handleDeleteSession(item.domain, item.id);
                                                    }}
                                                >
                                                    <CrossIcon fill='rgba(15, 16, 68, 0.5)' className='w-2.5 h-2.5' />
                                                </div>
                                            )}
                                        </div>
                                        <div className='flex justify-end'>
                                            {showDomainIcon && (
                                                <DomainIcon domain={item.domain} size={4} className='w-6 h-6' />
                                            )}
                                        </div>
                                    </div>
                                    {index < data.length - 1 && <HorizontalSeparator />}
                                </div>
                            ),
                    )}
                </div>
            </div>
        </div>
    );
};

export default SessionDomainCard;
