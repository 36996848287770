import { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { observer } from 'mobx-react-lite';

import { ReactComponent as BackIcon } from '../../assets/icons/icon-arrow-back.svg';
import { useUserInformationsForm } from '../../hooks/useUserInformations';
import { useStore } from '../../store';
import { isEmail } from '../../utils/validation';
import AccountPicture from '../basic/AccountPicture.component';
import { Button } from '../basic/Button.component';
import HorizontalSeparator from '../basic/HorizontalSeparator';
import { IconAlert } from '../basic/IconAlert.component';
import FormInput from '../profile/Form/FormInput';
import UpdatePassword from '../settings/UpdatePassword';

export const useStyles = makeStyles({
    emailCloseIcon: {
        fontSize: '14px',
        color: 'rgba(15, 16, 68, 0.35)',
        cursor: 'pointer',
    },
});

const AccountForm = () => {
    const [showNewComponent, setShowNewComponent] = useState(false);
    const [emailCount, setEmailCount] = useState(0);
    const [userEmail, setUserEmail] = useState<string[]>([]);
    const store = useStore();
    const classes = useStyles();
    const {
        user: { userData },
    } = store;
    const { fields, isLoading, setValues, deleteUserEmail, createOrUpdateUserEmail, error, setError, success } =
        useUserInformationsForm(userData);

    const handleUpdateClick = () => {
        setShowNewComponent(true);
    };

    const handleAddEmail = () => {
        if (emailCount < 3) {
            setEmailCount(emailCount + 1);
        }
    };

    const handleRemoveEmail = (index: number) => async () => {
        setEmailCount(emailCount - 1);

        const updatedUserEmails = [...userEmail];
        const deletedEmail = updatedUserEmails.splice(index, 1);
        setUserEmail(updatedUserEmails);

        const emailToDelete = userData?.userEmail.find((email) => email.email === deletedEmail[0]);

        if (emailToDelete) {
            await deleteUserEmail(emailToDelete.email);
        }
    };

    const handleUserEmailError = (index: number) => {
        const values = Object.values(userEmail);
        const uniqueValues = new Set(values);
        if (userEmail[index] === userData?.email) return true;
        if ((userEmail[index] !== undefined) && isEmail(userEmail[index])) {
            return true;
        }
        if (values.length !== uniqueValues.size) return true;
        return false;
    };

    useEffect(() => {
        if (userData?.firstName && userData?.lastName && userData?.email) {
            setValues((oldValues) => {
                return {
                    ...oldValues,
                    lastName: userData?.lastName,
                    firstName: userData?.firstName,
                    email: userData.email,
                };
            });
        }
        if (userData?.userEmail) {
            const emails = userData.userEmail.map((email) => {
                return email.email;
            });
            setUserEmail(emails);
            setEmailCount(userData.userEmail.length);
        }
    }, [setValues, userData]);

    return (
        <div className='w-full h-full flex justify-center items-start '>
            <div className='mx-auto max-w-5xl w-full mt-10 md:mt-0'>
                {showNewComponent ? (
                    <div className='relative w-full'>
                        <button
                            className='w-full flex justify-end items-center space-x-2 text-darker opacity-75 text-sm font-normal'
                            onClick={() => setShowNewComponent(false)}
                        >
                            <BackIcon className='w-4 h-4' fill='#0F1044' />
                            <p>Back</p>
                        </button>
                        <div className='mt-10'>
                            <UpdatePassword hide={setShowNewComponent} />
                        </div>
                    </div>
                ) : (
                    <div className='w-full'>
                        <div className='w-full flex items-center justify-center'>
                            <AccountPicture />
                        </div>
                        <div className='mx-auto w-full mt-0 md:mt-10 space-y-6 md:px-0 px-4'>
                            <div className='grid grid-rows-2 grid-cols-1 gap-2 md:grid-rows-1 md:grid-cols-2'>
                                <FormInput
                                    {...fields?.firstName}
                                    placeholder='First Name'
                                    edit={true}
                                    className='w-full rounded-full'
                                    label='First Name'
                                />
                                <FormInput
                                    {...fields?.lastName}
                                    placeholder='Last Name'
                                    edit={true}
                                    className='w-full rounded-full'
                                    label='Last Name'
                                />
                            </div>
                            <div className='grid grid-rows-1 grid-cols-1 gap-2 md:grid-rows-1 md:grid-cols-2'>
                                <FormInput
                                    {...fields?.email}
                                    placeholder='email@gmail.com'
                                    edit={true}
                                    className='w-full rounded-full'
                                    label='Email'
                                    disabled
                                />
                                {[...Array(emailCount)].map((_, index) => (
                                    <div className='w-full flex items-center justify-between space-x-2' key={index}>
                                        <div className='w-full'>
                                            <FormInput
                                                key={index}
                                                placeholder='email@gmail.com'
                                                edit={true}
                                                className='w-full rounded-full'
                                                label='Email'
                                                value={userEmail[index]}
                                                onChange={(e) => {
                                                    setError(null);
                                                    const updatedEmails = [...userEmail];
                                                    updatedEmails[index] = e.toLowerCase();
                                                    setUserEmail(updatedEmails);
                                                }}
                                                error={handleUserEmailError(index)}
                                            />
                                        </div>
                                        <button className='mt-5' onClick={handleRemoveEmail(index)}>
                                            <CloseOutlinedIcon className={classes.emailCloseIcon} />
                                        </button>
                                    </div>
                                ))}
                            </div>
                            <button
                                className='flex space-x-2 items-center disabled:opacity-50 disabled:cursor-not-allowed'
                                onClick={handleAddEmail}
                                disabled={emailCount === 3}
                            >
                                <div className='flex items-center bg-bberry-blue w-3 h-3 rounded-full justify-center'>
                                    <p className='text-white text-sm font-medium'>+</p>
                                </div>
                                <p className='text-sm text-bberry-blue'>Add email</p>
                            </button>
                            <HorizontalSeparator />
                            <div className='space-x-4 grid grid-rows-1 grid-cols-1 gap-2 md:grid-rows-1 md:grid-cols-2'>
                                <div className='flex items-center text-center'>
                                    <p
                                        className='font-medium text-bberry-blue text-sm cursor-pointer'
                                        onClick={handleUpdateClick}
                                    >
                                        Change password
                                    </p>
                                </div>
                            </div>
                            {success && <IconAlert message={success} type='success' />}
                            {error && <IconAlert message={error} />}
                            <div className='w-full'>
                                <Button
                                    className='w-full mt-4 py-5 h-10'
                                    variant='bberryBlue'
                                    size='oval'
                                    disabled={isLoading||fields?.firstName?.value==''||fields?.lastName?.value==''||(userData?.firstName==fields?.firstName?.value&&fields?.lastName?.value==userData?.lastName&&userEmail.length==0)}
                                    onClick={async () => {
                                        setError(null);
                                        const values = Object.values(userEmail);
                                        const uniqueValues = new Set(values);
                                        if (values.length !== uniqueValues.size) {
                                            setError('Emails must be unique');
                                            return;
                                        }
                                        if (userEmail.some((email) => isEmail(email))) {
                                            setError('Emails must be valid');
                                            return;
                                        }
                                        await createOrUpdateUserEmail(Object.values(userEmail));
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                            {userData?.userEmail && userData.userEmail.some((email) => !email.verified) && (
                                <div className='flex justify-center'>
                                    <p className='text-sm italic text-darker text-opacity-75'>
                                        Verify your email address
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default observer(AccountForm);
