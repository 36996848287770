import { makeAutoObservable } from 'mobx';

export type GetStartedValues = {
    firstName?: string;
    lastName?: string;
    email?: string;
    publicAccess?: string;
    country?: string;
    password?: string;
    confirmPassword?: string;
} | null;

export class SignUpStore {
    getStartedValues: GetStartedValues = null;
    password: string | null = null;
    reSendConfirmationEmail = false;
    confirmationEmail: string | null = null;
    constructor() {
        makeAutoObservable(this);
    }
    updateGetStartedValues = (newValues: GetStartedValues): void => {
        this.getStartedValues = newValues;
    };
    updatePassword = (newPassword: string | null): void => {
        this.password = newPassword;
    };
    updateReSendConfirmationEmail = (bool: boolean): void => {
        this.reSendConfirmationEmail = bool;
    };
    updateConfirmationEmail = (newEmail: string | null): void => {
        this.confirmationEmail = newEmail;
    };
}
