import { appInsights } from '../AppInsights';
import { Services } from '../components/ServiceProvider';
import { Store } from '../store/Store';
import { handleExtensionInstalled } from '../utils/chromeExtension';

export const initUserSession = async (services: Services, store: Store) => {
    try {
        const events = await services.stripeWebhook.getEvents();
        const userData = await services.auth.getProfile();
        const myDocuments = await services.documents.getAll();
        const currentSubscription = await services.payments.getCurrentSubscription();
        store.user.updateUserData(userData);
        store.documents.init(myDocuments);
        store.organisation.setStripeEvents(events);
        if (currentSubscription) store.subscriptions.setAickoCurrentSubscription(currentSubscription);
        services.organisation.connect();
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        console.log('cannot initialize session');
        if (e instanceof Error) {
            appInsights.trackException({ exception: e });
        }
    } finally {
        store.setInitiated(true);
        handleExtensionInstalled();
    }
};
