export type Validator = (value: string, values: Record<string, string>) => string | undefined;
export const required = (value: string) => (value ? undefined : ('This field is required' as string));
export const isSelected = (value: string) => (value === 'Select' ? ('An item must be selected' as string) : undefined);
export const isEmail = (value: string) => (!isEmailValid(value) ? ('Invalid email' as string) : undefined);
export const isUrl = (value: string) => (!isValidUrl(value) ? ('Invalid URL' as string) : undefined);
export const isIban = (value: string) => (!isValidIban(value) ? ('Invalid IBAN' as string) : undefined);
export const isNumber = (value: string) => (!value.match(/^-?\d*\.?\d*$/) ? 'Must be a valid number' : undefined);
export const isPhoneNumber = (value: string) =>
    !value.match(/^[0-9]{10}$/) ? 'Must be a valid phone number' : undefined;
export const isValidPassword = (value: string) =>
    value.length < 8
        ? 'Password must be at least 8 characters long'
        : !/(?:(?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(value)
        ? 'Password must be 8 characters long, have an uppercase, a lowercase, a number and a special character'
        : value.length > 40
        ? 'Password cannot be longer than 40 characters'
        : undefined;
export const confirmPassword = (value: string, values: Record<string, string>) =>
    value !== values.password ? "Passwords don't match" : undefined;
export const and = (...validators: Validator[]): Validator => {
    return (value: string, values: Record<string, string>) =>
        validators.reduce((error, validator) => error || validator(value, values), undefined as string | undefined);
};

function isEmailValid(email: string) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const isValidUrl = (url: string) => {
    if (url === '') return true;
    const re =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    return re.test(url);
};

export const isValidIban = (iban: string) => {
    const re = /[a-zA-Z0-9]{2}\s?([a-zA-Z]{4}\s?){1}([0-9]{4}\s?){3}([0-9]{2})\s?/;

    return re.test(iban);
};

export const isPasswordMatch = (password: string, confirmPassword: string) =>
    password !== confirmPassword ? "Passwords don't match" : undefined;

export const isFileValidation = (file: File, size: number) => {
    if (!file.type.startsWith('image/')) return 'Invalid file type. Please select an image file.';
    if (file.size > size)
        return `File size is too large, please upload a file smaller than ${Math.floor(size / 1000000)}MB`;
    return null;
};

export const isPasswordContainsSpace = (value: string) =>
    value && !value.includes(' ') ? undefined : 'Password cannot contain spaces';
