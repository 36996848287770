import clsx from 'clsx';

import copyIcon from '../../assets/icons/icon-copy.svg';

const PublicAccess = ({ className, url }: { className?: string; url?: string }) => {
    return (
        <div>
            <div className='flex items-start md:items-center lg:items-center flex-col lg:flex-row md:flex-row'>
                <div className='flex-row flex items-center'>
                    <p className={clsx('text-xs mr-2', className)}>
                        <span className='opacity-50'>app.blankstate.ai/</span>
                        {url && url}
                    </p>
                    <button
                        className='tooltip'
                        onMouseOut={() => {
                            const tooltip = document.getElementById('myTooltip');
                            if (tooltip) tooltip.innerHTML = 'Copy to clipboard';
                        }}
                    >
                        <span className='tooltiptext' id='myTooltip'>
                            Copy to clipboard
                        </span>
                        <img
                            src={copyIcon}
                            className='w-3 cursor-pointer'
                            onClick={() => {
                                navigator.clipboard.writeText(`app.blankstate.ai/${url && url}`);
                                const tooltip = document.getElementById('myTooltip');
                                if (tooltip) tooltip.innerHTML = 'Copied';
                            }}
                            alt=''
                        />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PublicAccess;
