import { EventEmitter } from 'events';

import { sleep } from '../utils/sleep';

export interface NetworkMock {
    fetch(): Promise<void>;
}

export class LatencyNetworkMock implements NetworkMock {
    constructor(public latency = 350) {}
    async fetch() {
        await sleep(this.latency);
    }
}

export class EventNetworkMock implements NetworkMock {
    private events: EventEmitter;
    constructor() {
        this.events = new EventEmitter();
    }
    fetch() {
        return new Promise<void>((res, rej) => {
            this.events.once('response', (success) => (success ? res() : rej()));
        });
    }
    resolveAll() {
        this.events.emit('response', true);
        return new Promise<void>((res) => setImmediate(res));
    }
    rejectAll() {
        this.events.emit('response', false);
        return new Promise<void>((res) => setImmediate(res));
    }
    clear() {
        this.events.removeAllListeners('response');
    }
}
