import { ReactComponent as CheckIcon } from '../../../assets/icons/icon-check-filled.svg';
import { TierFeature } from './TierTable';

export const organisationTier: TierFeature[] = [
    {
        title: 'EVA AI',
        tooltip: 'Go in depth and interact naturally with EVA.',
        children: [
            {
                title: 'Interactive Updates',
                tooltip:
                    'Stay up-to-date with the latest insight. EVA will update you on trends and help you improve oversight monitoring base don your priorities.',
                sheild: null,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
            {
                title: 'Gen Blueprint',
                tooltip: 'Let EVA Generate a Blueprint based on a few instructions',
                sheild: null,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
        ],
    },
    {
        title: 'Blueprints',
        tooltip:
            'Define and customize your organization`s policies and protocols with Blueprints. Set nuanced rules, thresholds, and guidelines to align interactions with your unique requirements.',
        children: [
            {
                title: 'Protocols',
                tooltip:
                    'Specify the number of protocols or rules within each Blueprint to ensure comprehensive coverage of your compliance and ethical standards.',
                sheild: '10',
                oversight: '40',
            },
            {
                title: 'Templates',
                tooltip:
                    'Access pre-built Blueprints for common compliance areas, or use them as a starting point to create customized Protocols tailored to your Organization.',
                sheild: '4',
                oversight: 'Full List',
            },
            {
                title: 'Collaborate',
                tooltip:
                    'Manage multiple Blueprints to address different areas of your organization and different priority timeframes.',
                sheild: '1',
                oversight: 'Unlimited',
            },
            {
                title: 'Test mode',
                tooltip:
                    'Test and simulate your Blueprints in a safe environment before deploying them globally, ensuring accuracy and identifying potential issues.',
                sheild: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
            {
                title: 'Snapshots',
                tooltip:
                    'Capture snapshots of your Blueprints at specific points in time, enabling version control and allowing you to revert to previous configurations if needed and match logs occurrences.',
                sheild: null,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
        ],
    },
    {
        title: 'Analytics',
        tooltip:
            'Gain valuable insights into potential flags, breaches, risk areas, and anonymised employee interactions through comprehensive analytics and reporting.',
        children: [
            {
                title: 'Engagement',
                tooltip:
                    'Monitor and analyse employee engagement levels with your organization`s policies and compliance initiatives.',
                sheild: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
            {
                title: 'Trends',
                tooltip: 'Visualise Log trends over time.',
                sheild: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
            {
                title: 'Flags',
                tooltip:
                    'Receive real-time notifications or "flags" when EVA detects potential issues or deviations from your defined policies, enabling proactive intervention.',
                sheild: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
            {
                title: 'Triggers',
                tooltip: 'Making sure to differentiate what was written, and what was sent.',
                sheild: null,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
            {
                title: 'Export Logs',
                tooltip:
                    'Export detailed logs and analytics in CSV, for further analysis, auditing, or integration with other systems.',
                sheild: null,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
            {
                title: 'NAVI',
                tooltip:
                    'Track and monitor generalised web browsing activities and domain access, helping you identify potential risks, security threats, or policy violations related to internet usage.',
                sheild: null,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
            // {
            //     title: 'Customizable Alerting and Escalation',
            //     tooltip: 'Go in depth and interact naturally with EVA.',
            //     sheild: null,
            //     oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            // },
        ],
    },
    {
        title: 'Admin',
        tooltip:
            'Grant specialized administrative roles with elevated permissions for managing and overseeing the entire oversight system, ensuring proper governance and stewardship.',
        children: [
            {
                title: 'Treasurer, Architect & Stewardship',
                tooltip:
                    'Grant specialized administrative roles with elevated permissions for managing and overseeing the entire oversight system, ensuring proper governance and stewardship.',
                sheild: null,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
        ],
    },
];
