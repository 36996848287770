import Cross from '../../assets/icons/WhiteCross.svg';

export type AlertProps = {
    email: string;
    onClick: () => void;
};

export const Alert = ({ email, onClick }: AlertProps) => {
    return (
        <div className='w-screen  text-sm text-secondary bg-warning-100 flex py-6 top-0 items-center justify-center ml-16'>
            <p className='w-screen flex justify-center items-center text-center'>
                We have resent the confirmation to
                <span className='font-semibold'>&nbsp; {email}</span>. Please check your inbox.
            </p>
            <a onClick={onClick} href='#'>
                <img src={Cross} className='mr-16' />
            </a>
        </div>
    );
};
