import React, { createContext, useContext, useEffect, useState } from 'react';

interface CreateEvaModalContext {
    isCreateEvaModalOpen: boolean;
    setCreateEvaModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setArchiveEvaId: React.Dispatch<React.SetStateAction<string | null>>
    archiveEvaId: string | null
}

const CreateEvaModalContext = createContext<CreateEvaModalContext | undefined>(undefined);

export const CreateEvaModalContextProvider: React.FC = ({ children }) => {
    const [isCreateEvaModalOpen, setCreateEvaModalOpen] = useState<boolean>(() => {
        const item = window.localStorage.getItem('checkOpenModal');
        return item ? JSON.parse(item) : false;
    });

    const [archiveEvaId, setArchiveEvaId] = useState<string | null>(() => {
        const item = window.localStorage.getItem('archiveEvaId');
        return item ? JSON.parse(item) : null;
    });

    useEffect(() => {
        // Save to local storage whenever isCreateEvaModalOpen changes
        window.localStorage.setItem('checkOpenModal', JSON.stringify(isCreateEvaModalOpen));
        window.localStorage.setItem('archiveEvaId', JSON.stringify(archiveEvaId));

        if (!isCreateEvaModalOpen) {
            window.localStorage.setItem('archiveEvaId', JSON.stringify(null));

        }

    }, [isCreateEvaModalOpen, archiveEvaId]);

    return (
        <CreateEvaModalContext.Provider
            value={{ isCreateEvaModalOpen, setCreateEvaModalOpen, setArchiveEvaId, archiveEvaId }}
        >
            {children}
        </CreateEvaModalContext.Provider>
    );
};

export const CreateEvaModalOpen = (): CreateEvaModalContext => {
    const context = useContext(CreateEvaModalContext);
    if (!context) {
        throw new Error(' CreateEvaModalOpen must be used within an CreateEvaModalContextProvider');
    }
    return context;
};
