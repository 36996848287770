import React from 'react';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { ReactComponent as MenuIcon } from '../../../assets/icons/icon-menu-nav.svg';
import blankstate from '../../../assets/logo/logo_b_dark.png';
import { useStore } from '../../../store';

const linkStyle = 'flex flex-col items-center space-y-2';
const fontStyle = 'font-medium text-[6px]';
const fontColor = 'text-darker text-opacity-35';
const iconSize = 'h-5 w-5';
const iconFill = '#0F104459';

const MobileNavBar: React.FC = () => {
    const store = useStore();

    const handleClick = () => {
        store.setShowMobileMenu(true);
    };

    return (
        <nav
            className={clsx(
                'flex items-center justify-between',
                'h-16 py-2 px-7 overflow-hidden w-full',
                'fixed bottom-0 z-20',
                'bg-white shadow-mobile-nav',
            )}
        >
            <div className='flex items-center space-x-4 cursor-pointer'>
                <div className='bg-white p-2 border rounded-lg border-blue-light border-opacity-10'>
                    <img src={blankstate} alt='logo' className='w-3 h-3' />
                </div>
                <div className='text-sm capitalize text-darker text-opacity-75 whitespace-nowrap truncate'>Home</div>
            </div>
            <div className={linkStyle} onClick={() => handleClick()}>
                <MenuIcon className={iconSize} fill={iconFill} />
                <p className={clsx(fontStyle, fontColor)}>Menu</p>
            </div>
        </nav>
    );
};

export default observer(MobileNavBar);
