import { useCallback, useEffect, useRef, useState } from 'react';

import { noop } from 'lodash';

import { ReactComponent as OpenDoorIcon } from '../../../../../../src/assets/icons/DoorOpen.svg';
import { ReactComponent as PlusBtn } from '../../../../../assets/icons/EVA_PlusBtn.svg';
import { ReactComponent as PlusBtnHover } from '../../../../../assets/icons/EVA_PlusBtnhover.svg';
import { MetaMarkerItem } from '../types';
import { OptionData } from './ProtocolTypeList';

interface ProtocolListProps {
    metaMarkerData: MetaMarkerItem[];
    setFilteredItems: React.Dispatch<React.SetStateAction<MetaMarkerItem[]>>;
    filteredItems: MetaMarkerItem[];
    setMarkerIndex?: React.Dispatch<React.SetStateAction<number | null>>;
    setProtocolIndex?: React.Dispatch<React.SetStateAction<number | null>>;
    handleDlClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const ProtocolList: React.FC<ProtocolListProps> = ({
    metaMarkerData,
    setFilteredItems,
    filteredItems,
    setMarkerIndex,
    handleDlClick,
    setProtocolIndex,
}) => {
    const [searchVal, setSearchVal] = useState<string>('');
    const [activeProtocol, setActiveProtocol] = useState<number[]>([]);
    const [isHoveringLastProtocol, setIsHoveringLastProtocol] = useState(false);

    const [toggle, setToggle] = useState<boolean>(false);
    const [hoverPlus, setHoverPlus] = useState(false);
    const [searchValDraft, setSearchValDraft] = useState<string>('');
    const filterData = () => {
        if (setMarkerIndex) {
            const filtered = metaMarkerData.filter((item) =>
                item?.metaMarker.toLowerCase().includes(searchValDraft.toLowerCase()),
            );
            setFilteredItems(filtered);
        } else {
            const filtered = metaMarkerData.filter((item) =>
                item?.metaMarker.toLowerCase().includes(searchVal.toLowerCase()),
            );
            setFilteredItems(filtered);
        }
    };
    useEffect(() => {
        filterData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchVal, searchValDraft, metaMarkerData]);

    const searchChange = (value: string) => {
        if (setMarkerIndex) {
            setSearchValDraft(value);
        } else {
            setSearchVal(value);
        }
    };

    const containerRef = useRef<HTMLDivElement | null>(null);
    const [isBottom, setIsBottom] = useState(false);

    useEffect(() => {
        const updateHeightAndScroll = () => {
            if (containerRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
                // Add or remove the class based on height comparison
                if (scrollHeight > clientHeight) {
                    setIsBottom(true);
                } else {
                    setIsBottom(false);
                }
                // Handle scroll to bottom detection
                if (scrollTop + clientHeight >= scrollHeight - 5) {
                    setIsBottom(true);
                } else {
                    setIsBottom(false);
                }
            }
        };
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', updateHeightAndScroll);
            updateHeightAndScroll(); // Check on mount if it is scrollable
        }
        return () => {
            if (container) {
                container.removeEventListener('scroll', updateHeightAndScroll);
            }
        };
    }, [filteredItems, searchValDraft]);

    const toggleProtocol = useCallback(
        (index: number) => {
            setActiveProtocol((prev) => {
                return prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index];
            });
        },
        [setActiveProtocol],
    );
    return (
        <>
            {metaMarkerData.length > 0 && (
                <div className={`${toggle ? 'show_protocol_list' : ''} main_protocol_group`}>
                    <div className='flex  top_protocol'>
                        <div className=' open_btn flex items-center justify-center' onClick={() => setToggle(!toggle)}>
                            <OpenDoorIcon />
                        </div>
                        <div
                            className={`${
                                searchVal.length > 0 || searchValDraft.length > 0 ? 'search_val' : ''
                            } protocol_input_box ml-3`}
                        >
                            <input
                                placeholder='Search'
                                type='text'
                                value={setMarkerIndex ? searchValDraft : searchVal}
                                onChange={(e) => searchChange(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='protocol_box  ' ref={containerRef}>
                        {filteredItems?.length > 0 ? (
                            <>
                                {filteredItems?.map((data: MetaMarkerItem, index: number) => {
                                    const isActive = activeProtocol.includes(index);

                                    const metaMarkersData = metaMarkerData.map(
                                        (item: MetaMarkerItem, index: number) => {
                                            return {
                                                ...item,
                                                indexNum: index, // Add the indexNum key here
                                            };
                                        },
                                    );
                                    const getIndexNum = metaMarkersData.find(
                                        (item, index) => item.metaMarker === data.metaMarker,
                                    )?.indexNum;

                                    return (
                                        <>
                                            <div
                                                onMouseEnter={() => setIsHoveringLastProtocol(true)}
                                                className={
                                                    index === filteredItems.length - 1 &&
                                                    !isHoveringLastProtocol &&
                                                    !isBottom
                                                        ? 'protocol_last_type'
                                                        : 'protocol_lists'
                                                }
                                            />
                                            <div
                                                className={`main_protocol_content ${isActive ? 'active_protocol' : ''}`}
                                                onClick={(e) => {
                                                    if (!isHoveringLastProtocol && typeof getIndexNum === 'number') {
                                                        toggleProtocol(getIndexNum);
                                                    }
                                                }}
                                                onMouseEnter={() =>
                                                    typeof getIndexNum === 'number' &&
                                                    setProtocolIndex &&
                                                    setProtocolIndex(getIndexNum)
                                                }
                                                onMouseLeave={() => {
                                                    if (setProtocolIndex) {
                                                        setProtocolIndex(null);
                                                    }
                                                    setIsHoveringLastProtocol(false);
                                                }}
                                            >
                                                <div
                                                    className='content'
                                                    onClick={(e) => {
                                                        if (isHoveringLastProtocol) {
                                                            noop();
                                                        } else {
                                                            if (setMarkerIndex) {
                                                                setMarkerIndex(data?.indexNum);
                                                            }
                                                            if (handleDlClick) {
                                                                handleDlClick(e);
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <span
                                                        className='side_bg'
                                                        style={{ backgroundColor: `${data?.color}` }}
                                                    />

                                                    <img
                                                        src={
                                                            OptionData.find((obj) => obj.name === data.protocolType)
                                                                ?.icon
                                                        }
                                                        alt='winnerCup'
                                                    />
                                                    <p>{data?.metaMarker}</p>
                                                </div>
                                                <div className='protocol_count relative'>
                                                    <div className='flex items-center justify-center'>
                                                        <span>{data?.triggerCount ?? 0}</span>
                                                        <div className='tooltip_box items-center justify-center'>
                                                            <p> Triggers</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                            </>
                        ) : (
                            <p className='no_data '> No protocol found </p>
                        )}
                    </div>
                    {setMarkerIndex && (
                        <div
                            className='eva_plus_btn cursor-pointer'
                            onMouseEnter={() => setHoverPlus(true)}
                            onMouseLeave={() => setHoverPlus(false)}
                            onClick={(e) => {
                                if (handleDlClick) {
                                    handleDlClick(e);
                                }
                            }}
                        >
                            {hoverPlus ? <PlusBtnHover /> : <PlusBtn />}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default ProtocolList;
