import { Dispatch, SetStateAction, useState } from 'react';

import { mapObject } from './mapObject';

export type FormField = {
    initialValue?: string;
    mapValue?: (value: string, oldValue: string) => string;
    validation?: (value: string, values: Record<string, string>) => string | undefined;
};

export type FormFieldProps = {
    value: string;
    onChange: (value: string | undefined) => void;
    onBlur: () => void;
    error: boolean;
    errorMessage?: string;
    label?: string;
};

export type FormParams<T> = {
    fields: T;
};

export type FormController<T> = {
    fields: Record<keyof T, FormFieldProps>;
    isValid: () => boolean;
    setAllVisited: () => void;
    visited: Record<string, boolean>;
    setValue: (name: keyof T, value: string) => void;
    setValues: Dispatch<SetStateAction<Record<keyof T, string>>>;
    values: Record<keyof T, string>;
};

export function useForm<T extends Record<string, FormField>>(params: FormParams<T>): FormController<T> {
    const [allVisited, setAllVisited] = useState(false);
    const [visited, setVisited] = useState<Record<string, boolean>>({});

    const [values, setValues] = useState<Record<keyof T, string>>(() =>
        mapObject(params.fields, (key, value) => value.initialValue || ''),
    );

    const fields = mapObject(params.fields, (fieldName, field) => {
        const error = field.validation?.(values[fieldName], values);
        return {
            value: values[fieldName],
            onChange: (value: string | undefined) => {
                if (value) {
                    setValues((values) => ({
                        ...values,
                        [fieldName]: field.mapValue ? field.mapValue(value, values[fieldName]) : value,
                    }));
                } else {
                    setValues((values) => ({
                        ...values,
                        [fieldName]: field.mapValue ? field.mapValue('', values[fieldName]) : '',
                    }));
                }
            },
            onBlur: () => setVisited({ ...visited, [fieldName]: true }),
            error: allVisited || visited[fieldName as string] ? !!error : false,
            errorMessage: allVisited || visited[fieldName as string] ? error : undefined,
        };
    });

    const setValue = (name: keyof T, value: string) => {
        setValues((values) => ({
            ...values,
            [name]: value,
        }));
        setVisited({ ...visited, [name]: true });
    };

    const isValid = () => {
        for (const key in params.fields) {
            if (params.fields[key].validation?.(values[key], values)) return false;
        }
        return true;
    };

    return { fields, isValid, visited, setValues, setValue, setAllVisited: () => setAllVisited(true), values };
}
