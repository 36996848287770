import { ComponentProps } from 'react';

import clsx from 'clsx';

export const buttonVariants = {
    primaryPurple:
        'bg-primary border-primary text-white hover:bg-primary hover:bg-primary-300 active:bg-primary-dark disabled:bg-primary-lightest',
    primaryDark:
        'bg-secondary border-secondary text-white hover:bg-secondary-light active:bg-secondary disabled:bg-secondary-lightest',
    secondaryPurple:
        'bg-white border-primary text-purple-dark border border-purple-dark hover:bg-primary hover:text-white active:bg-primary-dark disabled:text-primary-lightest disabled:border-primary-lightest',
    rectangleWithIcon: 'bg-neutral-50 disable:opacity-50 rounded-sm border-none',
    rejectRed: 'text-white bg-error-900 border-error-900',
    success: 'bg-success-900 border-success-900',
    secondaryRed: 'bg-white text-error border-error-900 hover:bg-error-100',
    transparent: 'border-none hover:shadow-none hover:text-primaryLight',
    outlinedBlack: 'text-black border-secondary bg-white',
    outlinedWarning: 'text-orange border-red bg-white',
    outlined: 'text-bberry-blue border-berry-blue px-8 py-2',
    outlinedRounded: 'text-purple border-primary-light px-8 py-2 rounded-rounded-b',
    outlinedPurple: 'text-purple hover:text-white border-primary hover:bg-primary-300 px-8 py-3',
    outlinedBlue: 'text-blue-dark hover:text-white border-blue hover:bg-blue-100',
    outlinedDark: 'text-darker opacity-75 hover:opacity-100 border-blue-dark',
    primaryBlue: 'bg-blue border-primary text-white hover:bg-blue-100',
    disabledPurple:
        'bg-white border-purple text-disabled-purple border border-purple-dark hover:bg-primary hover:text-white active:bg-primary-dark disabled:text-primary-lightest disabled:border-primary-lightest',
    outlinedDisabled: 'text-black border-secondary bg-warning-500',
    primaryDarkBlue: 'bg-primary-darker border-primary text-white active:bg-primary-dark disabled:bg-primary-lightest',
    primaryOrange: 'bg-warning-400 border-primary text-white hover:bg-warning-600',
    secondaryOrange: 'bg-warning-500 bg-opacity-50 border-orange-dark text-orange-dark',
    outlinedUnlock: 'bg-warning-400 bg-opacity-50 hover:bg-opacity-75 border-0 border-transparent',
    outlinedCancel: 'text-darker bg-white border border-blue-dark border-opacity-15 hover:border-opacity-30',
    subscribed: 'text-subscribed text-opacity-75 bg-success-100',
    unsubscribed: 'bg-warning-900 text-white hover:bg-error-900',
    outlinedWhite: 'text-white border border-white',
    outlinedLightBlue: 'text-lightblue text-opacity-15 border border-lightblue',
    outlinedRed:
        'text-error border-error-900 bg-transparent hover:bg-error-900 hover:text-white hover:border-transparent',
    outinedConnected: 'text-darker hover:opacity-100 border-blue-dark bg-primary-darker bg-opacity-3',
    bberryBlue: 'bg-bberry-blue text-white hover:bg-bberry-blue-dark disabled:opacity-50',
    outlinedBerryBlue: 'bg-white text-bberry-blue border-bberry-blue disabled:opacity-50',
    planPro: 'bg-plans-pro text-blue-darker disabled:opacity-50 border-none',
    fillLightBlue: 'bg-lightblue text-white border-lightblue',
    primaryCancel: 'bg-primary-darker bg-opacity-50 text-white hover:bg-primary-darker hover:bg-opacity-75',
    outlinedGray: 'text-black border-grey-light bg-white',
    outlinedGrayClose: 'text-black border-grey-light bg-gray-100',
    outlinedLightBlueDisabled: 'text-lightblue text-opacity-15 border border-lightblue cursor-not-allowed opacity-50',
    fillLightBlueDisabled: 'bg-lightblue text-white border-lightblue cursor-not-allowed opacity-50',
    contactUs: 'text-white text-opacity-75 disabled:opacity-50 border-white border-opacity-10',
    planLite: 'text-white disabled:opacity-50 border-white',
    downgrade: 'text-error border-error-900 hover:bg-error-900 hover:text-white',
    outlinedRedDisabled:
        'text-error border-error-900 bg-transparent hover:bg-transparent hover:text-error hover:border-error-900 opacity-50 disabled:cursor-not-allowed',
    outlinedArchive:
        'text-white border border-white border-opacity-5 text-opacity-75 hover:text-opacity-100 disabled:opacity-50 bg-white bg-opacity-10 hover:bg-opacity-20',
    archived:'text-white border border-white border-opacity-5 text-opacity-75 bg-white bg-opacity-10 cursor-not-allowed',
    outlinedArchiveNoHover:
    'text-white border border-white border-opacity-5 text-opacity-75 disabled:opacity-50 bg-white bg-opacity-10',
    bglight: 'text-white bg-light-white border-0 text-opacity-75 hover:text-opacity-100 disabled:opacity-50',
    evaRestart: 'text-white text-opacity-75 bg-eva-restart disabled:opacity-50 border border-white border-opacity-5 hover:text-opacity-100',
    evaPublish: 'bg-eva-publish text-white disabled:opacity-50 border-none',
    evaPublished: 'bg-eva-published bg-opacity-50 text-white disabled:opacity-50 border-none',
    fillMetamarker: 'bg-white bg-opacity-5 text-white border-white border-opacity-30',
    blueRYB: 'bg-blue-RYB text-white disabled:opacity-50'
};

export const sizes = {
    small: 'text-xs py-1 px-2 rounded-full flex items-center',
    medium: 'px-6 py-2 rounded-5',
    large: 'px-8 py-3 rounded-5',
    icon: 'px-6 py-4 rounded-5',
    round: 'rounded-full flex justify-center items-center',
    oval: 'rounded-full flex justify-center items-center py-2 px-4 h-8',
    rect: 'text-xs py-1 px-2 rounded-5 flex items-center',
    rectSmall :'rounded-lg flex justify-center items-center py-2 px-4 h-8',
};
export type ButtonProps = ComponentProps<'button'> & {
    variant?: keyof typeof buttonVariants;
    size?: keyof typeof sizes;
    disabled?: boolean;
};
export const Button = ({
    className,
    variant = 'primaryPurple',
    size = 'large',

    ...props
}: ButtonProps) => {
    return (
        <button
            disabled={props.disabled}
            className={clsx(
                'button',
                'transition-all border text-sm',
                'focus:outline-none',
                'active:shadow-sm',
                'disabled:cursor-not-allowed disabled:shadow-none disabled:bg-opacity-50',
                buttonVariants[variant],
                sizes[size],
                className,
            )}
            {...props}
        />
    );
};
