const interpolateColor = (color1: string, color2: string, ratio: number) => {
    const r1 = parseInt(color1.substring(1, 3), 16);
    const g1 = parseInt(color1.substring(3, 5), 16);
    const b1 = parseInt(color1.substring(5, 7), 16);
    const r2 = parseInt(color2.substring(1, 3), 16);
    const g2 = parseInt(color2.substring(3, 5), 16);
    const b2 = parseInt(color2.substring(5, 7), 16);
    const r = Math.round(r1 * (1 - ratio) + r2 * ratio);
    const g = Math.round(g1 * (1 - ratio) + g2 * ratio);
    const b = Math.round(b1 * (1 - ratio) + b2 * ratio);
    return `rgb(${r}, ${g}, ${b})`;
};

const calculateColor = (colorCode: number) => {
    const color1 = '#007bff';
    const color2 = '#f14f98';
    const interpolatedColor = interpolateColor(color1, color2, colorCode / 100);
    return interpolatedColor;
};

export const getRgbColorCode = (values: number[]) => {
    const sum = values.reduce((acc, curr) => acc + curr, 0);
    const average = sum / values.length;
    return calculateColor(Math.round(average));
};
