import clsx from 'clsx';

import { ReactComponent as SearchIcon } from '../../assets/icons/icon-search-colored.svg';
import { Input } from './Input.component';

export type SearchBarProps = {
    value: string;
    onChange: (value: string) => void;
    className?: string;
    placeholder?: string;
};

export const SearchBar = ({ value, onChange, className, placeholder }: SearchBarProps) => {
    return (
        <Input
            left={<SearchIcon className='h-4 ml-4' />}
            value={value}
            key='random'
            onChange={onChange}
            className={clsx(className, 'shadow-field')}
            placeholder={placeholder ? placeholder : 'Search...'}
        />
    );
};
