import * as React from 'react';

import { Box, CircularProgress } from '@material-ui/core';

interface LoadingProps {
    color?: 'primary' | 'secondary' | 'white' | 'evaluate';
    size?: number;
}

export const Loading: React.FC<LoadingProps> = ({ color = 'primary', size }) => {
    let circularProgressStyle: React.CSSProperties = {};

    // Switch statement to determine CircularProgress color based on the provided prop
    switch (color) {
        case 'primary':
            circularProgressStyle = { color: '#373bba' };
            break;
        case 'secondary':
            circularProgressStyle = { color: '#5a44ff' };
            break;
        case 'white':
            circularProgressStyle = { color: '#ffffff' };
            break;
        case 'evaluate':
            circularProgressStyle = { color: 'rgba(96, 244, 200, 1)' };
            break;
        default:
            circularProgressStyle = { color: '#373bba' };
    }

    return (
        <Box className='flex items-center justify-center absolute inset-0 m-auto h-full w-full'>
            <CircularProgress variant="indeterminate" style={circularProgressStyle} size={size} />
        </Box>
    );
};
