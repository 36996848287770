import React, { useState } from 'react';

import clsx from 'clsx';

import { useStore } from '../../../store';
import { Footer } from '../../basic/Footer.component';
import BottomSideMenu from '../../sideMenu/BottomSideMenu';
import NavbarBackground from '../NavBar/NavbarBackground.component';
import ReverseDeletionModal from './ReverseDeletionModal';

export type LayoutProps = {
    children: React.ReactNode;
    showSideMenu?: boolean;
    showFooter?: boolean;
    height?: 'screen' | 'full' | 'auto';
};

const WebLayout: React.FC<LayoutProps> = ({ children, showFooter = true, height = 'full' }) => {
    const pathname = window.location.pathname;
    const store = useStore();
    const [openModal, setOpenModal] = useState<boolean>(false);

    return (
        <div
            className={clsx(
                'h-full items-center justify-center',
                pathname === '/plans' ? 'bg-primary-darker' : 'bg-backgroundColor',
            )}
        >
            <div>
                {!!store.accountDeletion?.accountDeletionDate && (
                    <div className='fixed top-14 flex items-center justify-center bg-spaces-100 w-full h-8 p-2 z-50'>
                        <p className='text-sm text-darker text-opacity-75'>
                            Your account has been deleted. You can still{' '}
                            <button onClick={() => setOpenModal(true)} className='inline underline font-medium'>
                                reverse
                            </button>{' '}
                            the process.
                        </p>
                    </div>
                )}
                <ReverseDeletionModal open={openModal} handleClose={() => setOpenModal(false)} />

                <div
                    className={clsx(
                        `grid grid-rows-2-top w-full min-h-screen
                    ${pathname.includes('/spaces') ? 'h-full' : `h-${height}`}`,
                        pathname === '/plans' && 'bg-primary-darker' ? 'bg-primary-darker' : 'bg-backgroundColor',
                    )}
                >
                    <NavbarBackground />
                    <div className={clsx('pt-14 w-full h-full mx-auto md:px-6 grid grid-rows-2-bottom')}>
                        <div className='row-start-2 min-h-0'>{children}</div>
                    </div>
                    {showFooter && (
                        <Footer
                            whiteClass='text-xs text-darker text-opacity-75'
                            lightTextClass='text-xs text-darker text-opacity-50'
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
export default WebLayout;
