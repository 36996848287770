import { makeAutoObservable } from 'mobx';

import { DocumentType } from '../services/documents/documents.service';

import { store } from '.';

export type DocumentInfo = {
    id: number;
    blobName: string;
    fileName: string;
    fileSize: number;
    fileType: string;
    ownerId: number;
    type: DocumentType;
};

export class DocumentsStore {
    private documents: DocumentInfo[] = [];
    constructor() {
        makeAutoObservable(this);
    }
    getAllDocuments() {
        return this.documents;
    }
    getDocumentById(id: number) {
        for (const doc of this.documents) {
            if (doc.id === id) return document;
        }
    }
    init(documents: DocumentInfo[]) {
        this.documents = documents;
        for (const document of documents) {
            if (document.type === 'profilePicture') {
                store.user.setProfilePictureUrl(document.id);
            }
        }
    }
    pushDocument(document: DocumentInfo) {
        this.documents.push(document);
    }
}
