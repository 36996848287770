import { ChangeEvent, useState } from "react";

import { CircularProgress } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";

import { Organisation } from "../../../store/OrganisationStore";
import { useAsyncFetcher } from "../../../utils/useAsyncFetcher";
import HorizontalSeparator from "../../basic/HorizontalSeparator"
import { useServices } from "../../ServiceProvider";
import OrganisationLogo from "./OrganisationLogo.component";
import OrganistaionForm from "./OrganistaionForm.component"

const CreateOrganisation = () => {
  const { id: organisationId } = useParams<{ id: string }>();
  const { organisation } = useServices();
  const { data, loading } = useAsyncFetcher<Organisation>(async () => organisation.getOrganisation(organisationId), []);
  const [file, setFile] = useState<File | null>(null);

  if (loading)
    return (
      <div className='flex-grow flex flex-col justify-center items-center pt-20 text-darker text-opacity-75 mt-24'>
        <CircularProgress sx={{ color: '#373BBA', width: '32px', height: '32px' }} />
      </div>
  );

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => setFile(e.target.files ? e.target.files[0] : null);
  
  return (
    <div className='w-full h-full flex justify-center'>
        <div className='mx-auto max-w-5xl w-full mt-10 md:mt-0'>
          <div className="mt-5 mb-10">
            <div className='flex justify-center text-4xl text-royal-blue text-center font-bold'>
              <p>Set-up your Organisation</p>
            </div>
          </div>
          <OrganisationLogo onChange={handleUpload} documnetId={data?.logo}/>
          <HorizontalSeparator className="mt-10"/>
          <div className="mt-10">
            <OrganistaionForm initialValues={data} file={file}/>
          </div>
          
        </div>
    </div>
  )
}

export default observer(CreateOrganisation)