import { useState } from 'react';

import { ReactComponent as RestartIcon } from '../../../../../../assets/icons/icon-restart.svg';
import { Conversation } from '../../types';
import UserResponseCard, { UserType } from './UserResponseCard.component';

const EvaluateResponse = ({
    globalUser1Score,
    globalUser2Score,
    globalUser1Name,
    globalUser2Name,
    conversation,
    handleResponseChange,
    handleActionChange,
    handleUserResponseChange,
    protocolName,
    onRestart,
    setOrbitLoader,
    setEditUserResponse,
}: {
    globalUser1Score: number;
    globalUser2Score: number;
    globalUser1Name: string;
    globalUser2Name: string;
    conversation: Conversation[];
    handleResponseChange: (conversation: Conversation[]) => void;
    handleActionChange: (text: string, posneg: boolean) => void;
    handleUserResponseChange: (text: string) => Promise<{ score: number; flagged: boolean }>;
    protocolName: string;
    onRestart: () => void;
    setOrbitLoader: React.Dispatch<React.SetStateAction<boolean>>;
    setEditUserResponse: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const userMapping: { [key: string]: UserType } = {
        [globalUser1Name]: UserType.User1,
        [globalUser2Name]: UserType.User2,
    };

    const handleTextChange = async (index: number, text: string) => {
        const result = await handleUserResponseChange(text);
        if (result) {
            conversation[index].score = result.score;
            conversation[index].flagged = result.flagged;
            conversation[index].text = text;
            handleResponseChange(conversation);
        }
        return result;
    };

    const handleRestart = () => {
        onRestart();
    };

    return (
        <div className='w-full'>
            <div className='w-full flex justify-between items-center space-x-2'>
                <div className='flex space-x-2 pl-6'>
                    <div className='global-score global-score-user1'>
                        {globalUser1Name} Global Score: {globalUser1Score > 0 ? globalUser1Score.toFixed(0) : 'NA'}
                    </div>
                    <div className='global-score global-score-user2'>
                        {globalUser2Name} Global score: {globalUser2Score > 0 ? globalUser2Score.toFixed(0) : 'NA'}
                    </div>
                </div>
                <button className='flex restart_btn' onClick={handleRestart}>
                    <RestartIcon className='w-3 h-3' fill='#FFFFFFBF' />
                    <div className='edit_tooltip_box'>
                        <p>Restart</p>
                    </div>
                </button>
            </div>

            <div className='evaluate-response'>
                {conversation.map((item, index) => {
                    return (
                        <UserResponseCard
                            key={index}
                            setOrbitLoader={setOrbitLoader}
                            userType={userMapping[item.user]}
                            text={item.text}
                            evalScore={item.score}
                            evalFlagged={item.flagged}
                            onInputChange={(newText: string) => handleTextChange(index, newText)}
                            handleActionChange={handleActionChange}
                            protocolName={protocolName}
                            setEditUserResponse={setEditUserResponse}
                            isEditing={editingIndex === index}
                            onEditToggle={() => setEditingIndex(editingIndex === index ? null : index)}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default EvaluateResponse;
