import React from 'react';
import { createContext, useContext } from 'react';

import { AnyFunction } from './typeUtils';

export type HookMocker<H extends AnyFunction> = React.ComponentType<{ mock: H; children: React.ReactNode }>;

export function mockable<H extends AnyFunction>(hook: H): H & { Mock: HookMocker<H> } {
    const MockContext = createContext<H | null>(null);

    const useMockableHook = (...args: Parameters<H>[]) => {
        const finalHook = useContext(MockContext) || hook;
        return finalHook(...args);
    };

    useMockableHook.Mock = ({ mock, children }: { mock: H; children: React.ReactNode }) => {
        return <MockContext.Provider value={mock}>{children}</MockContext.Provider>;
    };

    return useMockableHook as H & { Mock: HookMocker<H> };
}
