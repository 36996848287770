import { useState } from 'react';

import { appInsights } from '../AppInsights';
import { useServices } from '../components/ServiceProvider';
import { store } from '../store';
import { UserData } from '../store/UserStore';
import { mockable } from '../utils/mockable';
import { useForm } from '../utils/useForm';
import { required } from '../utils/validation';

export const useUserInformationsForm = mockable((initialValues?: Partial<UserData> | null) => {
    const [isLoading, setIsLoading] = useState(false);
    const { users } = useServices();
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const { fields, isValid, setAllVisited, setValues } = useForm({
        fields: {
            firstName: { initialValue: initialValues?.firstName, validation: required },
            lastName: { initialValue: initialValues?.lastName, validation: required },
            email: { initialValue: initialValues?.email },
        },
    });

    const onSubmit = async () => {
        setIsLoading(true);
        setAllVisited();
        setError(null);
        setSuccess(null);
        try {
            if (!isValid()) throw new Error('Some fields are not valid, please check again');
            const result = await users.update({
                firstName: fields.firstName.value,
                lastName: fields.lastName.value,
                email: fields.email.value,
            });
            if (result) {
                setTimeout(() => {
                    setIsLoading(false);
                    setSuccess('Your informations have been updated');
                }, 1000);
            }
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
                appInsights.trackException({ exception: err });
            } else {
                setError('Unexpected error happened');
                appInsights.trackException({ exception: new Error('Error while updating user') });
            }
            setIsLoading(false);
        }
    };

    const createOrUpdateUserEmail = async (emails: string[]) => {
        try {
            setIsLoading(true);
            const result = await users.createOrUpdateUserEmail(emails);
            if (result) {
                store.user.updateUserEmail(emails);
                await onSubmit();
            }
        } catch (err) {
            setIsLoading(false);
            if (err instanceof Error) {
                setError(err.message);
                appInsights.trackException({ exception: err });
            }
        }
    };

    const deleteUserEmail = async (email: string) => {
        try {
            const result = await users.deleteUserEmail(email);
            if (result) {
                store.user.deleteUserEmail(email);
            }
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
                appInsights.trackException({ exception: err });
            } else setError('Error while deleting email');
        }
    };

    return {
        onSubmit,
        setError,
        fields,
        isLoading,
        error,
        setValues,
        createOrUpdateUserEmail,
        deleteUserEmail,
        success,
    };
});
