import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { store } from '../../store';

const CardWarning = () => {
    const stripeEvents = toJS(store.organisation.getStripeEvents());
    const expired = stripeEvents?.find((event) => event.status === 'expired_card');

    if (expired) {
        return (
            <div className='fixed top-5 left-0 right-0 z-50 p-2 mt-10 text-darker text-opacity-75 bg-warning-500'>
                <p className='text-center text-md'>
                    Your credit card expired for your <span className='font-semibold'>{expired?.pricingName}</span>{' '}
                    subscription, please {'  '}
                    <Link
                        to={expired.organisationId ? `/organisation/${expired.organisationId}/billing` : '/plans'}
                        className='underline'
                    >
                        update
                    </Link>{' '}
                    it or enter a new payment method.
                </p>
            </div>
        );
    }
    return <></>;
};

export default observer(CardWarning);
