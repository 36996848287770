import { NetworkMock } from '../../mocks/NetworkMock';
import { users } from '../../mocks/users';
import { defaultUser, UserData } from '../../store/UserStore';
import { AuthServiceInterface, LoginResponse } from './auth.service.interface';

export class AuthServiceMock implements AuthServiceInterface {
    constructor(private networkMock: NetworkMock) {}

    async login(email: string, password: string, userType: string): Promise<LoginResponse> {
        await this.networkMock.fetch();
        const user = users.find((user) => user.email === email && user.password === password);
        if (user) {
            return {
                access_token: '3090Z9RU0RUEUFJFE0E09E',
            };
        } else throw new Error('Wrong email or password ' + userType);
    }

    async renewToken() {
        // NOTHING
    }

    async logout(): Promise<void> {
        await this.networkMock.fetch();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async sendConfirmationEmail(email: string): Promise<void> {
        await this.networkMock.fetch();
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async sendInvitationEmail(email: string): Promise<void> {
        await this.networkMock.fetch();
    }

    async signup(): Promise<void> {
        await this.networkMock.fetch();
    }

    async getUserInformations(): Promise<void> {
        await this.networkMock.fetch();
    }
    async getProfile(): Promise<UserData> {
        await this.networkMock.fetch();
        //@ts-ignore
        return defaultUser;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async hasValidEmail(email: string): Promise<boolean> {
        return await true;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async updatePassword(email: string, password: string): Promise<boolean> {
        return true;
    }

    async requestPasswordReset() {
        /* NOTHING */
       
        return {success:true,message:'password reset'};
    }

    async resetPassword() {
        /* NOTHING */
    }
    
    //@ts-ignore
    async checkIfPasswordMatches(password: string): Promise<boolean> {
        /* NOTHING */
    }
}
