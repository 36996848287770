import { useState } from 'react';

import { ReactComponent as InfoIcon } from '../../../assets/icons/icon-info.svg';
import { organisationTier } from './constant';

export type TierFeature = {
    title: string;
    tooltip?: string;
    children?: TierSubFeature[];
};

export type TierSubFeature = {
    title: string;
    tooltip?: string;
    sheild: string | React.ReactNode | null;
    oversight: string | React.ReactNode | null;
};

const TierTable = () => {
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [hoveredChildIndex, setHoveredChildIndex] = useState<number | null>(null);

    return (
        <div className='w-full space-y-2'>
            {organisationTier.map((tier, index) => (
                <div key={index} className='w-full space-y-4 tier_table'>
                    <div className='w-full bg-primary-darker  bg-opacity-3 p-2 flex items-center space-x-2'>
                        <h1>{tier.title}</h1>
                        <div
                            onMouseEnter={() => setHoveredIndex(index)}
                            // onMouseLeave={() => setHoveredIndex(null)}
                            className='tooltip'
                        >
                            <InfoIcon className='w-3.5 h-3.5' fill='#0014FF' />
                            {hoveredIndex === index && <div className='tooltiptext'>{tier.tooltip}</div>}
                        </div>
                    </div>
                    <div className='space-y-4'>
                        {tier.children?.map((subFeature, subIndex) => (
                            <div
                                key={subIndex}
                                className='w-full flex items-center text-xs text-darker text-opacity-75 font-medium'
                            >
                                <div className='pl-2 flex space-x-2 w-1/2 items-center'>
                                    <p className='whitespace-nowrap sub-heading font-medium '>{subFeature.title}</p>
                                    <div
                                        onMouseEnter={() => setHoveredChildIndex(subIndex)}
                                        onMouseLeave={() => setHoveredChildIndex(null)}
                                        className='tooltip'
                                    >
                                        <InfoIcon className='w-3 h-3' fill='rgba(15, 16, 68, 0.75)' />
                                        {hoveredChildIndex === subIndex && (
                                            <div className='tooltiptext'>{subFeature.tooltip}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='flex-1 flex justify-center'>
                                    {subFeature.sheild ? (
                                        <p className='sub-heading font-normal'>{subFeature.sheild}</p>
                                    ) : (
                                        <p>-</p>
                                    )}
                                </div>
                                <p className='pr-4 flex w-1/4 justify-end text-right sub-heading font-normal'>
                                    {subFeature.oversight}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TierTable;
