import { store } from '../store';
import { DocumentInfo } from '../store/DocumentsStore';
import { getFullUrl } from './network';

export const getDocumentUrl = (documentId: number) => {
    return getFullUrl(`documents/${documentId}/file?auth-token=${store.user.token}`);
};

export const downloadFile = (document: DocumentInfo) => {
    const url = getDocumentUrl(document.id);
    const link = window.document.createElement('a');
    link.setAttribute('download', document.fileName);
    link.target = '_blank';
    link.href = url;
    link.click();
};

export const downloadAssetById = (documentId:number, fileName: string) => {
    const url = getDocumentUrl(documentId);
    const link = window.document.createElement('a');
    link.setAttribute('download', fileName);
    link.target = '_blank';
    link.href = url;
    link.click();
}
