import { useState } from 'react';

import clsx from 'clsx';
import { useHistory } from 'react-router';

import usePasswordToggle from '../../hooks/usePasswordToggle';
import { mockable } from '../../utils/mockable';
import { useForm } from '../../utils/useForm';
import { useQuery } from '../../utils/useQuery';
import { confirmPassword, isValidPassword } from '../../utils/validation';
import { Button } from '../basic/Button.component';
import { IconAlert } from '../basic/IconAlert.component';
import { Input } from '../basic/Input.component';
import { useServices } from '../ServiceProvider';

export const useSetupNewPasswordForm = mockable(() => {
    const query = useQuery();
    const email = query.get('email') || '';
    const token = query.get('token') || '';
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { auth } = useServices();
    const history = useHistory();
    const { fields, isValid, setAllVisited } = useForm({
        fields: {
            password: { validation: isValidPassword },
            passwordConfirmation: { validation: confirmPassword },
        },
    });

    const onSubmit = async (path: string) => {
        setIsLoading(true);
        setAllVisited();
        if (isValid()) {
            try {
                const validEmail = await auth.hasValidEmail(email);
                const updatePassword = await auth.resetPassword(token, fields.password.value);
                if (!validEmail) {
                    throw new Error('The email provided here is not valid.');
                }
                goTo(`${path}`);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (err: any) {
                setError(err.message);
            }
        } else {
            setError('This email is not valid');
        }
        setIsLoading(false);
    };

    const goTo = (path: string) => {
        history.push(path);
    };
    return { onSubmit, fields, isLoading, error, email };
});

export const SetupNewPasswordForm = () => {
    const { onSubmit, fields, isLoading, error, email } = useSetupNewPasswordForm();
    const newPasswordToggle = usePasswordToggle();
    const confirmPasswordToggle = usePasswordToggle();
    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                onSubmit('/sign-in');
            }}
            className={clsx('overflow-auto mx-auto max-w-md w-full px-1')}
        >
            <div className=''>
                <h1 className='text-tiny md:text-base font-semibold text-darker text-opacity-75 mb-6 text-center md:text-left'>
                    Setup new password
                </h1>
                <div className='space-y-4 text-sm'>
                    <Input
                        label='Email'
                        placeholder={email}
                        onChange={() => void 0}
                        required
                        value=''
                        type='email'
                        disabled={true}
                    />
                    {error !== null && <IconAlert message={error} />}
                    <Input 
                        label='Password' 
                        placeholder='Password' 
                        type={newPasswordToggle.InputType}
                        required 
                        {...fields.password} 
                        right={<img
                            className='absolute bottom-3 right-4 w-4 h-4 cursor-pointer opacity-50'
                            src={newPasswordToggle.Icon}
                            onClick={newPasswordToggle.handlePasswordVisibility}
                            alt=''
                        />}
                    />
                    {fields.password.error && <IconAlert message={fields.password.errorMessage as string} />}
                    <Input
                        label='Confirm Password'
                        placeholder='Confirm Password'
                        type={confirmPasswordToggle.InputType}
                        required
                        {...fields.passwordConfirmation}
                        right={<img
                            className='absolute bottom-3 right-4 w-4 h-4 cursor-pointer opacity-50'
                            src={confirmPasswordToggle.Icon}
                            onClick={confirmPasswordToggle.handlePasswordVisibility}
                            alt=''
                        />}
                    />
                    {fields.passwordConfirmation.error && (
                        <IconAlert message={fields.passwordConfirmation.errorMessage as string} />
                    )}
                </div>
                <Button
                    className='w-full h-10 mt-6'
                    variant='bberryBlue'
                    type='submit'
                    size='round'
                    children='Setup new password'
                    disabled={isLoading}
                />
            </div>
        </form>
    );
};
