import { Route, Switch, useRouteMatch } from "react-router-dom"

import { Footer } from "../../components/basic/Footer.component";
import NavbarBackground from "../../components/general/NavBar/NavbarBackground.component"
import SignUpForm from "../../components/signUp/SignUpForm.component";
import useMediaQuery from "../../hooks/useMediaQuery";
import { SIZES } from "../../utils/constants";
import { SignUpMoblePage } from "./SignUp.mobile";

const SignUpPage = () => {
    const { path } = useRouteMatch();
    const isMobile = useMediaQuery(SIZES.MOBILE_WIDTH);
    if (isMobile) return <SignUpMoblePage />;
    return (
        <div className='flex md:h-screen w-full'>
            <NavbarBackground />
            <div
                className={`w-full px-4 pt-14 flex flex-col `}
            >
                <div className={`flex w-full h-full max-w-7xl mx-auto justify-center items-center mb-10 md:mb-0`}>
                    <Switch>
                        <Route exact path={[`${path}`, `${path}/invite`]}>
                            <SignUpForm  />
                        </Route>
                    </Switch>
                </div>
                <Footer
                    whiteClass='text-xs text-darker text-opacity-75'
                    lightTextClass='text-xs text-darker text-opacity-50'
                />
            </div>
        </div>
    )
}

export default SignUpPage