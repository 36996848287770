import clsx from 'clsx';

import discordIcon from '../../assets/icons/socials/icon-discord.svg';
import gmailIcon from '../../assets/icons/socials/icon-gmail.svg';
import hubspotIcon from '../../assets/icons/socials/icon-hubspot.svg';
import localhostIcon from '../../assets/icons/socials/icon-localhost.svg';
import teamsIcon from '../../assets/icons/socials/icon-microsoft-teams.svg';
import outlookIcon from '../../assets/icons/socials/icon-outlook.svg';
import postmanIcon from '../../assets/icons/socials/icon-postman.svg';
import slackIcon from '../../assets/icons/socials/icon-slack.svg';
import whatsappIcon from '../../assets/icons/socials/icon-whatsapp.svg';
import bberryIcon from '../../assets/logo/logo_b_dark.png';

type DomainIcons = {
    [key: string]: string;
};
const DomainIcon = ({ domain, size = 5, className }: { domain: string; size?: number; className?: string }) => {
    const domainPath: DomainIcons = {
        'app.slack.com': slackIcon,
        'mail.google.com': gmailIcon,
        'outlook.live.com': outlookIcon,
        'outlook.office.com': outlookIcon,
        'hubspot.com': hubspotIcon,
        'localhost': localhostIcon,
        'web.whatsapp.com': whatsappIcon,
        'app.bberry.ai': bberryIcon,
        'app.blankstate.ai': bberryIcon,
        'bberry.ai': bberryIcon,
        'blankstate.ai': bberryIcon,
        'web.postman.co': postmanIcon,
        'teams.microsoft.com': teamsIcon,
        'teams.live.com': teamsIcon,
        'discord.com': discordIcon,
        'extension': localhostIcon,
        'default': localhostIcon,
    };
    const domainIcon = domainPath[domain] || (domain.endsWith('postman.co') ? postmanIcon : domainPath['default']);

    return (
        <div
            className={clsx(
                'flex rounded-lg bg-white items-center justify-center',
                className ? className : 'w-10 h-10',
            )}
            style={{
                border: '1px solid rgba(15, 16, 68, 0.10)',
                boxShadow: '0px 3px 6px 0px rgba(15, 16, 68, 0.06)',
            }}
        >
            <img src={domainIcon} className={`h-${size} resize-none`} />
        </div>
    );
};

export default DomainIcon;
