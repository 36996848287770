import React from 'react';

import { loadStripe } from '@stripe/stripe-js';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

ReactDOM.render(
    <React.StrictMode>
        <link href='https://fonts.googleapis.com/icon?family=Material+Icons' rel='stylesheet' />
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
