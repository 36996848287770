const SortIcon = ({
    fill = '#0F1044',
    fillTopOpacity = 0.5,
    fillBottomOpacity = 0.5,
    width = 6,
    height = 12,
}) => (
    <svg width={width} height={height} viewBox='0 0 6 12' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M4.87889 5.16797C5.35811 5.16797 5.64394 4.63388 5.37812 4.23515L3.49923 1.41681C3.26174 1.06057 2.73826 1.06057 2.50077 1.41681L0.621881 4.23515C0.356059 4.63388 0.641893 5.16797 1.12111 5.16797L4.87889 5.16797Z'
            fill={fill}
            fillOpacity={fillTopOpacity}
        />
        <path
            d='M1.12111 6.83203C0.641893 6.83203 0.356058 7.36612 0.62188 7.76485L2.50077 10.5832C2.73826 10.9394 3.26174 10.9394 3.49923 10.5832L5.37812 7.76485C5.64394 7.36612 5.35811 6.83203 4.87889 6.83203H1.12111Z'
            fill={fill}
            fillOpacity={fillBottomOpacity}
        />
    </svg>
);

export default SortIcon;
