import { useState } from 'react';

import clsx from 'clsx';
import { Link, useHistory } from 'react-router-dom';

import { appInsights } from '../../AppInsights';
import { useStore } from '../../store';
import { mockable } from '../../utils/mockable';
import { useForm } from '../../utils/useForm';
import { and, isEmail, required } from '../../utils/validation';
import { Button } from '../basic/Button.component';
import { IconAlert } from '../basic/IconAlert.component';
import { Input } from '../basic/Input.component';
import { useServices } from '../ServiceProvider';

export const useForgotPasswordForm = mockable(() => {
    const services = useServices();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const history = useHistory();
    const store = useStore();
    const { fields, isValid, setAllVisited } = useForm({
        fields: {
            email: { validation: and(isEmail, required) },
        },
    });

    const onSubmit = async () => {
        setIsLoading(true);
        setAllVisited();
        if (isValid()) {
            try {
                store.signInStore.updateForgotPasswordEmail(fields.email.value);
                const response = await services.auth.requestPasswordReset(fields.email.value);
                if (response.success) goTo('reset-password');
                setError(response.message);
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                    appInsights.trackException({ error: err });
                }
            }
        } else {
            setError('This email is not valid');
        }
        setIsLoading(false);
    };

    const goTo = (path: string) => {
        history.push(path);
    };

    return { onSubmit, fields, isLoading, error };
});

export const ForgotPasswordForm = () => {
    const { onSubmit, fields, isLoading, error } = useForgotPasswordForm();
    return (
        <div className='flex flex-col items-center mx-auto max-w-md w-full'>
            <form
                noValidate
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit();
                }}
                className={clsx('overflow-auto w-full px-1')}
            >
                <div className=''>
                    <h1 className='text-tiny md:text-base font-semibold text-darker text-opacity-75 mb-6 text-center md:text-left'>
                        Forgot Password?
                    </h1>
                    <p className='text-sm text-darker text-opacity-75 mb-4'>
                        Please enter your email address associated with your account and we will send a link to reset
                        your password
                    </p>
                    <Input
                        placeholder='Email'
                        type='email'
                        label='Email'
                        required
                        {...fields.email}
                        error={error !== null}
                    />
                    {error !== null && <IconAlert message={error} className='mt-4' />}
                    <Button
                        className='w-full h-10 mt-6'
                        disabled={isLoading}
                        type='submit'
                        size='round'
                        variant='bberryBlue'
                        children='Reset Password'
                    />
                </div>
            </form>
            <div className='flex items-center justify-center space-x-2 text-sm font-normal mt-12'>
                <Link to={'/sign-in'} className='text-bberry-blue underline'>
                    Back to sign-in
                </Link>
            </div>
        </div>
    );
};
