import { useServices } from '../components/ServiceProvider';
import { store } from '../store';


export const useProfile = () => {
    const { documents } = useServices();

    const uploadProfilePicture = async (file: File, customId = -1) => {
        if (customId == -1) {
            if (!store.user.userData?.id) throw new Error('cannot upload profile picture');
            const { document, blobSasUrl } = await documents.createDocument(
                file.name,
                file.size,
                file.type,
                store.user.userData?.id,
                'profilePicture',
            );
            await documents.uploadFile(file, { document, blobSasUrl });

            store.user.setProfilePictureUrl(document.id);
        } else {
            const { document, blobSasUrl } = await documents.createDocument(
                file.name,
                file.size,
                file.type,
                customId,
                'profilePicture',
            );
            await documents.uploadFile(file, { document, blobSasUrl });
        }
    };

    return {
        uploadProfilePicture,
        documents,
    };
};
