import clsx from 'clsx';

type FooterProps = {
    whiteClass?: string;
    lightTextClass?: string;
};

export const Footer = ({ whiteClass, lightTextClass }: FooterProps) => {
    const date = new Date();
    return (
        <div
            className={clsx(
                'flex bg-backgroundColor flex-row items-center justify-center text-xs h-14 py-5 space-x-1',
                whiteClass,
            )}
        >
            <span> ©{date.getFullYear()} blankstate.ai </span>
            <span className={clsx('flex text-neutral-400 space-x-1', lightTextClass)}>
                <p>- Company Number: 12302383</p>
                <p>|</p>
                <a
                    className='hover:text-darker hover:text-opacity-75 underline transition-all duration-75'
                    href='https://blankstate.ai/terms-conditions/'
                    target='_blank'
                >
                    Terms & Conditions
                </a>
                <p>|</p>
                <a
                    className='hover:text-darker hover:text-opacity-75 underline transition-all duration-75'
                    href='https://blankstate.ai/privacy-policy/'
                    target='_blank'
                >
                    Privacy
                </a>
                <p>|</p>
                <a
                    className='hover:text-darker hover:text-opacity-75 underline transition-all duration-75'
                    href='https://blankstate.ai/contact-us/'
                    target='_blank'
                >
                    Contact
                </a>
            </span>
        </div>
    );
};
