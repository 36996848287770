import { makeAutoObservable } from 'mobx';

import { Organisation } from './OrganisationStore';

export type Space = {
    spaceName: string;
    spaceIcon: string;
    spaceColor: string;
    spaceImage: string;
    spaceBanner: string;
};

export type SpaceInfo = {
    spaceId: string;
    id: number;
    spaceName: string;
    spaceIcon: string;
    spaceBanner: string;
    spaceColor: string;
    spaceLogo: string;
    createdById: number;
    createdAt: Date;
    associatedMemberIds: AssociatedMemberInfo[];
    imgUrl: string;
    inviteExists?: boolean;
    organisationSpace?: Organisation[];
    role?: string;
};

export type WorkspaceInviteInfo = {
    email: string;
    id: number;
    invitedBy: number;
    isAccepted: boolean;
    isOrganisationSpaceId: boolean;
    isUserExisting: true;
    role: string;
    spaceId: string;
    fullName: string | undefined;
};
export type AssociatedMemberInfo = {
    id?: number;
    email: string;
    role: string;
    isRegistered: boolean;
    profilePictureId?: number;
    firstName?: string;
    lastName?: string;
};

export type WorkspaceInfo = {
    workspaceBanner: string;
    workspaceIcon: string;
    workspaceName: string;
    workspaceColor: string;
    workspaceLogo: string;
    isOrganisation?: boolean;
};


export type WorkspaceBanner = {
    url: string;
};

export class SpacesStore {
    currentSpaceName = '';
    currentSpaceId = '';
    currentSpaceColor = '';
    currentSpaceBannerDocumentId = '';
    currentSpaceLogoDocumentId = '';
    currentWorkSpaceBannerDocumentId = '';
    currentWorkSpaceLogoDocumentId = '';
    spaces: SpaceInfo[] = [];
    workSpaceInfo: WorkspaceInfo = {
        workspaceBanner: '',
        workspaceIcon: '',
        workspaceName: '',
        workspaceColor: '',
        workspaceLogo: '',
    };
    currentSelectedSpaceId = '';
    currentSelectedWorkSpaceInfo: WorkspaceInfo = {
        workspaceBanner: '',
        workspaceIcon: '',
        workspaceName: '',
        workspaceColor: '',
        workspaceLogo: '',
    };

    constructor() {
        makeAutoObservable(this);
    }

    init(spaces: SpaceInfo[]) {
        this.spaces = spaces;
    }

    getAll() {
        return this.spaces;
    }

    updateSelectedSpaceId(spaceId: string) {
        this.currentSelectedSpaceId = spaceId;
    }

    get getCurrentSelectedSpaceId() {
        return this.currentSelectedSpaceId;
    }

    setSpaceName(val: string) {
        this.currentSpaceName = val;
    }

    setCurrentSpaceId(id: string) {
        this.currentSpaceId = id;
    }

    setWorkSpaceInfo(workSpaceInfo: WorkspaceInfo) {
        this.workSpaceInfo = workSpaceInfo;
    }

    setCurrentSelectedWorkSpaceInfo(workSpaceInfo: WorkspaceInfo) {
        this.currentSelectedWorkSpaceInfo = workSpaceInfo;
    }

    setSelectedSpace(space: SpaceInfo) {
        const workspaceInfo = {
            workspaceBanner: space.spaceBanner,
            workspaceIcon: space.spaceIcon,
            workspaceName: space.spaceName,
            workspaceColor: space.spaceColor,
            workspaceLogo: space.spaceLogo,
            isOrganisation: space.organisationSpace?.length ? true : false,
        };
        this.currentSelectedWorkSpaceInfo = workspaceInfo;
    }

    resetWorkspaceInfo() {
        this.currentSelectedWorkSpaceInfo = this.workSpaceInfo;
    }
}
