import { Route, Switch, useRouteMatch } from 'react-router-dom';

import SpaceHome from '../../components/manageSpaces/SpaceHome.component';
import NotFound from '../NotFound.page';


const Spaces = () => {
    const { path } = useRouteMatch();

    return (
        <div className='h-[90vh] bg-backgroundColor overflow-x-hidden'>
            <div className='w-full h-full px-4 md:px-0'>
                <Switch>
                    <Route exact path={[`${path}/view/:id`]} component={SpaceHome} />
                    <Route component={NotFound} />
                </Switch>
            </div>
        </div>
    );
};

export default Spaces;
