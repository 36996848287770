import React from 'react';

import clsx from 'clsx';

type props = {
    className?: string;
};
const HorizontalSeparator: React.FC<props> = ({ className }) => {
    return <hr className={clsx('w-full h-[1px] border-t border-blue-dark border-opacity-10', className)} />;
};

export default HorizontalSeparator;
