import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Link, useLocation } from 'react-router-dom';

import useMediaQuery from '../../../../hooks/useMediaQuery';
import { SIZES } from '../../../../utils/constants';

type VerticalTab = {
    title: string;
    path: string;
    exactPath?: string[];
    icon?: React.ReactElement;
    onClick?: () => void;
    tour?: string;
    disabled?: boolean;
    hidden?: boolean;
};
type Props = {
    tabs: (VerticalTab | null)[];
};

const VeritcalTabLayout: React.FC<Props> = ({ tabs }) => {
    const location = useLocation();
    const [value, setValue] = React.useState(location.pathname);
    const isMobile = useMediaQuery(SIZES.MOBILE_WIDTH);

    const [bluePrintExists, setBluePrintExists] = useState<boolean>(false);
    const [evaluationExist, setEvaluationExist] = useState<boolean>(false);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    useEffect(() => {
        tabs.map((element) => {
            if (element && element.exactPath) {
                if (element.exactPath.includes(location.pathname)) {
                    setValue(element.path);
                }
            }
        });
    }, [location.pathname, tabs]);

    const visibleTabs = tabs.filter((tab) => !tab?.hidden);

    useEffect(() => {
        function checkIfElementExists() {
            const element = document.querySelector('#blue_print_modal');
            const Evaluate = document.querySelector('#evaluate_mode_panel');
            setBluePrintExists(element !== null);
            setEvaluationExist(Evaluate !== null);
        }
        checkIfElementExists();
        const observer = new MutationObserver(() => {
            checkIfElementExists();
        });
        observer.observe(document.body, { childList: true, subtree: true });
        // Cleanup observer on component unmount
        return () => observer.disconnect();
    }, []);

    if (visibleTabs.length === 0) return null;

    return (
        <div className='top_tab'>
            <Box sx={{ width: isMobile ? '100%' : 'fit-content' }}>
                <Tabs
                    orientation='horizontal'
                    className={`${bluePrintExists ? 'active_blueprint' : ''} ${
                        evaluationExist ? 'evaluation_mode' : ''
                    } tab_link`}
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{
                        style: {
                            width: 0,
                            display: 'none',
                            height: 0,
                        },
                    }}
                    sx={{ marginTop: '24px' }}
                >
                    {visibleTabs &&
                        visibleTabs.map((element, index) => {
                            if (!element) return;
                            return (
                                <Tab
                                    className='flex '
                                    key={index}
                                    label={
                                        <div style={{ display: 'none', alignItems: 'start' }}>
                                            <span>{element.title}</span>
                                        </div>
                                    }
                                    value={element.path || value}
                                    to={element.path}
                                    component={Link}
                                    icon={element.icon}
                                    iconPosition='start'
                                    sx={{
                                        textTransform: 'none',
                                        minWidth: '24px !important',
                                        maxHeight: '24px !important',
                                        minHeight: '24px !important',
                                        fontFamily: 'Poppins',
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        padding: 0,
                                        color: 'rgba(15, 16, 68, 0.5)',
                                        '&.MuiTab-root.Mui-selected': {
                                            color: 'rgba(15, 16, 68, 0.75)',
                                            background: 'rgba(15, 16, 68, 0.03)',
                                            borderRadius: '5px',
                                            '& svg path': {
                                                fill: 'rgba(15, 16, 68, 0.75)',
                                            },
                                        },
                                        '&.Mui-disabled': {
                                            '& svg path': {
                                                fill: 'rgba(15, 16, 68, 0.60)',
                                            },
                                        },
                                        '& .MuiTab-iconWrapper': {
                                            margin: 0,
                                        },
                                        whiteSpace: 'nowrap',
                                    }}
                                    onClick={element.onClick}
                                    data-tour={element.tour}
                                    disabled={element.disabled}
                                    hidden={element.hidden}
                                />
                            );
                        })}
                </Tabs>
            </Box>
        </div>
    );
};

export default VeritcalTabLayout;
