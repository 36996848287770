import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Logo from '../../assets/logo/logo_blankstate_beta_white.png';
import SignUpForm from '../../components/signUp/SignUpForm.component';
import NotFound from '../NotFound.page';

export const SignUpMoblePage = () => {
    const { path } = useRouteMatch();
 
    return (
        <div className='flex flex-row min-h-screen w-screen'>
            <div className='overflow-hidden flex flex-col flex-grow w-full h-screen relative'>
                <div className='bg-mobile-background bg-cover bg-center bg-no-repeat h-2/5 w-full flex items-center justify-center'>
                    <img src={Logo} className='h-8' alt='logo' />
                </div>
                <div className='px-4 py-16 rounded-t-2xl absolute bg-backgroundColor w-full top-1/3 z-10'>
                    <Switch>
                        <Route exact path={[`${path}`]}>
                            <SignUpForm />
                        </Route>
                        <Route component={NotFound} />
                    </Switch>
                </div>
            </div>
        </div>
    );
};
