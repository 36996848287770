import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import { useStore } from '../../../store';
import MobileMenu from '../MobileMenu';
import MobileNavBar from '../NavBar/MobileNavBar';
import { LayoutProps } from './Layout';

const MobileLayout: React.FC<LayoutProps> = ({ children, height = 'auto' }) => {
    const store = useStore();
    const pathname = window.location.pathname;

    useEffect(() => {
        document.body.style.overflow = store.showMobileMenu ? 'hidden' : '';
    }, [store.showMobileMenu]);

    return (
        <div
            className={`flex flex-col overflow-y-hidden orbit_box
        ${pathname.includes('/spaces') ? 'h-screen' : `h-${height}`}`}
        >
            {store.showMobileMenu && <MobileMenu />}
            <div className={`mt-4 mb-16 h-full`}>
                {children}
                {!pathname.includes('/schedule/reschedule') && <MobileNavBar />}
            </div>
        </div>
    );
};

export default observer(MobileLayout);
