interface NodataProps {
    EmptyImage: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string;
        }
    >;
    description: JSX.Element;
    title: string;
    button: JSX.Element;
}

const Nodata: React.FC<NodataProps> = ({ EmptyImage, title, description, button }) => {
    return (
        <div className='empty_box flex items-center justify-center'>
            <div>
                <div className='empty_graph flex items-center justify-center'>
                    <EmptyImage />
                </div>
                <h4>{title}</h4>
                {description}
                {button}
            </div>
        </div>
    );
};

export default Nodata;
