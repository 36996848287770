import { ChangeEvent, useEffect, useState } from 'react';

import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router-dom';

import { useSpaces } from '../../../hooks/useSpaces';
import { store } from '../../../store';
import { SpaceInfo } from '../../../store/SpacesStore';
import { handleNewSpace, handleSpaceDeleted, handleSpaceUpdated } from '../../../utils/chromeExtension';
import { Button } from '../../basic/Button.component';
import HorizontalSeparator from '../../basic/HorizontalSeparator';
import { IconAlert } from '../../basic/IconAlert.component';
import SpaceHeader from '../home/SpaceComponent/SpaceHeader.component';
import DeleteDialog from '../styles/DeleteDialog.component';
import SpaceIcon from './SpaceIcon.component';

const CreateSpace = ({ update = false }: { update?: boolean }) => {
    const [color, setColor] = useState('orange');
    const [currentSpace, setCurrentSpace] = useState<SpaceInfo | undefined>();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [fileValidation, setFileValidation] = useState<string | null>(null);
    const [file, setFile] = useState<File | null>(null);

    const handleUpload = (e: ChangeEvent<HTMLInputElement>) => setFile(e.target.files ? e.target.files[0] : null);

    const history = useHistory();
    const {
        deleteSpace,
        getSpaceDetails,
        setSpaceValues,
        spaceFields,
        updateSpace,
        error,
        createCopilotSpace,
        loading,
        setLoading,
        setError,
        deleteSpaceLogo,
    } = useSpaces();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (update) {
            getSpaceDetails(id).then((res) => setCurrentSpace(res));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (update && currentSpace) {
            const { spaceName, spaceBanner, spaceColor, spaceLogo, spaceIcon } = currentSpace;
            setSpaceValues({
                spaceName,
                spaceBanner,
                spaceColor,
                spaceIcon,
                spaceImage: spaceLogo,
            });
            setColor(spaceColor);
        }
    }, [currentSpace, setSpaceValues, update]);

    useEffect(() => {
        window.scrollTo(0, 0);
        store.spaces.currentSpaceBannerDocumentId = '';
        store.spaces.currentSpaceLogoDocumentId = '';
    }, []);

    const handleSpaceName = (value: string) => {
        if (value.length > 50) setError('Space name should be less than 50 characters');
        else setError(null);
        spaceFields.spaceName.onChange(value);
    };

    return (
        <div className='w-full h-full overflow-y-auto pr-2 no-scrollbar'>
            <div className='absolute right-4 mt-10'>
                <button
                    className='flex space-x-2 items-center text-xs text-darker text-opacity-75'
                    onClick={() => history.goBack()}
                >
                    <KeyboardBackspaceOutlinedIcon
                        style={{
                            fontSize: '16px',
                            color: 'rgba(15, 16, 68, 0.75)',
                        }}
                    />
                    <p className='text-sm'>Back</p>
                </button>
            </div>
            <SpaceHeader
                name={spaceFields.spaceName.value}
                edit
                onChange={handleSpaceName}
                space={currentSpace}
                spaceId={id}
            />
            <div className='relative py-2 w-full h-full mt-40 px-4 md:px-5 md:py-10 md:mt-40'>
                <div className='md:mt-5 max-w-5xl mx-auto text-sm text-darker text-opacity-75 space-y-6'>
                    <p className='text-center font-medium'>{update ? 'Edit' : 'Create a space'}</p>
                    <div className='flex flex-col items-center justify-center space-y-4'>
                        <SpaceIcon
                            letter={spaceFields.spaceName.value[0]}
                            color={color}
                            setColor={setColor}
                            spaceId={id}
                            currentSpace={currentSpace}
                            {...spaceFields.spaceColor}
                            setFileValidation={setFileValidation}
                            onChange={handleUpload}
                        />
                    </div>
                    {error ? (
                        <div className='flex items-center justify-center'>
                            <IconAlert message={error} />
                        </div>
                    ) : (
                        fileValidation && (
                            <div className='flex items-center justify-center'>
                                <IconAlert message={fileValidation} />
                            </div>
                        )
                    )}
                    <Button
                        className='w-full'
                        size='oval'
                        variant='bberryBlue'
                        disabled={
                            loading ||
                            spaceFields.spaceName.value === '' ||
                            error !== null ||
                            fileValidation !== null ||
                            (update &&
                                currentSpace?.spaceName == spaceFields?.spaceName?.value &&
                                !file &&
                                store.spaces.currentSpaceBannerDocumentId === '')
                        }
                        onClick={async () => {
                            setLoading(true);
                            store.spaces.setSpaceName(spaceFields.spaceName.value);
                            store.spaces.currentSpaceColor = color;
                            const { currentSpaceName } = store.spaces;
                            const data: SpaceInfo = {
                                spaceId: id,
                                spaceName: currentSpaceName,
                                spaceIcon: currentSpaceName[0],
                                spaceBanner:
                                    store.spaces.currentSpaceBannerDocumentId !== ''
                                        ? store.spaces.currentSpaceBannerDocumentId
                                        : currentSpace?.spaceBanner
                                        ? currentSpace?.spaceBanner
                                        : '',
                                spaceLogo:
                                    store.spaces.currentSpaceLogoDocumentId !== ''
                                        ? store.spaces.currentSpaceLogoDocumentId
                                        : currentSpace?.spaceLogo
                                        ? currentSpace?.spaceLogo
                                        : '',
                                spaceColor: store.spaces.currentSpaceColor,
                                // @ts-ignore
                                createdById: store.user.userData?.id,
                            };
                            if (update) {
                                if (currentSpace?.spaceId) {
                                    const space = await updateSpace(data, currentSpace.spaceId, file);
                                    if (
                                        currentSpace.spaceLogo !== '' &&
                                        store.spaces.currentSpaceLogoDocumentId === ''
                                    ) {
                                        await deleteSpaceLogo(currentSpace.spaceId);
                                        currentSpace.spaceLogo = '';
                                    }
                                    if (space) {
                                        setLoading(false);
                                        handleSpaceUpdated(data);
                                        history.goBack();
                                    }
                                }
                            } else {
                                const space = await createCopilotSpace(data);
                                if (space) {
                                    setLoading(false);
                                    handleNewSpace(data);
                                    history.push('/workspace/spaces');
                                }
                            }
                        }}
                    >
                        {update ? 'Save' : 'Create'}
                    </Button>
                </div>
                {update && (
                    <div className='flex' style={{ minHeight: '45vh'}}>
                        <div className='w-full mt-auto'>
                            <HorizontalSeparator />
                            <button
                                className='font-semibold text-sm text-error mt-4 mb-2'
                                onClick={() => setShowDeleteDialog(true)}
                            >
                                DELETE SPACE
                            </button>
                            <p className='text-sm text-darker text-opacity-75'>
                                Your Space will be removed permanently and all your saved data will be permanently
                                deleted. Individuals will retain their own resources but not the ones only shared in the
                                Space.
                            </p>
                        </div>
                    </div>
                )}
                {showDeleteDialog && (
                    <DeleteDialog
                        title={currentSpace?.spaceName}
                        description='Deleting a space will delete all its content including messages and files if not linked to your own Workspace. This operation cannot be undone. '
                        close={() => setShowDeleteDialog(false)}
                        open={() => setShowDeleteDialog(true)}
                        handleConfirm={() => {
                            if (currentSpace?.spaceId) {
                                deleteSpace(currentSpace?.spaceId);
                                handleSpaceDeleted(currentSpace?.spaceId);
                            }
                            history.push('/workspace/spaces');
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default observer(CreateSpace);
