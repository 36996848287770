import { useState } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import banner from '../../assets/blueberry/background.png';
import { ReactComponent as CrossIcon } from '../../assets/icons/icon-cross-fill.svg';
import { ReactComponent as DownArrowIcon } from '../../assets/icons/icon-down-arrow.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/icon-logout-new.svg';
import { ReactComponent as NotificationIcon } from '../../assets/icons/icon-notification-fill.svg';
import blankstate from '../../assets/logo/logo_b_dark.png';
import { useStore } from '../../store';
import { ProfilePicture } from '../basic/ProfilePicture';
import NavbarSpaceMenu from './NavBar/NavbarSpaceMenu.component';

const menuItemStyle =
    'hover:bg-primary-darker hover:bg-opacity-3 text-darker text-opacity-75 text-sm ' +
    'hover:text-blue-dark hover:text-opacity-100 rounded flex w-full items-center';

const MobileMenu = () => {
    const store = useStore();
    const userData = store.user.userData;
    const profilePicture = userData?.profilePictureUrl;
    const profileHeader = banner;
    const [openWorkspaceMenu, setOpenWorkspaceMenu] = useState<boolean>(false);

    const spaceClick = () => {
        setOpenWorkspaceMenu(!openWorkspaceMenu);
    };

    const closeMenu = () => {
        store.setShowMobileMenu(false);
    };

    return (
        <div className='fixed top-0 left-0 w-screen h-full z-50 bg-backgroundColor flex flex-col overflow-y-auto scrollbar'>
            <button className={clsx('justify-between mt-4 px-5 py-2', menuItemStyle)} onClick={spaceClick}>
                <div className='flex items-center space-x-4 cursor-pointer'>
                    <div className='bg-white p-2 border rounded-lg border-blue-light border-opacity-10'>
                        <img src={blankstate} alt='logo' className='w-3 h-3' />
                    </div>
                    <div className='text-sm capitalize text-darker text-opacity-75 whitespace-nowrap truncate'>
                        Home
                    </div>
                </div>
                <DownArrowIcon className='w-2 ' />
            </button>

            {openWorkspaceMenu && (
                <div className='bg-white px-2' onClick={closeMenu}>
                    <NavbarSpaceMenu handleClose={closeMenu} />
                </div>
            )}
            <div className='absolute w-full bottom-0'>
                <Link
                    to='/account-details'
                    onClick={closeMenu}
                    className='flex flex-col items-center justify-center space-y-2 p-2 rounded-lg relative h-[88px] mx-4 mt-auto'
                    style={{
                        backgroundImage: `url('${profileHeader}')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    <ProfilePicture path={profilePicture} />
                    <p className='text-sm text-white font-medium'>
                        {userData?.firstName} {userData?.lastName}
                    </p>
                </Link>
                <hr className={`border-blue-dark border-opacity-10 mx-4 my-4`} />
                <div className='flex flex-row items-center justify-between pb-5 px-4'>
                    <Link
                        className='flex flex-row space-x-4 group'
                        to={`/sign-in`}
                        onClick={() => {
                            localStorage.clear();
                            closeMenu();
                            window.location.reload();
                        }}
                    >
                        <LogoutIcon className='h-4 opacity-40 group-hover:opacity-100' fill='#0F1044' />
                        <p className='text-sm text-darker'>Logout</p>
                    </Link>

                    <div className='flex flex-row space-x-5 cursor-pointer '>
                        <button>
                            <NotificationIcon className='w-3 h-3' fill='#0F1044' fillOpacity={0.5} />
                        </button>
                        <button onClick={closeMenu}>
                            <CrossIcon className='w-3 h-3' fill='#0F1044' fillOpacity={0.5} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileMenu;
