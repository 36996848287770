import { useEffect, useRef, useState } from 'react';

export function useAsyncFetcher<T>(fetcher: () => Promise<T>, deps: unknown[]) {
    const [state, setState] = useState({ data: null as T | null, loading: true, error: false });
    const fetcherRef = useRef(fetcher);
    fetcherRef.current = fetcher;
    const pendingRequest = useRef({
        cancel: () => {
            /* do nothing */
        },
    });

    const refresh = async() => {
        if (!state.loading) setState({ ...state, loading: true });
        const callbacks = {
            success: (res: T) => setState({ loading: false, data: res, error: false }),
            error: () => setState({ loading: false, data: null, error: true }),
        };
        pendingRequest.current.cancel();
        fetcher()
            .then((res) => callbacks.success(res))
            .catch(() => callbacks.error());
        const request = {
            cancel() {
                callbacks.success = () => {
                    /* do nothing */
                };
                callbacks.error = () => {
                    /* do nothing */
                };
            },
        };
        pendingRequest.current = request;
        return request;
    };

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    return { ...state, refresh };
}
