import { XCircleIcon } from '@heroicons/react/solid';
import { CheckIcon } from '@heroicons/react/solid';

export type IconAlertProps = {
    message: string;
    className?: string;
    type?: 'error' | 'warning' | 'success';
};

export const IconAlert = ({ message, className = '', type }: IconAlertProps) => {
    return (
        <div className={`text-sm flex ${className}`}>
            {type === 'success' ? (
                <div className='flex-shrink flex flex-row space-x-4'>
                    <CheckIcon color='#373BBA' className='h-5' />
                    <h1 className='text-blue-dark'>{message}</h1>
                </div>
            ): (
                <div className='bg-warning-100 text-warning-100 flex-shrink flex flex-row space-x-4 p-2'>
                    <XCircleIcon color='red' className='h-5' />
                    <h1 className='text-warning'>{message}</h1>
                </div>
            )}
            
        </div>
    );
};
