import { useState } from 'react';

import { ClickAwayListener } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/icon-delete-fill.svg';
import { ReactComponent as DuplicateIcon } from '../../../assets/icons/icon-duplicate.svg';
import { ReactComponent as MoreIcon } from '../../../assets/icons/icon-more2.svg';
import { CreateEvaModalContextProvider, CreateEvaModalOpen } from '../../../contexts/CreateEvaModalContext';
import { useOrganisationForm } from '../../../hooks/useOrganisation';
import { store } from '../../../store';
import { ROLES } from '../../../utils/constants';
import HorizontalSeparator from '../../basic/HorizontalSeparator';
import EvaDialog from './eva/components/EvaDialog.component';

type EvaArchiveCardProps = {
    evaId: string;
    title: string;
    description: string;
    lastEdited: string;
    daysUsed: number;
    triggers: number;
    isUserAdmin: boolean;
    role?: string;
    setCreateEvaModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const EvaArchiveCard = ({
    evaId,
    title,
    description,
    lastEdited,
    daysUsed,
    triggers,
    isUserAdmin,
    role,
}: EvaArchiveCardProps) => {
    return (
        <>
            <CreateEvaModalContextProvider>
                <EvaArchiveCardComponent
                    isUserAdmin={isUserAdmin}
                    triggers={triggers}
                    daysUsed={daysUsed}
                    lastEdited={lastEdited}
                    evaId={evaId}
                    title={title}
                    description={description}
                    role={role}
                />
            </CreateEvaModalContextProvider>
        </>
    );
};

const EvaArchiveCardComponent = ({
    evaId,
    title,
    description,
    lastEdited,
    daysUsed,
    triggers,
    isUserAdmin,
    role,
}: EvaArchiveCardProps) => {
    const [showPopUp, setShowPopUp] = useState(false);

    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
    const { deleteEvaByEvaId, duplicateEva } = useOrganisationForm();
    const history = useHistory();
    const { id: organisationId } = useParams<{ id: string }>();

    const { setCreateEvaModalOpen, setArchiveEvaId } = CreateEvaModalOpen();

    const handleOpen = () => {
        setArchiveEvaId(evaId);
        if (isUserAdmin || role === ROLES.Architect) {
            setCreateEvaModalOpen(true);
            setTimeout(() => {
                history.push(`/organisation/${organisationId}/eva`);
            }, 300);
        }
    };


    const handleMoreButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setShowPopUp(!showPopUp);
    };

    const handleDeleteEva = async () => {
        if (evaId) {
            await deleteEvaByEvaId(evaId);
            store.organisation.deleteArchivedEVA(evaId);
        }
    };

    const handleDuplicateEva = async () => {
        if (evaId) {
            const newEVA = await duplicateEva(evaId);
            store.organisation.duplicateArchivedEVA(newEVA);
        }
    };

    return (
        <>
            <EvaDialog
                open={isOpenDeleteModal}
                setClose={setIsOpenDeleteModal}
                onConfirm={handleDeleteEva}
                disabled={false}
                title='Delete EVA'
                content={
                    <div>
                        This action cannot be undone. Are you sure want to delete{' '}
                        <span className='font-semibold'>{title}</span> ?
                    </div>
                }
                confirmButtonText='Delete'
                buttonVariant='rejectRed'
            />
            <div
                className='h-auto bg-white border border-blue-dark border-opacity-10 rounded-md p-4 cursor-pointer'
                onClick={handleOpen}
            >
                <div className='flex justify-between items-center'>
                    <label className='text-sm font-medium text-darker text-opacity-75 truncate'>{title}</label>
                    <div className='flex space-x-2'>
                        <label className='text-sm text-darker text-opacity-50 font-normal whitespace-nowrap'>
                            Last edited {lastEdited}
                        </label>
                        <button onClick={handleMoreButtonClick}>
                            <MoreIcon className='w-3 h-3' fill='#0F1044' fillOpacity='0.5' />
                            {showPopUp && (
                                <ClickAwayListener onClickAway={() => setShowPopUp(false)}>
                                    <div className='w-60 absolute ml-5 rounded-md py-4 z-10 bg-white shadow-message-container'>
                                        <button
                                            className='w-full flex space-x-2 text-sm text-darker text-opacity-75 px-4 py-3 items-center hover:bg-primary-darker hover:bg-opacity-3 disabled:cursor-not-allowed'
                                            onClick={handleDuplicateEva}
                                            disabled={!isUserAdmin}
                                        >
                                            <DuplicateIcon className='w-3 h-3' fill='#0F1044' fillOpacity={0.75} />
                                            <p>Duplicate</p>
                                        </button>
                                        <HorizontalSeparator className='my-2' />
                                        <button
                                            className='w-full flex space-x-2 text-sm text-darker text-opacity-75 px-4 py-3 items-center hover:bg-primary-darker hover:bg-opacity-3 disabled:cursor-not-allowed'
                                            onClick={() => setIsOpenDeleteModal(true)}
                                            disabled={!isUserAdmin}
                                        >
                                            <DeleteIcon className='w-3 h-3' fill='#0F1044' />
                                            <p className='text-center'>Delete</p>
                                        </button>
                                    </div>
                                </ClickAwayListener>
                            )}
                        </button>
                    </div>
                </div>
                <div className='text-xs mt-5'>
                    <label className='text-darker font-normal text-opacity-75 multi-line-ellipsis'>{description}</label>
                </div>
                <div className='mt-5 rounded-md bg-primary-darker bg-opacity-3 p-2 space-y-2'>
                    <div className='text-sm text-darker text-opacity-75 font-normal'>
                        <p>
                            <strong>{daysUsed}</strong> days used
                        </p>
                    </div>
                    <div className='flex text-xs text-darker text-opacity-75 justify-between items-center'>
                        <p>
                            <strong className='text-bberry-blue'>{triggers}</strong> triggers
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EvaArchiveCard;
