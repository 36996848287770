import { useState } from 'react';

import { useParams } from 'react-router-dom';

import { timestampToDate } from '../../../../utils/dateUtils';
import { Button } from '../../../basic/Button.component';
import { Dialog } from '../../../basic/Dialog.component';

const Downgrade = ({
    organisationName,
    endDate,
    handleDowngrade,
}: {
    organisationName: string;
    endDate: number;
    handleDowngrade: () => void;
}) => {
    const [showDialog, setShowDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const open = () => setShowDialog(true);
    const close = () => setShowDialog(false);
    const { id: organisationId } = useParams<{ id: string }>();
    if (organisationId) localStorage.setItem('spaceId', organisationId);

    const handleConfirm = async () => {
        setLoading(true);
        handleDowngrade();
        setTimeout(async () => {
            setLoading(false);
            close();
        }, 2000);
    };

    return (
        <div className='w-full h-full'>
            {showDialog && (
                <Dialog
                    handleConfirm={handleConfirm}
                    title={`Confirm Subscription cancellation`}
                    close={close}
                    open={open}
                    loading={loading}
                    content={
                        <div className='h-14 text-sm text-darker text-opacity-75'>
                            {/* By cancelling <strong>{organisationName}</strong> subscription, you will no longer have access
                            to <strong>blankstate’s EVA</strong> features and <strong>Members</strong> will be removed
                            from it.  */}
                            The cancellation with be effective at the end of the current billing period on the{' '}
                            <strong>{timestampToDate(endDate)}</strong>
                        </div>
                    }
                    confirmText='Cancel Subscription'
                    buttonVariant='primaryCancel'
                />
            )}
            <div className='w-full'>
                <div className='w-full p-4 flex items-center justify-between'>
                    <div className='max-w-3xl text-darker text-opacity-75 mr-14'>
                        <h3 className='font-medium text-sm mb-2'>Cancel {organisationName}</h3>
                        <p className='text-xs whitespace-pre-line font-normal'>
                            If you cancel the Organisation now, you will no longer be charged monthly but you can still
                            use the Features account until{' '}
                            <strong className='text-darker text-opacity-75'>{timestampToDate(endDate)}</strong>.
                        </p>
                    </div>
                    <Button onClick={open} variant='primaryCancel' size='oval' className='whitespace-nowrap'>
                        Cancel Subscription
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Downgrade;
