import { appInsights } from '../../AppInsights';
import { NetworkMock } from '../../mocks/NetworkMock';
import { store } from '../../store';
import { GetStartedValues } from '../../store/SignUpStore';
import { get, post } from '../../utils/network';
import { AuthServiceInterface } from './auth.service.interface';

const user = store.user;

export class AuthService implements AuthServiceInterface {
    constructor(private networkMock: NetworkMock) {}

    async login(email: string, password: string, userType: string) {
        try {
            return await post('/auth/login', { email, password, userType });
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
            throw err;
        }
    }

    async renewToken() {
        if (store.user && store.user.token) {
            try {
                const { access_token } = await post('/auth/renew-token');
                store.user.setToken(access_token);
            } catch (err) {
                console.error(err);
                if (err instanceof Error) {
                    appInsights.trackException({ exception: err });
                }
            }
        }
    }

    async logout(): Promise<void> {
        user.setToken(null);
        // Nothing
    }

    async sendConfirmationEmail(email: string): Promise<void> {
        try {
            await post('/auth/verification', { email });
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    async sendInvitationEmail(email: string): Promise<void> {
        try {
            await post('auth/invitation', { email: email, businessEmail: user.userData?.email });
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    async signup(getStartedValues: GetStartedValues): Promise<void> {
        try {
            await post('/users/sign-up', { getStartedValues });
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    async getUserInformations(userId: number) {
        try {
            return await get('/users?id=' + userId);
        } catch (e) {
            if (e instanceof Error) {
                appInsights.trackException({ exception: e });
            }
        }
    }

    async getProfile() {
        try {
            return await get('auth/profile');
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    async hasValidEmail(email: string) {
        try {
            return await get(`auth/validation/${email}`);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    async updatePassword(email: string, password: string) {
        try {
            return await post('auth/update-password', { email, password });
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    async requestPasswordReset(email: string) {
        try {
            
            const response=await post('auth/request-password-reset', { email });
            
            return response;
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
                return { success: false, message: err.message };
            }
        }
    }

    async resetPassword(token: string, password: string) {
        try {
            return await post('auth/reset-password', { token, password });
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    async checkIfPasswordMatches(password: string) {
        try {
            return await post('auth/check-password', { password });
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }
}
