import { Skeleton } from '@mui/material';

type TableSkeletonProps = {
    rows: number;
    className?: string;
};

const TableSkeleton = ({ rows, className }: TableSkeletonProps) => {
    const tableRows: JSX.Element[] = [];

    for (let i = 0; i < rows; i++) {
        tableRows.push(<Skeleton key={i} variant='rectangular' width={'100%'} height={32} sx={{ borderRadius: '7px' }} />);
    }

    return (
        <div className={className}>
            <Skeleton variant='text' sx={{ fontSize: '12px' }} />
            <div className='mt-3 space-y-3'>{tableRows}</div>
        </div>
    );
};

export default TableSkeleton;
