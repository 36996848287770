import { Document, Image as Img, Line, Page, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';

import BlankstateLogo from '../../../assets/blueberry/logo_blankstate_dark.png';

export type Summary = {
    id: string;
    date: Date;
    periodStartDate: Date;
    periodEndDate: Date;
    transactions: {
        date: Date;
        id: string;
        paymentMethod: string;
        amount: number;
        description: string;
    }[];
    paymentMethod: {
        method: string;
        type: string;
        cardNumber: string;
    };
};

type BillingSummaryProps = {
    summary: Summary;
};

export const BillingSummary = ({ summary }: BillingSummaryProps) => {
    const { id, date, periodStartDate, periodEndDate, transactions, paymentMethod } = summary;

    const total: number = transactions.reduce((res, curr) => {
        return res + curr.amount;
    }, 0);

    return (
        <Document>
            <Page size='A4' style={styles.page}>
                <View style={styles.header}>
                    <View style={{ width: '60%' }}>
                        <View style={styles.logoDiv}>
                            <Img src={BlankstateLogo} style={styles.logo} />
                        </View>
                        <Text style={styles.underLogo}>
                            TraceFlow. Ltd.71-75 Shelton Street, Covent Garden, WC2H 9JQ London, United Kingdom
                        </Text>
                    </View>
                    <View style={{ width: '40%' }}>
                        <Text style={styles.invoiceTitle}>SUBSCRIPTION INVOICE</Text>
                        <Text style={styles.invoiceShortInformation}>Invoice {id}</Text>
                        <Text style={styles.invoiceShortInformation}>
                            Invoice Date {dayjs(date).format('DD MMM YYYY hh:mm UTC')}
                        </Text>
                        <Text style={styles.invoiceShortInformation}>
                            Service Period {dayjs(periodStartDate).format('MMM DD YYYY')} to{' '}
                            {dayjs(periodEndDate).format('MMM DD YYYY')}
                        </Text>
                        <Text style={styles.invoiceShortInformation}>Amount £{Number(total).toFixed(2)} (GBP)</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellRef}>Date</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellRef}>Description</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={[styles.tableCellRef, { textAlign: 'right' }]}>Amount</Text>
                        </View>
                    </View>
                    {transactions.map((transaction) => (
                        <View key={transaction.id} style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{dayjs(transaction.date).format('MMM DD, YYYY')}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={[styles.tableCell, { fontWeight: 600 }]}>
                                    {/*todo transaction description*/}
                                    {transaction.description}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={[styles.tableCell, { marginLeft: '72%' }]}>
                                    £{Number(transaction.amount).toFixed(2)}
                                </Text>
                            </View>
                        </View>
                    ))}
                    <Svg height='1' width='100%'>
                        <Line x1='0' y1='0' x2='1000' y2='0' strokeWidth={1} stroke='rgb(228,228,228)' />
                    </Svg>
                    <View style={styles.total}>
                        <Text style={{ fontWeight: 'bold', marginRight: 20 }}>TOTAL</Text>
                        <Text>£{Number(total).toFixed(2)}</Text>
                    </View>
                    <View style={styles.footer}>
                        <View>
                            <Text style={styles.paymentTitle}>PAYMENT METHOD</Text>
                            <Svg style={{ marginBottom: 10, marginTop: 10 }} height='1' width='100%'>
                                <Line x1='0' y1='0' x2='1000' y2='0' strokeWidth={1} stroke='rgb(228,228,228)' />
                            </Svg>
                            <View style={styles.paymentRow}>
                                <Text style={styles.paymentDetailsNames}>Method</Text>
                                <Text style={styles.paymentDetailsNames}>Type</Text>
                                <Text style={styles.paymentDetailsNames}>Credit Card Number</Text>
                            </View>
                            <View style={styles.paymentRow}>
                                <Text style={styles.paymentDetails}>{paymentMethod.method}</Text>
                                <Text style={styles.paymentDetails}>{paymentMethod.type}</Text>
                                <Text style={styles.paymentDetails}>{paymentMethod.cardNumber}</Text>
                            </View>
                        </View>
                        <View>
                            <Text style={{ fontSize: 8, textAlign: 'center', marginTop: 20, color: '#1E1F24' }}>
                                TraceFlow. Ltd. 71-75 Shelton Street, Covent Garden, WC2H 9JQ London, United Kingdom.
                                Company number 12302383
                            </Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#ffffff',
        padding: 60,
        color: '#0F1044',
        height: '100%',
        position: 'relative',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
    },
    logoDiv: {
        width: '40%',
    },
    logo: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
    underLogo: {
        width: '90%',
        fontSize: 10,
        marginTop: 10,
    },
    invoiceTitle: {
        marginBottom: 20,
        fontSize: 12,
        fontWeight: 600,
    },
    invoiceShortInformation: {
        fontSize: 10,
        marginBottom: 1,
    },
    table: {
        display: 'flex',
        width: 'auto',
        marginTop: 50,
    },
    tableRow: {
        margin: 'auto',
        flexDirection: 'row',
    },
    tableCol: {
        width: '33%',
    },
    tableCell: {
        marginLeft: 10,
        marginTop: 5,
        fontSize: 10,
        paddingBottom: 10,
        paddingTop: 10,
    },
    tableCellRef: {
        fontSize: 10,
        backgroundColor: '#0F1044',
        color: '#ffffff',
        padding: 10,
    },
    total: {
        fontSize: 10,
        marginLeft: '77%',
        padding: 10,
        textAlign: 'left',
        flexWrap: 'nowrap',
        display: 'flex',
        flexDirection: 'row',
    },
    footer: {
        marginTop: '30%',
        fontSize: 10,
    },
    footerItems: {
        marginBottom: 5,
    },
    paymentTitle: {
        fontSize: 12,
        fontWeight: 600,
    },
    paymentRow: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
    },
    paymentDetailsNames: {
        width: '33%',
        fontWeight: 600,
    },
    paymentDetails: {
        width: '33%',
        textTransform: 'capitalize',
    },
});
