import React, { useEffect, useState } from 'react';

import { useQuery } from 'react-query';

import './evaAnalysisStyle.css';
import AwardIcon from '../../../../../../../src/assets/icons/AwardIcon.svg';
import AwardIconDisable from '../../../../../../../src/assets/icons/AwardIconDisable.svg';
import CustomDisable from '../../../../../../../src/assets/icons/Custom_disable.svg';
import GraphLine from '../../../../../../../src/assets/icons/GraphLine.svg';
import GraphLineDisable from '../../../../../../../src/assets/icons/GraphLineDisable.svg';
import GraphLineDown from '../../../../../../../src/assets/icons/GraphLineDown.svg';
import NotePolicy from '../../../../../../../src/assets/icons/NotePolicy.svg';
import NotePolicyDisable from '../../../../../../../src/assets/icons/NotePolicyDisable.svg';
import OverviewName from '../../../../../../../src/assets/icons/Overview_name.svg';
import OverviewNameDisable from '../../../../../../../src/assets/icons/Overview_name_Disable.svg';
import OverviewPerIcon from '../../../../../../../src/assets/icons/shaded-round.svg';
import OverviewPerIconDisable from '../../../../../../../src/assets/icons/shaded-round_Disable.svg';
import StarUser from '../../../../../../../src/assets/icons/StarUser.svg';
import StarUserDisable from '../../../../../../../src/assets/icons/StarUserDisable.svg';
import StartUserProfile from '../../../../../../../src/assets/icons/StarUserProfile.svg';
import { ReactComponent as PublishedDisablePlanet } from '../../../../../../assets/images/published-disable-planet.svg';
import { EvaService } from '../../../../../../services/eva/eva.service';
import { getDocumentUrl } from '../../../../../../utils/getDocumentUrl';
import { AnimatedCircle } from '../../../../../basic/AnimatedCircle';
import { ProtocolInsights } from '../../../evaAnalytics/components/analyticsChart/types';
import { OptionData } from '../ProtocolTypeList';
import Orbit from './Orbit';

interface EvaOrbitProps {
    enablePlanet: boolean;
    labelShow: boolean;
    isCreateEvaModalOpen: boolean;
    setCreateEvaModalOpen: (value: boolean) => void;
    evaId: string;
    setPublishedEvaExists: (value: boolean) => void;
    setDraftEvaExists: (value: boolean) => void;
    protocolIndex: number | null;
}

export const EvaOrbit: React.FC<EvaOrbitProps> = ({
    enablePlanet,
    labelShow,
    isCreateEvaModalOpen,
    setCreateEvaModalOpen,
    evaId,
    setPublishedEvaExists,
    setDraftEvaExists,
    protocolIndex,
}) => {
    const [publishedEvaExist, setPublishedEvaExist] = useState(true);

    useEffect(() => {
        if (!publishedEvaExist) {
            setPublishedEvaExists(false);
            setDraftEvaExists(false);
        }
    }, [publishedEvaExist, setPublishedEvaExists, setDraftEvaExists]);

    const evaService = new EvaService();

    const { data: protocolInsights } = useQuery(
        ['ProtocolInsights', evaId],
        async () => {
            if (evaId) {
                const protocolInsight: ProtocolInsights = await evaService.getProtocolInsights(evaId);
                const protocolInsightData = { ...protocolInsight, evaId };
                sessionStorage.setItem(`protocol-${evaId.slice(-8)}`, JSON.stringify(protocolInsightData));
                return protocolInsight;
            }
        },
        {
            refetchInterval: 7200000, // Poll every 2 hour
            refetchOnWindowFocus: false, // Refetch on  page refresh
            staleTime: 7200000, // Keep data fresh for 2 hour
            enabled: !!evaId,
            initialData: () => {
                const storedData = sessionStorage.getItem(`protocol-${evaId.slice(-8)}`);
                if (storedData) {
                    const parsedStoredData = JSON.parse(storedData);
                    if (parsedStoredData.evaId === evaId) {
                        return parsedStoredData;
                    }
                }
                return undefined;
            },
        },
    );
    const organizationName = localStorage.getItem('organisationName');
    const disable = false;

    const getTypeImage = OptionData.find(
        (item) =>
            protocolInsights?.topProtocol &&
            protocolInsights?.topProtocol.length > 0 &&
            item.name === protocolInsights?.topProtocol[0].type,
    );

    useEffect(() => {
        const mainOutsideEva = document.querySelector('.main_outside_eva');
        if (mainOutsideEva) {
            const toggleHighlight = (action: 'add' | 'remove') => {
                mainOutsideEva?.classList[action]('z-999');
            };
            const overviewElements = document.querySelectorAll('.overview_info');
            overviewElements?.forEach((element) => {
                element.addEventListener('mouseenter', () => toggleHighlight('add'));
                element.addEventListener('mouseleave', () => toggleHighlight('remove'));
            });

            // Cleanup event listeners on unmount
            return () => {
                overviewElements?.forEach((element) => {
                    element.removeEventListener('mouseenter', () => toggleHighlight('add'));
                    element.removeEventListener('mouseleave', () => toggleHighlight('remove'));
                });
            };
        }
    }, []);

    return (
        <>
            <div className={`${disable ? 'disable' : ''} overview_top`}>
                <div className='overview_info totalEngagement'>
                    <div className='img'>
                        <img
                            src={protocolInsights?.totalEngagement === null ? OverviewPerIconDisable : OverviewPerIcon}
                            alt='OverviewPerIcon'
                        />
                    </div>

                    <p className={`${protocolInsights?.totalEngagement == null ? 'no_content_box' : ''}`}>
                        {protocolInsights?.totalEngagement == null
                            ? '%'
                            : `${protocolInsights?.totalEngagement?.total_percentage}%`}
                    </p>

                    {protocolInsights?.totalEngagement !== null && (
                        <div className='tooltip_box'>
                            <span>This metric gives you an overall understanding of the user base engagement.</span>
                        </div>
                    )}
                </div>
                <div className='overview_info topProtocol'>
                    <div className='img'>
                        <img
                            src={protocolInsights?.topProtocol !== null ? getTypeImage?.fillIcon : CustomDisable}
                            alt=''
                        />
                    </div>

                    <p className={`${protocolInsights?.topProtocol == null ? 'no_content_box' : ''}`}>
                        {protocolInsights && protocolInsights.topProtocol === null
                            ? 'No Protocol'
                            : protocolInsights?.topProtocol &&
                              protocolInsights?.topProtocol.length > 0 &&
                              protocolInsights?.topProtocol[0].type}
                    </p>

                    {protocolInsights?.topProtocol !== null && (
                        <div className='tooltip_box'>
                            <span>
                                This statistic can help you identify the area of greatest interest or need among &nbsp;
                                {organizationName} ’s members{' '}
                            </span>
                        </div>
                    )}
                </div>
                {/* <div className='overview_info mostUsedProtocol'>
                    <div className='img'>
                        <img
                            src={protocolInsights?.mostUsedProtocol === null ? OverviewNameDisable : OverviewName}
                            alt='OverviewPerIcon'
                        />
                    </div>

                    {protocolInsights?.mostUsedProtocol === null ? (
                        <p className='no_content_box'> No Protocol </p>
                    ) : (
                        <p>NAMEOFPROTOCOLLOREMIPS</p>
                    )}
                    {protocolInsights?.mostUsedProtocol !== null && (
                        <div className='tooltip_box'>
                            <span> NameofProtocol is garnering the most attention or usage.</span>
                        </div>
                    )}
                </div> */}

                <div className='overview_info topContributor'>
                    <div className='img'>
                        <img
                            src={protocolInsights?.topContributor === null ? AwardIconDisable : AwardIcon}
                            alt='OverviewPerIcon'
                        />
                    </div>
                    <div className='img'>
                        <img
                            className='organisation-img'
                            src={
                                protocolInsights &&
                                protocolInsights.topContributor &&
                                protocolInsights.topContributor.documentId
                                    ? getDocumentUrl(+protocolInsights.topContributor.documentId)
                                    : StartUserProfile
                            }
                            alt='OverviewPerIcon'
                        />
                    </div>

                    <p
                        className={`${
                            protocolInsights?.topContributor === null ||
                            protocolInsights?.topContributor.firstName.length == 0
                                ? 'no_content_box'
                                : ''
                        }`}
                    >
                        {protocolInsights?.topContributor === null ||
                        protocolInsights?.topContributor.firstName.length == 0
                            ? ' No Contributor'
                            : protocolInsights?.topContributor.firstName}
                    </p>

                    {protocolInsights?.topContributor !== null && (
                        <div className='tooltip_box'>
                            <span>
                                {' '}
                                Congrats {protocolInsights?.topContributor.firstName}{' '}
                                {protocolInsights?.topContributor.lastName} for all Contribution! 🎉
                                <br /> Identify valuable sources of knowledge and expertise.
                            </span>
                        </div>
                    )}
                </div>

                <div className='overview_info complianceScore'>
                    <div className='img'>
                        <img
                            src={protocolInsights?.complianceScore === null ? NotePolicyDisable : NotePolicy}
                            alt='OverviewPerIcon'
                        />
                    </div>
                    <p className={`${protocolInsights?.complianceScore === null ? 'no_content_box' : ''}`}>
                        {protocolInsights && protocolInsights?.complianceScore === null
                            ? '%'
                            : `${protocolInsights?.complianceScore?.average_score}%`}{' '}
                    </p>

                    {protocolInsights?.complianceScore !== null && (
                        <div className='tooltip_box'>
                            <span> Quick pulse on {organizationName} ’s compliance posture. </span>
                        </div>
                    )}
                </div>

                <div className='overview_info riskTrend'>
                    <div className='img'>
                        <img
                            src={
                                protocolInsights?.riskTrend === null
                                    ? GraphLineDisable
                                    : protocolInsights?.riskTrend > 0
                                    ? GraphLine
                                    : protocolInsights?.riskTrend < 0
                                    ? GraphLineDown
                                    : GraphLineDisable
                            }
                            alt='OverviewPerIcon'
                        />
                    </div>

                    <p className={`${protocolInsights?.riskTrend === null ? 'no_content_box' : ''}`}>
                        {' '}
                        {protocolInsights?.riskTrend === null ? '%' : `${protocolInsights?.riskTrend}%`}
                    </p>

                    {protocolInsights?.riskTrend !== null && (
                        <div className='tooltip_box'>
                            <span> Track Risk Trend of critical incidents over time </span>
                        </div>
                    )}
                </div>

                <div className='overview_info latestMember'>
                    <div className='img'>
                        <img src={disable ? StarUserDisable : StarUser} alt='OverviewPerIcon' />
                    </div>
                    {!disable && (
                        <img
                            className='organisation-img'
                            src={
                                protocolInsights &&
                                protocolInsights.latestMember &&
                                protocolInsights.latestMember.documentId
                                    ? getDocumentUrl(+protocolInsights.latestMember.documentId)
                                    : StartUserProfile
                            }
                            alt='OverviewPerIcon'
                        />
                    )}
                    <p
                        className={`${
                            (protocolInsights && protocolInsights.latestMember === null) ||
                            (protocolInsights?.latestMember?.firstName.length == 0 && 'no_content_box')
                        }`}
                    >
                        {' '}
                        {(protocolInsights && protocolInsights.latestMember === null) ||
                        protocolInsights?.latestMember?.firstName.length == 0
                            ? 'No Name'
                            : protocolInsights?.latestMember?.firstName}
                    </p>
                    {protocolInsights?.latestMember !== null && (
                        <div className='tooltip_box'>
                            <span>
                                Welcome to {protocolInsights && protocolInsights?.latestMember.firstName}{' '}
                                {protocolInsights && protocolInsights?.latestMember.lastName}! 👏 <br />
                                Your latest Member to Join {organizationName}’s Consensus!
                            </span>
                        </div>
                    )}
                </div>
            </div>

            <div className='eva-orbit-space '>
                <div className=' relative w-full h-full'>
                    <div className='absolute w-full h-full top-0 left-0' style={{ pointerEvents: 'none' }}>
                        <Orbit
                            labelShow={labelShow}
                            isCreateEvaModalOpen={isCreateEvaModalOpen}
                            isEnableEva={enablePlanet}
                            protocolIndex={protocolIndex}
                        />
                    </div>

                    <div className='flex justify-between items-center w-full h-full'>
                        <>
                            <div className='flex justify-center outer_img items-center relative w-full h-full'>
                                {enablePlanet ? (
                                    <AnimatedCircle />
                                ) : (
                                    <PublishedDisablePlanet className='svgComponent absolute' />
                                )}
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </>
    );
};
