import { ReactComponent as AmericanExpressIcon } from '../../assets/icons/icon-americanexpress.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/icon-check-filled.svg';
import { ReactComponent as MastercardIcon } from '../../assets/icons/icon-mastercard.svg';
import { ReactComponent as VisaIcon } from '../../assets/icons/icon-visa.svg';

type CardCheckButtonProps = {
    checked: boolean;
    onClick: () => void;
};

const buttonStyle = 'bg-white shadow-consult hover:shadow-message-container';
const buttonCheckedStyle = 'border border-blue bg-primary-400 ';

const CardCheckButton = ({ checked, onClick }: CardCheckButtonProps) => {
    return (
        <button
            className={`relative rounded-md h-12 w-32 ${checked ? buttonCheckedStyle : buttonStyle}
                flex items-center justify-center space-x-2`}
            onClick={onClick}
        >
            <VisaIcon />
            <MastercardIcon />
            <AmericanExpressIcon />
            {checked && (
                <div
                    className='absolute -top-2 -right-2 w-4 h-4 rounded-full bg-blue
                        flex items-center justify-center'
                >
                    <CheckIcon fill='white' />
                </div>
            )}
        </button>
    );
};

export default CardCheckButton;
