import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { ReactComponent as CheckIcon } from '../../assets/icons/icon-check-filled.svg';
import { mockable } from '../../utils/mockable';
import { Button } from '../basic/Button.component';

export const useThanks = mockable(() => {
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        localStorage.setItem('first-visit', 'true');
    }, []);

    const onSubmit = async () => {
        setIsLoading(true);
        history.push(`/onboarding/business-informations`);
        setIsLoading(false);
    };
    return { onSubmit, isLoading };
});

export const Thanks = () => {
    const { onSubmit, isLoading } = useThanks();

    const isExpert: boolean = localStorage.getItem('expert-sign-up') === 'true';

    return (
        <div className='w-screen h-screen'>
            <div className={`w-full h-full px-4 ${isExpert ? 'lg:w-4/6 ml-auto justify-end' : ''}`}>
                <div
                    className={`flex flex-col justify-center items-center h-full 
                ${isExpert ? 'text-darker text-opacity-75' : 'text-darker'}`}
                >
                    <div
                        className={`rounded-full h-10 w-10 flex justify-center items-center 
                    ${isExpert ? 'border-2 border-blue' : 'bg-blue'}`}
                    >
                        <CheckIcon fill={isExpert ? '#373BBA' : 'white'} width={18} height={18} />
                    </div>
                    <p className={`text-xl font-semibold ${isExpert ? 'mt-6' : 'mt-3 md:text-3xl'}`}>
                        {isExpert ? 'Thank you for Signing Up' : 'Thanks for Signing Up!'}
                    </p>
                    {isExpert && <p className='mt-2 mb-4 text-sm'>Time to create an edge!</p>}
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            onSubmit();
                        }}
                    >
                        <Button
                            className={`h-10 px-8 ${isExpert ? '' : 'mt-7'}`}
                            variant='primaryBlue'
                            size='oval'
                            children='Get In'
                            type='submit'
                            disabled={isLoading}
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};
