import { useState } from 'react';

import clsx from 'clsx';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { appInsights } from '../../../AppInsights';
import { ReactComponent as BackIcon } from '../../../assets/icons/icon-arrow-left-long.svg';
import { store } from '../../../store';
import { ORGANISATION_TIERS, SUBSCRIPTION_BUTTON_LABEL, SUBSCRIPTION_STATUS } from '../../../store/OrganisationStore';
import { SUBSCRIPTION_TYPE } from '../../../utils/constants';
import { useServices } from '../../ServiceProvider';
import TierCard from './TierCard';
import TierTable from './TierTable';

const OrgainisationTier = ({ subscriptionExist }: { subscriptionExist?: boolean }) => {
    const { id } = useParams<{ id: string }>();
    const { organisation } = useServices();
    const history = useHistory();
    const { data: subscription } = useQuery(['getSubscription', id], () => {
        try {
            return organisation.getSubscription(id);
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
            }
        }
    });
    const [selectedTierType, setSelectedTierType] = useState(subscription?.type || SUBSCRIPTION_TYPE.YEARLY);

    const handleSelectTier = (tier: string) => {
        store.organisation.setSelectedTier(tier);
        if (subscriptionExist) {
            history.push(`/organisation/${id}/${selectedTierType}/confirm-plan`);
        } else history.push(`/create-organisation/${id}/${selectedTierType}/confirm-plan`);
    };

    const handleSubscriptionButtonName = (name: string, type: string) => {
        if (subscriptionExist) {
            if (
                subscription?.productName === name &&
                subscription.type === selectedTierType &&
                subscription.status !== SUBSCRIPTION_STATUS.CANCELED
            ) {
                return SUBSCRIPTION_BUTTON_LABEL.CURRENT_TIER;
            } else if (
                subscription?.productName === name &&
                subscription.type === selectedTierType &&
                subscription.status === SUBSCRIPTION_STATUS.CANCELED
            ) {
                return SUBSCRIPTION_BUTTON_LABEL.SUBSCRIBE;
            } else if (
                subscription?.productName === ORGANISATION_TIERS.OVERSIGHT &&
                name === ORGANISATION_TIERS.SHIELD
            ) {
                return SUBSCRIPTION_BUTTON_LABEL.DOWNGRADE;
            } else if (
                subscription?.type === SUBSCRIPTION_TYPE.YEARLY &&
                subscription?.productName === ORGANISATION_TIERS.SHIELD &&
                name === ORGANISATION_TIERS.SHIELD
            ) {
                return SUBSCRIPTION_BUTTON_LABEL.DOWNGRADE;
            } else if (
                subscription?.type === SUBSCRIPTION_TYPE.YEARLY &&
                subscription?.productName === ORGANISATION_TIERS.OVERSIGHT &&
                name === ORGANISATION_TIERS.OVERSIGHT
            ) {
                return SUBSCRIPTION_BUTTON_LABEL.DOWNGRADE;
            } else {
                return SUBSCRIPTION_BUTTON_LABEL.UPGRADE;
            }
        } else {
            return undefined;
        }
    };

    const handleDisable = (name: string) => {
        if (subscriptionExist) {
            if (
                subscription?.productName === name &&
                subscription.type === selectedTierType &&
                subscription.status !== SUBSCRIPTION_STATUS.CANCELED
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    return (
        <div className='w-full h-full flex justify-center'>
            <button
                onClick={() => history.goBack()}
                className='absolute top-10 mt-10 right-6 flex items-center space-x-2 ml-auto h-8'
            >
                <BackIcon />
                <p className='text-sm text-darker text-opacity-75'>Back</p>
            </button>
            <div className='mx-auto max-w-5xl w-full mt-10 md:mt-0'>
                <div className='mt-5 mb-10 flex flex-col justify-center items-center'>
                    <div className='text-4xl text-royal-blue text-center font-bold'>
                        <p>Pick your Tier</p>
                    </div>
                    <div className='text-sm text-darker text-opacity-75 font-normal'>
                        <p>Privacy-First Organization Dynamics Insight</p>
                    </div>
                </div>
                <div className='flex justify-end text-darker text-opacity-75 text-sm'>
                    <div className='bg-white rounded-full px-1 flex space-x-2 items-center'>
                        <button
                            className={clsx(
                                'flex space-x-2 items-center font-medium py-3 px-6 rounded-full',
                                selectedTierType === SUBSCRIPTION_TYPE.YEARLY && 'text-white bg-primary-darker',
                            )}
                            onClick={() => setSelectedTierType(SUBSCRIPTION_TYPE.YEARLY)}
                        >
                            <p>Annual</p>
                            <div className='text-xs bg-warning-100 p-1 rounded-md'>
                                <p className='text-orange'>20% off</p>
                            </div>
                        </button>
                        <button
                            className={clsx(
                                'rounded-full py-3 px-6 font-medium items-center',
                                selectedTierType === SUBSCRIPTION_TYPE.MONTHLY && 'text-white bg-primary-darker',
                            )}
                            onClick={() => setSelectedTierType(SUBSCRIPTION_TYPE.MONTHLY)}
                        >
                            <p>Monthly</p>
                        </button>
                    </div>
                </div>
                <div className='flex justify-end space-x-4 mt-4'>
                    <TierCard
                        title={ORGANISATION_TIERS.SHIELD}
                        description='Eliminate Liability Exposure in Real-Time.'
                        price={selectedTierType === SUBSCRIPTION_TYPE.MONTHLY ? 195 : 156}
                        onClick={() => handleSelectTier(ORGANISATION_TIERS.SHIELD)}
                        disabled={handleDisable(ORGANISATION_TIERS.SHIELD)}
                        buttonName={handleSubscriptionButtonName(ORGANISATION_TIERS.SHIELD, selectedTierType)}
                        showAnnualBilling={selectedTierType === SUBSCRIPTION_TYPE.YEARLY}
                    />
                    <TierCard
                        title={ORGANISATION_TIERS.OVERSIGHT}
                        description='Ever Wondered? Now you Know.'
                        bgColor='rgba(0, 20, 255, 0.03)'
                        price={selectedTierType === SUBSCRIPTION_TYPE.MONTHLY ? 395 : 316}
                        buttonClass='oversight-button'
                        onClick={() => handleSelectTier(ORGANISATION_TIERS.OVERSIGHT)}
                        buttonName={handleSubscriptionButtonName(ORGANISATION_TIERS.OVERSIGHT, selectedTierType)}
                        disabled={handleDisable(ORGANISATION_TIERS.OVERSIGHT)}
                        showAnnualBilling={selectedTierType === SUBSCRIPTION_TYPE.YEARLY}
                    />
                </div>
                <div className='w-full mt-5'>
                    <TierTable />
                </div>
            </div>
        </div>
    );
};

export default OrgainisationTier;
