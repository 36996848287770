import { Session } from "../../store/SessionStore"
import { renderDate } from "../sessions/SessionDomain.component"
import SessionDomainCard from "../sessions/SessionDomainCard.component"

const SpaceSession = ({
    date,
    data
}:{
    date: string,
    data: Session[]
}) => {
    return (
        <div className="w-full items-center justify-center space-y-4">
            <div className="overflow-y-auto scrollbar max-h-80 pr-2 space-y-4">
                <div className="space-y-4">
                        <p className="text-sm text-darker text-opacity-50">{renderDate(date)}</p>
                        <SessionDomainCard data={data} showDomainIcon={true}/>
                    </div>
            </div>
        </div>
    )
}

export default SpaceSession