import { useEffect } from 'react';

import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { Footer } from '../../components/basic/Footer.component';
import NavbarBackground from '../../components/general/NavBar/NavbarBackground.component';
import { ForgotPasswordForm } from '../../components/signIn/ForgotPasswordForm.component';
import NewSignInForm from '../../components/signIn/NewSignInForm.component';
import { ResetPasswordCard } from '../../components/signIn/ResetPasswordCard.component';
import { SetupNewPasswordForm } from '../../components/signIn/SetupNewPasswordForm.component';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useStore } from '../../store';
import { SIZES } from '../../utils/constants';
import NotFound from '../NotFound.page';
import { SignInPageMobile } from './SignIn.mobile';

export const SignInPage = () => {
    const { user } = useStore();
    const history = useHistory();
    const { path } = useRouteMatch();
    const store = useStore();
    const isMobile = useMediaQuery(SIZES.MOBILE_WIDTH);

    useEffect(() => {
        if (user.token) history.push('/home');
    }, [history, user.token]);
    if (isMobile) return <SignInPageMobile />;

    return (
        <div className='flex h-screen w-full'>
            <NavbarBackground />
            <div className={`w-full px-4 pt-14 flex flex-col `}>
                <div className={`flex w-full h-full max-w-7xl mx-auto justify-center items-center mb-10 md:mb-0`}>
                    <Switch>
                        <Route exact path={[`${path}`]}>
                            <NewSignInForm />
                        </Route>
                        <Route exact path={[`${path}/forgot-password`]}>
                            <ForgotPasswordForm />
                        </Route>
                        <Route exact path={[`${path}/reset-password`]}>
                            <ResetPasswordCard email={store.signInStore.forgotPasswordEmail as string} />
                        </Route>
                        <Route exact path={[`${path}/setup-new-password`]}>
                            <SetupNewPasswordForm />
                        </Route>
                        <Route component={NotFound} />
                    </Switch>
                </div>
                <Footer
                    whiteClass='text-xs text-darker text-opacity-75'
                    lightTextClass='text-xs text-darker text-opacity-50'
                />
            </div>
        </div>
    );
};
