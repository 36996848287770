import { createContext, useContext, useEffect, useState } from 'react';

import { Alert } from '@mui/material';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { appInsights } from '../AppInsights';
import { CustomLoader } from '../components/basic/CustomeLoader/CustomLoader';
import { Loading } from '../components/loading';
import { useServices } from '../components/ServiceProvider';
import { AickoCurrentSubscription } from '../store/SubscriptionsStore';

export const SubscriptionContext = createContext<AickoCurrentSubscription | null>(null);

export interface SubscriptionProviderProps {}

export const SubscriptionProvider: React.FC<SubscriptionProviderProps> = ({ children }) => {
    const location = useLocation();
    const [organisationId, setOrganisationId] = useState<string | null>(location.pathname.split('/')[2]);
    const { organisation } = useServices();

    useEffect(() => {
        const storedOrganisationId = localStorage.getItem('spaceId');
        if (storedOrganisationId) setOrganisationId(storedOrganisationId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem('spaceId')]);

    const {
        data: subscription,
        isLoading,
        error,
    } = useQuery<AickoCurrentSubscription | null>(
        ['currentSubscription', organisationId],
        async (): Promise<AickoCurrentSubscription | null> => {
            try {
                if (organisationId) {
                    const response: AickoCurrentSubscription = await organisation.getSubscription(organisationId);

                    return response; // Ensure to return the response
                }
                return null;
            } catch (e) {
                if (e instanceof Error) {
                    appInsights.trackException({ error: e });
                }
                return null; // Ensure to return null in case of error
            }
        },
        {
            enabled: !!organisationId, // Ensure the query only runs when organisationId is defined
        },
    );

    if (isLoading) {
        return <CustomLoader/> ;
    }

    if (error || !subscription) {
        return <Alert severity='error'> Unable to load subscription</Alert>;
    }

    return <SubscriptionContext.Provider value={subscription ?? null}>{children}</SubscriptionContext.Provider>;
};

export const useSubscriptionDetail = () => {
    const context = useContext(SubscriptionContext);

    if (!context) {
        throw new Error(`useSubscriptionDetail must be called within a SubscriptionProvider`);
    }

    return context;
};
