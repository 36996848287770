import React, { createContext, useContext } from 'react';

import { AuthServiceInterface } from '../services/auth/auth.service.interface';
import { CopilotService } from '../services/copilot/copilot.service';
import { DocumentsService } from '../services/documents/documents.service';
import { EvaService } from '../services/eva/eva.service';
import { OrganisationService } from '../services/organisation/organisation.service';
import { PaymentsService } from '../services/payments/payments.service';
import { SessionService } from '../services/session/session.service';
import { SpacesService } from '../services/spaces/spaces.service';
import { StripeWebhookService } from '../services/stripeWebhook/stripe.webhook.sevice';
import { UsersService } from '../services/users/users.service';

export type Services = {
    auth: AuthServiceInterface;
    users: UsersService;
    documents: DocumentsService;
    payments: PaymentsService;
    spaces: SpacesService;
    session: SessionService;
    copilot: CopilotService;
    organisation: OrganisationService;
    eva: EvaService;
    stripeWebhook: StripeWebhookService
};

const ServiceContext = createContext<Services | null>(null);

export const ServiceProvider = ({ services, children }: { services: Services; children: React.ReactNode }) => {
    return <ServiceContext.Provider value={services}>{children}</ServiceContext.Provider>;
};

export const useServices = () => {
    const services = useContext(ServiceContext);
    if (!services) throw new Error('App must be wrapped by a ServiceProdider');
    return services;
};
