import { CheckIcon, XIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Link, useHistory } from 'react-router-dom'; // Add useHistory for manual navigation

import { ReactComponent as BillingIcon } from '../../../assets/icons/icon-billing.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/icon-info-warning.svg';
import { ReactComponent as LeaveIcon } from '../../../assets/icons/icon-leave.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/icon-settings.svg';
import { useSpaces } from '../../../hooks/useSpaces';
import { OrganisationService } from '../../../services/organisation/organisation.service';
import { store } from '../../../store';
import { SUBSCRIPTION_STATUS } from '../../../store/OrganisationStore';
import { SpaceInfo } from '../../../store/SpacesStore';
import { ROLES } from '../../../utils/constants';
import { getDocumentUrl } from '../../../utils/getDocumentUrl';
import SpaceLogo from '../../basic/SpaceLogo.component';
import { useConfirm } from '../../Confirm';

const SpaceMenuIcon = ({
    space,
    handleClose,
    type,
}: {
    space: SpaceInfo;
    handleClose: () => void;
    type: 'organisation' | 'space';
}) => {
    const confirm = useConfirm();
    const history = useHistory(); // Use useHistory for manual navigation
    const { acceptSpaceInvite, declineSpaceInvite, getUserPermission, updateSpaceVisitedAt } = useSpaces();
    const organisationService = new OrganisationService();

    const handleNavigation = async () => {
        if (space.inviteExists) return;
        store.spaces.updateSelectedSpaceId(space.spaceId);
        localStorage.setItem('spaceId', space.spaceId);
        const { role } = await getUserPermission(space.spaceId);
        localStorage.setItem('permission', role);
        localStorage.setItem('organisationName', space.spaceName);

        const workspaceInfo = {
            workspaceBanner: space.spaceBanner,
            workspaceIcon: space.spaceIcon,
            workspaceName: space.spaceName,
            workspaceColor: space.spaceColor,
            workspaceLogo: space.spaceLogo,
            isOrganisation: space.organisationSpace?.length ? true : false,
        };
        store.spaces.setCurrentSelectedWorkSpaceInfo(workspaceInfo);
        await updateSpaceVisitedAt(space.spaceId);
        handleClose();

        if (space.organisationSpace && space.organisationSpace.length > 0) {
            history.push(
                space.role === ROLES.Treasurer ||
                    space.organisationSpace[0].subscription?.status === SUBSCRIPTION_STATUS.CANCELED
                    ? `/organisation/${space.spaceId}/billing`
                    : `/organisation/${space.spaceId}/eva`,
            );
        } else {
            history.push(`/spaces/view/${space.spaceId}`);
        }
    };

    const handleAcceptInvite = async (spaceId: string) => {
        const confirmed = await confirm({
            message:
                'By clicking accept, your access to Billings, Transactions, Identity will be revoked and will be managed by your organisation admin. Are you sure you want to accept the invite?',
            title: `Accept`,
        });
        if (confirmed) {
            await acceptSpaceInvite(spaceId);
            window.location.reload();
        }
    };

    const handleDeclineInvite = async (spaceId: string) => {
        const confirmed = await confirm({
            message: 'Are you sure you want to decline the invite?',
            title: `Decline`,
        });
        if (confirmed) {
            await declineSpaceInvite(spaceId);
            window.location.reload();
        }
    };

    const handleLeaveOrganisation = async (spaceId: string) => {
        let confirmed = await confirm({
            message: `Are you sure you want to leave ${space.spaceName}?`,
            yesLabel: 'Leave',
            noLabel: 'Cancel',
            yesColor: 'blueRYB',
            title: `Leave ${space.spaceName}`,
        });

        if (confirmed) {
            try {
                await organisationService.leaveOrganisation(spaceId);
                history.push('/home');
                //clearing the local storage and space info
                localStorage.removeItem('spaceId');
                store.spaces.setCurrentSelectedWorkSpaceInfo({
                    workspaceBanner: '',
                    workspaceIcon: '',
                    workspaceName: '',
                    workspaceColor: '',
                    workspaceLogo: '',
                });
                handleClose();
            } catch (e) {
                // If an error occurs, update the confirm box with the error message
                confirmed = await confirm({
                    message: `Error leaving the ${space.spaceName}. Please try again.`,
                    yesLabel: 'Retry',
                    noLabel: 'Cancel',
                    title: `Error`,
                });
                if (confirmed) {
                    // Retry if user clicks 'Retry'
                    handleLeaveOrganisation(spaceId);
                }
            }
        }
    };

    return (
        <div
            className={clsx(
                'w-full flex flex-row items-center justify-between group hover:bg-primary-darker hover:bg-opacity-3 py-2 px-2 rounded-md cursor-pointer',
                store.spaces.getCurrentSelectedSpaceId === space.spaceId ? 'bg-primary-darker bg-opacity-3' : '',
            )}
            onClick={handleNavigation} // Manually trigger navigation only when clicking on the space item
        >
            <div className='w-full flex flex-row items-center space-x-2'>
                <div>
                    <SpaceLogo
                        className='w-8 h-8'
                        // @ts-ignore
                        textColor={space.spaceColor ? space.spaceColor : 'workSpace'}
                        title={space.spaceIcon}
                        borderColor='border-blue-dark border-opacity-10'
                        preview={space.spaceLogo && getDocumentUrl(+space.spaceLogo)}
                        organization={type === 'organisation' ? true : false}
                        imgSize={8}
                    />
                </div>
                <div className='w-full flex flex-col'>
                    <div className='w-full flex justify-between items-center'>
                        <div className='text-sm capitalize text-darker text-opacity-75 whitespace-nowrap w-40 truncate'>
                            {space.spaceName}
                        </div>
                    </div>
                    <p className='text-xs text-darker text-opacity-50'>{space.associatedMemberIds.length} coworkers</p>
                </div>
            </div>
            {type === 'organisation' && !space.inviteExists && (
                <div
                    className={clsx(
                        'flex space-x-4 items-center justify-center',
                        store.spaces.getCurrentSelectedSpaceId === space.spaceId
                            ? 'opacity-100'
                            : 'opacity-0 group-hover:opacity-100 transition-opacity duration-300',
                    )}
                >
                    {space.role !== ROLES.Member && space.role !== ROLES.Architect && (
                        <Link
                            to={`/organisation/${space.spaceId}/billing`}
                            onClick={(e) => {
                                e.stopPropagation(); // Prevents handleNavigation from being called
                            }}
                        >
                            <BillingIcon className='h-3 w-3' />
                        </Link>
                    )}

                    {space.role !== ROLES.Treasurer && space.role !== ROLES.Member && space.role !== ROLES.Architect && (
                        <Link
                            to={`/organisation/${space.spaceId}/settings`}
                            onClick={(e) => {
                                e.stopPropagation(); // Prevents handleNavigation from being called
                            }}
                        >
                            <SettingsIcon className='h-3 w-3' />
                        </Link>
                    )}
                    <button
                        className='opacity-50 hover:opacity-100'
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent navigation when clicking the button
                            handleLeaveOrganisation(space.spaceId);
                        }}
                    >
                        <LeaveIcon className='w-3 h-3' fill='#0F1044' />
                    </button>
                </div>
            )}

            <div className='ml-4'>
                {space.organisationSpace &&
                    space.organisationSpace.length > 0 &&
                    space.organisationSpace[0].subscription?.status === SUBSCRIPTION_STATUS.CANCELED && (
                        <div className='flex justify-end self-end items-center'>
                            <InfoIcon className='h-3 w-3' />
                        </div>
                    )}
            </div>
            {space.inviteExists && (
                <div className='ml-2 flex space-x-2'>
                    <button
                        className='bg-white border border-blue-dark border-opacity-10 hover:bg-opacity-5 h-5 w-5 flex items-center justify-center rounded-md'
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent navigation when clicking the button
                            handleDeclineInvite(space.spaceId);
                        }}
                    >
                        <XIcon className='text-darker text-opacity-75 stroke-current h-3 w-3' />
                    </button>
                    <button
                        className='bg-white border border-blue-dark border-opacity-10 hover:bg-opacity-5 h-5 w-5 flex items-center justify-center rounded-md'
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent navigation when clicking the button
                            handleAcceptInvite(space.spaceId);
                        }}
                    >
                        <CheckIcon className='text-blue-dark stroke-current h-3 w-3' />
                    </button>
                </div>
            )}
        </div>
    );
};

export default observer(SpaceMenuIcon);
