import { useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { ReactComponent as BackIcon } from '../../../assets/icons/icon-arrow-left-long.svg';
import { useOrganisationForm } from '../../../hooks/useOrganisation';
import { store } from '../../../store';
import { Member } from '../../../store/OrganisationStore';
import { Button } from '../../basic/Button.component';
import { IconAlert } from '../../basic/IconAlert.component';
import { Input } from '../../basic/Input.component';
import { Select } from '../../basic/Select.component';

const AddMember = ({ mode, roles }: { mode: 'create' | 'edit'; roles: string[] }) => {
    const history = useHistory();
    const { membersFields, inviteMember, error, isLoading, success, setMembersFields, updateMember } =
        useOrganisationForm();
    const { id: organisationId } = useParams<{ id: string }>();
    const { memberId } = useParams<{ memberId: string }>();
    if (organisationId) localStorage.setItem('spaceId', organisationId);

    useEffect(() => {
        if (memberId) {
            const selectedMember = store.organisation.organisationMembers.find(
                (member: Member) => member.id === +memberId,
            );
            if (selectedMember) {
                setMembersFields({
                    email: selectedMember.email,
                    role: selectedMember.role,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberId]);

    return (
        <div className='w-full max-w-5xl mx-auto'>
            <button onClick={() => history.goBack()} className='flex items-center space-x-2 absolute right-0'>
                <BackIcon />
                <p className='text-sm text-darker text-opacity-75'>Back</p>
            </button>
            <div className='flex flex-col w-full space-y-6 mx-auto'>
                {mode === 'create' ? (
                    <p className='text-center text-sm text-darker text-opacity-75 mt-4'>Invite Member</p>
                ) : (
                    <p className='text-center text-sm text-darker text-opacity-75 mt-4'>Edit Member</p>
                )}

                <div className='w-full flex flex-row space-x-6'>
                    <Input
                        placeholder='Email'
                        required
                        label='Email'
                        type='text'
                        {...membersFields.email}
                        disabled={mode === 'edit'}
                        rounded='rounded-lg'
                    />
                    <Select
                        {...membersFields.role}
                        entries={roles}
                        label='Role'
                        className='w-[49%]'
                        placeholder={membersFields.role.value}
                        marginTop={70}
                        rounded='rounded-lg'
                    />
                </div>
                {error && <IconAlert message={error} />}
                {success && <IconAlert message={success} type='success' />}
                <div className='flex flex-row justify-end items-center w-full space-x-6'>
                    <Button onClick={() => history.goBack()} variant='outlinedCancel' size='rectSmall'>
                        Cancel
                    </Button>
                    <Button
                        type='submit'
                        variant='blueRYB'
                        size='rectSmall'
                        disabled={membersFields.email.value === '' || membersFields.role.value === '' || isLoading}
                        onClick={async () => {
                            if (mode === 'create') await inviteMember(organisationId);
                            else updateMember(organisationId, +memberId, membersFields.role.value);
                        }}
                    >
                        {mode === 'create' ? 'Send Invite' : 'Update'}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AddMember;
