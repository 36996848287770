import { useState } from 'react';

import { Link } from 'react-router-dom';

import usePasswordToggle from '../../hooks/usePasswordToggle';
import { useSignInForm } from '../../hooks/useSignInForm';
import { Button } from '../basic/Button.component';
import { IconAlert } from '../basic/IconAlert.component';
import { Input } from '../basic/Input.component';

const NewSignInForm = () => {
    const [showPasswordInput, setShowPasswordInput] = useState(false);
    const { InputType, Icon, handlePasswordVisibility } = usePasswordToggle();
    const { onSubmit, fields, isLoading, isEmailValid, clearError, error } = useSignInForm();
    return (
        <form
            noValidate
            onSubmit={(e) => {
                e.preventDefault();
                if (!showPasswordInput) {
                    const res = isEmailValid();
                    if (res) {
                        setShowPasswordInput(true);
                        clearError();
                    }
                    return;
                }
                onSubmit();
            }}
            className='grid grid-1-cols justify-items-stretch text-darker text-opacity-75 max-w-lg w-full mx-auto'
        >
            <div className='overflow-auto w-full'>
                <div className='space-y-5 px-1'>
                    <h1 className='md:text-base text-tiny font-semibold'>Sign In</h1>
                    <Input placeholder='Email*' type='email' required {...fields.email} error={fields.email.error} />
                    {fields.email.error && fields.email.errorMessage && (
                        <IconAlert message={fields.email.errorMessage} className='mt-4' />
                    )}
                    <div style={{ display: showPasswordInput ? 'block' : 'none' }}>
                        <div className='relative w-full'>
                            <Input
                                required
                                placeholder='Password*'
                                type={InputType}
                                {...fields.password}
                                isPassword={true}
                                error={error !== null}
                                right={
                                    <img
                                        className='absolute bottom-3 right-4 w-4 h-4 cursor-pointer opacity-50'
                                        src={Icon}
                                        onClick={handlePasswordVisibility}
                                        alt=''
                                    />
                                }
                            />
                        </div>
                        {error !== null && <IconAlert message={error} className='mt-4' />}
                    </div>
                    <div className='flex flex-row justify-end mt-4'>
                        <Link to='sign-in/forgot-password' className='text-sm text-bberry-blue font-medium'>
                            Forgot Password?
                        </Link>
                    </div>
                    <Button
                        className='w-full h-10'
                        type='submit'
                        variant='bberryBlue'
                        size='oval'
                        children={showPasswordInput ? 'Sign In' : 'Continue'}
                        disabled={!fields?.email?.value||isLoading}
                    />
                    {/* <SignInAddons /> */}
                    <div className='flex items-center justify-center space-x-2 text-sm font-medium mt-12'>
                        <Link to={'/sign-up'} className='text-bberry-blue underline'>
                            I don’t have an account
                        </Link>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default NewSignInForm;
