import { Route, Switch, useRouteMatch } from 'react-router-dom';

import TabsLayout from '../../components/general/Layout/TabsLayout';
import CreateSpace from '../../components/spaces/createSpace/CreateSpace.component';
import SpacesTable from '../../components/spaces/SpacesTable.component';
import useMediaQuery from '../../hooks/useMediaQuery';
import { SIZES } from '../../utils/constants';
import NotFound from '../NotFound.page';

const SpacesPage = () => {
    const { path } = useRouteMatch();
    const currentPathname = window.location.pathname;
    const isMobile = useMediaQuery(SIZES.MOBILE_WIDTH);

    return (
        <div className='h-full bg-backgroundColor overflow-x-hidden'>
            {!isMobile && !currentPathname.includes('/add-space') && !currentPathname.includes('/edit-space') && (
                <TabsLayout
                    tabs={[{ title: 'Spaces', path: `${path}/spaces`, exactPath: [`${path}/spaces`], tour: 'spaces' }]}
                />
            )}

            <div className='w-full px-4 md:px-0'>
                <Switch>
                    <Route exact path={[`${path}/spaces`]} component={SpacesTable} />
                    <Route exact path={[`${path}/add-space/:id`]} component={() => <CreateSpace />} />
                    <Route exact path={[`${path}/edit-space/:id`]} component={() => <CreateSpace update />} />
                    <Route component={NotFound} />
                </Switch>
            </div>
        </div>
    );
};

export default SpacesPage;
