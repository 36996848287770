import { forwardRef, ComponentProps, ChangeEvent, useState, useEffect } from 'react';

import clsx from 'clsx';

export type InputProps = {
    value?: string;
    required?: boolean;
    error?: boolean;
    type?: ComponentProps<'input'>['type'];
    placeholder?: string;
    onChange?: (value: string) => void;
    onBlur?: () => void;
    className?: string;
    inputProps?: ComponentProps<'input'>;
    disabled?: boolean;
    containerClassName?: string;
    id?: string;
    onFocus : () => void;
    maxLength?: number;
    textColor?: string;
    rounded?: string;
};

export const InputWithLength = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            value: realValue,
            onChange,
            onBlur,
            error,
            placeholder,
            type,
            required,
            inputProps,
            containerClassName,
            disabled,
            id,
            onFocus,
            maxLength,
            rounded,
        },
        ref,
    ) => {
        //added this because we couldn't delete the last char
        const [value, setValue] = useState(realValue);

        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
                if (!e.target.value) {
                    onChange('');
                    setValue('');
                }
                setValue(e.target.value);
                onChange(e.target.value);
            }
        };
        useEffect(() => {
            setValue(realValue);
        }, [realValue]);

        // Get a reference to the input element
        const inputElement = document.querySelector('.input-text input') as HTMLInputElement;

        inputElement?.addEventListener('focus', function () {
            this.placeholder = ''; 
        });

        inputElement?.addEventListener('blur', function () {
            this.placeholder = "Nuance"; 
        });

        return (
            <div className={clsx('text-sm flex-grow relative', containerClassName)}>
                <div className='input-text relative'>
                    <input
                        maxLength={maxLength}
                        id={id}
                        onFocus={onFocus }
                        ref={ref}
                        {...inputProps}
                        type={type}
                        onBlur={ onBlur}
                        placeholder={placeholder}
                        required={required}
                        className={clsx(
                            `flex-grow px-4 w-full  `,
                            inputProps?.className,
                            'disabled:text-neutral-400',
                            disabled && 'cursor-not-allowed text-opacity-75',
                            error && 'ring-error-900 ring-2',
                            value && value.length > 0 ? 'input-eva-placeholder' : 'input-text',
                        )}
                        value={value}
                        onChange={(e) => handleChange(e)}
                        disabled={disabled}
                    />
                    <div className='input-length flex absolute items-center'>
                        <div className='input-length-border' />
                        <span className='input-max-length absolute'>
                            {value && value.length > 0 ? 60 - value.length : 60}
                        </span>
                    </div>
                </div>
            </div>
        );
    },
);
