import { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { appInsights } from '../AppInsights';
import { EvaType, MetaMarkerData } from '../components/organisation/copilotOrganisation/eva/types';
import { Websites } from '../components/organisation/copilotOrganisation/evaAnalytics/types';
import { useServices } from '../components/ServiceProvider';
import { SubscriptionTF } from '../services/payments/payments.service';
import { store } from '../store';
import { Organisation } from '../store/OrganisationStore';
import { AickoCurrentSubscription } from '../store/SubscriptionsStore';
import { businessTypes, ROLES } from '../utils/constants';
import { mockable } from '../utils/mockable';
import { useAsyncFetcher } from '../utils/useAsyncFetcher';
import { useForm } from '../utils/useForm';
import { isUrl, required, isNumber, and, isEmail } from '../utils/validation';

export const useOrganisationForm = mockable(
    (settings?: boolean, initialValues?: Organisation | null, organisationId?: string | null) => {
        const [isLoading, setIsLoading] = useState(false);
        const [error, setError] = useState<string | null>(null);
        const [success, setSuccess] = useState<string | null>(null);
        const [emailVerified, setEmailVerified] = useState<boolean>(true);
        const history = useHistory();
        const { organisation, documents } = useServices();
        const { fields, isValid, setAllVisited, setValue } = useForm({
            fields: {
                website: { initialValue: initialValues?.website, validation: isUrl },
                phone: { initialValue: initialValues?.phone, validation: isNumber },
                organisationName: { initialValue: initialValues?.organisationName, validation: required },
                businessType: { initialValue: initialValues?.businessType || businessTypes.LLP, validation: required },
                postcode: { initialValue: initialValues?.postcode, validation: required },
                address: { initialValue: initialValues?.address, validation: required },
                city: { initialValue: initialValues?.city, validation: required },
                country: { initialValue: initialValues?.country || 'United Kingdom', validation: required },
                vat: { initialValue: initialValues?.vat },
                crn: { initialValue: initialValues?.crn },
                email: {
                    initialValue: initialValues?.email,
                    validation: settings ? and(required, isEmail) : undefined,
                },
                taxId: { initialValue: initialValues?.taxId },
            },
        });

        const {
            fields: membersFields,
            isValid: memberIsValid,
            setValues: setMembersFields,
        } = useForm({
            fields: {
                email: { initialValue: '', validation: and(isEmail, required) },
                role: { initialValue: '', validation: required },
            },
        });
        const { data: subscription } = useAsyncFetcher<AickoCurrentSubscription>(
            async () => organisationId && organisation.getSubscription(organisationId),
            [],
        );

        const getOrganisation = async (id: string) => {
            setError(null);
            try {
                const response = await organisation.getOrganisation(id);
                return response;
            } catch (err) {
                console.log(err);
                if (err instanceof Error) {
                    appInsights.trackException({ exception: err });
                }
            }
        };

        const updateOrganisation = async (id: string, file?: File | null, logoId?: number) => {
            setIsLoading(true);
            setAllVisited();
            setError(null);
            setSuccess(null);
            try {
                if (!isValid()) throw new Error('Some fields are not valid, please check again');
                const response = await organisation.createUpdateOrganisation({
                    organisationId: id,
                    organisationName: fields.organisationName.value,
                    businessType: fields.businessType.value,
                    crn: fields.crn.value,
                    vat: fields.vat.value,
                    website: fields.website.value,
                    phone: fields.phone.value,
                    address: fields.address.value,
                    postcode: fields.postcode.value,
                    city: fields.city.value,
                    country: fields.country.value,
                    email: fields.email.value.toLowerCase(),
                });
                store.organisation.setOrganisationData(response);
                if (file) await uploadLogo(id, file, logoId);
                setTimeout(() => {
                    setIsLoading(false);
                    if (response) {
                        setSuccess('Organisation details updated successfully.');
                        const workspaceInfo = {
                            workspaceBanner: '',
                            workspaceIcon: fields.organisationName.value[0],
                            workspaceName: fields.organisationName.value,
                            workspaceColor: '',
                            workspaceLogo: logoId ? logoId.toString() : '',
                            isOrganisation: true,
                        };
                        store.spaces.setCurrentSelectedWorkSpaceInfo(workspaceInfo);
                        if (response.email && !response.emailVerified) setEmailVerified(false);
                    }
                }, 1000);
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                    appInsights.trackException({ exception: err });
                } else setError('Unexpected error happened');
                setIsLoading(false);
            }
        };

        const createOrganisation = async (id: string, file?: File | null) => {
            setIsLoading(true);
            setAllVisited();
            setError(null);
            try {
                if (!isValid()) throw new Error('Some fields are not valid, please check again');
                const response = await organisation.createUpdateOrganisation({
                    organisationId: id,
                    organisationName: fields.organisationName.value,
                    businessType: fields.businessType.value,
                    crn: fields.crn.value,
                    vat: fields.vat.value,
                    website: fields.website.value,
                    phone: fields.phone.value,
                    address: fields.address.value,
                    postcode: fields.postcode.value,
                    city: fields.city.value,
                    country: fields.country.value,
                    email: fields.email.value,
                });
                if (file) await uploadLogo(id, file);
                store.organisation.setOrganisationData(response);
                setIsLoading(false);
                history.push(`/create-organisation/${id}/select-tier`);
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                    appInsights.trackException({ exception: err });
                } else setError('Unexpected error happened');
                setIsLoading(false);
            }
        };

        const createOrUpdateSubscription = async (input: SubscriptionTF) => {
            return await organisation.createOrUpdateSubscription(input);
        };

        const uploadLogo = async (id: string, file: File, logoId?: number) => {
            if (!store.user.userData?.id) throw new Error('cannot upload asset');
            const { document, blobSasUrl } = await documents.createDocument(
                file.name,
                file.size,
                file.type,
                store.user.userData.id,
                'organisationLogo',
                undefined,
                false,
                logoId,
            );
            await documents.uploadFile(file, { document, blobSasUrl });
            if (document.id) {
                await organisation.uploadLogo(document.id.toString(), id);
            }
        };

        const downgradeSubscription = async (id: string) => {
            return await organisation.downgradeSubscription(id);
        };

        const inviteMember = async (organisationId: string) => {
            setIsLoading(true);
            setAllVisited();
            setError(null);
            setSuccess(null);
            try {
                if (!memberIsValid()) throw new Error('Some fields are not valid, please check again');
                const response = await organisation.inviteMember(
                    membersFields.email.value,
                    organisationId,
                    membersFields.role.value,
                );
                if (response) {
                    setIsLoading(false);
                    setSuccess('Member invited successfully.');
                }
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                    appInsights.trackException({ exception: err });
                } else setError('Unexpected error happened');
                setIsLoading(false);
            }
        };

        const updateMemberInvite = async (email: string) => {
            return organisation.updateMemberInvite(email);
        };

        const getMembers = async (organisationId: string) => {
            return await organisation.getMembers(organisationId);
        };

        const voidCanceledSubscription = async (organisationId: string) => {
            return await organisation.voidCanceledSubscription(organisationId);
        };

        const resumeSubscription = async (input: SubscriptionTF) => {
            return await organisation.resumeSubscription(input);
        };

        const createEva = async (input: MetaMarkerData, id: string) => {
            return await organisation.createEVA(input, id);
        };

        const getEvaByOrganisationId = async (organisationId: string, type: EvaType) => {
            return await organisation.getEvaByOrganisationId(organisationId, type);
        };

        const updateEva = async (input: MetaMarkerData, id: string, evaId: string) => {
            return await organisation.updateEVA(input, id, evaId);
        };

        const getEva = async (organisationId: string, evaId: string, type: EvaType) => {
            return await organisation.getEVA(organisationId, evaId, type);
        };

        const getEvaGraphAndLog = async (organisationId: string, evaId: string) => {
            return await organisation.getEvaGraphAndLog(organisationId, evaId);
        };

        const getInvoices = async (organisationId: string) => {
            return await organisation.getInvoices(organisationId);
        };

        const updateMember = async (organisationId: string, memberId: number, role: string) => {
            try {
                const response = await organisation.updateMember(organisationId, memberId, role);
                if (response) {
                    setIsLoading(false);
                    setSuccess('Member updated successfully.');
                }
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                    appInsights.trackException({ exception: err });
                } else setError('Unexpected error happened');
                setIsLoading(false);
            }
        };

        const makeEvaEnableDisable = async (input: { isEvaEnable: boolean }, organisationId: string, evaId: string) => {
            return await organisation.makeEvaEnableDisable(input, organisationId, evaId);
        };

        const deleteMember = async (organisationId: string, memberId: number) => {
            try {
                setError(null);
                return await organisation.deleteMember(organisationId, memberId);
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                    appInsights.trackException({ exception: err });
                } else {
                    setError('Unexpected error happened');
                    appInsights.trackException({ exception: new Error('Error while deleting member') });
                }
                setIsLoading(false);
            }
        };

        const getOrganisationWithInvites = async () => {
            return await organisation.getOrganisationWithInvites();
        };

        const deleteEva = async (organisationId: string, evaId: string) => {
            return await organisation.deleteEva(organisationId, evaId);
        };

        const getAllArchivedEva = async (organisationId: string) => {
            return await organisation.getAllArchivedEva(organisationId);
        };

        const archiveEva = async (evaId: string) => {
            return await organisation.archiveEva(evaId);
        };

        const unarchiveEva = async (
            organisationId: string,
            evaId: string,
            publishObj: MetaMarkerData,
            isEvaEnable: boolean,
        ) => {
            return await organisation.unarchiveEva(organisationId, evaId, publishObj, isEvaEnable);
        };

        const getEvaByEvaId = async (evaId: string, type: EvaType) => {
            return await organisation.getEvaByEvaId(evaId, type);
        };

        const deleteEvaByEvaId = async (evaId: string) => {
            return await organisation.deleteEvaByEvaId(evaId);
        };

        const duplicateEva = async (evaId: string) => {
            return await organisation.duplicateEva(evaId);
        };

        const getEvaListByOrganisationId = async (organisationId: string) => {
            return await organisation.getEvaListByOrganisationId(organisationId);
        };

        const checkUserPermission = async (organisationId: string, type?: string) => {
            try {
                const userPermit = await organisation.checkUserPermission(organisationId);
                if (
                    type === 'blueprint' &&
                    (userPermit.role === ROLES.Global ||
                        userPermit.role === ROLES.Steward ||
                        userPermit.role === ROLES.Architect)
                ) {
                    return true;
                } else {
                    if (userPermit.role && (userPermit.role === ROLES.Global || userPermit.role === ROLES.Steward)) {
                        return true;
                    }
                    return false;
                }
            } catch (e) {
                if (e instanceof Error) {
                    appInsights.trackException({ exception: e });
                }
                return false;
            }
        };

        const getUserPermission = async (organisationId: string) => {
            try {
                const userPermit = await organisation.checkUserPermission(organisationId);
                return userPermit.role;
            } catch (e) {
                if (e instanceof Error) {
                    appInsights.trackException({ exception: e });
                }
                return false;
            }
        };

        const updateBlocklistDomain = async (blocklistDomain: Websites[], organisationId: string) => {
            return await organisation.updateBlocklistDomain(blocklistDomain, organisationId);
        };

        const getComplianceMonitoring = async (organisationId: string) => {
            return await organisation.getComplianceMonitoring(organisationId);
        };

        const getDomainByTLD = async (organisationId: string) => {
            return await organisation.getAllDomainByTLD(organisationId);
        };

        const removeInvitedMember = async (email: string, organisationId: string) => {
            return await organisation.removeInvitedMember(email, organisationId);
        };

        return {
            isLoading,
            error,
            fields,
            membersFields,
            success,
            updateOrganisation,
            createOrganisation,
            getOrganisation,
            createOrUpdateSubscription,
            uploadLogo,
            downgradeSubscription,
            inviteMember,
            updateMemberInvite,
            getMembers,
            voidCanceledSubscription,
            resumeSubscription,
            createEva,
            getEvaByOrganisationId,
            updateEva,
            getEva,
            emailVerified,
            getEvaGraphAndLog,
            getInvoices,
            setMembersFields,
            updateMember,
            makeEvaEnableDisable,
            deleteMember,
            setError,
            setIsLoading,
            getOrganisationWithInvites,
            deleteEva,
            getAllArchivedEva,
            archiveEva,
            unarchiveEva,
            getEvaByEvaId,
            deleteEvaByEvaId,
            duplicateEva,
            getEvaListByOrganisationId,
            checkUserPermission,
            isValid,
            getUserPermission,
            setValue,
            updateBlocklistDomain,
            getComplianceMonitoring,
            getDomainByTLD,
            removeInvitedMember,
        };
    },
);
