export type UserMock = {
    id: string;
    email: string;
    displayName: string;
    userName: string;
    password: string;
};

export const users: UserMock[] = [
    {
        id: '1',
        email: 'test@test.com',
        displayName: 'Test',
        userName: 'test',
        password: 'test',
    },
];
