import { createContext, useContext, useState } from 'react';

import { ReactComponent as CloseIcon } from '../assets/icons/icon-cross-fill.svg';
import { Button, buttonVariants } from './basic/Button.component';
import HorizontalSeparator from './basic/HorizontalSeparator';
import { Paper } from './basic/Paper';

const ConfirmContext = createContext<{ setOptions: (options: ExtendedConfirmOptions | null) => void } | null>(null);

type ConfirmOption = {
    title?: string;
    message?: string;
    yesLabel?: string;
    noLabel?: string;
    maxWidth?: string;
    yesColor?: keyof typeof buttonVariants;
};

type ExtendedConfirmOptions = ConfirmOption & {
    onResponse: (confirmed: boolean) => void;
};

export const useConfirm = () => {
    const contr = useContext(ConfirmContext);
    return (options: ConfirmOption) => {
        return new Promise<boolean>((res) => {
            contr?.setOptions({
                ...options,
                onResponse: (confirmed) => {
                    res(confirmed);
                    contr?.setOptions(null);
                },
            });
        });
    };
};

export const ConfirmProvider: React.FC = ({ children }) => {
    const [options, setOptions] = useState<ExtendedConfirmOptions | null>(null);

    return (
        <ConfirmContext.Provider value={{ setOptions }}>
            {children}
            {options && (
                <div
                    onClick={() => options.onResponse(false)}
                    className='fixed top-0 left-0 flex items-center justify-center w-screen h-screen'
                    style={{
                        background: 'rgba(0,0,0,0.4)',
                        zIndex: 200000000,
                    }}
                >
                    <Paper
                        onClick={(e) => e.stopPropagation()}
                        className='flex flex-col p-0 rounded-lg'
                        style={{ minWidth: 'min(500px, calc(100vw - 100px))' }}
                    >
                        <div className='w-full flex justify-between items-center'>
                            <div className='text-sm font-medium text-darker text-opacity-75'>{options.title}</div>
                            <button onClick={() => options.onResponse(false)}>
                                <CloseIcon className='h-2.5 w-2.5' fill='rgba(15, 16, 68, 0.5)'/>
                            </button>
                        </div>
                        <HorizontalSeparator className='mt-3' />
                        <div className='flex flex-grow text-xs text-darker text-opacity-75 font-medium mt-3'>
                            {options?.message || 'Are you sure ?'}
                        </div>
                        <div className='flex justify-end space-x-4 mt-4'>
                            <Button
                                onClick={() => options.onResponse(false)}
                                variant='outlinedCancel'
                                size='oval'
                                className='text-xs'
                            >
                                {options.noLabel || 'Cancel'}
                            </Button>
                            <Button
                                onClick={() => options.onResponse(true)}
                                variant={options.yesColor ? options.yesColor : 'secondaryRed'}
                                size='oval'
                                className='text-xs'
                            >
                                {options.yesLabel || 'Yes'}
                            </Button>
                        </div>
                    </Paper>
                </div>
            )}
        </ConfirmContext.Provider>
    );
};
