import { useState } from 'react';

import { observer } from 'mobx-react-lite';

import { ReactComponent as DownArrowIcon } from '../../assets/icons/icon-down-arrow.svg';
import { useStore } from '../../store';
import { ProfilePicture } from '../basic/ProfilePicture';
import BottomSideMenuPopUp from './BottomSideMenuPopUp';

const BottomSideMenu = () => {
    const store = useStore();
    const profilePicture = store.user.userData?.profilePictureUrl;
    const [showPopUp, setShowPopUp] = useState(false);

    return (
        <div className='fixed top-50  z-20 flex flex-col items-start user-dropdown'>
            {store?.user?.token && (
                <>
                    <div
                        className='group flex items-center space-x-2 cursor-pointer'
                        data-tour='profile'
                        onClick={() => setShowPopUp(true)}
                    >
                        <button
                            className='flex items-center hover:bg-primary-darker hover:bg-opacity-3 bg-opacity-0 
                        p-2 rounded-lg border-blue-dark border border-opacity-0 hover:border-opacity-5'
                        >
                            <DownArrowIcon
                                className='w-2 transition-all ease-in-out'
                            />
                        </button>

                        <ProfilePicture size='32px' path={profilePicture} />
                    </div>

                    {showPopUp && (
                        <div className='absolute show_dropdown right-0 z-50'>
                            <BottomSideMenuPopUp setShowPopUp={setShowPopUp} />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default observer(BottomSideMenu);
