import { CHROME_EXTENSION_URL } from '../../utils/chromeExtension';

const ExtensionButton = () => {
    return (
        <div
            className='max-w-64 group rounded-md border p-1 border-blue-dark border-opacity-10 bg-white'
            data-tour='home'
        >
            <button
                className='w-full flex rounded-full px-5 py-2 space-x-2 items-center'
                onClick={() => window.open(CHROME_EXTENSION_URL, '_blank')}
            >
                <div
                    className='w-5 h-5 rounded-full'
                    style={{
                        background: 'linear-gradient(90deg, #6466F0 0%, #6BABF4 99.99%, #6DC5F5 100%)',
                    }}
                />
                <span className='text-darker text-opacity-75 text-xs font-medium'>Get the Extension for Chrome</span>
            </button>
        </div>
    );
};

export default ExtensionButton;
