import LinearProgress from '@mui/material/LinearProgress';

const ProgressBar = ({
    value,
    color,
}:{ 
    value: number,
    color: string
}) => {
    return (
        <div className='w-full'>
            <LinearProgress
                variant='determinate'
                value={value}
                sx={{
                    backgroundColor: 'white',
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: color,
                    },
                   height: '8px',
                }}
                
            />
        </div>
    );
};

export default ProgressBar;
