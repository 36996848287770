/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, useEffect } from 'react';

import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import { Store } from './Store';

export const store = new Store();

const StoreContext = createContext<{ store: Store }>({ store });
export const StoreProvider = observer(({ children }: { children: any }) => {
    useEffect(() => {
        (window as any).__STORE = store;
        (window as any).__JS_STORE = () => toJS(store);
    }, []);
    return <StoreContext.Provider value={{ store }}>{children}</StoreContext.Provider>;
});

export const useStore = () => {
    const store = useContext(StoreContext);
    if (!store.store) {
        throw new Error('useStore must be used within a StoreProvider.');
    }
    return store.store;
};
