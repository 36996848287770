import { Skeleton } from '@mui/material';
import clsx from 'clsx';

type WorkspaceSkeletonProps = {
    number: number;
    className?: string;
};

const WorkspaceSkeleton = ({ number, className }: WorkspaceSkeletonProps) => {
    const workspaceMenu: JSX.Element[] = [];

    for (let i = 0; i < number; i++) {
        workspaceMenu.push(
            <div className={clsx(className, 'flex space-x-2')} key={i}>
                <Skeleton variant='rectangular' sx={{ borderRadius: '12px' }} width={40} height={40}/>
                <div>
                    <Skeleton variant='text' sx={{ fontSize: '12px' }} width={200} />
                    <Skeleton variant='text' sx={{ fontSize: '12px' }} width={100} />
                </div>
            </div>
        );
    }
    return <div className={clsx('w-full', className)}>{workspaceMenu}</div>;
}

export default WorkspaceSkeleton