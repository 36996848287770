import { useTour } from '@reactour/tour';

import { useSettings } from '../../hooks/useSettings';
import { store } from '../../store';
import { getCategoryIcon } from '../../utils/categoryUnicode';
import { Button } from '../basic/Button.component';

const Welcome = () => {
    const { setIsOpen, setCurrentStep } = useTour();
    const { updateOnboardingTour } = useSettings();

    return (
        <div className='flex flex-col text-darker text-opacity-75 items-center justify-center'>
            <div className='flex space-x-2 justify-center'>
                <p className='font-medium text-xl'>Welcome! {getCategoryIcon('Raised Hand')}</p>
            </div>
            <p className='text-xs mt-2 font-medium'>Harnessing Humanity's Hidden Potential</p>
            <div className='flex justify-center mt-4'>
                <Button
                    variant='bberryBlue'
                    size='oval'
                    onClick={async () => {
                        await updateOnboardingTour(false, 'showWelcome');
                        await updateOnboardingTour(true, 'startTour');
                        setCurrentStep(0);
                        await updateOnboardingTour(true, 'extension');
                        setIsOpen(true);
                    }}
                >
                    Explore Blankstate
                </Button>
            </div>
            <div className='flex space-x-2 mt-8'>
                <p className='text-xs'>Experienced with Blankstate?</p>
                <button
                    className='text-xs text-bberry-blue'
                    onClick={() => {
                        updateOnboardingTour(false, 'showWelcome');
                    }}
                >
                    Skip the tutorial
                </button>
            </div>
        </div>
    );
};

export default Welcome;
