import { ChangeEvent, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';

import { Organisation } from '../../../store/OrganisationStore';
import { useAsyncFetcher } from '../../../utils/useAsyncFetcher';
import HorizontalSeparator from '../../basic/HorizontalSeparator';
import { useServices } from '../../ServiceProvider';
import OrganisationLogo from '../createOrganisation/OrganisationLogo.component';
import OrganistaionForm from '../createOrganisation/OrganistaionForm.component';

const Settings = () => {
    const { id: organisationId } = useParams<{ id: string }>();
    const { organisation } = useServices();
    const { data, loading } = useAsyncFetcher<Organisation>(
        async () => organisation.getOrganisation(organisationId),
        [],
    );
    const [file, setFile] = useState<File | null>(null);
    if (organisationId) localStorage.setItem('spaceId', organisationId);

    if (loading)
        return (
            <div className='flex-grow flex flex-col justify-center items-center pt-20 text-darker text-opacity-75 mt-24'>
                <CircularProgress sx={{ color: '#373BBA', width: '32px', height: '32px' }} />
            </div>
        );
    const handleUpload = (e: ChangeEvent<HTMLInputElement>) => setFile(e.target.files ? e.target.files[0] : null);

    return (
        <div className='w-full h-full max-w-5xl mx-auto space-y-8 mt-5'>
            <OrganisationLogo onChange={handleUpload} documnetId={data?.logo} />
            <HorizontalSeparator />
            <OrganistaionForm settings initialValues={data} file={file} />
        </div>
    );
};

export default Settings;
