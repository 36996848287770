import { useState } from 'react';

import { Route, Switch, useRouteMatch } from 'react-router';

import { Alert } from '../components/basic/Alert.component';
import { Footer } from '../components/basic/Footer.component';
import NavbarBackground from '../components/general/NavBar/NavbarBackground.component';
import { ReSendForm } from '../components/signUp/ReSendForm.component';

export const PageConfirmEmail = () => {
    const { path } = useRouteMatch();
    const [sent, setSent] = useState(false);
    const [email, setEmail] = useState('');

    const isExpert: boolean = localStorage.getItem('expert-sign-up') === 'true';

    return (
        <div className='bg-backgroundColor h-screen'>
            {!sent && <NavbarBackground />}
            {sent && (
                <Alert
                    email={email}
                    onClick={() => {
                        setSent(false);
                    }}
                />
            )}

            <div className={`w-full h-full px-4 ${isExpert ? 'lg:w-4/6 ml-auto justify-end' : ''}`}>
                <div className={`w-full h-full mx-auto ${isExpert ? 'max-w-lg' : 'max-w-xl'}`}>
                    <Switch>
                        <Route exact path={[`${path}/`, `${path}/confirm-message`]}>
                            <div className='h-full overflow-y-auto scrollbar text-xl grid grid-rows-2-top'>
                                <div
                                    className={`flex flex-col justify-center space-y-4 
                            ${isExpert ? 'text-darker text-opacity-75' : 'text-darker'}`}
                                >
                                    <h1
                                        className={`text-tiny font-semibold ${
                                            isExpert ? 'md:text-base' : 'md:text-2xl'
                                        }`}
                                    >
                                        Confirm your email
                                    </h1>
                                    <h1 className={`text-sm ${isExpert ? '' : 'md:text-tiny'}`}>
                                        Please check up your inbox for a communication email. Click the link in the
                                        email to confirm your email address.
                                    </h1>
                                    <a href='/confirm-email/re-send-confirmation'>
                                        <p className='text-bberry-blue text-sm font-medium'>
                                            Re-send confirmation email
                                        </p>
                                    </a>
                                </div>
                                <div className='hidden md:block mt-auto'>
                                    <Footer
                                        whiteClass='text-xs text-darker text-opacity-75'
                                        lightTextClass='text-xs text-darker text-opacity-50'
                                    />
                                </div>
                            </div>
                        </Route>
                        <Route exact path={[`${path}/re-send-confirmation`]}>
                            <div className='h-full flex flex-col items-center justify-center'>
                                <ReSendForm setSent={setSent} setEmail={setEmail} />
                            </div>
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
};
