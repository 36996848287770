import clsx from 'clsx';

const TierCard = ({
    bgColor = '#FFFF',
    title,
    description,
    price,
    buttonClass,
    onClick,
    buttonName = 'Start with 14 Days Trial',
    disabled = false,
    showAnnualBilling = true,
}: {
    bgColor?: string;
    title: string;
    description: string;
    price: number;
    buttonClass?: string;
    onClick: () => void;
    buttonName?: string;
    disabled?: boolean;
    showAnnualBilling?: boolean;
}) => {
    return (
        <div
            className={clsx(
                'w-74 p-6 border border-blue-dark border-opacity-10 rounded-2xl text-darker text-opacity-75',
            )}
            style={{
                background: bgColor,
                height: '200px'
            }}
        >
            <h1 className='font-semibold uppercase'>{title}</h1>
            <p className='text-sm text-opacity-50 text-darker whitespace-nowrap'>{description}</p>
            <div className='flex mt-4 items-center space-x-1'>
                <p className='font-semibold text-darker text-opacity-75 text-xl'>£{price}</p>
                <span className='text-xs text-darker text-opacity-50 font-normal'>/org/mo</span>
            </div>
            {showAnnualBilling && <p className='text-xxs text-darker text-opacity-35 font-light'>Annual Billing</p>}

            <button
                className={clsx(
                    'w-full rounded-full p-2 mt-6 text-sm font-medium',
                    buttonClass
                        ? buttonClass
                        : 'border border-blue-dark border-opacity-10 hover:border-opacity-25 text-darker text-opacity-75',
                    'disabled:opacity-50 disabled:cursor-not-allowed',
                )}
                onClick={onClick}
                disabled={disabled}
            >
                <p>{buttonName}</p>
            </button>
        </div>
    );
};

export default TierCard;
