import { makeAutoObservable } from "mobx";

export type Session = {
    id: number;
    domain: string;
    sessionUrl: string;
    tracked: boolean;
    lastSummary: string;
    fields: string[];
    metatags: string[];
    date: Date;
    tabTitle: string;
    goal: string;
    protocolType: string;
    hint: string;
    spaceLogo: string;
}

export type SessionByDomain = {
    domain: string;
    data: Session[];
}

export type SessionByDate = {
    date: string;
    data: Session[];
}

export class SessionStore{
    private session: Session[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    getAll(){
        return this.session;
    }

    init(session: Session[]) {
        this.session = session;
    }
}