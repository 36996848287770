import React from 'react';

import clsx from 'clsx';

import { Select, SelectProps } from '../../basic/Select.component';

type FormSelectProps = SelectProps<string> & {
    edit: boolean;
    addComponent?: React.ReactNode;
    errot?: boolean;
    required?: boolean;
    startIcon?: React.ReactNode;
    disabledBackground?: boolean;
    marginTop?: number;
    handleFilterChange?: () => void;
    selectedShortFilters?: {
        sortBy: string;
        order: string;
    };
};

const FormSelect: React.FC<FormSelectProps> = ({
    edit,
    value,
    label,
    onChange,
    placeholder,
    className,
    disabled,
    addComponent,
    entries,
    icons,
    error,
    required,
    startIcon,
    disabledBackground,
    marginTop,
    selectedShortFilters,
    handleFilterChange,
}) => {
    return (
        <div>
            <div className='flex'>
                {label && <p className='text-sm font-medium text-darker text-opacity-75 mb-2'>{label}</p>}
                {required && <span className='text-error'>*</span>}
            </div>
            {edit ? (
                <div className='relative m_select'>
                    {startIcon && <div className='absolute top-1/2 transform -translate-y-1/2 left-4'>{startIcon}</div>}
                    <Select
                        entries={entries}
                        disabled={disabled}
                        className={clsx(className, !className?.includes('w-') && 'md:max-w-[290px] w-full')}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        icons={icons}
                        error={error}
                        disabledBackground={disabledBackground}
                        marginTop={marginTop}
                        selectedShortFilters={selectedShortFilters}
                        handleFilterChange={handleFilterChange}
                    />
                    {addComponent}
                </div>
            ) : (
                <>
                    <p className='text-normal'>{value ?? placeholder}</p>
                </>
            )}
        </div>
    );
};

export default FormSelect;
