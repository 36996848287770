import { JSXElementConstructor, ReactElement } from 'react';

import ReactPDF, { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

export const generateAndDownloadPdf = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document: ReactElement<ReactPDF.DocumentProps, string | JSXElementConstructor<any>>,
    fileName: string,
) => {
    const blob = await pdf(document).toBlob();
    saveAs(blob, fileName);
};
