import { Dialog, makeStyles } from '@material-ui/core';

import { ReactComponent as CloseIcon } from '../../../../../assets/icons/icon-cross-fill.svg';
import { OnboaringTour } from '../../../../../store/UserStore';

const TourDialog = ({
    open,
    top,
    left,
    type,
    title,
    content,
    handleClose,
}: {
    open: boolean;
    handleClose: () => void;
    top: number;
    left: number;
    type: keyof OnboaringTour;
    title: string;
    content: React.ReactNode;
}) => {
    const useStyles = makeStyles(() => ({
        dialog: {
            position: 'absolute',
            left: left,
            top: top,
        },
    }));
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            classes={{
                paper: classes.dialog,
            }}
            BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        >
            <div
                className='flex flex-col p-6 mt-5 bg-spaces-black'
                style={{
                    width: '280px',
                    border: '1px solid rgba(0, 0, 0, 0.5)',
                    borderRadius: '5px',
                    borderInlineStart: '5px solid rgba(74, 149, 214, 1)',
                }}
            >
                <div className='w-full flex justify-between items-center text-white text-sm'>
                    <p className='font-semibold'>{title}</p>
                    <button onClick={handleClose}>
                        <CloseIcon fill='rgba(255, 255, 255, 0.35)' className='w-3 h-3' />
                    </button>
                </div>
                {content}
                <div className='flex space-x-2 text-white text-opacity-75 text-xs mt-5'>
                    <p>Done?</p>
                    <button
                        style={{
                            textDecoration: 'underline',
                        }}
                        onClick={handleClose}
                    >
                        Click here to skip
                    </button>
                </div>
            </div>
        </Dialog>
    );
};

export default TourDialog;
