import { useState } from 'react';

import { appInsights } from '../AppInsights';
import { useServices } from '../components/ServiceProvider';
import { store } from '../store/index';
import { AssociatedMemberInfo, Space, SpaceInfo, WorkspaceInfo } from '../store/SpacesStore';
import { getDocumentUrl } from '../utils/getDocumentUrl';
import { useForm } from '../utils/useForm';
import { required } from '../utils/validation';

export const useSpaces = (currentSpace?: Space | undefined) => {
    const [error, setError] = useState<string | null>(null);
    const { spaces, documents, organisation } = useServices();
    const [loading, setLoading] = useState(false);
    const {
        fields: spaceFields,
        isValid: spaceIsValid,
        setValues: setSpaceValues,
    } = useForm({
        fields: {
            spaceName: { initialValue: currentSpace?.spaceName || '', validation: required },
            spaceIcon: { initialValue: currentSpace?.spaceIcon || '' },
            spaceColor: { initialValue: currentSpace?.spaceColor || '' },
            spaceImage: { initialValue: currentSpace?.spaceImage || '' },
            spaceBanner: { initialValue: currentSpace?.spaceBanner || '' },
        },
    });

    const createCopilotSpace = async (data: SpaceInfo, file?: File | null) => {
        try {
            if (!spaceIsValid()) throw new Error('Space Name is required');
            try {
                const space = await spaces.createCopilotSpace(data);
                if (file) await uploadSpaceLogo(file, space.id);
                //updating the space list after creating a new space
                const mySpaces = await spaces.getSpaces();
                store.spaces.init(mySpaces);
                return space;
            } catch (err) {
                setLoading(false);
                if (err instanceof Error) setError(err.message);
                else setError('Some fields are not valid, please check again');
            }
        } catch (err) {
            setLoading(false);
            if (err instanceof Error) setError(err.message);
        }
    };

    const updateSpace = async (data: SpaceInfo, spaceId: string, file?: File | null) => {
        try {
            if (!spaceIsValid()) throw new Error('Space Name is required');
            try {
                const response = await spaces.updateSpace(data, spaceId);
                if (file) await uploadSpaceLogo(file, spaceId);
                return response;
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                    appInsights.trackException({ exception: err });
                } else {
                    setError('Some fields are not valid, please check again');
                    appInsights.trackException({ exception: new Error('Error while updating space') });
                }
            }
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
                appInsights.trackException({ exception: err });
            }
        }
    };

    const getSpaces = async () => {
        return await spaces.getSpaces();
    };

    const getSpace = async (id: string) => {
        return await spaces.getSpacesById(id);
    };

    const getWorkspaceDetails = async (): Promise<WorkspaceInfo> => {
        const data = await spaces.getWorkspaceDetails();
        store.spaces.setWorkSpaceInfo(data);
        return data;
    };

    const deleteSpace = async (id: string) => {
        return await spaces.deleteSpace(id);
    };

    const getUserPermission = async (spaceId: string) => {
        return await spaces.getUserPermission(spaceId);
    };

    const getSpaceDetails = async (spaceId: string) => {
        return await spaces.getSpaceDetails(spaceId);
    };

    const uploadSpaceLogo = async (file: File, spaceId: string) => {
        if (!store.user.userData?.id) throw new Error('cannot upload asset');
        const { document, blobSasUrl } = await documents.createDocument(
            file.name,
            file.size,
            file.type,
            store.user.userData.id,
            'sharedFile',
        );
        await documents.uploadFile(file, { document, blobSasUrl });
        if (document.id) {
            await organisation.uploadLogo(document.id.toString(), spaceId);
        }
    };

    const uploadSpaceBanner = async (file: File, spaceId?: string, isLogo = false) => {
        if (!store.user.userData?.id) throw new Error('cannot upload asset');
        const { document, blobSasUrl } = await documents.createDocument(
            file.name,
            file.size,
            file.type,
            store.user.userData.id,
            'sharedFile',
        );
        await documents.uploadFile(file, { document, blobSasUrl });
        if (document.id) {
            console.log('uploaded document url', getDocumentUrl(document.id));
            const url = getDocumentUrl(document.id);
            const data = {
                url: document.id.toString(),
            };
            if (!spaceId && isLogo) {
                console.log('Updating the Workspace Logo!!!!', spaceId);
                store.spaces.currentWorkSpaceLogoDocumentId = document.id.toString();
            } else if (!spaceId && !isLogo) {
                console.log('Updating the Workspace Banner!!!!', spaceId);
                store.spaces.currentWorkSpaceBannerDocumentId = document.id.toString();
                spaces.updateWorkspaceBanner(store.user.userData.id, data);
            } else if (isLogo && spaceId) {
                console.log('Updating Space Logo');
                store.spaces.currentSpaceLogoDocumentId = document.id.toString();
            } else {
                console.log('Updating the banner of the space');
                store.spaces.currentSpaceBannerDocumentId = document.id.toString();
            }
        }
    };

    const getUserProfilePicture = async (id: number) => {
        const url = await spaces.getUserProfilePicture(id);
        return url;
    };

    const checkUserInvitationExists = async (spaceId: string) => {
        return await spaces.checkUserInvitationExists(spaceId);
    };

    const acceptSpaceInvite = async (spaceId: string) => {
        return await spaces.acceptSpaceInvite(spaceId);
    };

    const declineSpaceInvite = async (spaceId: string) => {
        return await spaces.declineSpaceInvite(spaceId);
    };

    const checkGlobalPermission = (spaceId: string, memberIds?: AssociatedMemberInfo[]) => {
        const organisationId = store.user.userData?.organisationSpaceId;
        const permission = store.user.userData?.permissions.some(
            (permission) => permission.spaceId === spaceId && permission.role === 'Global',
        );
        const memberGlobalPermission =
            memberIds &&
            store.user.userData?.permissions.some(
                (permission) =>
                    memberIds.some((member) => member.id === permission.associatedMemberId) &&
                    permission.role === 'Global' &&
                    permission.spaceId === organisationId,
            );
        return permission ? true : memberGlobalPermission ? true : false;
    };

    const deleteSpaceLogo = async (spaceId: string) => {
        return await spaces.deleteSpaceLogo(spaceId);
    };

    const getSpacesWithoutOrg = async () => {
        return await spaces.getSpacesWithoutOrg();
    };

    const updateSpaceVisitedAt = async (spaceId: string) => {
        return await spaces.updateSpaceVisitedAt(spaceId);
    }

    return {
        spaceFields,
        getSpaces,
        getWorkspaceDetails,
        deleteSpace,
        getUserPermission,
        getSpaceDetails,
        uploadSpaceBanner,
        getUserProfilePicture,
        checkUserInvitationExists,
        acceptSpaceInvite,
        declineSpaceInvite,
        setSpaceValues,
        updateSpace,
        error,
        checkGlobalPermission,
        getSpace,
        createCopilotSpace,
        deleteSpaceLogo,
        getSpacesWithoutOrg,
        loading,
        setLoading,
        setError,
        updateSpaceVisitedAt,
    };
};
