/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';

import { Box, makeStyles } from '@material-ui/core';
import { noop } from 'lodash';
import Draggable from 'react-draggable';
import { FaAngleRight } from 'react-icons/fa6';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';
import ReactSlider from 'react-slider';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ReactComponent as CheckboxIcon } from '../../../../../../src/assets/icons/checkbox_Icon.svg';
import { ReactComponent as HorizontalLine } from '../../../../../../src/assets/icons/dashline.svg';
import { ReactComponent as DownArrow } from '../../../../../../src/assets/icons/DownArrow.svg';
import { ReactComponent as FlagIcon } from '../../../../../../src/components/organisation/copilotOrganisation/eva/components/img/icon-flag.svg';
import { appInsights } from '../../../../../AppInsights';
import { ReactComponent as ActiveFileUploadIcon } from '../../../../../assets/icons/active_file_Upload.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/Close_icon.svg';
import { ReactComponent as CsvFile } from '../../../../../assets/icons/Csv_Icon.svg';
import { ReactComponent as DocFile } from '../../../../../assets/icons/doc_Icon.svg';
import { ReactComponent as EayIcon } from '../../../../../assets/icons/Eay.svg';
import { ReactComponent as FileUploadIcon } from '../../../../../assets/icons/file_uplod_icon.svg';
import { ReactComponent as FileUploadIconHover } from '../../../../../assets/icons/file_uplod_iconHover.svg';
import { ReactComponent as HtmlFile } from '../../../../../assets/icons/Html_Icon.svg';
import { ReactComponent as CloseIconNuance } from '../../../../../assets/icons/icon-close.svg';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/icon-nuance-trigger-plus.svg';
import { ReactComponent as JsonFile } from '../../../../../assets/icons/Json_Icon.svg';
import { ReactComponent as LeftArrow } from '../../../../../assets/icons/Left_arrow.svg';
import { ReactComponent as OpenEayIcon } from '../../../../../assets/icons/OpenEay.svg';
import { ReactComponent as OtherFile } from '../../../../../assets/icons/Other_File_Icon.svg';
import { ReactComponent as PDFIcon } from '../../../../../assets/icons/PDF_Icon.svg';
import { ReactComponent as RefreshIcon } from '../../../../../assets/icons/Refresh_Icon.svg';
import { ReactComponent as ReplaceIcon } from '../../../../../assets/icons/Replace_Icon.svg';
import { ReactComponent as RightArrow } from '../../../../../assets/icons/Right_arrow.svg';
import { ReactComponent as SaveIcon } from '../../../../../assets/icons/Save_icon.svg';
import { ReactComponent as SaveIconHover } from '../../../../../assets/icons/Save_iconHover.svg';
import { ReactComponent as TxtFile } from '../../../../../assets/icons/Txt_Icon.svg';
import { ReactComponent as XlsFile } from '../../../../../assets/icons/Xls_Icon.svg';
import { ReactComponent as XmlFile } from '../../../../../assets/icons/Xml_Icon.svg';
import { EvaService } from '../../../../../services/eva/eva.service';
import { Button } from '../../../../basic/Button.component';
import { InputWithLength } from '../../../../basic/InputWithLength.component';
import { ReactComponent as IconDelete } from '../components/img/icon-delete.svg';
import { ReactComponent as IconRight } from '../components/img/icon-right.svg';
import { EvaType, Nuances, ProtocolOptionType, ThumbData } from '../types';
import './style.css';
import './slider/slider.css';
import { convertToThumbData, deepCompareData, formatCamelCase, formatDateToDDMMYYYY } from './createEva/EvaCompare';
import NuancesMapping from './NuancesMapping';
import { OptionData } from './ProtocolTypeList';

const useStyles = makeStyles(() => ({
    metaMarkerTooltipBoxText: {
        fontSize: '10px !important',
        lineHeight: '15px',
        color: '#fff',
        fontWeight: 400,
    },
    metaMarkerTooltipBox: {
        left: '1.25rem',
        top: 0,
        width: '18rem',
        height: '5rem',
        backgroundColor: 'rgba(0, 20, 255, 1)',
        zIndex: 9999,
    },
}));

interface CreateEvaModelProps {
    createEvaMetaMarker: (event: React.MouseEvent<HTMLElement>) => void;
    inputValues: { nuance: string; threshold: number }[];
    setInputValues: React.Dispatch<
        React.SetStateAction<
            {
                nuance: string;
                threshold: number;
            }[]
        >
    >;
    formattedData: Nuances[];
    selectedItemIndex: null | number;
    setSelectedItemIndex: React.Dispatch<React.SetStateAction<number | null>>;
    nuancesValidate: boolean;
    setNuancesValidate: React.Dispatch<React.SetStateAction<boolean>>;
    metaMarkerValidation: boolean;
    setMetaMarkerValidation: React.Dispatch<React.SetStateAction<boolean>>;
    checkNuancesLeg: boolean;
    setCheCkNuancesLeg: React.Dispatch<React.SetStateAction<boolean>>;
    currentThreshold: null | number;
    setCurrentThreshold: React.Dispatch<React.SetStateAction<number | null>>;
    values: number[];
    setValues: React.Dispatch<React.SetStateAction<number[]>>;
    triggerNumber: number;
    setTriggerNumber: React.Dispatch<React.SetStateAction<number>>;
    openDrawer: boolean;
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    markerIndex: number | null;
    setMarkerIndex: React.Dispatch<React.SetStateAction<number | null>>;
    setMetaMarkerValue: React.Dispatch<React.SetStateAction<string>>;
    metaMarkerValue: string;
    title: string;
    iconState: string;
    setIconState: React.Dispatch<React.SetStateAction<string>>;
    setSaveConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleIconClick: (iconValue: string) => void;
    handleValidation: (type: string, value: string | boolean) => void;
    metaMarker: any;
    deleteEvaMetaMarker: (metamarkerId: number | null, saveType: EvaType) => Promise<void>;
    refModal: React.RefObject<HTMLDivElement>;
    data: ThumbData[];
    setData: React.Dispatch<React.SetStateAction<ThumbData[]>>;
    setOrbitLoader: React.Dispatch<React.SetStateAction<boolean>>;
    clickOutSideSideBar: boolean;
    setClickOutSideSideBar: React.Dispatch<React.SetStateAction<boolean>>;
    setTitleName: React.Dispatch<React.SetStateAction<string>>;
    titleName: string;
    setIsCheckSelf: React.Dispatch<React.SetStateAction<boolean>>;
    isCheckSelf: boolean;
    setEvaluateMode: React.Dispatch<React.SetStateAction<boolean>>;
    evaluateMode: boolean;
    updateDate: string;
    setCopySelect: React.Dispatch<React.SetStateAction<string>>;
    CopySelect: string;
    copyTriggerNumber: number;
    setCopyTriggerNumber: React.Dispatch<React.SetStateAction<number>>;
    setCopyIsSelf: React.Dispatch<React.SetStateAction<boolean>>;
    CopyIsSelf: boolean;
    setCurrentProtocolNuances: React.Dispatch<React.SetStateAction<Nuances[]>>;
    setDisableArrow: React.Dispatch<React.SetStateAction<boolean>>;
    disableArrow?: boolean;
}

const Sidebar: React.FC<CreateEvaModelProps> = ({
    createEvaMetaMarker,
    inputValues,
    setInputValues,
    formattedData,
    selectedItemIndex,
    setSelectedItemIndex,
    nuancesValidate,
    setNuancesValidate,
    metaMarkerValidation,
    setMetaMarkerValidation,
    checkNuancesLeg,
    setCheCkNuancesLeg,
    currentThreshold,
    setCurrentThreshold,
    values,
    setValues,
    triggerNumber,
    setTriggerNumber,
    openDrawer,
    setOpenDrawer,
    setMarkerIndex,
    markerIndex,
    setMetaMarkerValue,
    metaMarkerValue,
    title,
    setIconState,
    iconState,
    setSaveConfirmModal,
    handleIconClick,
    handleValidation,
    metaMarker,
    deleteEvaMetaMarker,
    refModal,
    data,
    setData,
    setOrbitLoader,
    clickOutSideSideBar,
    setClickOutSideSideBar,
    setTitleName,
    titleName,
    setIsCheckSelf,
    isCheckSelf,
    setEvaluateMode,
    evaluateMode,
    CopySelect,
    setCopySelect,
    updateDate,
    copyTriggerNumber,
    setCopyTriggerNumber,
    setCopyIsSelf,
    CopyIsSelf,
    setCurrentProtocolNuances,
    setDisableArrow,
    disableArrow = false,
}) => {
    const initialTrigger = 50;
    const classes = useStyles();
    const evaService = new EvaService();
    const [showInput, setShowInput] = useState<boolean>(false);
    const [nuanceInputValue, setNuanceInputValue] = useState<string>('');
    const [editTitlePosition, setEditTitlePosition] = useState<number | null>(null);
    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    const titlesRef = useRef<HTMLDivElement>(null);
    const selectedVal = 0;
    const [dragSelectVal, setDragSelectVal] = useState<boolean>(false);
    const [showNuancesMsg, setShowNuancesMsg] = useState<boolean>(true);
    const [isShowInfoBox, setIsShowInfoBox] = useState(false);
    const [copyMetaMarkerValue, setCopyMetaMarkerValue] = useState<string>('');
    const [copyIconState, setCopyIconState] = useState<string>(iconState);
    const [blobcode, setBlobcode] = useState<Blob | null>(null);

    // select dropdown =========
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [protocolNuances, setProtocolNuances] = useState<Nuances[]>([]);
    const [optionList, setOptionList] = useState<ProtocolOptionType[]>(OptionData);
    const [currentOption, setCurrentOption] = useState<ProtocolOptionType>();
    const [checkChangeData, setCheckChangeData] = useState(false);
    const customSelectRef = useRef<HTMLDivElement>(null);
    const [isDuplicateNuance, setIsDuplicateNuance] = useState<boolean>(false);

    const metaMarkerLength = metaMarker ? metaMarker?.length : null;
    const [dotHoverIndex, setDotHoverIndex] = useState<null | number>(null);

    const [fileError, setFileError] = useState<string | null>(null);
    const [fileInfo, setFileInfo] = useState({
        fileName: '',
        fileSize: '',
        fileType: '',
    });

    const [changeNuancesScreen, setChangeNuancesScreen] = useState(false);
    // swap data convert
    const [nuancesData, setNuancesData] = useState<ThumbData[]>([]);
    const [nuancesDataValues, setNuancesDataValues] = useState<number[]>([]);
    const [InputFocus, setInputFocus] = useState(false);
    const [emptyNuances, setEmptyNuances] = useState(false);
    const [isTitleEmpty, setIsTitleEmpty] = useState(false);
    // copy orignal nuance
    const [copyNuancesData, setCopyNuancesData] = useState<ThumbData[]>([]);
    const [copyNuancesValues, setCopyNuancesValues] = useState<number[]>([]);
    const [apiNuances, setApiNuances] = useState<Nuances[]>([]);
    const [copyApiNuances, setCopyApiNuances] = useState<Nuances[]>([]);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const checkNuancesData = copyApiNuances?.length === apiNuances?.length ? true : false;
    // new design
    const protocolNuancesData = convertToThumbData(inputValues);
    const sliderHeight = 416;
    const thresholdValues: number[] = protocolNuancesData?.map((item) => item.value);
    const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes
    const acceptedFileTypes = [
        'application/msword', // .doc -
        'application/pdf', // .pdf -
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx -
        'application/vnd.ms-excel', // .xls
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
        'text/xml', // .xml
        'text/csv', // .csv
        'text/plain', // .txt
        'application/json', // .json
        'text/html', // .html, .htm
        'application/xhtml+xml', // .xhtml
    ];

    // Helper function to check if MIME type contains '+xml'
    const isXmlFile = (fileType: string) => fileType.includes('+xml');

    let FileIcon;
    switch (fileInfo.fileType) {
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            FileIcon = DocFile;
            break;
        case 'application/pdf':
            FileIcon = PDFIcon;
            break;
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            FileIcon = XlsFile;
            break;
        case 'text/xml':
            FileIcon = XmlFile;
            break;
        case 'text/csv':
            FileIcon = CsvFile;
            break;
        case 'text/plain':
            FileIcon = TxtFile;
            break;
        case 'application/json':
            FileIcon = JsonFile;
            break;
        case 'text/html':
        case 'application/xhtml+xml':
            FileIcon = HtmlFile;
            break;
        default:
            FileIcon = OtherFile;
            break;
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (customSelectRef?.current && !customSelectRef?.current?.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (data || values) {
            const convertedData: Nuances[] = data?.flatMap((item) =>
                item?.titles?.map((title) => ({
                    nuance: title,
                    threshold: item.value,
                })),
            );
            setProtocolNuances(convertedData);
            const filteredData = convertedData.filter((item) => item?.nuance.trim() !== '');
            setCurrentProtocolNuances(filteredData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, values, evaluateMode]);

    const checkData = deepCompareData(data, protocolNuancesData);
    useEffect(() => {
        // Debugging: print the value of data when useEffect is run

        if (metaMarkerValue) {
            setCopyMetaMarkerValue(metaMarkerValue);
        }
        if (triggerNumber) {
            setCopyTriggerNumber(triggerNumber);
        }
        if (iconState) {
            setCopyIconState(iconState);
        }

        if (titleName) {
            setCopySelect(titleName);
        }

        if (isCheckSelf) {
            setCopyIsSelf(isCheckSelf);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openDrawer, markerIndex, metaMarkerValue, titleName, isCheckSelf]);

    useEffect(() => {
        const checkIfClickedOutside = (e: MouseEvent) => {
            if (clickOutSideSideBar) {
                if (openDrawer && refModal.current && !refModal.current.contains(e.target as Node) && !evaluateMode) {
                    setSaveConfirmModal(true);
                }
            } else {
                if (openDrawer && refModal.current && !refModal.current.contains(e.target as Node) && !evaluateMode) {
                    setOpenDrawer(false);
                }
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openDrawer, clickOutSideSideBar, evaluateMode, markerIndex]);

    useEffect(() => {
        if (openDrawer) {
            if (
                copyMetaMarkerValue !== metaMarkerValue ||
                copyTriggerNumber !== triggerNumber ||
                copyIconState !== iconState ||
                CopySelect !== titleName ||
                CopyIsSelf !== isCheckSelf ||
                !checkData
            ) {
                setClickOutSideSideBar(true);
            } else {
                setClickOutSideSideBar(false);
            }
        }
        for (const item of data) {
            if (item?.titles?.includes('')) {
                return setEmptyNuances(true);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkData, markerIndex, metaMarkerValue, triggerNumber, iconState, titleName, data, openDrawer, isCheckSelf]);

    //============== end code , open conform modal when i close eva sidebar ==================

    const findDuplicateNuances = () => {
        const allTitles = data.flatMap((item) => item.titles);
        const titleCounts: Record<string, number> = {};
        allTitles.forEach((title) => {
            titleCounts[title] = (titleCounts[title] || 0) + 1;
        });
        const hasDuplicate = Object.values(titleCounts).some((count) => count > 1);
        setIsDuplicateNuance(hasDuplicate);
    };

    useEffect(() => {
        if (selectedItemIndex !== null) {
            setShowInput(true);
            setDotHoverIndex(null);
            setShowNuancesMsg(false);
        } else {
            setShowInput(false);
            setEditTitlePosition(null);
        }

        if (selectedItemIndex !== null && editTitlePosition !== null) {
            // Filter out items where all titles are empty or whitespace
            const filteredData = data.filter((item) => item.titles.some((title) => title.trim() !== ''));
            // Check if the specific title is empty in the filtered data
            const isEmptyTitle = filteredData[selectedItemIndex]?.titles[editTitlePosition]?.trim() === '';
            // Set the state for isTitleEmpty based on the result
            setIsTitleEmpty(isEmptyTitle);
        }
        findDuplicateNuances();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItemIndex, editTitlePosition, data, values]);

    useEffect(() => {
        if (selectedItemIndex == null) {
            if (emptyNuances) {
                checkEmptyNuances();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItemIndex, emptyNuances]);

    // slider left side ==========

    const handleChangeMetaMarker = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMetaMarkerValue(e.target.value);
        setMetaMarkerValidation(false);
        if (isCheckSelf) {
            setCheckChangeData(true);
        }
    };

    useEffect(() => {
        if (inputValues) {
            setValues(thresholdValues);
            setData(protocolNuancesData);
            setCopyNuancesData(protocolNuancesData);
            setCopyNuancesValues(thresholdValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValues]);

    // convert data to api response formate
    const dataFormat = () => {
        // Iterate over each item in the data array
        data?.forEach((item) => {
            const thresholdValue = item.value; // Get the threshold value from the current item
            item.titles.forEach((title) => {
                // Push a new object with the current title and threshold value into the formattedData array
                formattedData.push({ nuance: title, threshold: thresholdValue });
            });
        });
    };

    useEffect(() => {
        if (!openDrawer) {
            setMarkerIndex(null);
            setInputValues([]);
            setMetaMarkerValue('');
            setNuanceInputValue('');
            setTriggerNumber(initialTrigger);
            setCopyTriggerNumber(initialTrigger);
            setSelectedItemIndex(null);
            setEditTitlePosition(null);
            setMetaMarkerValidation(false);
            setNuancesValidate(false);
            setShowInput(false);
            setShowNuancesMsg(true);
            setIconState('>');
            setClickOutSideSideBar(false);
            setCheckChangeData(false);
            setIsCheckSelf(true);
            setTitleName('Custom');
            setData([]);
            setValues([]);
            setCopyNuancesValues([]);
            setCopyNuancesData([]);
            setNuancesDataValues([]);
            setNuancesData([]);
            setIsDuplicateNuance(false);
            setCheCkNuancesLeg(false);
            setEmptyNuances(false);
            setCurrentProtocolNuances([]);
            setIsTitleEmpty(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openDrawer]);

    // submit data
    const handleSubmitData = (event: React.MouseEvent<HTMLElement>) => {
        for (const item of data) {
            if (item.titles.includes('')) {
                return setCheCkNuancesLeg(true);
            }
        }

        setOrbitLoader(true);
        setBtnLoading(true);
        setTimeout(() => {
            // Format the data
            dataFormat();
            // Create an EVA Meta Marker with the event details
            createEvaMetaMarker(event);
            // Set button loading state to false after the operations
            setBtnLoading(false);
            setOrbitLoader(false);
            setCopyMetaMarkerValue(metaMarkerValue);
            setCopyTriggerNumber(triggerNumber);
            setCopyIconState(iconState);
            setOpenDrawer(true);
            // Check if the input is shown
            // If the input value is empty, set the check nuances flag to true
        }, 1500); // 1.5 second delay
    };

    // remove Nuance one by one
    const handleRemoveNuance = (
        index: number,
        titleIndex: number,
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        // Prevent the event from propagating further
        event.stopPropagation();
        // Update the data state
        setData((prevData) => {
            const newData = [...prevData];
            // Remove the title at the specified titleIndex
            newData[index]?.titles?.splice(titleIndex, 1);
            // If the titles array is empty after removal, remove the entire entry
            if (newData[index]?.titles.length === 0) {
                newData.splice(index, 1);
                // Update values state accordingly
                setValues((prevValues) => {
                    const newValues = [...prevValues];
                    newValues.splice(index, 1);
                    return newValues;
                });
                // Hide input and show nuances message
                setShowInput(false);
                setShowNuancesMsg(true);
                setNuanceInputValue('');
            }
            return newData;
        });

        // Adjust the edit title position and input value if titles remain
        setData((prevData) => {
            const newData = [...prevData];
            if (titleIndex > 0) {
                setEditTitlePosition(titleIndex - 1);
                setNuanceInputValue(newData[index]?.titles[titleIndex - 1] || '');
            } else if (titleIndex === 0 && newData[index]?.titles.length > 0) {
                setEditTitlePosition(0);
                setNuanceInputValue(newData[index]?.titles[0] || '');
            }
            return newData;
        });

        // Check if the selected item has no titles and reset the selected index if necessary
        if (data?.find((obj, idx) => idx === selectedItemIndex)?.titles.length === 0) {
            setSelectedItemIndex(null);
        }
    };

    // current selected Nuance and heigh light
    const handleSpanClick = (index: number) => {
        if (selectedItemIndex !== index) {
            checkEmptyNuances();
        }
        // Check if dragSelectVal is false before proceeding
        if (!dragSelectVal) {
            // Toggle the selected item index between the current index and null
            setSelectedItemIndex((prevIndex) => (prevIndex === index ? null : index));
            // Reset the edit title position to null
            setEditTitlePosition(null);
            // Toggle the visibility of the input field
            setShowInput(!showInput);
            // Toggle the visibility of the nuances message
            setShowNuancesMsg(!showNuancesMsg);
            // Get the title data for the specified index
            const getTitle = data[index];
            // If the clicked item is not the currently selected item, set the input value and edit position
            if (selectedItemIndex !== index) {
                setNuanceInputValue(getTitle?.titles[0] || '');
                setEditTitlePosition(0);
            } else {
                // Clear the input value if the item is deselected
                setNuanceInputValue('');
            }
        }
    };

    useEffect(() => {
        // Check if selectedItemIndex is not null or undefined
        if (selectedItemIndex) {
            // Find the data item corresponding to selectedItemIndex
            const singleData = data?.find((item, i) => i === selectedItemIndex);
            // If the data item exists, update the current threshold
            if (singleData) {
                setCurrentThreshold(singleData?.value);
            }
        } else {
            // Special case when selectedItemIndex is exactly 0
            if (selectedItemIndex === 0) {
                const singleData = data[0];
                // Update the current threshold with the value of the first item
                setCurrentThreshold(singleData?.value);
            }
        }

        if (selectedItemIndex == null) {
            setIsTitleEmpty(false);
        }

        if (showInput) {
            if (nuanceInputValue.length > 0) {
                setCheCkNuancesLeg(false);
            } else {
                setIsTitleEmpty(true);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItemIndex, nuanceInputValue, showInput]);

    const selectTitle = (
        nuance: string,
        index: number,
        nuanceIndex: number,
        event: React.MouseEvent<HTMLDivElement>,
    ) => {
        event.stopPropagation();
        setEditTitlePosition(index);
        setNuanceInputValue(nuance);
    };

    const nuanceHandleChange = (nuance: string) => {
        setCheckChangeData(true);
        setNuanceInputValue(nuance);
        if (editTitlePosition !== null && selectedItemIndex !== null) {
            setData((prevData) => {
                const newData = [...prevData];
                const selectedItem = newData[selectedItemIndex];

                // If nuance is not empty, update the title; if empty, set the title to an empty string
                const updatedTitles = selectedItem.titles.map((title, index) =>
                    index === editTitlePosition ? nuance : title,
                );

                newData[selectedItemIndex] = { ...selectedItem, titles: updatedTitles };
                return newData;
            });
        }
        // Additional logic for updating if the titles array is empty and selectedItemIndex is not null
        if (selectedItemIndex !== null && nuance.trim() !== '') {
            setData((prevData) => {
                return prevData.map((item, index) => {
                    if (index === selectedItemIndex) {
                        const updatedTitles = item.titles.length === 0 ? [nuance] : [...item.titles];
                        return { ...item, titles: updatedTitles };
                    }
                    return item;
                });
            });
            setEditTitlePosition(editTitlePosition);
        }
        setNuancesValidate(false);
    };

    // Check if the data array has at least 2 items or if every item in the data array has at least 2 titles
    const allTitlesLengthTwo = data.length >= 2 || data.every((item) => item.titles.length >= 2);

    // add validation Nuance length is GreaterThan 15 hide a plus button
    function checkTitleArrayLength(data: { value: number; titles: string[] }[]): boolean {
        let totalLength = 0;
        // Iterate over each item in the data array
        for (let i = 0; i < data.length; i++) {
            // Add the length of the titles array of the current item to totalLength
            totalLength += data[i].titles.length;
        }
        // If totalLength is 15 or more, return false; otherwise, return true
        if (totalLength >= 15) {
            return false;
        } else {
            return true;
        }
    }
    useEffect(() => {
        if (editTitlePosition !== null) {
            if (editTitlePosition > 1) {
                scrollToBottom();
            }
        }
        // Call the scrollToBottom function whenever the data array changes
    }, [data, editTitlePosition]);

    const scrollToBottom = () => {
        // Check if titlesRef.current is defined
        if (titlesRef.current) {
            // Set the scrollTop of the element to its scrollHeight to scroll to the bottom
            titlesRef.current.scrollTop = titlesRef.current.scrollHeight;
        }
    };

    const addNuance = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, selectedIndex: number) => {
        // Prevent the event from propagating further
        event.stopPropagation();
        // Clear the detail input value
        setNuanceInputValue('');
        setShowInput(true);
        setShowNuancesMsg(false);
        setTimeout(() => {
            // Check if a valid selectedIndex is provided
            if (selectedIndex !== null) {
                // Update the data with the new title
                setData((prevData) => {
                    const updatedData = prevData.map((item, i) =>
                        // If the index matches the selectedIndex, update its titles array
                        i === selectedIndex
                            ? {
                                  ...item,
                                  titles: [
                                      ...item.titles,
                                      // Add an empty string if the nuanceInputValue is empty, otherwise add the current value
                                      nuanceInputValue?.length > 0 ? '' : nuanceInputValue,
                                  ],
                              }
                            : item,
                    );
                    // Set the edit title position to the index of the last added title
                    const lastAddedIndex = updatedData[selectedIndex].titles.length - 1;
                    setEditTitlePosition(lastAddedIndex);
                    return updatedData;
                });
            }
        }, 100);
    };

    const handleAddThumb = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        // Check if 0 is already present in the values array
        const isZeroPresent = values.includes(0);
        // Find the index of 0 in the values array
        const zeroIndex = values.indexOf(0);
        // Set the selected item index to the index of 0 in the values array
        setSelectedItemIndex(zeroIndex);
        // If 0 is not present, proceed to add the new item
        if (!isZeroPresent && selectedVal !== null) {
            const newValues = [...values, selectedVal];
            setValues(newValues);
            // Create a new thumb data object with selectedVal as its value and an empty title array
            const newThumbData: ThumbData = { value: selectedVal, titles: [''] };
            // Create a new array with newThumbData added to the existing data
            const newData = [...data, newThumbData];
            // Set the data state to the new array
            setData(newData);
            // Calculate the index of the newly added value
            const index = newValues.length - 1;
            // Set the selected item index to the index of the newly added value
            setSelectedItemIndex(index);
            // Set the edit title position to 0
            setEditTitlePosition(0);
            // Hide the nuances message
            setShowNuancesMsg(false);
        } else if (isZeroPresent && selectedVal !== null) {
            // Call the addNuance function if 0 is already present
            addNuance(event, zeroIndex);
        }
        // Hide the nuances message
        setShowNuancesMsg(false);
        // Show the input field
        setShowInput(true);
    };

    const checkNuances = () => {
        if (nuanceInputValue.trim() === '') {
            setCheCkNuancesLeg(true);
            // If detail input value is empty, set the nuances check flag to true
        } else {
            setShowNuancesMsg(true);
            // Hide the input field
            setShowInput(false);
        }
        // Check if detail input value's length is 0
        if (nuanceInputValue?.length === 0) {
            // If detail input value's length is 0 and there's data
            if (data) {
                // Filter items with non-empty titles
                const itemsWithNonEmptyTitles = data?.filter((item) => item.titles && item.titles.length > 0);
                // Check if the number of items with non-empty titles is less than 2
                if (itemsWithNonEmptyTitles.length < 2) {
                    // If less than 2, set the nuances check flag to true
                    setCheCkNuancesLeg(true);
                }
            }
        } else {
            setCheCkNuancesLeg(false);
            // Reset selected item index
            setSelectedItemIndex(null);
            // Clear detail input value
            setNuanceInputValue('');
            // Show the nuances message
            setShowNuancesMsg(true);
        }
    };

    // left side bar
    const handleChange = (triggerValue: number) => {
        setTriggerNumber(triggerValue);
    };

    const renderThumb = (props: any) => {
        return (
            // Container for the thumb
            <div {...props} className='main_thumb'>
                {/* Container for the scroll area */}
                <div className='scrollthumb'>
                    {/* Container for the flag icon */}
                    <div className='scroll_flag'>
                        {/* Flag icon component */}
                        <FlagIcon />
                        {/* Conditional rendering of arrow icon */}
                        {iconState === '<' ? (
                            <div
                                // Apply CSS class based on iconState for arrow rotation
                                className={`${iconState === '<' ? 'roted_arrow' : ''} arrow`}
                                // Handle click event to change iconState
                                onClick={() => handleIconClick('>')}
                            >
                                {/* Right arrow icon */}
                                <FaAngleRight />
                            </div>
                        ) : (
                            <div
                                // Container for arrow icon
                                className='arrow'
                                // Handle click event to change iconState
                                onClick={() => handleIconClick('<')}
                            >
                                {/* Left arrow icon */}
                                <FaAngleRight />
                            </div>
                        )}
                    </div>
                    {/* Container for scroll information */}
                    <div className='scroll_info'>
                        <p>
                            Set what interaction level <b>Triggers</b>
                        </p>
                    </div>
                </div>
                {/* Container for horizontal line */}
                <div className='horizontal-line'>
                    {/* Horizontal line component */}
                    <HorizontalLine />
                </div>
                {/* Display trigger number */}
                <div className='scroll_count'>{triggerNumber}</div>
            </div>
        );
    };

    const checkEmptyNuances = () => {
        const updatedData = data
            .filter((item) => {
                const filteredTitles = item?.titles?.filter((title) => title.trim() !== '');
                return filteredTitles.length > 0;
            })
            .map((item) => ({
                ...item,
                titles: item?.titles?.filter((title) => title.trim() !== ''), // Ensure titles are cleaned
            }));
        const updatedValues = updatedData.map((item) => item.value);
        setData(updatedData);
        setValues(updatedValues);
    };

    const handleChangeThreshold = (val: number) => {
        // Check for empty nuances
        if (emptyNuances) {
            checkEmptyNuances();
        }

        const newValue = val;
        // Ensure newValue is a valid number between 0 and 100 and not the current threshold
        if (!isNaN(newValue) && newValue >= 0 && newValue <= 100 && newValue !== currentThreshold) {
            setCurrentThreshold(newValue);

            // Check if selectedItemIndex is valid
            if (selectedItemIndex !== null && selectedItemIndex >= 0 && selectedItemIndex < values.length) {
                // Check if the newValue is already present in `values` (excluding the selected item index)
                const isValueAlreadyPresent = values.some(
                    (value, index) => index !== selectedItemIndex && value === newValue,
                );

                if (!isValueAlreadyPresent) {
                    // Handle title editing if editTitlePosition is provided
                    if (
                        editTitlePosition !== null &&
                        editTitlePosition >= 0 &&
                        data[selectedItemIndex]?.titles?.length > 1
                    ) {
                        const newValues = [...values];
                        const newData = [...data];

                        // Insert newValue at selectedItemIndex in values
                        newValues.splice(selectedItemIndex, 0, newValue);

                        // Clone the selected data item
                        const copiedThumb = { ...data[selectedItemIndex] };
                        const uniqueTitles = Array.from(new Set(data[selectedItemIndex].titles)); // Ensure unique titles

                        // Insert a copy of the selected item at the next index
                        newData.splice(selectedItemIndex + 1, 0, copiedThumb);
                        // Remove the title at editTitlePosition from the original item and update the value
                        newData[selectedItemIndex].titles.splice(editTitlePosition, 1);
                        newData[selectedItemIndex].value = newValue;
                        // Update the original item in newData with the title at the editTitlePosition
                        const updatedOriginalThumb = {
                            ...data[selectedItemIndex],
                            titles: [uniqueTitles[editTitlePosition]],
                        };
                        newData[selectedItemIndex] = updatedOriginalThumb;

                        // Update state
                        setValues(newValues);
                        setData(newData);
                    } else {
                        // If no editTitlePosition or only one title exists
                        const updatedValues = [...values];
                        updatedValues[selectedItemIndex] = newValue;
                        setValues(updatedValues);
                        const updatedData = [...data];
                        updatedData[selectedItemIndex] = { ...updatedData[selectedItemIndex], value: newValue };
                        setData(updatedData);
                    }
                    // Reset editTitlePosition after updates
                    setEditTitlePosition(0);
                } else {
                    // If the value is already present, remove currentThreshold from `values` and merge titles
                    const updatedValues = [...values];
                    const currentThresholdIndex = updatedValues.indexOf(Number(currentThreshold));
                    const updatedData = data.filter((item) => item.value !== currentThreshold);

                    if (currentThresholdIndex >= 0) {
                        updatedValues.splice(currentThresholdIndex, 1); // Remove the current threshold
                    }

                    const tempId = updatedValues.indexOf(newValue);
                    const newData = updatedData.map((item) => {
                        return item.value === updatedValues[tempId]
                            ? {
                                  ...item,
                                  titles: Array.from(new Set([...data[selectedItemIndex].titles, ...item.titles])), // Merge titles
                              }
                            : item;
                    });

                    setEditTitlePosition(0);

                    // Update state
                    setValues(updatedValues);
                    setData(newData);
                    setSelectedItemIndex(updatedValues.indexOf(newValue));
                }
            }
        }

        // Set checkChangeData to true
        setCheckChangeData(true);
    };

    useEffect(() => {
        if (InputFocus && checkChangeData && emptyNuances && currentThreshold) {
            checkEmptyNuances();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentThreshold]);
    const HandelChange = (name: string) => {
        if (!isCheckSelf) {
            setTitleName(name);
        }
        setIsOpen(false);
    };
    useEffect(() => {
        const current = optionList.find((option) => option.name == titleName);
        if (current !== undefined) {
            setCurrentOption(current);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [titleName, markerIndex]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const updateMetaData = async () => {
        if (isCheckSelf) {
            setIsLoading(true);
            const protocolTypes = await evaService.getProtocolTypes({
                metaMarker: metaMarkerValue,
                nuances: protocolNuances,
            });
            setIsLoading(false);
            setCheckChangeData(false);

            if (protocolTypes) {
                setTitleName(typeof protocolTypes.name === 'object' ? 'Custom' : protocolTypes.name);

                const exists = optionList?.some((option) => option.name === protocolTypes.name);
                if (!exists) {
                    setOptionList([
                        ...optionList,
                        { name: protocolTypes.name, icon: '', txtColor: '', linearBg: '', description: '' },
                    ]);
                }
            }
        }
    };
    useEffect(() => {
        if (isCheckSelf !== CopyIsSelf && data.length > 0) {
            updateMetaData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCheckSelf]);

    const handelProtocolType = () => {
        if (isCheckSelf) {
            if (checkChangeData) {
                updateMetaData();
            }
        }
    };
    const {
        isLoading: protocolNuancesLoading,
        isFetching,
        refetch,
    } = useQuery(['postNuancesData', blobcode], async () => {
        try {
            if (blobcode) {
                const formData = new FormData();
                formData.append('file', blobcode);
                const data = await evaService.postNuancesData(formData);
                if (data.length > 0) {
                    const formattedData = {
                        ...data[0],
                        nuances: data[0].nuances?.map((nuance: { nuance: string }) => ({
                            ...nuance,
                            nuance: formatCamelCase(nuance.nuance),
                        })),
                    };
                    // Update state with formatted nuances
                    setApiNuances(formattedData?.nuances);
                    setCopyApiNuances(formattedData?.nuances);
                }
            }
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
                setErrorMsg(e.message);
            }
        }
    });

    // upload file
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const blobData = new Blob([file], { type: file.type });

            // Validate file size
            if (file.size > maxFileSize) {
                setFileError('File size does not exceed 10MB');
                setFileInfo({ fileName: '', fileSize: '', fileType: '' });
                return;
            }

            // Validate file type
            const isAcceptedFileType = acceptedFileTypes.includes(file.type) || isXmlFile(file.type);
            if (!isAcceptedFileType) {
                setFileError(
                    'Invalid file type. Only DOC, PDF, XML, DOCX, Excel, CSV, JSON, HTML, and plain text files are allowed.',
                );
                setFileInfo({ fileName: '', fileSize: '', fileType: '' });
                return;
            }
            // If validation passes
            const fileSizeMB = (file.size / 1024 / 1024).toFixed(2) + ' MB';
            const fileSizeKB = (file.size / 1024).toFixed(2) + ' KB';
            setFileError(null);
            setFileInfo({
                fileName: file.name,
                fileSize: fileSizeKB || fileSizeMB,
                fileType: file.type,
            });
            setBlobcode(blobData);
            setChangeNuancesScreen(true);
            refetch();
        }
        // Reset the input value to allow the same file to be selected again
        e.target.value = '';
    };
    const removeFile = () => {
        setFileInfo({ fileName: '', fileSize: '', fileType: '' });
        setBlobcode(null);
    };

    const confirm = () => {
        setChangeNuancesScreen(false);
        setFileInfo({ fileName: '', fileSize: '', fileType: '' });
    };

    const swapData = () => {
        if (nuancesData && nuancesDataValues) {
            setData(nuancesData);
            setValues(nuancesDataValues);
        }
    };
    const resetData = () => {
        if (!checkNuancesData) {
            refetch();
        }
    };

    const resetOriginalProtocol = () => {
        setData(protocolNuancesData);
        setValues(thresholdValues);
        setNuanceInputValue('');
        setShowInput(false);
    };

    const cancel = () => {
        if (copyNuancesData && copyNuancesValues) {
            setData(copyNuancesData);
            setValues(copyNuancesValues);
            setChangeNuancesScreen(false);
            setCopyApiNuances([]);
            setApiNuances([]);
            setFileInfo({ fileName: '', fileSize: '', fileType: '' });
            setBlobcode(null);
        }
    };

    // protocol slide left and  right arrow

    const handleLeftClick = () => {
        setMarkerIndex((prevIndex) => {
            if (prevIndex === null) {
                return metaMarkerLength - 1;
            }
            return prevIndex > 0 ? prevIndex - 1 : 0;
        });
        setSelectedItemIndex(null);
        setCheCkNuancesLeg(false);
    };

    const handleRightClick = () => {
        setMarkerIndex((prevIndex) => {
            if (prevIndex === null) {
                return 1;
            }
            return prevIndex < metaMarkerLength - 1 ? prevIndex + 1 : prevIndex;
        });
        setSelectedItemIndex(null);
        setCheCkNuancesLeg(false);
    };

    const handleKeyPress = (event: KeyboardEvent) => {
        if (!InputFocus) {
            if (event.key === 'ArrowLeft') {
                handleLeftClick();
                setCheCkNuancesLeg(false);
            } else if (event.key === 'ArrowRight') {
                handleRightClick();
                setCheCkNuancesLeg(false);
            }
        }
    };

    useEffect(() => {
        if (!InputFocus && !disableArrow) {
            window.addEventListener('keydown', handleKeyPress);
            // Cleanup the event listener
            return () => {
                window.removeEventListener('keydown', handleKeyPress);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [InputFocus, disableArrow]);

    return (
        <>
            <Box className='h-full main_sidebar_box flex justify-start flex-col'>
                {isLoading && <div className='loading_overly' />}
                <div className='file_upload_box flex items-center justify-between'>
                    <div className='upload_btn_box flex items-center gap-2 relative'>
                        <button
                            className={`${fileInfo?.fileName ? 'active_file' : ''} ${
                                protocolNuancesLoading || isFetching ? 'file_loading_btn' : ''
                            }  upload_btn  sidebar_common_btn flex items-center  justify-center `}
                        >
                            <input
                                type='file'
                                name=''
                                onChange={handleFileChange}
                                accept='.doc,.docx,.pdf,.xls,.xlsx,.xml,.csv,.txt,.json,.html,.htm,.xhtml'
                                id=''
                            />
                            {protocolNuancesLoading || isFetching ? (
                                <div className='file_loading' />
                            ) : (
                                <>
                                    {fileInfo?.fileName ? (
                                        <ActiveFileUploadIcon />
                                    ) : (
                                        <>
                                            <FileUploadIcon className='before_icon' />
                                            <FileUploadIconHover className='after_icon' />
                                        </>
                                    )}
                                </>
                            )}
                        </button>

                        {fileInfo?.fileName && (
                            <div className='file_info'>
                                <p>
                                    <FileIcon /> <span className='ml-3'> {fileInfo?.fileName} </span>
                                </p>
                                <p>
                                    {fileInfo.fileSize}
                                    <button onClick={removeFile} className='remove_file cursor-pointer ml-4'>
                                        <CloseIcon />
                                    </button>
                                </p>
                            </div>
                        )}

                        {fileError && <p className='error_msg'>{fileError}</p>}
                        <div className='tooltip_box'>
                            <p> Map your Protocol to a file {`<`} 10MB. </p>
                        </div>
                    </div>
                    <div className='flex items-center gap-4'>
                        <button
                            className={`${evaluateMode ? 'evaluate_mode' : ''} sidebar_common_btn eay_btn relative`}
                            onClick={() => {
                                setEvaluateMode(!evaluateMode);
                                setDisableArrow(false);
                            }}
                        >
                            {evaluateMode ? <OpenEayIcon /> : <EayIcon />}
                            <div className='tooltip_box '>
                                <p>Evaluate mode </p>
                            </div>
                        </button>
                        <span className='right_border' />
                        <button onClick={() => setOpenDrawer(false)} className='sidebar_common_btn sidebar_close_btn'>
                            <CloseIcon />
                        </button>
                    </div>
                </div>

                <div className='main_container'>
                    <Box className='top_input_box'>
                        <Box>
                            <input
                                autoComplete='off'
                                id='metaMarker-title'
                                name='metaMarker'
                                onMouseLeave={handelProtocolType}
                                type='text'
                                maxLength={50}
                                onFocus={() => setInputFocus(true)}
                                value={metaMarkerValue}
                                placeholder={`PROTOCOL#${
                                    (markerIndex || markerIndex === 0) && typeof markerIndex === 'number'
                                        ? markerIndex + 1
                                        : metaMarker?.length + 1
                                }`}
                                className='meta-input bg-transparent title_input text-center text-white focus:bg-none text-opacity-75 w-full pt-50 pb-15'
                                onBlur={() => {
                                    handleValidation('title', title);
                                    setInputFocus(false);
                                }}
                                onChange={handleChangeMetaMarker}
                            />
                            <hr className='border-white border-opacity-10' />
                            {metaMarkerValidation && <span className='error_msg'> please Enter Protocol </span>}
                            <Box className='flex justify-between items-center pt-0.25 pb-0.12 px-2'>
                                <div className='relative'>
                                    <div
                                        onMouseEnter={() => setIsShowInfoBox(true)}
                                        onMouseLeave={() => setIsShowInfoBox(false)}
                                    >
                                        <IoMdInformationCircleOutline
                                            color='rgba(255, 255, 255, 1)'
                                            className='cursor-pointer'
                                        />
                                    </div>

                                    {isShowInfoBox && (
                                        <div className={`p-2 absolute rounded-lg ${classes.metaMarkerTooltipBox}`}>
                                            <p className={`${classes.metaMarkerTooltipBoxText}`}>
                                                Distinct segments into which broader concepts are divided for analysis.
                                                For example, a policy may have protocols around comprehension,
                                                interpretation, exceptions and enforcement.
                                            </p>
                                        </div>
                                    )}
                                </div>

                                <p className='text-white text-opacity-75 text-normal'>
                                    {metaMarkerValue?.length > 0 ? 50 - metaMarkerValue?.length : 50}
                                </p>
                            </Box>
                        </Box>
                    </Box>

                    <div className='flex items-center m-auto select_type_box'>
                        <div ref={customSelectRef}>
                            <div className='custom_select relative'>
                                <div
                                    className={`${
                                        isOpen ? 'active' : ''
                                    } select_label flex items-center justify-between cursor-pointer`}
                                    onClick={() => setIsOpen(!isOpen)}
                                >
                                    {isLoading ? (
                                        <div className='select_loader' />
                                    ) : (
                                        <>
                                            <p
                                                className='flex items-center '
                                                style={{ backgroundImage: currentOption?.linearBg }}
                                            >
                                                <img src={currentOption?.icon} alt={currentOption?.icon} />{' '}
                                                <span style={{ backgroundImage: currentOption?.txtColor }}>
                                                    {' '}
                                                    {currentOption?.name}
                                                </span>
                                            </p>
                                            <DownArrow />
                                        </>
                                    )}
                                </div>

                                {isOpen && (
                                    <ul className={`option_list ${!isCheckSelf ? '' : 'disable'}`}>
                                        {optionList?.map((item) => {
                                            return (
                                                <li
                                                    className={`${titleName === item?.name ? 'active' : '   '}`}
                                                    onClick={() => HandelChange(item?.name)}
                                                >
                                                    <img src={item.icon} alt='' />{' '}
                                                    <span style={{ backgroundImage: item?.txtColor }}>
                                                        {item?.name}
                                                    </span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                            </div>
                        </div>

                        <div className='custom_check ml-3  relative  cursor-pointer'>
                            <div className='flex items-center gap-2' onClick={() => setIsCheckSelf(!isCheckSelf)}>
                                {isCheckSelf ? <CheckboxIcon /> : <div className='check_box'> </div>}
                                <span>self</span>
                            </div>

                            <div className='check_tooltip_box'>
                                {isCheckSelf ? (
                                    <p>
                                        {' '}
                                        <b>Disabling Self</b> will stop auto-categorising the Protocol at every changes.
                                    </p>
                                ) : (
                                    <p>
                                        {' '}
                                        <b>Enabling Self</b> will let EVA categorise the Protocol for you.
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>

                    <p className='policy_txt'> {currentOption?.description} </p>

                    <Box className='main_box'>
                        {!changeNuancesScreen && (
                            <div id='leftside'>
                                <ReactSlider
                                    className='vertical-slider'
                                    thumbClassName='thumb-container'
                                    trackClassName='example-track'
                                    defaultValue={50}
                                    ariaLabel={'Middle thumb'}
                                    orientation='vertical'
                                    invert
                                    pearling
                                    min={0}
                                    value={triggerNumber}
                                    max={100}
                                    onChange={handleChange}
                                    renderThumb={renderThumb}
                                />
                            </div>
                        )}

                        <div
                            className={`${
                                protocolNuancesLoading || isFetching
                                    ? 'updated_screen  show_loading'
                                    : changeNuancesScreen
                                    ? 'updated_screen  show_new_screen '
                                    : ''
                            }  ${evaluateMode ? 'evaluateMode' : ''} main_content `}
                        >
                            <div className='action_box flex items-center justify-between'>
                                <div className='relative nuance_info'>
                                    {protocolNuancesLoading || isFetching || changeNuancesScreen ? (
                                        <p> Update your protocol</p>
                                    ) : (
                                        <p> Set your nuances and triggers</p>
                                    )}
                                    {data.length == 0 && (
                                        <div className='tooltip_box'>
                                            <p>
                                                {' '}
                                                Start by adding a first nuance by clicking on the “+” button. A nuance
                                                represent an expected interaction to capture.
                                            </p>
                                        </div>
                                    )}

                                    {data.length >= 1 && showNuancesMsg && (
                                        <div className='tooltip_box'>
                                            <p> Add multiple nuances to balance your Protocol.</p>
                                        </div>
                                    )}

                                    {showInput && (
                                        <div className=' tooltip_box'>
                                            <p>
                                                {' '}
                                                Each nuance must represent an expected interaction to get insight and
                                                outcome from.
                                            </p>
                                        </div>
                                    )}
                                </div>

                                <div className='action_button_box flex items-center '>
                                    <div className='refresh_btn_wrapper'>
                                        {!changeNuancesScreen && (
                                            <button
                                                onClick={resetOriginalProtocol}
                                                className={`${
                                                    checkData ? 'disable_btn' : ''
                                                } border-none sidebar_common_btn refresh_btn `}
                                            >
                                                <RefreshIcon />
                                                <div className='tooltip_box'>
                                                    <p> Reset </p>
                                                </div>
                                            </button>
                                        )}
                                    </div>

                                    {protocolNuancesLoading ||
                                        isFetching ||
                                        (changeNuancesScreen && (
                                            <>
                                                <div className='refresh_btn_wrapper'>
                                                    <button
                                                        onClick={resetData}
                                                        className={`${
                                                            checkData ? 'disable_btn' : ''
                                                        } border-none sidebar_common_btn refresh_btn `}
                                                    >
                                                        <RefreshIcon />
                                                        <div className='tooltip_box'>
                                                            <p> Reset </p>
                                                        </div>
                                                    </button>
                                                </div>

                                                <button
                                                    onClick={swapData}
                                                    className=' border-none sidebar_common_btn replace_btn '
                                                >
                                                    <ReplaceIcon />
                                                    <div className='tooltip_box'>
                                                        <p> Overwrite </p>
                                                    </div>
                                                </button>
                                                <button
                                                    onClick={confirm}
                                                    className='  border-none sidebar_common_btn save_btn '
                                                >
                                                    <SaveIcon className='save_icon' />
                                                    <SaveIconHover className='hover_save' />
                                                    <div className='tooltip_box'>
                                                        <p> Confirm </p>
                                                    </div>
                                                </button>
                                                <button
                                                    onClick={cancel}
                                                    className=' border-none  sidebar_common_btn  close_icon_btn '
                                                >
                                                    <CloseIcon />
                                                    <div className='tooltip_box'>
                                                        <p> Cancel</p>
                                                    </div>
                                                </button>
                                            </>
                                        ))}
                                </div>
                            </div>

                            <>
                                <div className='screen_one'>
                                    {/* vartical slider  */}

                                    <div className='main_slider'>
                                        <p> 100</p>
                                        <div className='main_slider_box'>
                                            <div className='slider'>
                                                <div className='track'>
                                                    {values.map((value, index) => {
                                                        const updateScreen = changeNuancesScreen
                                                            ? true
                                                            : selectedItemIndex === null
                                                            ? dotHoverIndex === index
                                                            : selectedItemIndex === index
                                                            ? true
                                                            : false;

                                                        return (
                                                            <Draggable
                                                                onStop={() => {
                                                                    if (checkChangeData && clickOutSideSideBar) {
                                                                        updateMetaData();
                                                                    }
                                                                }}
                                                                axis='y'
                                                                bounds={{
                                                                    top: 0,
                                                                    bottom: sliderHeight,
                                                                }}
                                                                disabled={
                                                                    selectedItemIndex === null || isDuplicateNuance
                                                                        ? true
                                                                        : false
                                                                }
                                                                key={index}
                                                                position={{
                                                                    x: 0,
                                                                    y: sliderHeight * (1 - value / 100),
                                                                }}
                                                                onDrag={(event, data) => {
                                                                    if (isTitleEmpty) {
                                                                        noop(); // Prevent dragging if the title is empty or it's a duplicate nuance
                                                                        setCheCkNuancesLeg(true);
                                                                    } else {
                                                                        const dragValue = Math.round(
                                                                            ((sliderHeight - data.y) / sliderHeight) *
                                                                                100,
                                                                        );
                                                                        handleChangeThreshold(dragValue);
                                                                    }
                                                                }}
                                                            >
                                                                <div
                                                                    className='drag_dot'
                                                                    onClick={() => {
                                                                        if (!isDuplicateNuance) {
                                                                            handleSpanClick(index);
                                                                        }
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        setDragSelectVal(false);
                                                                        setDotHoverIndex(null);
                                                                    }}
                                                                    onMouseEnter={() => setDotHoverIndex(index)}
                                                                >
                                                                    <div className='thumb'> </div>
                                                                    {updateScreen && (
                                                                        <>
                                                                            <div className='dot_info show_num'>
                                                                                {value}
                                                                            </div>
                                                                            {data[index]?.titles && (
                                                                                <>
                                                                                    {data[index]?.titles.length > 0 && (
                                                                                        <>
                                                                                            {checkTitleArrayLength(
                                                                                                data,
                                                                                            ) &&
                                                                                                selectedItemIndex ===
                                                                                                    index && (
                                                                                                    <button
                                                                                                        className={`${
                                                                                                            isDuplicateNuance
                                                                                                                ? 'disable_btn'
                                                                                                                : ''
                                                                                                        } add_info new_add_btn`}
                                                                                                        disabled={
                                                                                                            isDuplicateNuance ||
                                                                                                            nuanceInputValue?.length ===
                                                                                                                0
                                                                                                                ? true
                                                                                                                : false
                                                                                                        }
                                                                                                        onClick={(
                                                                                                            event,
                                                                                                        ) =>
                                                                                                            addNuance(
                                                                                                                event,
                                                                                                                index,
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <PlusIcon />
                                                                                                    </button>
                                                                                                )}
                                                                                        </>
                                                                                    )}

                                                                                    <div
                                                                                        className='title_content'
                                                                                        ref={titlesRef}
                                                                                    >
                                                                                        {data[index]?.titles.map(
                                                                                            (title, titleIndex) => {
                                                                                                const item =
                                                                                                    data[index]; // Current item from `data`
                                                                                                const copyItem =
                                                                                                    protocolNuancesData.find(
                                                                                                        (copy) =>
                                                                                                            copy.value ===
                                                                                                            item.value,
                                                                                                    ); // Find the matching item by value in `protocolNuancesData`

                                                                                                const copyTitleExists =
                                                                                                    copyItem?.titles?.includes(
                                                                                                        title,
                                                                                                    ); // Check if the title exists in the corresponding `copyItem`

                                                                                                // Determine the class to apply based on conditions
                                                                                                const isNotMatch =
                                                                                                    !copyItem ||
                                                                                                    !title ||
                                                                                                    !copyTitleExists;

                                                                                                return (
                                                                                                    <div
                                                                                                        key={titleIndex}
                                                                                                        onClick={(
                                                                                                            event,
                                                                                                        ) =>
                                                                                                            selectTitle(
                                                                                                                title,
                                                                                                                titleIndex,
                                                                                                                index,
                                                                                                                event,
                                                                                                            )
                                                                                                        }
                                                                                                        className={`titles ${
                                                                                                            isNotMatch
                                                                                                                ? 'not-match'
                                                                                                                : ''
                                                                                                        }`} // Add 'not-match' class if title is empty or doesn't match
                                                                                                    >
                                                                                                        <span
                                                                                                            onClick={() => {
                                                                                                                setSelectedItemIndex(
                                                                                                                    index,
                                                                                                                );
                                                                                                            }}
                                                                                                            className={`${
                                                                                                                editTitlePosition ===
                                                                                                                titleIndex
                                                                                                                    ? 'selected-title'
                                                                                                                    : ''
                                                                                                            } `}
                                                                                                        >
                                                                                                            <label>
                                                                                                                {title}
                                                                                                            </label>
                                                                                                        </span>
                                                                                                        <button
                                                                                                            className='remove_title'
                                                                                                            onClick={(
                                                                                                                event,
                                                                                                            ) => {
                                                                                                                handleRemoveNuance(
                                                                                                                    index,
                                                                                                                    titleIndex,
                                                                                                                    event,
                                                                                                                );
                                                                                                                updateMetaData();
                                                                                                            }}
                                                                                                        >
                                                                                                            <CloseIconNuance />
                                                                                                        </button>
                                                                                                    </div>
                                                                                                );
                                                                                            },
                                                                                        )}
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </Draggable>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <p className={`${changeNuancesScreen ? 'bottom_num' : ''}`}> 0</p>
                                        {changeNuancesScreen && (
                                            <h6>{`Latest date Of Last SavedEdit ${formatDateToDDMMYYYY(
                                                updateDate,
                                            )}`}</h6>
                                        )}
                                    </div>

                                    {changeNuancesScreen && (
                                        <NuancesMapping
                                            setNuancesData={setNuancesData}
                                            setNuancesDataValues={setNuancesDataValues}
                                            nuancesDataValues={nuancesDataValues}
                                            setApiNuances={setApiNuances}
                                            apiNuances={apiNuances}
                                            fileInfo={fileInfo}
                                            errorMsg={errorMsg}
                                            // protocol_nuances[0]
                                        />
                                    )}

                                    <>
                                        {/* add button and input  */}
                                        <div className={`${showInput && 'show_input'} main_add_detail`}>
                                            {!showInput ? (
                                                checkTitleArrayLength(data) && (
                                                    <button
                                                        className='add_info plus_btn'
                                                        onClick={(event) => {
                                                            setNuanceInputValue('');
                                                            handleAddThumb(event);
                                                        }}
                                                    >
                                                        <PlusIcon />
                                                    </button>
                                                )
                                            ) : (
                                                <Box className={`${showInput ? 'show_bottom_box' : ''} bottom_box`}>
                                                    <>
                                                        <div className='input_box' onMouseLeave={handelProtocolType}>
                                                            <div>
                                                                <InputWithLength
                                                                    className='w-full'
                                                                    required
                                                                    maxLength={60}
                                                                    onFocus={() => setInputFocus(true)}
                                                                    value={nuanceInputValue}
                                                                    onChange={nuanceHandleChange}
                                                                    onBlur={() => setInputFocus(false)}
                                                                />

                                                                {isDuplicateNuance && (
                                                                    <span className='error_msg mt-0.5'>
                                                                        Nuance already exists
                                                                    </span>
                                                                )}

                                                                {nuancesValidate ? (
                                                                    <>
                                                                        {nuancesValidate && (
                                                                            <span className='error_msg'>
                                                                                please add minimum two nuances{' '}
                                                                            </span>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {checkNuancesLeg && (
                                                                            <span className='error_msg'>
                                                                                Nuances Can Not Be empty
                                                                            </span>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>

                                                            <Button
                                                                onClick={() => {
                                                                    checkNuances();
                                                                    updateMetaData();
                                                                    setCurrentProtocolNuances(protocolNuances);
                                                                }}
                                                                className={`${
                                                                    isDuplicateNuance ? 'disable_btn' : ' '
                                                                } add_btn`}
                                                            >
                                                                <IconRight />
                                                            </Button>
                                                        </div>

                                                        <div className='eva-threshold'>
                                                            <p>
                                                                {' '}
                                                                <FlagIcon /> Threshold
                                                            </p>
                                                            <span
                                                                className={`${
                                                                    typeof currentThreshold === 'number' &&
                                                                    currentThreshold > 99
                                                                        ? 'count_limit'
                                                                        : ''
                                                                } eva-threshold-count`}
                                                            >
                                                                <button
                                                                    onClick={() => {
                                                                        if (isTitleEmpty) {
                                                                            setCheCkNuancesLeg(true);
                                                                        }
                                                                        if (!isTitleEmpty) {
                                                                            handleChangeThreshold(
                                                                                currentThreshold !== null
                                                                                    ? currentThreshold - 1
                                                                                    : 0,
                                                                            );
                                                                        }
                                                                    }}
                                                                    className={`${
                                                                        isDuplicateNuance ? 'disable_btn' : ''
                                                                    }`}
                                                                >
                                                                    -
                                                                </button>

                                                                <input
                                                                    onFocus={() => setInputFocus(true)}
                                                                    onBlur={() => setInputFocus(false)}
                                                                    className={`${
                                                                        isDuplicateNuance ? 'disable_btn' : ''
                                                                    }`}
                                                                    type='number'
                                                                    value={
                                                                        currentThreshold === null
                                                                            ? ''
                                                                            : currentThreshold.toString()
                                                                    }
                                                                    onChange={(e) => {
                                                                        if (isTitleEmpty) {
                                                                            setCheCkNuancesLeg(true);
                                                                        }
                                                                        if (!isTitleEmpty) {
                                                                            handleChangeThreshold(
                                                                                Number(e.target.value),
                                                                            );
                                                                        }
                                                                    }}
                                                                    onMouseLeave={() => setCheckChangeData(true)}
                                                                />
                                                                <button
                                                                    onClick={() => {
                                                                        if (isTitleEmpty) {
                                                                            setCheCkNuancesLeg(true);
                                                                        }

                                                                        if (!isTitleEmpty) {
                                                                            handleChangeThreshold(
                                                                                currentThreshold !== null
                                                                                    ? currentThreshold + 1
                                                                                    : 1,
                                                                            );
                                                                        }
                                                                    }}
                                                                    className={`${
                                                                        isDuplicateNuance ? 'disable_btn' : ''
                                                                    }`}
                                                                >
                                                                    +
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </>
                                                </Box>
                                            )}
                                        </div>
                                        {/* slider button */}
                                        <button
                                            className={`${
                                                markerIndex === 0 ? 'arrow_disable' : ''
                                            } left_side slider_btn flex items-center justify-center`}
                                            disabled={markerIndex === 0 ? true : false}
                                            onClick={handleLeftClick}
                                        >
                                            {' '}
                                            <LeftArrow />{' '}
                                        </button>
                                        <button
                                            className={`${
                                                markerIndex === metaMarkerLength - 1 || markerIndex === null
                                                    ? 'arrow_disable'
                                                    : ''
                                            } right_side slider_btn  flex items-center justify-center`}
                                            disabled={
                                                markerIndex === metaMarkerLength - 1 || markerIndex === null
                                                    ? true
                                                    : false
                                            }
                                            onClick={handleRightClick}
                                        >
                                            {' '}
                                            <RightArrow />{' '}
                                        </button>
                                    </>
                                </div>

                                <div className='loading_screen'>
                                    <div className='skeleton1'>
                                        <Skeleton />
                                    </div>
                                    <div className='skeleton2'>
                                        <Skeleton />
                                    </div>
                                    <div className='skeleton3'>
                                        <Skeleton />
                                    </div>
                                </div>
                            </>
                        </div>

                        <Box
                            className='flex justify-end gap-4'
                            style={{
                                marginTop: 15,
                                marginBottom: 15,
                            }}
                        >
                            <div className='bottom_submit_btn'>
                                <Button
                                    className='add_btn'
                                    onClick={() => deleteEvaMetaMarker(markerIndex, EvaType.draft)}
                                >
                                    <IconDelete />
                                </Button>
                            </div>

                            {allTitlesLengthTwo && data?.length !== 0 && (
                                <div className={` ${isDuplicateNuance ? 'disable_btn' : ''} bottom_submit_btn`}>
                                    {btnLoading ? (
                                        <button className='loading_btn' type='button' disabled>
                                            <span className='animate-spin '> </span>
                                        </button>
                                    ) : (
                                        <Button
                                            className={`${clickOutSideSideBar ? '' : 'disable_btn '} add_btn`}
                                            onClick={(e) => (clickOutSideSideBar ? handleSubmitData(e) : noop())}
                                        >
                                            <IconRight />
                                        </Button>
                                    )}
                                </div>
                            )}
                        </Box>
                    </Box>
                </div>
            </Box>
        </>
    );
};

export default Sidebar;
