import { ReactNode } from 'react';
import { MouseEventHandler } from 'react';

import clsx from 'clsx';

import { ReactComponent as SortIcon } from '../../assets/icons/icon-sort-table.svg';
import { Checkbox } from '../basic/Checkbox.component';
import { TablePagination } from './TablePagination.component';

export function Table<ROW>(props: {
    title?: ReactNode;
    rows: ROW[];
    columns: ColumnConfig<ROW>[];
    page: number;
    setPage: (page: number) => void;
    pageCount: number;
    totalItems: number;
    onSelectionChange: (selection: ROW[]) => void;
    selection?: ROW[];
    selectable?: boolean;
    getKey: (row: ROW) => number | string;
    onClickRow?: (row: ROW) => void;
    onClickColumnData?: (row: ROW) => void;
    onClickCheckbox?: (row: ROW, e: Parameters<MouseEventHandler<HTMLInputElement>>[0]) => void;
    maxRowsPerPage?: number;
    handleSort?: (id: string) => void;
}) {
    const {
        title,
        rows,
        columns,
        pageCount,
        page,
        setPage,
        totalItems,
        onSelectionChange,
        selection = [],
        selectable = true,
        getKey,
        onClickRow,
        onClickCheckbox,
        onClickColumnData,
        maxRowsPerPage = 10,
        handleSort,
    } = {
        ...props,
    };
    const selectionKeyList = selection.map((row) => getKey(row));

    return (
        <div className='w-full overflow-visible px-2'>
            {title && (
                <div className='flex items-center justify-between mb-10'>
                    <h1 className='text-sm text-darker text-opacity-75 text-center w-full'>{title}</h1>
                </div>
            )}
            <table className='min-w-full table-auto px-5'>
                <thead>
                    <tr className='text-darker text-opacity-75 font-medium whitespace-nowrap'>
                        {selectable && (
                            <th className='pl-4 w-7 pb-2'>
                                <Checkbox
                                    className='w-4 h-4'
                                    onClick={() => {
                                        if (selection.length < rows.length) {
                                            onSelectionChange(rows);
                                        } else {
                                            onSelectionChange([]);
                                        }
                                    }}
                                    checked={selection.length === rows.length}
                                />
                            </th>
                        )}
                        {columns.map((column: ColumnConfig<ROW>, index) => {
                            const headerCell = column.hide ? (
                                ''
                            ) : typeof column.header === 'string' ? (
                                column.header
                            ) : (
                                <column.header />
                            );
                            const sortable = column.sortable;
                            const hide = column.hide;
                            return (
                                <th
                                    key={index}
                                    className={`p-2 text-sm font-medium text-left ${
                                        index === columns.length - 1 ? 'flex items-center justify-end' : ''
                                    }`}
                                >
                                    <button
                                        className='flex flex-row items-center pb-2 font-medium text-darker text-opacity-50'
                                        onClick={() => sortable && handleSort && handleSort(column.id)}
                                    >
                                        {headerCell}
                                        {sortable && <SortIcon className='ml-2' />}
                                    </button>
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, index) => {
                        const selected = selectionKeyList.includes(getKey(row));
                        return (
                            <tr
                                key={getKey(row)}
                                className={clsx(
                                    'rounded-xl overflow-visible hover:shadow-message-container',
                                    'group cursor-pointer h-12',
                                )}
                                onClick={() => onClickRow?.(row)}
                            >
                                {selectable && (
                                    <td className='pl-4 w-7'>
                                        <Checkbox
                                            onClick={(e) => {
                                                onSelectionChange(
                                                    selected
                                                        ? selection.filter((value) => getKey(row) !== getKey(value))
                                                        : [...selection, row],
                                                );
                                                onClickCheckbox?.(row, e);
                                            }}
                                            checked={selected}
                                        />
                                    </td>
                                )}
                                {columns.map((column, index) => {
                                    const CellComponent = column.renderCell;
                                    const clickable = column.clickable !== undefined ? column.clickable : true;
                                    return (
                                        <td
                                            key={index}
                                            className={`px-2 overflow-visible
                                        ${index === 0 ? 'rounded-l-xl' : ''} 
                                        ${
                                            index === columns.length - 1
                                                ? 'rounded-r-xl h-10 flex items-center justify-end'
                                                : ''
                                        }`}
                                            onClick={() => clickable && onClickColumnData?.(row)}
                                        >
                                            <CellComponent row={row} />
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {rows.length === 0 && (
                <div className='flex items-center justify-center p-20 text-base text-darker text-opacity-75'>
                    The table is empty
                </div>
            )}
            {pageCount > 1 && (
                <div className='w-full flex flex-row mt-8 text-sm'>
                    <div className='text-sm whitespace-nowrap text-darker text-opacity-75'>
                        Showing {Math.min((page - 1) * maxRowsPerPage + 1, totalItems)} to{' '}
                        {Math.min(page * maxRowsPerPage, totalItems)} of {totalItems}
                    </div>
                    <div className='w-full flex justify-center'>
                        <TablePagination page={page} pageCount={pageCount} setPage={setPage} />
                    </div>
                    <div />
                </div>
            )}
        </div>
    );
}
export type ColumnConfig<ROW> = {
    id: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    header: string | React.ComponentType<{}>;
    renderCell: React.ComponentType<{ row: ROW }>;
    sortable?: boolean;
    hide?: boolean;
    clickable?: boolean;
};

export type Sort = [string, 'ASC' | 'DESC'] | null;
