import { useState } from 'react';

import { useServices } from '../components/ServiceProvider';
import { AickoPricings } from '../store/SubscriptionsStore';
import { mockable } from '../utils/mockable';
import { useAsyncFetcher } from '../utils/useAsyncFetcher';

export const useSubscription = mockable(() => {
    const [error, setError] = useState<string | null>(null);
    const services = useServices();
    const { payments, copilot, session } = services;

    const { data: pricings } = useAsyncFetcher<AickoPricings>(async () => payments.getAickoSubscriptionPricings(), []);

    const downgradeSubscriptionFeatures = async () => {
        await copilot.downgradePrompts();
        await session.downgradeTrackedSession();
    };

    return { setError, error, pricings, downgradeSubscriptionFeatures };
});
