import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import DeleteAccountDialog from '../general/Layout/DeleteAccountDialog';
import AccountForm from './AccountForm';
import Copilot from './Copilot';

const Nav = () => {
    const [activeLink, setActiveLink] = useState('account');
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

    const location = useLocation();

    const handleLinkClick = (link: string) => {
        setActiveLink(link);
    };

    useEffect(() => {
        if (location.state === 'copilot') setActiveLink('dashboard');
        else if (location.state === 'account') setActiveLink('account');
    }, [location]);

    return (
        <div className='h-full'>
            <div className='w-full flex justify-center items-center md:mt-10 md:mb-6'>
                <div className='w-full flex justify-center items-center'>
                    <div className='text-sm text-darker text-opacity-75 font-medium align-middle '>
                        <ul className='flex flex-wrap mb-2 gap-6 '>
                            <li className='mr-1'>
                                <a
                                    onClick={() => handleLinkClick('account')}
                                    className={`pb-2 border-transparent cursor-pointer ${
                                        activeLink === 'account' ? 'border-b-2 border-berry-blue' : 'border-0'
                                    }`}
                                >
                                    Account
                                </a>
                            </li>
                            <li className='mr-1'>
                                <a
                                    // href='#'
                                    // onClick={() => handleLinkClick('dashboard')}
                                    className={`pb-2 border-transparent cursor-not-allowed opacity-50 ${
                                        activeLink === 'dashboard' ? 'border-b-2 border-berry-blue' : 'border-0'
                                    }`}
                                >
                                    Phantom Settings
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className='w-full h-screen flex-grow'>
                {activeLink === 'account' && <AccountForm />}
                {activeLink === 'dashboard' && <Copilot />}
            </div>
            <div className='w-full mb-4 md:mb-0 mt-10 md:mt-20'>
                <hr className='opacity-10 bg-gray-100' />
                <div className='px-4'>
                    <button onClick={() => setOpenDeleteDialog(true)} className='mt-4 text-sm font-semibold text-error'>
                        DELETE ACCOUNT
                    </button>
                    <p className='mt-2 text-sm font-Poppins text-darker text-opacity-75'>
                        When you press the button, your account will be removed permanently and all your saved data will
                        be permanently deleted.
                    </p>
                </div>
            </div>
            <DeleteAccountDialog open={openDeleteDialog} handleClose={() => setOpenDeleteDialog(false)} />
        </div>
    );
};

export default Nav;
