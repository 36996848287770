import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { PaymentMethod } from '@stripe/stripe-js';
import { useParams } from 'react-router-dom';

import { usePaymentMethods } from '../../../hooks/usePaymentMethods';
import { Button } from '../../basic/Button.component';
import { Checkbox } from '../../basic/Checkbox.component';
import { IconAlert } from '../../basic/IconAlert.component';
import { Input } from '../../basic/Input.component';

const CreditCardDetails = ({
    currentCreditCard,
    isCardTypeChecked,
}: {
    currentCreditCard?: PaymentMethod;
    isAppointment?: boolean;
    amount?: number;
    isCardTypeChecked?: boolean;
}) => {
    const {
        fields,
        error,
        setError,
        createPaymentMethod,
        defaultMethod,
        setDefaultMethod,
        updatePaymentMethod,
        loading,
        createOrganisationPaymentMethod,
        updateOrganisationPaymentMethod,
        handleStripeElementChange,
        validStripeElements,
    } = usePaymentMethods();

    const { id: organisationId } = useParams<{ id: string }>();
    const { cardId } = useParams<{ cardId: string }>();

    const handleSubmit = async () => {
        if (!isCardTypeChecked) {
            setError('Please select a card type');
            return;
        }
        if (organisationId) return handleOrganisationSubmit();
        else {
            if (currentCreditCard) updatePaymentMethod(currentCreditCard.id);
            else await createPaymentMethod();
        }
    };

    const handleOrganisationSubmit = async () => {
        if (cardId) await updateOrganisationPaymentMethod(cardId, organisationId);
        else await createOrganisationPaymentMethod(organisationId);
    };

    return (
        <div className='text-sm text-darker text-opacity-75 font-medium'>
            <Input
                label='Name on Card'
                placeholder={currentCreditCard ? (currentCreditCard.billing_details.name as string) : 'Name on Card'}
                type='text'
                {...fields.name}
                error={error && fields.name.error ? true : false}
            />
            <div className='grid lg:grid-cols-5 gap-x-10 mt-6'>
                <div className='lg:col-span-3'>
                    <p className='mb-2'>Credit Card Number</p>
                    <div className=''>
                        <CardNumberElement
                            options={{
                                classes: {
                                    base: 'StripeInput--base',
                                },
                                style: {
                                    base: {
                                        '::placeholder': {
                                            color: '#0F104480',
                                            fontSize: '12px',
                                            fontFamily: 'Poppins, sans-serif',
                                        },
                                        fontSize: '12px',
                                        fontFamily: 'Poppins, sans-serif',
                                        color: '#0F1044BF',
                                    },
                                },
                                placeholder: currentCreditCard
                                    ? `**** **** **** ${currentCreditCard.card?.last4}`
                                    : 'Credit Card Number',
                            }}
                            onChange={(e) => handleStripeElementChange(e, 'card')}
                        />
                    </div>
                </div>
                <div>
                    <p className='mb-2'>Expiration</p>
                    <div className=''>
                        <CardExpiryElement
                            options={{
                                classes: {
                                    base: 'StripeInput--base',
                                },
                                style: {
                                    base: {
                                        '::placeholder': {
                                            color: '#0F104480',
                                            fontSize: '12px',
                                            fontFamily: 'Poppins, sans-serif',
                                        },
                                        fontSize: '12px',
                                        fontFamily: 'Poppins, sans-serif',
                                        color: '#0F1044BF',
                                    },
                                },
                                placeholder: currentCreditCard
                                    ? `${currentCreditCard.card?.exp_month}/${currentCreditCard.card?.exp_year
                                          .toString()
                                          .slice(-2)}`
                                    : 'MM/YY',
                            }}
                            onChange={(e) => handleStripeElementChange(e, 'expiration')}
                        />
                    </div>
                </div>
                <div>
                    <p className='mb-2'>CVV</p>
                    <div className=''>
                        <CardCvcElement
                            options={{
                                classes: {
                                    base: 'StripeInput--base',
                                },
                                style: {
                                    base: {
                                        '::placeholder': {
                                            color: '#0F104480',
                                            fontSize: '12px',
                                            fontFamily: 'Poppins, sans-serif',
                                        },
                                        fontSize: '12px',
                                        fontFamily: 'Poppins, sans-serif',
                                        color: '#0F1044BF',
                                    },
                                },
                                placeholder: 'CVV',
                            }}
                            onChange={(e) => handleStripeElementChange(e, 'cvv')}
                        />
                    </div>
                </div>
            </div>
            <div className='flex items-center mt-4 space-x-2'>
                <Checkbox checked={defaultMethod} onClick={() => setDefaultMethod(!defaultMethod)} />
                <p className='text-normal'>Default Card</p>
            </div>
            <p className='my-4 text-xs text-darker text-opacity-50'>
                Your subscription will automatically renew at the end of your current subscription period. You will be
                charged every month until you cancel in your account settings. If you cancel your subscription, previous
                charges will not be refunded, but you may continue to use TRACEFLOW until the end of the term you paid
                for. <br />
                <br />
                By selecting "Saved", you agree to the terms and conditions of the TraceFlow{' '}
                <a className='underline' href='https://blankstate.ai/terms-conditions/' target='_blank'>
                    Terms of Use
                </a>
                ,{' '}
                <a className='underline' href='https://blankstate.ai/privacy-policy/' target='_blank'>
                    Privacy Policy
                </a>{' '}
                and any Cancelation or No-Show Policy.
            </p>
            {error && <IconAlert type='error' message={error} className='mb-4' />}
            <div className='flex flex-row space-x-12 items-center justify-center w-full'>
                <Button
                    disabled={loading || !validStripeElements}
                    onClick={handleSubmit}
                    variant='bberryBlue'
                    size='oval'
                    className={'w-full'}
                >
                    Confirm
                </Button>
            </div>
        </div>
    );
};

export default CreditCardDetails;
