import { useCallback, useEffect, useRef, useState } from 'react';

import { Button, withStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import evaGraphFlag from '../../../../../../../src/assets/icons/EvaGraphFlag.svg';
import evaGraphIcon from '../../../../../../../src/assets/icons/EvaGraphIcon.svg';
import { appInsights } from '../../../../../../AppInsights';
import { useOrganisationForm } from '../../../../../../hooks/useOrganisation';
import { EvaService } from '../../../../../../services/eva/eva.service';
import { CustomLoader } from '../../../../../basic/CustomeLoader/CustomLoader';
import { GraphPoints } from '../../types';
import { WebsiteCategories } from './WebsiteCategories';

const StyledButton = withStyles({
    root: {
        textTransform: 'none',
    },
})(Button);
interface EvaGraphLogProps {
    evaId: string | undefined;
    handleOpen: () => void;
    publishedEvaExist: boolean;
    title: string;
    description: string;
    isCreateEvaModalOpen: boolean;
    labelShow: boolean;
    testMode: boolean;
    setLabelShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EvaGraphLog: React.FC<EvaGraphLogProps> = ({
    evaId,
    handleOpen,
    publishedEvaExist,
    title,
    labelShow,
    setLabelShow,
    description,
    isCreateEvaModalOpen,
    testMode,
}) => {
    const [page, setPage] = useState(1);
    const evaService = new EvaService();
    const containerRef = useRef<HTMLDivElement>(null);
    const [data, setData] = useState<GraphPoints[]>([]);
    const [logMsg, setLogMsg] = useState<string>('');
    const [scrollHeight, setScrollHeight] = useState(0);
    const [OrbitHover, setOrbitHover] = useState(false);
    const [scrollText, setScrollText] = useState(false);

    setTimeout(() => {
        setScrollText(true);
    }, 700);

    const { getUserPermission } = useOrganisationForm();

    const { id: organisationId } = useParams<{ id: string }>();

    const {
        data: evaData,
        isLoading,
        isFetching,
    } = useQuery(
        ['evaData', page],
        () => {
            if (evaId) {
                return evaService.getEvaCtrlLog(evaId, page);
            }
            return undefined;
        },
        {
            enabled: Boolean(!!evaId && containerRef),
        },
    );
    useEffect(() => {
        const logRecord = document.querySelector('.record') as HTMLElement | null;
        if (evaData) {
            if (page === 1) {
                setData(evaData.log);
                setLogMsg(evaData.msg);
            } else {
                const newData: GraphPoints[] = evaData.log;
                const mergedArray = [...data, ...newData];
                if (logRecord) {
                    const calculation = (page - 1) * evaData.perPages * logRecord.offsetHeight;
                    setScrollHeight(calculation);
                }
                setData(mergedArray);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, evaData]);

    useEffect(() => {
        const graphLog = document.querySelector('.graph-log') as HTMLElement | null;
        if (page !== 1) {
            if (graphLog) {
                graphLog.scrollTo({
                    top: -scrollHeight,
                    behavior: 'smooth',
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const scrollFunction = useCallback(
        (event: { currentTarget: { scrollHeight: number; clientHeight: number; scrollTop: number } }) => {
            const { scrollHeight, clientHeight, scrollTop } = event.currentTarget;
            let prevScrollPosition = 0;
            const scrollPosition = (Math.abs(scrollTop) + clientHeight) / scrollHeight;

            setPage((pre) => {
                if (evaData?.totalPage > pre) {
                    if (scrollPosition > prevScrollPosition) {
                        if (Math.abs(scrollTop) + clientHeight > scrollHeight - 10) {
                            return pre + 1;
                        }
                    }
                }
                return pre;
            });
            prevScrollPosition = scrollPosition;
        },
        [evaData?.totalPage],
    );
    const bgColor = false;
    const evaIDundefined = evaId === undefined || evaId === '';

    const { data: percentageChange, isLoading: isPercentageChangeLoading } = useQuery(
        ['getEvaUserEngagement', organisationId, evaId, isCreateEvaModalOpen],
        () => {
            if (evaId) {
                return evaService.getEvaUserEngagement(evaId);
            }
        },
        {
            enabled: !!organisationId && !!evaId && !isCreateEvaModalOpen,
        },
    );

    if (isLoading || isPercentageChangeLoading) {
        return (
            // <div className='outset_loader '>
            //     {/* <AnimatedCircle isThinking={true} /> */}
            // </div>
            <CustomLoader />
        );
    }

    return (
        <>
            <div className='graph-log-row absolute'>
                <div className=' content_box border_hover'>
                    {publishedEvaExist && (
                        <div className='top_box'>
                            <WebsiteCategories evaId={evaId} percentageChange={percentageChange} />

                            <div className='is_testingBox'>
                                {testMode ? (
                                    <p className={`${scrollText ? 'scroll_text' : ''}`}>TESTING</p>
                                ) : (
                                    <p className={`${scrollText ? 'scroll_text' : ''} live`}>Live</p>
                                )}
                            </div>
                        </div>
                    )}

                    <div className='white_overlay '>
                        <span className='corner corner1'> </span>
                        <span className='corner corner2'> </span>
                        <span className=' corner corner3'> </span>
                        <span className=' corner corner4'> </span>

                        {(isLoading || isFetching) && <CustomLoader />}
                        {evaIDundefined || (data && isEmpty(data)) ? (
                            <span className='block no_content'> {logMsg}</span>
                        ) : (
                            <div className='graph-log' ref={containerRef} onScroll={scrollFunction}>
                                {data &&
                                    data.length > 0 &&
                                    data.map((e: GraphPoints, index: number) => {
                                        const date = e.date.split(' : ');
                                        return (
                                            <div
                                                key={index}
                                                className={`${
                                                    bgColor ? 'heighLight' : ''
                                                } bg-transparent flex justify-between w-100 text-center items-center my-auto py-1 record`}
                                            >
                                                <div className='flex space-x-5 text-center'>
                                                    <div className='flex space-x-3 text-center m-auto list'>
                                                        {bgColor ? (
                                                            <img src={evaGraphIcon} alt='' />
                                                        ) : (
                                                            <img src={evaGraphFlag} alt='' />
                                                        )}

                                                        <p className='text-small gray-text-log text-right my-auto text-nowrap'>
                                                            <div> {date[0]}</div>
                                                            <div className='w-1 h-1 bg-sky-blue rounded-full text-center my-auto ' />
                                                            <div> {date[1]}</div>
                                                        </p>
                                                        <p className='text-small name gray-text-log date text-left m-auto mt-0 text-nowrap'>
                                                            <span
                                                                className={`${ e.name.length > 35 ? 'scroll_name' : ''}`}
                                                            >
                                                                { e.name}{' '}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='flex items-center ml-5 log_dots '>
                                                    <p className='text-small text-sky-blue  gray-text-log font-medium text-center text-nowrap'>
                                                        {e.score}
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
