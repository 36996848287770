import useMediaQuery from '../../../hooks/useMediaQuery';
import { SIZES } from '../../../utils/constants';
import WebLayout, { LayoutProps } from './Layout';
import MobileLayout from './MobileLayout';

const Layout = (props: LayoutProps) => {
    const isMobile = useMediaQuery(SIZES.MOBILE_WIDTH);
    if (isMobile) return <MobileLayout {...props} />;
    return <WebLayout {...props} />;
};
export default Layout;
