import React from 'react';

import clsx from 'clsx';

type RadioButtonProps = {
    checked?: boolean;
    onClick?: () => void;
    label?: string;
    className?: string;
    disabled?: boolean;
};

const RadioButton: React.FC<RadioButtonProps> = ({ checked, disabled, onClick, label, className }) => {
    return (
        <div
            className={clsx(
                'select-none',
                label && 'flex flex-row items-center',
                disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                className,
            )}
            onClick={onClick}
        >
            <div
                className={clsx(
                    'h-4 w-4 rounded-full flex items-center justify-center',
                    checked && 'border border-blue',
                )}
            >
                <div
                    className={clsx(
                        'w-2 h-2 rounded-full',
                        checked ? 'bg-blue' : 'border border-blue-dark border-opacity-40',
                    )}
                />
            </div>
            {label && <p className='text-sm text-darker text-opacity-75 ml-2'>{label}</p>}
        </div>
    );
};

export default RadioButton;
