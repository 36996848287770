import React, { Fragment, useState } from 'react';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import clsx from 'clsx';
import { usePopper } from 'react-popper';

import { ReactComponent as ArrowDownIcon } from '../../assets/icons/icon-arrow-down.svg';

export type SelectProps<T> = {
    entries: Array<T>;
    label?: string;
    className?: string;
    error?: boolean;
    value?: T;
    onChange?: (value: T) => void;
    disabled?: boolean;
    disabledBackground?: boolean;
    bottom?: boolean;
    getName?: (value: T) => string;
    placeholder?: string;
    multiple?: boolean;
    containerClass?: string;
    renderItem?: (value: T, selected: boolean, active: boolean) => React.ReactElement;
    checkIcon?: boolean;
    icons?: { icon: JSX.Element; value: string }[];
    height?: string;
    marginTop?: number;
    rounded?: string;
    selectedShortFilters?: {
        sortBy: string;
        order: string;
    },
    handleFilterChange?: () => void;
};

export function Select<T>({
    entries,
    label,
    className,
    error,
    value,
    onChange,
    disabled = false,
    disabledBackground = false,
    bottom = false,
    getName = (value: T) => value as unknown as string,
    placeholder,
    containerClass,
    renderItem,
    checkIcon,
    icons,
    height,
    marginTop,
    rounded,
    selectedShortFilters,
    handleFilterChange
}: SelectProps<T>) {
    const [selected, setSelected] = useState(value);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [referenceElement, setReferenceElement] = useState<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [popperElement, setPopperElement] = useState<any>();
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'auto-start',
    });

    return (
        <div className={clsx(className)}>
            <Listbox
                value={selected}
                onChange={(e) => {
                    if (selectedShortFilters && selectedShortFilters.sortBy) {
                        if (selectedShortFilters.sortBy === e as unknown as string) {
                            if (handleFilterChange) {
                                handleFilterChange();
                            }
                        }
                    }
                    if (onChange) {
                        setSelected(e);
                        onChange(e as T);
                    }
                }}
                disabled={disabled || disabledBackground}
            >
                <div className='flex flex-col relative text-sm text-darker text-opacity-75'>
                    {label && <Listbox.Label className='font-medium mb-2'>{label}</Listbox.Label>}

                    {!bottom && (
                        <Listbox.Button
                            className={clsx(
                                height ? height : 'h-10',
                                rounded ? rounded : 'rounded-full',
                                'relative w-full pl-4 pr-10 text-left bg-white',
                                'border border-blue-dark border-opacity-10 hover:border-opacity-20',
                                'cursor-pointer focus:outline-none focus:border-neutral-500',
                                'disabled:bg-secondary-100 disabled:text-neutral-400 ',
                                'disabledBackground:text-neutral-400 ',
                                disabled && 'cursor-not-allowed',
                                disabledBackground && 'bg-white disable_border_color',
                                error && 'ring-error-900 ring-2',
                                containerClass,
                            )}
                            ref={setReferenceElement}
                        >
                            <div className='flex items-center h-4'>
                                {icons && selected && (
                                    <div className='mr-3 h-3 w-6'>
                                        <div
                                            className='absolute left-4 top-2 z-50 h-6 w-6 bg-blue rounded-full
                                             flex items-center justify-center'
                                        >
                                            {icons.find((icon) => icon.value === getName(selected as T))?.icon}
                                        </div>
                                    </div>
                                )}
                                <span
                                    className={`block truncate text-darker h-4 ${selected ? 'opacity-100' : 'opacity-50'
                                        }`}
                                >
                                    {selected ? getName(selected as T) : placeholder || 'Select a value'}
                                </span>
                            </div>
                            <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                                {selectedShortFilters ?
                                    <KeyboardArrowDownOutlinedIcon sx={{ color: '#0F1044BF', fontSize: '1rem' }} />
                                    :
                                    <ArrowDropDownIcon sx={{ color: '#0F1044BF' }} />
                                }
                            </span>
                        </Listbox.Button>
                    )}
                    <Transition
                        as={Fragment}
                        leave='transition ease-in duration-150'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <Listbox.Options
                            static
                            className='w-full overflow-auto text-base bg-white border rounded-md shadow-lg max-h-60
                                border-neutral-500 focus:outline-none sm:text-sm'
                            ref={setPopperElement}
                            style={{ ...styles.popper, zIndex: 1000000, marginTop: marginTop }}
                            {...attributes.popper}
                        >
                            {entries &&
                                entries.length > 0 &&
                                entries.map((entry, nameIdx) => (
                                    <Listbox.Option
                                        key={nameIdx}
                                        className={({ active }) =>
                                            `${active && 'bg-neutral-100'}
                                         cursor-default select-none relative py-3 px-4 `
                                        }
                                        value={entry}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                {(renderItem && renderItem(entry, selected, active)) || (
                                                    <>
                                                        <div className='flex items-center h-4'>
                                                            {icons && (
                                                                <div className='mr-3 h-3 w-6'>
                                                                    <div
                                                                        className='absolute left-4 top-2 z-50 h-6 w-6 bg-blue
                                                                    rounded-full flex items-center justify-center'
                                                                    >
                                                                        {
                                                                            icons.find(
                                                                                (icon) => icon.value === getName(entry),
                                                                            )?.icon
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {selectedShortFilters && (
                                                                selected &&
                                                                    selectedShortFilters?.order === 'desc' ? <div className=' transform rotate-180' ><ArrowDownIcon className='ml-3' /> </div> : <ArrowDownIcon className='mr-3' />
                                                            )}

                                                            <span
                                                                className={`${selected ? 'font-bold' : 'font-normal'} 
                                                        block truncate ${checkIcon && 'ml-5'}`}
                                                            >
                                                                {entry ? getName(entry) : ''}
                                                            </span>
                                                        </div>

                                                        {selected && checkIcon ? (
                                                            <span
                                                                className={`
                                                            ${active && 'bg-neutral-100'} 
                                                            absolute inset-y-0 left-0 flex items-center pl-3
                                                        `}
                                                            >
                                                                <CheckIcon
                                                                    className='w-5 h-5 text-primary'
                                                                    aria-hidden='true'
                                                                />
                                                            </span>
                                                        ) : null}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                        </Listbox.Options>
                    </Transition>
                    {bottom && (
                        <Listbox.Button
                            className={clsx(
                                'relative w-full py-4 pl-4 pr-10 text-left bg-white rounded-lg border border-neutral-300',
                                'cursor-default focus:outline-none',
                                'focus:border focus:border-neutral-500',
                                'disabled:bg-secondary-100 disabled:text-neutral-400',
                                error && 'ring-error-900 ring-2',
                            )}
                        >
                            <span className='block truncate'>{selected}</span>
                            <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                                <SelectorIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
                            </span>
                        </Listbox.Button>
                    )}
                </div>
            </Listbox>
        </div>
    );
}
