import { FunctionComponent } from 'react';

import { observer } from 'mobx-react-lite';

import usePasswordToggle from '../../hooks/usePasswordToggle';
import { useSettings } from '../../hooks/useSettings';
import { Button } from '../basic/Button.component';
import { IconAlert } from '../basic/IconAlert.component';
import { Input } from '../basic/Input.component';

interface UpdatePasswordProps {
    className?: string;
    isClient?: boolean;
    hide?: (value:boolean) => void;
}

const UpdatePassword : FunctionComponent<UpdatePasswordProps> = ({ className = '', isClient = false, hide }) => {
    const {
        error,
        updatingPassword,
        fields,
        resetPassword,
        setError
    } = useSettings();
    const currentPasswordToggle = usePasswordToggle();
    const newPasswordToggle = usePasswordToggle();
    const confirmPasswordToggle = usePasswordToggle();
    
    
    return (
        <div className={className}>
            <div className='relative md:px-0 px-4'>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                    <Input
                        label='Current password'
                        placeholder='Current password'
                        className='w-full'
                        {...fields.currentPassword}
                        type={currentPasswordToggle.InputType}
                        error={fields.currentPassword.error || error === 'Incorrect current password.'}
                        required
                        onClick={() => setError('')}
                        right={<img
                            className='absolute bottom-3 right-4 w-4 h-4 cursor-pointer opacity-50'
                            src={currentPasswordToggle.Icon}
                            onClick={currentPasswordToggle.handlePasswordVisibility}
                            alt=''
                        />}
                    />
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-6 mt-6'>
                    <Input
                        label='New password'
                        placeholder='New password'
                        className='w-full'
                        {...fields.newPassword}
                        type={newPasswordToggle.InputType}
                        isPassword={true}
                        required
                        error={fields.newPassword.error}
                        onClick={() => setError('')}
                        right={<img
                            className='absolute bottom-3 right-4 w-4 h-4 cursor-pointer opacity-50'
                            src={newPasswordToggle.Icon}
                            onClick={newPasswordToggle.handlePasswordVisibility}
                            alt=''
                        />}
                    />
                    <Input
                        label='Confirm password'
                        placeholder='Confirm password'
                        className='w-full'
                        type={confirmPasswordToggle.InputType}
                        isPassword={true}
                        required
                        {...fields.confirmPassword}
                        error={fields.confirmPassword.error}
                        right={<img
                            className='absolute bottom-3 right-4 w-4 h-4 cursor-pointer opacity-50'
                            src={confirmPasswordToggle.Icon}
                            onClick={confirmPasswordToggle.handlePasswordVisibility}
                            alt=''
                        />}
                    />
                </div>
                <div className='mt-4 space-y-2'>
                    {(fields.currentPassword.errorMessage || fields.newPassword.errorMessage || fields.confirmPassword.errorMessage || error !== '') && (
                        <div >
                            <IconAlert message={
                                fields.currentPassword.errorMessage ||
                                fields.newPassword.errorMessage ||
                                fields.confirmPassword.errorMessage || error} 
                            />
                        </div>
                    )}
                    <div className='flex items-center justify-end space-x-6'>
                        {isClient && (
                            <Button variant='outlinedCancel' size='oval'>
                                Cancel
                            </Button>
                        )}
                        <Button
                            disabled={updatingPassword||fields?.confirmPassword?.value==''||fields?.newPassword?.value==''||fields?.currentPassword?.value==''||fields?.newPassword?.error||fields?.confirmPassword?.error}
                            onClick={async () => {
                                const resppnse = await resetPassword()
                                if(resppnse && hide) hide(false)
                            }}
                            className={isClient ? '' : 'w-full mt-4 py-5 h-10'}
                            type='submit'
                            variant='bberryBlue'
                            size='oval'
                        >
                            Update
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default observer(UpdatePassword);
