import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { EvaGraphAnalyticsEvaList } from '../../eva/types';
import '../Trends/Trends.css';

const ActiveEvaSelect = ({
    evaId,
    handleEvaChange,
    titleList,
    isShield,
}: {
    evaId: string;
    handleEvaChange: (value: string) => void;
    titleList: EvaGraphAnalyticsEvaList[] | undefined;
    isShield: boolean;
}) => {
    return (
        <div className='title-box'>
            <FormControl className='select_drop_down'>
                <Select value={evaId} onChange={(e) => handleEvaChange(e.target.value as string)}>
                    {titleList?.map((item) => {
                        return (
                            <MenuItem
                                className={`${item.isArchived && !item.isPublished && isShield ? 'disable' : ''}`}
                                value={item?.id}
                            >
                                E.V.A {item?.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </div>
    );
};

export default ActiveEvaSelect;
