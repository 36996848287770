export const Loader = () => {
    return (
        <div className='lds-ring'>
            <div />
            <div />
            <div />
            <div />
        </div>
    );
};
