import _ from 'lodash';
import { makeAutoObservable } from 'mobx';

export type SubscriptionType = 'MONTHLY' | 'WEEKLY' | 'FREE' | 'FORTNIGHTLY';

export type AickoCurrentSubscription = {
    organisationName: string;
    price: number;
    type: string;
    productName: string;
    startedAt: number;
    endAt: number;
    status: string;
    cancelsAt: number;
    currentPeriod: {
        startedAt: number;
        endAt: number;
    };
};

export type AickoPricing = {
    id: string;
    type: string;
    productName: string;
    price: string;
};

export type AickoPricings = {
    aickoEarlyFreePricing: AickoPricing;
    aickoGrowthMonthlyPricing: AickoPricing;
    aickoGrowthYearlyPricing: AickoPricing;
    shieldMonthlyPricing: AickoPricing;
    shieldYearlyPricing: AickoPricing;
    oversightMonthlyPricing: AickoPricing;
    oversightYearlyPricing: AickoPricing;
};

export class SubscriptionStore {
    aickoCurrentSubscription: AickoCurrentSubscription | null = null;
    aickoPricings: AickoPricings | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    setAickoCurrentSubscription = (currentSubscription: AickoCurrentSubscription | null) => {
        this.aickoCurrentSubscription = currentSubscription;
    };

    setAickoSubscriptionPricings = (subscriptionPricings: AickoPricings) => {
        this.aickoPricings = _.isEmpty(subscriptionPricings) ? null : subscriptionPricings;
    };
}
