import { useEffect, useState } from 'react';

import { TextField, Autocomplete, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';

import { scenarios, Scenario } from './ScenarioList';

const theme = createTheme({
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    backgroundColor: '#60F4C81A',
                    borderRadius: '10px',
                    border: '1px solid rgba(96, 244, 200, 0.5)',
                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                        fontSize: '12px',
                        fontFamily: 'Poppins',
                        height: 0,
                    },
                    '.MuiSvgIcon-root ': {
                        fill: '#60F4C8BF !important',
                    },
                },
                popper: {
                    width: 'auto',
                    zIndex: 1300,
                    '& .MuiAutocomplete-listbox': {
                        marginTop: '5px',
                        borderRadius: '7px',
                        boxShadow: '0px 6px 16px 0px rgba(15, 16, 68, 0.16)',
                        backgroundColor: 'rgba(35, 70, 96, 1)', // Background color of Popper
                        maxHeight: 200,
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    },
                    '& .MuiAutocomplete-option': {
                        fontFamily: 'Poppins',
                        fontSize: '12.5px !important',
                        lineHeight: '16px',
                        color: 'rgba(96, 244, 200, 0.75) !important',
                    },
                },
                noOptions: {
                    color: '#60F4C8BF',
                    fontSize: '12px !important',
                    fontFamily: 'Poppins',
                    backgroundColor: 'rgba(35, 70, 96, 1)',
                    marginTop: '5px',
                    borderRadius: '7px',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-input': {
                        padding: '10px 14px', // Adjust padding to fit height
                        color: '#60F4C8BF', // Text color
                    },
                    '& .MuiInputBase-input::placeholder': {
                        color: '#60F4C8BF', // Placeholder text color
                    },
                },
            },
        },
    },
});

const SelectScenarios = ({
    selectedScenario,
    setSelectedScenario,
    error,
    setError,
    setUser1Name,
    setUser2Name,
}: {
    selectedScenario: Scenario | undefined;
    setSelectedScenario: (value: Scenario) => void;
    error: boolean;
    setError: (key: string, value: boolean) => void;
    setUser1Name: (value: string | null) => void;
    setUser2Name: (value: string | null) => void;
}) => {
    const [inputValue, setInputValue] = useState<Scenario | null>(null);

    useEffect(() => {
        // Ensure inputValue matches an option
        if (selectedScenario && scenarios.find((option) => option.id === selectedScenario.id)) {
            setInputValue(selectedScenario);
        } else {
            setInputValue(null); // Or set to a default value
        }
    }, [selectedScenario]);

    return (
        <div className='w-full flex space-x-6 items-center'>
            <p className='w-14 text-sm font-medium'>Scenarios</p>
            <div className='w-full evaluate_select'>
                <ThemeProvider theme={theme}>
                    <Autocomplete
                        fullWidth
                        autoHighlight
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderStyle: error ? '' : 'none',
                                borderRadius: '10px',
                            },
                        }}
                        value={inputValue}
                        options={scenarios}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => {
                            return option?.id === value?.id;
                        }}
                        onChange={(_, value) => {
                            if (!value) return;
                            setSelectedScenario(value);
                            setUser1Name(value.user1);
                            setUser2Name(value.user2);
                            setInputValue(value);
                            setError('scenarioError', false);
                        }}
                        renderOption={(props, option, { selected }) => <li {...props}>{option.name}</li>}
                        renderInput={(params) => (
                            <TextField {...params} placeholder='-Select-' size='medium' error={error} />
                        )}
                    />
                </ThemeProvider>
                {error && (
                    <Typography
                        color='error'
                        variant='body2'
                        sx={{
                            fontFamily: 'Poppins',
                            fontSize: 12,
                        }}
                    >
                        Please select a scenario
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default observer(SelectScenarios);
