import { appInsights } from "../../AppInsights";
import { post } from "../../utils/network";

export class CopilotService{
    async downgradePrompts() {
        try{
            return await post(`copilot/downgrade-prompts`);
        }catch(e){
            if (e instanceof Error) {
                appInsights.trackException({ exception: e });
            }
        }
    }
}