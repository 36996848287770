import { useState } from 'react';

import { noop } from 'lodash';
import { Link, useParams } from 'react-router-dom';

import { ReactComponent as ArchiveIcon } from '../../../../../../src/assets/icons/archive_btn.svg';
import { ReactComponent as CloseIcon } from '../../../../../../src/assets/icons/close_btn.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../src/assets/icons/delete_btn.svg';
import { ReactComponent as RestartIcon } from '../../../../../../src/assets/icons/restart_btn.svg';
import { ReactComponent as Check } from '../../../../../assets/icons/check.svg';
import { ReactComponent as CloseEye } from '../../../../../assets/icons/icon-purple-eye-closed.svg';
import { ReactComponent as CloseEyeWhite } from '../../../../../assets/icons/icon-white-eye-closed.svg';
import { useSubscriptionDetail } from '../../../../../contexts/subscription.context';
import { Button } from '../../../../basic/Button.component';
import { StarIcon } from '../../../../basic/StarIcon.component';
import { EvaType, MetaMarkerData, SubScriptionType } from '../types';

interface CreateEvaModelProps {
    currentSlide: number;
    isArchived: boolean;
    isTestMode: boolean;
    setIsTestMode: React.Dispatch<React.SetStateAction<boolean>>;
    evaId: string | undefined;
    publishedAt: string | null;
    archive: boolean;
    isUserAdmin: boolean | undefined;
    handleArchiveEva: () => void;
    handleUnarchiveEva: () => void;
    setIsOpenRestartModal: React.Dispatch<React.SetStateAction<boolean>>;
    setIsOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    setIsOpenPublishModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleSubmit: (saveType: EvaType, data?: MetaMarkerData) => Promise<void>;
    setEvaPublished: React.Dispatch<React.SetStateAction<boolean>>;
    isPublishEnable: boolean;
    isEvaPublished: boolean;
    handleClose: () => void;
    isPublished: boolean;
    setLabelShow: React.Dispatch<React.SetStateAction<boolean>>;
    labelShow: boolean;
    setEditUserResponse: React.Dispatch<React.SetStateAction<boolean>>;
}

const EvaHeader: React.FC<CreateEvaModelProps> = ({
    currentSlide,
    isArchived,
    isTestMode,
    setIsTestMode,
    evaId,
    publishedAt,
    archive,
    isUserAdmin,
    handleArchiveEva,
    handleUnarchiveEva,
    setIsOpenRestartModal,
    setIsOpenDeleteModal,
    setIsOpenPublishModal,
    handleSubmit,
    setEvaPublished,
    isPublishEnable,
    isEvaPublished,
    handleClose,
    setLabelShow,
    labelShow,
    isPublished,
    setEditUserResponse,
}) => {
    const handleTestModeClick = (isTestModeValue: boolean) => {
        setIsTestMode(isTestModeValue);
        setEditUserResponse(false);
    };

    const { id: organisationId } = useParams<{ id: string }>();

    const subscription = useSubscriptionDetail();
    const [OrbitHover, setOrbitHover] = useState<boolean>(false);

    const shieldSubscription = subscription.productName === SubScriptionType.SHIELD;

    return (
        <div className='buttons-eva eva_header flex   '>
            <div
                className={
                    currentSlide === 0
                        ? 'relative flex w-full h-fit align-center'
                        : 'flex w-full align-center right_side_button '
                }
            >
                <>
                    {!publishedAt && !archive && (
                        <Button
                            size='oval'
                            className='flex space-x-2 relative items-center restart_btn head_btn'
                            variant='evaRestart'
                            disabled={!isUserAdmin}
                            onClick={() => setIsOpenRestartModal(true)}
                        >
                            <RestartIcon />
                            <div className='tooltip_box'>
                                <p> Restart </p>
                            </div>
                        </Button>
                    )}
                </>
                {/* )} */}

                {!archive ? (
                    <Button
                        size='oval'
                        className='flex space-x-2 relative items-center head_btn isarchive_btn '
                        variant={shieldSubscription ? 'outlinedArchiveNoHover' : 'outlinedArchive'}
                        onClick={shieldSubscription ? noop : handleArchiveEva}
                        disabled={!isUserAdmin}
                    >
                        {shieldSubscription ? (
                            <Link to={`/organisation/${organisationId}/change-tier`}>
                                <StarIcon isChangePlusIcon={true} isHover={true} />
                            </Link>
                        ) : (
                            <ArchiveIcon />
                        )}
                        <div className='tooltip_box'>
                            <p>Archive </p>
                        </div>
                    </Button>
                ) : (
                    <Button
                        className='flex space-x-2 relative isarchive_disable_btn  head_btn items-center'
                        variant='archived'
                        size='oval'
                    >
                        <ArchiveIcon />
                        <div className='tooltip_box'>
                            <p> Unarchive</p>
                        </div>
                    </Button>
                )}

                {currentSlide === 1 && !isArchived && (
                    <div className='left_side_button'>
                        {isTestMode ? (
                            <Button
                                size='oval'
                                className={`eva-test-btn-active relative eva-test-btn `}
                                onClick={() => handleTestModeClick(false)}
                            >
                                <div className='flex items-center space-x-2'>
                                    <div className='eva-test-eye-icon-active'>
                                        <CloseEyeWhite width={15} height={15} fill='rgba(15, 16, 68, 0.75)' />
                                    </div>
                                    <p>Evaluate</p>
                                </div>
                                <div className='tooltip_box'>
                                    <p>Evaluate Mode</p>
                                </div>
                            </Button>
                        ) : (
                            <Button className='eva-test-btn relative' onClick={() => handleTestModeClick(true)}>
                                <div className='flex items-center space-x-2'>
                                    <p>Evaluate</p>
                                    <div className='eva-test-eye-icon'>
                                        <CloseEye width={15} height={15} fill='rgba(15, 16, 68, 0.75)' />
                                    </div>
                                </div>
                                <div className='tooltip_box'>
                                    <p>Evaluate Mode</p>
                                </div>
                            </Button>
                        )}
                    </div>
                )}

                <div className='right_side_button '>
                    {evaId && (
                        <>
                            <Button
                                className='delete_btn relative cursor-pointer head_btn '
                                disabled={!isUserAdmin}
                                onClick={() => setIsOpenDeleteModal(true)}
                            >
                                <DeleteIcon />
                                <div className='tooltip_box'>
                                    <p> Delete</p>
                                </div>
                            </Button>
                        </>
                    )}

                    {isEvaPublished ? (
                        <Button
                            className='flex space-x-2 ml-4 relative items-center publish_btn publish_btn_disable'
                            size='oval'
                        >
                            <Check fill='#ffff' fillOpacity={1} />
                            <p className='text-sm text-white'>Published</p>
                            <div className='tooltip_box'>
                                <p>
                                    Visibility: <span>Live</span>{' '}
                                </p>
                            </div>
                        </Button>
                    ) : (
                        <Button
                            className='publish_btn relative'
                            variant={isPublishEnable && isUserAdmin ? 'evaPublish' : 'fillLightBlueDisabled'}
                            size='oval'
                            onClick={() => {
                                if (archive) {
                                    if (isPublished) {
                                        setIsOpenPublishModal(true);
                                    } else handleUnarchiveEva();
                                } else {
                                    handleSubmit(EvaType.publish);
                                    setEvaPublished(true);
                                }
                            }}
                            disabled={!isPublishEnable || !isUserAdmin}
                        >
                            Publish
                            <div className='tooltip_box'>
                                <p>
                                    Visibility: <span>Live</span>{' '}
                                </p>
                            </div>
                        </Button>
                    )}
                    <Button
                        variant='outlinedCancel'
                        size='small'
                        className='bg-transparent close_btn m-0 eva_close_btn rounded-none h-8'
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EvaHeader;
