import { Slider, ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';

const muiTheme = createTheme({
    overrides: {
        MuiSlider: {
            thumb: {
                color: '#373BBA',
                border: '2px solid white',
                width: '11px',
                height: '11px',
                top: '15px',
            },
            track: {
                color: '#373BBA',
                height: '3px',
            },
            rail: {
                color: '#FFF',
                opacity: 0.1,
                height: '4px',
                backgroundColor: '#0f1044',
            },
            mark: {
                color: '#0f1044',
                opacity: 0.1,
                width: '10px',
                border: '2px solid white',
                height: '10px',
                boxShadow: '0px 3px 6px rgba(15, 16, 68)',
                top: '10px',
                borderRadius: '50%',
                backgroundColor: 'currentColor',
            },
            markActive: {
                color: '#373BBA',
                opacity: 0,
            },
            root: {
                padding: '13px 0px !important',
            },
        },
    },
});

const RangeSlider = ({ onChange }: { onChange?: (val: number | number[]) => void }) => {
    return (
        <ThemeProvider theme={muiTheme}>
            <Slider defaultValue={40} step={20} marks min={0} max={100} />
        </ThemeProvider>
    );
};

export default RangeSlider;
