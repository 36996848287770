import { useState } from 'react';

import { BsFillPenFill } from 'react-icons/bs';
import { CiTwitter } from 'react-icons/ci';
import { GiWaveSurfer } from 'react-icons/gi';

import PrespectiveIcon from '../../assets/images/bulb.svg';
import EthesIcon from '../../assets/images/palra.svg';
import StarIcon from '../../assets/images/time.svg';
import SelectComponent from '../../utils/selectComponent';
import { Button } from '../basic/Button.component';
import RangeSlider from '../basic/RangeSlider';

const Copilot = () => {
    type SwitchesState = {
        [key: string]: boolean;
    };
    //@ts-ignore
    const [switchesState, setSwitchesState] = useState<SwitchesState>(false);

    const toggleSwitch = (name: string) => {
        setSwitchesState((prevState) => ({
            ...prevState,
            [name]: !prevState[name],
        }));
    };

    const switchesData = [
        { text: 'North Star', icon: StarIcon },
        { text: 'Ethes', icon: EthesIcon },
        { text: 'Perspective', icon: PrespectiveIcon },
    ];

    return (
        <>
            <div className='w-full h-full flex justify-center items-start'>
                <div className='w-[55%] h-fit p-1'>
                    <div className='w-full flex justify-center items-center'>
                        <div className='h-full lg:w-1/2 md:w-full'>
                            <p className='text-darker opacity-75 text-sm'>👋 How do you want to call me?</p>
                            <div className='w-full border mt-2 text-center border-blue-dark border-opacity-10'>
                                <Button variant='transparent' className='text-darker font-bold text-sm' size='large'>
                                    Bberry
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className='lg:w-full md:w-full h-20 my-6'>
                        <SelectComponent />
                    </div>
                    <hr className='w-full h-0.5 mt-6 border-t border-blue-dark border-opacity-10' />
                    {/* <div className='w-full border-b border-blue-dark border-opacity-10 py-6'>
                        <p className='text-darker opacity-75 text-sm mb-4'>What Should I display?</p>
                        <div className='w-4/5 md:w-full flex items-center justify-between text-sm'>
                            {switchesData &&
                                switchesData.map((switchData) => (
                                    <Switch
                                        key={switchData.text}
                                        checked={switchesState[switchData.text]}
                                        icon={switchData.icon}
                                        text={switchData.text}
                                        onChange={() => toggleSwitch(switchData.text)}
                                    />
                                ))}
                        </div>
                    </div> */}
                    <div className='w-full h-[max-content] py-6 border-b border-blue-dark border-opacity-10'>
                        <div className='w-full  flex justify-start items-center text-sm'>
                            <p className='text-darker opacity-75 text-sm'>🔃 I will catch you up on the last</p>
                            <select className='ml-4 w-40 -[90%] rounded-[50px] border border-blue-dark border-opacity-10 bg-white outline-none py-3 px-4 text-darker opacity-75 text-sm'>
                                <option value=''>30 Days</option>
                            </select>
                        </div>
                    </div>
                    <div className='w-full h-8 mt-6'>
                        <div className='w-full h-full flex items-center justify-start'>
                            <BsFillPenFill className='w-3 h-3 mr-2' />
                            <p className='text-sm text-darker opacity-75'>How sensitive...</p>
                        </div>
                    </div>
                    <div className='w-full flex flex-col mt-4'>
                        <div className='w-full flex justify-between gap-6'>
                            <div className='w-6/12'>
                                <div className='flex items-center'>
                                    <CiTwitter className='w-3 h-3 mr-2' />
                                    <p className='text-sm text-darker opacity-75'>Peacekeeper</p>
                                </div>
                                {/* @ts-ignore */}
                                <RangeSlider label='Utility' />
                                <div className='bg-secondary-300 p-2 rounded-2xl'>
                                    <p className='font-medium text-darker opacity-75 text-xs pb-2'>Severe</p>
                                    <p className='font-normal text-darker opacity-75 text-xs'>
                                        I will only flags overtly unethical statements like threats or harassment.
                                    </p>
                                </div>
                            </div>
                            <div className='w-6/12'>
                                <div className='flex items-center'>
                                    <GiWaveSurfer className='w-3 h-3 mr-2' />
                                    <p className='text-sm text-darker opacity-75'>Flow</p>
                                </div>
                                {/* @ts-ignore */}
                                <RangeSlider label='Flow' />
                                <div className='bg-secondary-300 p-2 rounded-2xl'>
                                    <p className='font-medium text-darker opacity-75 text-xs pb-2'>Surface</p>
                                    <p className='font-normal text-darker opacity-75 text-xs'>
                                        I will only notice obvious tone and dynamic issues like aggression.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='w-full h-[fit-content] flex justify-between items-center mt-6'>
                            <Button variant='bberryBlue' size='round' className='w-full py-4'>
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Copilot;
