export const MAX_PAGE_BUTTONS_COUNT = 7;

export const getDisplayedPageButtons = (currentPage: number, pageCount: number): (string | number)[] => {
    const pageButtons: (string | number)[] = [];
    const max = MAX_PAGE_BUTTONS_COUNT;
    if (pageCount < max) {
        for (let i = 0; i < pageCount; i++) {
            pageButtons.push(i + 1);
        }
        return pageButtons;
    }
    if (Math.floor((currentPage + 1) / 2) < Math.floor(max / 2)) {
        for (let i = 0; i < max; i++) {
            if (i === max - 2) {
                pageButtons.push('...');
            } else {
                if (i === max - 1) {
                    pageButtons.push(pageCount);
                } else {
                    pageButtons.push(i + 1);
                }
            }
        }
    } else {
        if (Math.floor((pageCount - currentPage + 1) / 2) < Math.floor(max / 2)) {
            for (let i = pageCount - max; i < pageCount; i++) {
                if (i === pageCount - max) {
                    pageButtons.push('1');
                } else {
                    if (i === pageCount - max + 1) {
                        pageButtons.push('...');
                    } else {
                        pageButtons.push(i + 1);
                    }
                }
            }
        } else {
            for (let i = 0; i < max; i++) {
                switch (i) {
                    case 0:
                        pageButtons.push(1);
                        break;
                    case 1:
                        pageButtons.push('...');
                        break;
                    case max - 2:
                        pageButtons.push('...');
                        break;
                    case max - 1:
                        pageButtons.push(pageCount);
                        break;
                    default:
                        pageButtons.push(currentPage - 1 + (i - Math.floor(max / 2 - 1)));
                }
            }
        }
    }

    return pageButtons;
};
