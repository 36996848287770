import React from 'react';

import clsx from 'clsx';

import DefaultProfilePicture from '../../assets/profilePictures/img-profile.svg';

type ProfilePictureProps = {
    size?: string;
    path?: string;
    title?: string;
};
export const ProfilePicture: React.FC<JSX.IntrinsicElements['img'] & ProfilePictureProps> = ({
    size = '40px',
    path,
    title,
}) => {
    const profilePicture = path;
    return (
        <div className='relative profile_img'>
            <div
                className={clsx('bg-center bg-no-repeat bg-cover rounded-full border border-white')}
                style={{
                    backgroundImage: profilePicture ? `url(${profilePicture})` : `url(${DefaultProfilePicture})`,
                    width: size,
                    height: size,
                }}
                data-title={title}
            />
        </div>
    );
};
