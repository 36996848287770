import { useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { appInsights } from '../../../../../AppInsights';
import { ReactComponent as ArrowRight } from '../../../../../assets/icons/icon-arrow-right.svg';
import { DomainCategory } from '../../../../../store/OrganisationStore';
import HorizontalSeparator from '../../../../basic/HorizontalSeparator';
import { useServices } from '../../../../ServiceProvider';
import ProgressBar from './ProgressBar.component';
import SortIcon from './SortIcon.component';

const WebsiteCategoryTable = () => {
    const [domainCategory, setDomainCategory] = useState<DomainCategory[]>([]);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>();
    const [isAscending, setIsAscending] = useState(false);
    const { organisation } = useServices();
    const { id } = useParams<{ id: string }>();

    const { isLoading } = useQuery<DomainCategory[] | null>(['domain-category', id], async () => {
        try {
            const data = await organisation.getAllDomainCategory(id);
            if (data) {
                setDomainCategory(data);
            }
            return data;
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
            }
        }
    });

    const sortDomainCategoryByPercentage = () => {
        if (!domainCategory) return;
        const sortedCategories = [...domainCategory].sort((a, b) => {
            if (isAscending) {
                setSortOrder('asc');
                return a.percentage - b.percentage;
            } else {
                setSortOrder('desc');
                return b.percentage - a.percentage;
            }
        });
        setDomainCategory(sortedCategories);
        setIsAscending(!isAscending); // Toggle sorting order
    };

    return (
        <div className='w-full bg-white  domain_box scrollbar  rounded-xl p-4 border border-blue-dark border-opacity-10 text-darker text-opacity-75'>
            <div>
                <h1 className='top_view'>Top viewed websites by categories</h1>
            </div>
            <div>
                <div className='flex justify-between mt-5 text-xs font-medium'>
                    <p>Categories</p>
                    <div className='flex w-20 items-center cursor-pointer' onClick={sortDomainCategoryByPercentage}>
                        <p>Ratio</p>
                        <div className='ml-2'>
                            <SortIcon
                                fill='#0F1044'
                                fillTopOpacity={sortOrder === 'asc' ? 1 : 0.5}
                                fillBottomOpacity={sortOrder === 'desc' ? 1 : 0.5}
                            />
                        </div>
                    </div>
                </div>
                <HorizontalSeparator className='bg-primary-darker bg-opacity-25 mt-2' />
            </div>
            {isLoading ? (
                <div className='w-full flex justify-center mt-2'>
                    <CircularProgress variant='indeterminate' />
                </div>
            ) : (
                domainCategory &&
                domainCategory.map((data) => (
                    <div key={data.id}>
                        <div className='flex text-xs mt-2 justify-between'>
                            <div className='flex space-x-4 items-center w-40'>
                                <ArrowRight className='w-2.5 h-2.5' fill='#C2C4C8' />
                                <p className='font-medium'>{data.category}</p>
                            </div>
                            {data.count > 0 && (
                                <div className='w-1/4'>
                                    <ProgressBar value={data.percentage} color={data.colour} />
                                </div>
                            )}
                            <div className='w-20'>{data.percentage}%</div>
                        </div>
                        <HorizontalSeparator className='bg-primary-darker bg-opacity-10 mt-2' />
                    </div>
                ))
            )}
        </div>
    );
};

export default WebsiteCategoryTable;
