import { useState } from 'react';

import frenchPng from '../../src/assets/images/french.jpg';
import spanishPng from '../../src/assets/images/spanish.png';
import usdPng from '../../src/assets/images/usa.png';

const languageOptions = [
    { value: 'English', label: 'English', image: usdPng },
    { value: 'Spanish', label: 'Spanish', image: spanishPng },
    { value: 'French', label: 'French', image: frenchPng },
];

const SelectComponent = () => {
    const [selectedLanguage, setSelectedLanguage] = useState('');
    //@ts-ignore

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
    };

    const selectedLanguageFlag = selectedLanguage
        ? //@ts-ignore
          languageOptions.find((option) => option.value === selectedLanguage).image
        : null;

    return (
        <>
            <div className='flex items-center'>
                <p className='text-darker font-normal text-sm opacity-75'>
                    I will understand {selectedLanguageFlag && ''}
                </p>
                {selectedLanguageFlag && (
                    <img src={selectedLanguageFlag} alt={selectedLanguage} className='w-6 h-6 ml-2' />
                )}
            </div>
            <div className='relative inline-flex w-1/2'>
                <div className='absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none'>
                    {selectedLanguage && (
                        <img
                            //@ts-ignore
                            src={selectedLanguageFlag}
                            alt={selectedLanguage}
                            className='w-6 h-6 mt-2'
                        />
                    )}
                </div>
                <div className='w-full h-[max-content]'>
                    <select
                        value={selectedLanguage}
                        onChange={handleLanguageChange}
                        className='lg:w-full md:w-full h-10 bg-secondary-100 text-sm text-darker text-opacity-75 rounded-full px-10 py-2 mt-2 border border-blue-dark border-opacity-10 appearance-none focus:outline-none'
                    >
                        <option value=''>Select a language</option>
                        {languageOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </>
    );
};

export default SelectComponent;
