import { useHistory, useParams } from 'react-router-dom';

import { Button } from '../components/basic/Button.component';

const NotFound = () => {
    const history = useHistory();
    const { status } = useParams<{ status: string }>();
    const errorCode = Number(status);
    if (errorCode && errorCode >= 500) {
        return (
            <div className='w-full h-full text-darker font-normal'>
                <div className='w-full h-full flex flex-col justify-center items-center space-y-8'>
                    <p className='text-2xl'>⚙️ we are working on it...</p>
                    <p>We should be up again in a few. Please try again at a later stage.</p>
                </div>
            </div>
        );
    }
    return (
        <div className='absolute top-14 left-0 right-0 bottom-0 z-50 min-h-screen bg-cover bg-login-background'>
            <div className='font-normal flex flex-col justify-center items-center w-full h-full'>
                <p className='w-full not-found'>{errorCode ? errorCode : 404}</p>
                <div className='flex flex-col text-white text-opacity-75 space-y-6 items-center'>
                    <p className='text-2xl'>Page Not Found</p>
                    <p className='text-lg'>Unfortunately we can’t find the page you are looking for. 😔</p>
                    <Button
                        size='oval'
                        variant='outlinedWhite'
                        className='px-4 py-2'
                        onClick={() => history.push('/home')}
                    >
                        Back Home
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
