import { useEffect, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { appInsights } from '../../../../../AppInsights';
import { ReactComponent as EditIcon } from '../../../../../assets/icons/icon-edit.svg';
import { useOrganisationForm } from '../../../../../hooks/useOrganisation';
import { ComplianceMonitoring } from '../../../../../store/OrganisationStore';
import HorizontalSeparator from '../../../../basic/HorizontalSeparator';
import { useServices } from '../../../../ServiceProvider';
import { Websites } from '../types';
import InputNewCompliance from './InputNewCompliance.component';

const ComplianceMontioring = () => {
    const { id } = useParams<{ id: string }>();
    const [showInput, setShowInput] = useState(false);
    const [originalWebsites, setOriginalWebsites] = useState<Array<Websites>>([{ id: '', url: '' }]);
    const [websites, setWebsites] = useState<Array<Websites>>([{ id: '', url: '' }]);
    const [websitesToRemove, setWebsitesToRemove] = useState<string[]>([]);
    const [error, setError] = useState<string | null>(null);
    const { updateBlocklistDomain } = useOrganisationForm();
    const { organisation } = useServices();
    const [isDuplicateUrls, setIsDuplicateUrls] = useState(false);

    const {
        data: complianceData,
        isLoading,
        isFetching,
        refetch,
    } = useQuery<ComplianceMonitoring[] | null>(['compliance-monitoring', id], async () => {
        try {
            return await organisation.getComplianceMonitoring(id);
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
            }
        }
    });

    const hasDuplicateUrls = (websites: Websites[]): boolean => {
        const urlSet = new Set<string>();
        for (const website of websites) {
            if (urlSet.has(website.url)) {
                return true;
            }
            urlSet.add(website.url);
        }
        return false;
    };
    useEffect(() => {
        if (websites) {
            const result = hasDuplicateUrls(websites);
            setIsDuplicateUrls(result);
        }
    }, [websites]);

    const handleSave = async () => {
        setShowInput(false);
        if (websites?.length === 1 && websites[0].url === '') return;
        try {
            await updateBlocklistDomain(websites, id);
            setOriginalWebsites(websites);
            refetch();
        } catch (e) {
            console.log('error', e);
        }
        setWebsitesToRemove([]);
    };

    useEffect(() => {
        if (complianceData && complianceData.length > 0) {
            const transformedData = complianceData.map((item) => ({
                id: item.id,
                url: item.url,
            }));
            setWebsites(transformedData);
            setOriginalWebsites(transformedData);
        }
    }, [complianceData]);

    const handleRemoveWebsite = (index: number) => {
        const domainToRemove = websites[index];

        // Add to removal list if it is in the original websites
        if (originalWebsites.includes(domainToRemove)) {
            setWebsitesToRemove((prev) => [...prev, domainToRemove.url]);
        }

        setWebsites((prev) => prev.filter((_, i) => i !== index));
    };

    return (
        <div className='w-full bg-white p-4  monitoring_box scrollbar   rounded-xl border border-opacity-10 border-blue-dark text-darker text-opacity-75'>
            <div className='flex justify-between text-sm font-medium items-center'>
                <h1 className='novi_left_heading'>Compliance Monitoring</h1>
                {!showInput ? (
                    <button
                        className='rounded-full p-2 flex items-center'
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        }}
                        onClick={() => setShowInput(true)}
                    >
                        <EditIcon className='w-2 h-2' fill='rgba(15, 16, 68, 1)' />
                    </button>
                ) : (
                    <button
                        className='rounded-full px-2 py-1 flex items-center disabled:cursor-not-allowed disabled:opacity-50'
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        }}
                        onClick={handleSave}
                        disabled={error !== null || isLoading || isFetching || isDuplicateUrls}
                    >
                        <p className='text-xs'>Save</p>
                    </button>
                )}
            </div>
            {showInput ? (
                <InputNewCompliance
                    websites={websites}
                    setWebsites={setWebsites}
                    removeWebsite={handleRemoveWebsite}
                    error={error}
                    setError={setError}
                    isDuplicateUrls={isDuplicateUrls}
                />
            ) : (
                <div>
                    <div>
                        <div className='grid grid-cols-4 gap-4 mt-5 text-xs font-medium'>
                            <p className='col-span-2'>Websites</p>
                            <p className='flex justify-end col-span-2'>Visits</p>
                        </div>
                        <HorizontalSeparator className='bg-primary-darker bg-opacity-25 mt-2' />
                    </div>
                    {isLoading ? (
                        <div className='w-full flex justify-center mt-2'>
                            <CircularProgress variant='indeterminate' />
                        </div>
                    ) : (
                        <div className='w-full overflow-y-auto scrollbar max-h-96'>
                            {complianceData &&
                                complianceData.map((data) => (
                                    <div className='w-full' key={data.id}>
                                        <div className='grid grid-cols-4 text-xs mt-2 items-center'>
                                            <div className='flex space-x-4 items-center col-span-2'>
                                                <p className='font-normal w-24 italic break-words'>{data.domain}</p>
                                            </div>

                                            <div className='w-full flex justify-end pr-2 col-span-2'>
                                                {data.pageVisitCount}
                                            </div>
                                        </div>
                                        <HorizontalSeparator className='bg-primary-darker bg-opacity-10 mt-2' />
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ComplianceMontioring;
