import { ActionTypes } from '../../types';
import { ReactComponent as HintIcon } from '../img/evaluate-hint.svg';

const EvaluateHint = ({
    evaluateHint,
    actionType,
    handleActionButtonClick,
    newNuances,
    protocolName,
}: {
    evaluateHint: string;
    actionType: string | null;
    handleActionButtonClick: () => void;
    newNuances: { nuance: string; threshold: number; oldNuances?: string };
    protocolName: string;
}) => {
    return (
        <div className='evaluate-hint-container text-xs font-normal'>
            <div>
                {actionType && (
                    <button className='action-btn' onClick={handleActionButtonClick}>
                        <div className='action-text text-xxs'>
                            <span>
                                {actionType === ActionTypes.CREATE
                                    ? `Create ${newNuances.nuance}`
                                    : actionType === ActionTypes.UPDATE
                                    ? `Update ${newNuances.oldNuances}`
                                    : `Format ${protocolName}`}{' '}
                            </span>
                        </div>
                    </button>
                )}
            </div>
            <div className='flex space-x-2 mt-2'>
                <div>
                    <HintIcon className='w-6 h-6' />
                </div>
                <p>{evaluateHint}</p>
            </div>
        </div>
    );
};

export default EvaluateHint;
