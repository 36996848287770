import { useEffect } from 'react';

import { useRouteMatch, Route, Switch, useHistory, useParams } from 'react-router-dom';

import { Thanks } from '../components/registrationComplete/Thanks.component';

export const RegistrationCompletePage = () => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { token } = useParams<{ token: string }>();

    useEffect(() => {
        // Redirect to the home page after confirmation
        //change back to home after beta feedback
        history.push('/sign-in');
    }, [token, history]);
    return (
        <div className='bg-backgroundColor h-screen'>
            <Switch>
                <Route exact path={[`${path}/:token/:id/:userType`]}>
                    <Thanks />
                </Route>
            </Switch>
        </div>
    );
};
