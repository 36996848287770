import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Logo from '../../assets/logo/logo_blankstate_beta_white.png';
import { ForgotPasswordForm } from '../../components/signIn/ForgotPasswordForm.component';
import NewSignInForm from '../../components/signIn/NewSignInForm.component';
import { ResetPasswordCard } from '../../components/signIn/ResetPasswordCard.component';
import { SetupNewPasswordForm } from '../../components/signIn/SetupNewPasswordForm.component';
import { useStore } from '../../store';
import NotFound from '../NotFound.page';

export const SignInPageMobile = () => {
    const { path } = useRouteMatch();
    const store = useStore();
  
    return (
        <div className='flex flex-row min-h-screen w-screen'>
            <div className='overflow-hidden flex flex-col flex-grow w-full h-screen relative'>
                <div className='bg-mobile-background bg-cover bg-center bg-no-repeat h-2/5 w-full flex items-center justify-center'>
                    <img src={Logo} className='h-8' alt='logo' />
                </div>
                <div className='px-4 py-16 rounded-t-2xl absolute bg-backgroundColor w-full top-1/3 z-10'>
                    <Switch>
                        <Route exact path={[`${path}`]}>
                            <NewSignInForm />
                        </Route>
                        <Route exact path={[`${path}/forgot-password`]}>
                            <ForgotPasswordForm />
                        </Route>
                        <Route exact path={[`${path}/reset-password`]}>
                            <ResetPasswordCard email={store.signInStore.forgotPasswordEmail as string} />
                        </Route>
                        <Route exact path={[`${path}/setup-new-password`]}>
                            <SetupNewPasswordForm />
                        </Route>
                        <Route component={NotFound} />
                    </Switch>
                </div>
            </div>
        </div>
    );
};
