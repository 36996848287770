import { Dialog } from '@mui/material';

import { ReactComponent as CrossIcon } from '../../../assets/icons/icon-cross-fill.svg';
import { useStore } from '../../../store';
import { Button } from '../../basic/Button.component';
import { useServices } from '../../ServiceProvider';

type ReverseDeletionModalProps = {
    open: boolean;
    handleClose: () => void;
};

const ReverseDeletionModal = ({ open, handleClose }: ReverseDeletionModalProps) => {
    const store = useStore();
    const { users } = useServices();

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            PaperProps={{
                style: {
                    borderRadius: '15px',
                    width: '100%',
                    maxWidth: '656px',
                    padding: '32px 24px',
                },
            }}
        >
            <div className='flex justify-between items-center pb-4 mb-4 border-b border-blue-dark border-opacity-10'>
                <h1 className='text-tiny font-medium text-darker text-opacity-75'>Reverse Deletion of Account</h1>
                <button onClick={handleClose}>
                    <CrossIcon fill='#0F104480' width={12} height={12} />
                </button>
            </div>
            <div className='space-y-4 w-full'>
                <p className='text-sm text-darker text-opacity-75'>Your account deletion will be stopped</p>
                <div className='flex justify-end items-center space-x-5'>
                    <Button size='oval' variant='outlinedCancel' onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        size='oval'
                        variant='primaryOrange'
                        onClick={() => {
                            //@ts-ignore
                            store.setAccountDeletionDate(null);
                            handleClose();
                            users.reverseDelete().then((r) => r);
                        }}
                    >
                        Reverse
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default ReverseDeletionModal;
