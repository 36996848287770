import { useState } from 'react';

import { appInsights } from '../../../../AppInsights';
import { SUBSCRIPTION_BUTTON_LABEL } from '../../../../store/OrganisationStore';
import { timestampToDate } from '../../../../utils/dateUtils';
import { Button } from '../../../basic/Button.component';
import { Dialog } from '../../../basic/Dialog.component';

const ResumeSubscription = ({
    organisationName,
    endDate,
    handleResume,
}: {
    organisationName: string;
    endDate: number;
    handleResume: () => void;
}) => {
    const [showDialog, setShowDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const open = () => setShowDialog(true);
    const close = () => setShowDialog(false);

    const handleConfirm = async () => {
        setLoading(true);
        try {
            handleResume();
            setTimeout(async () => {
                setLoading(false);
                close();
            }, 2000);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ error: err });
            }
            setLoading(false);
            close();
        }
    };

    return (
        <div className='w-full h-full'>
            {showDialog && (
                <Dialog
                    handleConfirm={handleConfirm}
                    title={SUBSCRIPTION_BUTTON_LABEL.CANCEL_UNSUBSCRIPTION}
                    close={close}
                    open={open}
                    loading={loading}
                    content={
                        <div className='h-14 text-sm text-darker text-opacity-75'>
                            The cancellation with be effective at the end of the current billing period on the{' '}
                            <strong>{timestampToDate(endDate)}</strong>. Please resume subscription to have
                            uninterrupted access to blankstate's EVA features for your organization after{' '}
                            {timestampToDate(endDate)}.
                        </div>
                    }
                    confirmText={SUBSCRIPTION_BUTTON_LABEL.CANCEL_UNSUBSCRIPTION}
                    buttonVariant='blueRYB'
                />
            )}
            <div className='w-full'>
                <div className='p-4 flex items-center justify-between'>
                    <div className='max-w-3xl text-darker text-opacity-75 mr-14'>
                        <h3 className='font-medium   text-sm mb-2'>Revert Cancellation {organisationName}</h3>
                        <p className='text-sm whitespace-pre-line'>
                            You have cancelled the organisation subscription and you can still use the features account
                            until {timestampToDate(endDate)}. . You may resubscribe at any time before{' '}
                            {timestampToDate(endDate)} to continue uninterrupted access to our services.
                        </p>
                    </div>
                    <Button onClick={open} variant='blueRYB' size='oval' className='whitespace-nowrap'>
                        {SUBSCRIPTION_BUTTON_LABEL.CANCEL_UNSUBSCRIPTION}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ResumeSubscription;
