import { useState } from 'react';

import HidePassword from '../assets/icons/icon-hide-password.svg';
import ShowPassword from '../assets/icons/icon-show-password.svg';

const usePasswordToggle = () => {
    const [showPassword, setShowPassword] = useState(false);
    const Icon = showPassword ? HidePassword : ShowPassword;
    const InputType = showPassword ? 'text' : 'password';
    const handlePasswordVisibility = () => {
        if (Icon === HidePassword) {
            setShowPassword(!showPassword);
        } else if (Icon === ShowPassword) {
            setShowPassword(!showPassword);
        }
    };
    return { InputType, Icon, handlePasswordVisibility };
};

export default usePasswordToggle;
