export type Scenario = {
    name: string;
    id: string;
    category: string;
    user1: string;
    user2: string;
};

export const Categories = {
    1: 'Financial Services',
    2: 'Healthcare',
    3: 'Retail',
    4: 'Technology',
    5: 'Education',
    6: 'Human Resources',
    7: 'Legal Services',
    8: 'Real Estate',
    9: 'Hospitality',
    10: 'Automotive',
    11: 'Government Services',
    12: 'Non-Profit',
};

export const scenarios: Scenario[] = [
    // Financial Services
    {
        name: 'Banker Advising on Investments',
        id: 'f47ac10b-58cc-4372-a567-0e02b2c3d479',
        category: Categories[1],
        user1: 'Bank Advisor',
        user2: 'Client',
    },
    {
        name: 'Mortgage Loan Consultation',
        id: 'c9bf9e57-1685-4c89-bafb-ff5af830be8a',
        category: Categories[1],
        user1: 'Mortgage Officer',
        user2: 'Borrower',
    },
    {
        name: 'Credit Card Application Process',
        id: '7f263e90-bd24-4b97-a47b-c2fdd7b8d8bb',
        category: Categories[1],
        user1: 'Bank Representative',
        user2: 'Applicant',
    },
    {
        name: 'Retirement Planning Session',
        id: '1c2d35e6-38f8-46fa-bf5b-007b5fef49a0',
        category: Categories[1],
        user1: 'Financial Advisor',
        user2: 'Client',
    },
    {
        name: 'Small Business Loan Negotiation',
        id: '3d594650-3f91-44ff-baae-2baf6a234536',
        category: Categories[1],
        user1: 'Loan Officer',
        user2: 'Business Owner',
    },
    {
        name: 'Fraud Detection and Resolution',
        id: 'e0294e36-26fc-4e1a-b2b5-3721a2ff52c5',
        category: Categories[1],
        user1: 'Fraud Analyst',
        user2: 'Account Holder',
    },
    {
        name: 'Wealth Management Consultation',
        id: 'de305d54-75b4-431b-adb2-eb6b9e546014',
        category: Categories[1],
        user1: 'Wealth Manager',
        user2: 'Client',
    },
    // Healthcare
    {
        name: 'Doctor-Patient Consultation',
        id: '9a09a3a5-2094-444c-bb09-b8bba7b2fbbd',
        category: Categories[2],
        user1: 'Doctor',
        user2: 'Patient',
    },
    {
        name: 'Emergency Room Triage',
        id: '4f629d42-3876-4562-964a-0debb6c72f39',
        category: Categories[2],
        user1: 'Nurse',
        user2: 'Patient',
    },
    {
        name: 'Mental Health Therapy Session',
        id: '1b13b35a-3b77-4e90-bc6a-174bc90f8766',
        category: Categories[2],
        user1: 'Therapist',
        user2: 'Client',
    },
    {
        name: 'Pharmacist Medication Counseling',
        id: '5346d012-7da5-4b8b-87fc-3e430d6310fa',
        category: Categories[2],
        user1: 'Pharmacist',
        user2: 'Customer',
    },
    {
        name: 'Insurance Claim Processing',
        id: 'f4fba69c-78b4-4a90-a8f4-76f184370a39',
        category: Categories[2],
        user1: 'Insurance Representative',
        user2: 'Policyholder',
    },
    {
        name: 'Telemedicine Appointment',
        id: '0b2f0bc4-e19c-4658-85ae-70f3b1bb69a4',
        category: Categories[2],
        user1: 'Healthcare Provider',
        user2: 'Patient',
    },
    {
        name: 'Nursing Home Care Planning',
        id: 'e10dc9b4-2f8e-4c8f-9dbe-5f9b8e1cdb97',
        category: Categories[2],
        user1: 'Social Worker',
        user2: 'Family',
    },
    // Retail
    {
        name: 'Customer Service Complaint Resolution',
        id: '99ed37b7-4569-4c95-ae3d-0d8b285ef6b5',
        category: Categories[3],
        user1: 'Customer Service Representative',
        user2: 'Customer',
    },
    {
        name: 'Product Return and Exchange',
        id: 'd1f404be-d7c6-466c-b9e6-9b77de1f06e4',
        category: Categories[3],
        user1: 'Store Associate',
        user2: 'Customer',
    },
    {
        name: 'VIP Customer Assistance',
        id: 'a5a78b6c-2f53-431e-9808-3b535cdc4dc1',
        category: Categories[3],
        user1: 'VIP Concierge',
        user2: 'Customer',
    },
    {
        name: 'In-Store Personal Shopping Experience',
        id: '3bb77b55-1225-4462-b4a5-79d4567a2f88',
        category: Categories[3],
        user1: 'Personal Shopper',
        user2: 'Customer',
    },
    {
        name: 'Online Shopping Support',
        id: 'd52a2765-fde5-4b90-8e39-e3a25f9785b9',
        category: Categories[3],
        user1: 'Support Representative',
        user2: 'Customer',
    },
    {
        name: 'Loyalty Program Enrollment',
        id: 'e36e7422-8880-4f9a-a598-8e67f024a1c6',
        category: Categories[3],
        user1: 'Customer Service Representative',
        user2: 'Customer',
    },
    {
        name: 'Product Recommendation',
        id: '64bd60e8-9a82-4827-989f-799a5cf68c68',
        category: Categories[3],
        user1: 'Sales Associate',
        user2: 'Customer',
    },
    // Technology
    {
        name: 'Technical Support Troubleshooting',
        id: 'd53e9891-b4f6-4f82-a681-63260a8651f0',
        category: Categories[4],
        user1: 'Technical Support Agent',
        user2: 'User',
    },
    {
        name: 'Software Demo and Sales',
        id: 'a7d23e5f-23b7-4d4d-a10a-4b4d8f39173c',
        category: Categories[4],
        user1: 'Salesperson',
        user2: 'Potential Customer',
    },
    {
        name: 'Data Privacy Compliance Check',
        id: 'c0e44712-09d8-4930-9f7b-8dbca57b13e4',
        category: Categories[4],
        user1: 'Compliance Officer',
        user2: 'IT Specialist',
    },
    {
        name: 'Cloud Migration Consultation',
        id: 'e63d2e38-e4de-4f2b-83a7-027d146ee999',
        category: Categories[4],
        user1: 'Cloud Consultant',
        user2: 'IT Manager',
    },
    {
        name: 'Cybersecurity Incident Response',
        id: 'b6ab9d5f-9c31-4b9b-b66e-65f8049e66c1',
        category: Categories[4],
        user1: 'Security Analyst',
        user2: 'IT Administrator',
    },
    {
        name: 'User Experience Feedback Session',
        id: '978907ea-68f5-41b4-b462-5ab7f238a47e',
        category: Categories[4],
        user1: 'UX Researcher',
        user2: 'User',
    },
    {
        name: 'Agile Sprint Planning Meeting',
        id: '7f4d940a-d70e-41fd-9f44-929af6959f36',
        category: Categories[4],
        user1: 'Scrum Master',
        user2: 'Development Team',
    },
    // Education
    {
        name: 'Student Academic Advising',
        id: 'b5b660ed-95b6-4628-a29e-3e25b80fc751',
        category: Categories[5],
        user1: 'Academic Advisor',
        user2: 'Student',
    },
    {
        name: 'Parent-Teacher Conference',
        id: '1e840c07-b183-45bb-bbd7-b3bc1ecb3f90',
        category: Categories[5],
        user1: 'Teacher',
        user2: 'Parent',
    },
    {
        name: 'Online Course Technical Support',
        id: 'fb90c4ae-91a0-4e54-a029-7e28f5d663e8',
        category: Categories[5],
        user1: 'Technical Support Agent',
        user2: 'Student',
    },
    {
        name: 'College Admissions Interview',
        id: '752c2c2a-7e2a-4b4b-84f7-56a9e1e593d8',
        category: Categories[5],
        user1: 'Admissions Officer',
        user2: 'Applicant',
    },
    {
        name: 'Special Education Planning Meeting',
        id: '6f8a2162-924e-4625-b06b-87e37cf7ea7d',
        category: Categories[5],
        user1: 'Special Education Coordinator',
        user2: 'Student',
    },
    {
        name: 'Career Counseling Session',
        id: 'e586158e-0c02-43a4-84b4-97d74e11f6d4',
        category: Categories[5],
        user1: 'Career Counselor',
        user2: 'Student',
    },
    {
        name: 'Student Disciplinary Hearing',
        id: 'f28c76a1-5abf-43b2-978e-447d4dd7e26a',
        category: Categories[5],
        user1: 'School Administrator',
        user2: 'Student',
    },
    // Human Resources
    {
        name: 'Job Interview',
        id: '44d8d2f2-c32f-4d07-81d3-2fcd1a2be308',
        category: Categories[6],
        user1: 'Interviewer',
        user2: 'Job Applicant',
    },
    {
        name: 'Employee Performance Review',
        id: '1a7c1e22-9be6-41f8-b13e-5c0b0285b7fb',
        category: Categories[6],
        user1: 'Manager',
        user2: 'Employee',
    },
    {
        name: 'Workplace Conflict Resolution',
        id: '5a8b2c9f-165c-4f6c-b46c-14e431cfcc25',
        category: Categories[6],
        user1: 'HR Representative',
        user2: 'Employees',
    },
    {
        name: 'Benefits Enrollment Assistance',
        id: '768d4a34-2c37-4056-8e29-7a2bb60af689',
        category: Categories[6],
        user1: 'HR Specialist',
        user2: 'Employee',
    },
    {
        name: 'Onboarding New Employee',
        id: '5cf06d91-9d35-4a3f-84d4-74665398d2ae',
        category: Categories[6],
        user1: 'HR Representative',
        user2: 'New Employee',
    },
    {
        name: 'Diversity and Inclusion Training',
        id: 'b5c2841f-76ed-4569-a737-e0ed5db1a203',
        category: Categories[6],
        user1: 'Trainer',
        user2: 'Employees',
    },
    {
        name: 'Exit Interview',
        id: '01f2c8bc-10a2-44e5-91ad-b66a6e6b69c4',
        category: Categories[6],
        user1: 'HR Representative',
        user2: 'Departing Employee',
    },
    // Legal Services
    {
        name: 'Attorney-Client Case Discussion',
        id: '18f563cc-e5ba-4634-842e-3704dc63c65d',
        category: Categories[7],
        user1: 'Attorney',
        user2: 'Client',
    },
    {
        name: 'Witness Interview',
        id: 'b5b7dc02-e446-4415-b85f-4d6e9edc405d',
        category: Categories[7],
        user1: 'Attorney',
        user2: 'Witness',
    },
    {
        name: 'Mediation Session',
        id: '5797e52d-b37c-4228-9d89-d4e2a2d3589b',
        category: Categories[7],
        user1: 'Mediator',
        user2: 'Disputing Parties',
    },
    {
        name: 'Legal Document Review',
        id: 'f6b3f77a-2094-4e3f-98b4-421e4f8c112a',
        category: Categories[7],
        user1: 'Lawyer',
        user2: 'Client',
    },
    {
        name: 'Court Hearing Preparation',
        id: '65d54dd5-b482-42f0-8120-6d346032f6c8',
        category: Categories[7],
        user1: 'Attorney',
        user2: 'Client',
    },
    {
        name: 'Contract Negotiation',
        id: '8b7f73d7-d1c4-421a-a1b2-320d12b2e4d3',
        category: Categories[7],
        user1: 'Negotiator',
        user2: 'Client',
    },
    {
        name: 'Legal Consultation for Startups',
        id: '6bb0e086-d1de-4e88-8b5d-69fa6df7075c',
        category: Categories[7],
        user1: 'Attorney',
        user2: 'Entrepreneur',
    },
    // Real Estate
    {
        name: 'Property Showing and Discussion',
        id: '5f7adf31-6486-4ac7-80f3-f247dc5f2dc5',
        category: Categories[8],
        user1: 'Real Estate Agent',
        user2: 'Buyer',
    },
    {
        name: 'Lease Agreement Negotiation',
        id: '914dfd45-1c98-4d55-b41e-ff2a2ecb2d9a',
        category: Categories[8],
        user1: 'Landlord',
        user2: 'Tenant',
    },
    {
        name: 'Home Inspection Review',
        id: 'f57b1a26-c0b4-42d2-95d6-3ad2d5e410f4',
        category: Categories[8],
        user1: 'Inspector',
        user2: 'Homebuyer',
    },
    {
        name: 'Property Management Complaint Resolution',
        id: 'ad399ee9-1d06-460d-9e48-d1e160c023f2',
        category: Categories[8],
        user1: 'Property Manager',
        user2: 'Tenant',
    },
    {
        name: 'Real Estate Investment Consultation',
        id: '7e0c27e6-34ea-4a2f-a4ed-b9cf7c39b6f7',
        category: Categories[8],
        user1: 'Investment Advisor',
        user2: 'Investor',
    },
    {
        name: 'Commercial Property Acquisition Meeting',
        id: '931a0f3c-8a19-4a47-a8d4-4ea6ccaf35fc',
        category: Categories[8],
        user1: 'Real Estate Agent',
        user2: 'Business Owner',
    },
    {
        name: 'Rental Application Process',
        id: '80f14bda-4be7-4b0d-8aa3-8335baf3791e',
        category: Categories[8],
        user1: 'Landlord',
        user2: 'Applicant',
    },
    // Hospitality
    {
        name: 'Hotel Check-in and Concierge Service',
        id: '7ebd930e-0731-4f22-891f-c3e15b7fa5e4',
        category: Categories[9],
        user1: 'Hotel Staff',
        user2: 'Guest',
    },
    {
        name: 'Restaurant Reservation and Seating',
        id: '9e034d4b-72c4-4215-9ae3-d1d72b9bb4d4',
        category: Categories[9],
        user1: 'Host/Hostess',
        user2: 'Guest',
    },
    {
        name: 'Travel Booking Assistance',
        id: '208a97d9-c8f2-41a0-83d4-ec8cc8cdb50c',
        category: Categories[9],
        user1: 'Travel Agent',
        user2: 'Traveler',
    },
    {
        name: 'Guest Complaint Resolution',
        id: 'e0f9cb56-9638-4a1e-8e9d-56c9b67b7d1f',
        category: Categories[9],
        user1: 'Customer Service Representative',
        user2: 'Guest',
    },
    {
        name: 'Event Planning Consultation',
        id: '88b32e2d-1ef6-4b83-8a3f-5ed5e4bca8a5',
        category: Categories[9],
        user1: 'Event Planner',
        user2: 'Client',
    },
    {
        name: 'Room Service Order',
        id: 'f5d6ef44-d2a3-48b2-9024-7921f52a0ab2',
        category: Categories[9],
        user1: 'Guest',
        user2: 'Room Service Staff',
    },
    {
        name: 'Tourism Information and Recommendations',
        id: 'dd28280b-ef32-46f3-9c12-81d2c0aab745',
        category: Categories[9],
        user1: 'Tour Guide',
        user2: 'Tourist',
    },
    // Automotive
    {
        name: 'Car Sales Negotiation',
        id: 'aab7187e-6b2f-4990-85a7-7d86b7a1c047',
        category: Categories[10],
        user1: 'Salesperson',
        user2: 'Buyer',
    },
    {
        name: 'Vehicle Repair Consultation',
        id: 'a1c10a74-d239-4110-858e-7bc88c8f7ed5',
        category: Categories[10],
        user1: 'Mechanic',
        user2: 'Vehicle Owner',
    },
    {
        name: 'Insurance Claim After Accident',
        id: '87d8f4b6-ff3a-4f02-85c5-94da6fc3f9a6',
        category: Categories[10],
        user1: 'Insurance Adjuster',
        user2: 'Policyholder',
    },
    {
        name: 'Test Drive Experience',
        id: 'ab2e7a82-78de-4b22-b06a-404299ebef83',
        category: Categories[10],
        user1: 'Salesperson',
        user2: 'Potential Buyer',
    },
    {
        name: 'Car Loan Application',
        id: '2b9df35f-c83a-4c91-9950-64a75995f1b5',
        category: Categories[10],
        user1: 'Loan Officer',
        user2: 'Applicant',
    },
    {
        name: 'Vehicle Maintenance Advisory',
        id: '0cf16a8a-88c2-4b9a-8005-79ad47e2eaf2',
        category: Categories[10],
        user1: 'Service Advisor',
        user2: 'Vehicle Owner',
    },
    {
        name: 'Used Car Inspection and Valuation',
        id: 'c79d9495-5b25-46f9-90de-d82f836d2da0',
        category: Categories[10],
        user1: 'Inspector',
        user2: 'Buyer',
    },
    // Government Services
    {
        name: 'Passport Application Process',
        id: 'b420967d-9266-4e80-988f-b8b7a64bfc15',
        category: Categories[11],
        user1: 'Government Official',
        user2: 'Applicant',
    },
    {
        name: 'Tax Filing Assistance',
        id: '409a376f-bb7e-4e82-bbd1-3f6b7cbfbd60',
        category: Categories[11],
        user1: 'Tax Advisor',
        user2: 'Taxpayer',
    },
    {
        name: 'Voting Registration',
        id: 'b2f4b4e3-9270-48b2-8e78-c8b0c9e3ff88',
        category: Categories[11],
        user1: 'Election Officer',
        user2: 'Voter',
    },
    {
        name: 'Social Services Benefits Application',
        id: '9264f9e6-47f5-4f91-bc9a-5393cfd45b62',
        category: Categories[11],
        user1: 'Caseworker',
        user2: 'Applicant',
    },
    {
        name: 'Building Permit Application',
        id: '3b8c3a23-4d5f-499a-b9ed-dcb93115f248',
        category: Categories[11],
        user1: 'Government Official',
        user2: 'Applicant',
    },
    {
        name: 'Public Transportation Information',
        id: 'a9f7b994-0fe2-44da-a9c1-765c5d6c09b3',
        category: Categories[11],
        user1: 'Transit Officer',
        user2: 'Commuter',
    },
    {
        name: 'City Council Meeting Public Comment',
        id: 'f5e6b6c4-0ee7-4e4b-b6a8-2191159e9be7',
        category: Categories[11],
        user1: 'Council Member',
        user2: 'Citizen',
    },
    // Non-Profit
    {
        name: 'Donation Processing',
        id: 'b56952e6-d38a-4782-8fe6-6dba8e9dfbae',
        category: Categories[12],
        user1: 'Fundraiser',
        user2: 'Donor',
    },
    {
        name: 'Volunteer Recruitment',
        id: 'a7de450b-c356-46d9-a6d5-593b6ff4c7e2',
        category: Categories[12],
        user1: 'Volunteer Coordinator',
        user2: 'Volunteer',
    },
    {
        name: 'Grant Application Review',
        id: 'ebc05e43-1420-4f5f-8641-7b4d4b94d85d',
        category: Categories[12],
        user1: 'Grant Officer',
        user2: 'Applicant',
    },
    {
        name: 'Beneficiary Needs Assessment',
        id: '531d1bfa-46e6-4ec3-8515-349a32e1c2e7',
        category: Categories[12],
        user1: 'Caseworker',
        user2: 'Beneficiary',
    },
    {
        name: 'Fundraising Event Planning',
        id: '64ae53eb-01ae-41c1-9571-7b3e621ce158',
        category: Categories[12],
        user1: 'Event Planner',
        user2: 'Organizer',
    },
    {
        name: 'Community Outreach Program',
        id: '7d0fe9c2-1c2b-48ef-83e0-6c3bc62c10fb',
        category: Categories[12],
        user1: 'Outreach Coordinator',
        user2: 'Community Member',
    },
    {
        name: 'Board Member Selection Interview',
        id: 'c7b2792d-5f88-4727-849f-0b6c6493c8fe',
        category: Categories[12],
        user1: 'Board Member',
        user2: 'Candidate',
    },
];
