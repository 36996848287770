import { useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';

import useMediaQuery from '../../hooks/useMediaQuery';
import { useSession } from '../../hooks/useSession';
import { useSpaces } from '../../hooks/useSpaces';
import { store } from '../../store';
import { SessionByDate } from '../../store/SessionStore';
import { SpaceInfo } from '../../store/SpacesStore';
import { SIZES } from '../../utils/constants';
import { ProfilePicture } from '../basic/ProfilePicture';
import SessionSkeleton from '../sessions/SessionSkeleton.component';
import SpaceHeader from '../spaces/home/SpaceComponent/SpaceHeader.component';
import SpaceSession from './SpaceSession.component';

const SpaceHome = () => {
    const { id } = useParams<{ id: string }>();
    const { getSpaceDetails } = useSpaces();
    const [spaceDetails, setSpaceDetails] = useState<SpaceInfo>();
    const [sessions, setSession] = useState<SessionByDate[]>([]);
    const { getSessionsByDate, loading } = useSession();
    const fullName = store.user.userData?.firstName
        ? store.user.userData?.firstName + ' ' + store.user.userData?.lastName
        : store.user.userData?.email;

    const isMobile = useMediaQuery(SIZES.MOBILE_WIDTH);

    useEffect(() => {
        // space data
        getSpaceDetails(id).then((res) => setSpaceDetails(res));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        getSessionsByDate(id).then((res) => setSession(res));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const profilePicture = store.user.userData?.profilePictureUrl;
    return (
        <div className='w-full h-full overflow-y-auto pr-2 no-scrollbar z-20'>
            {!isMobile && (
                <div className='relative'>
                    <div className='flex flex-col space-y-4 absolute right-0 top-5'>
                        <Link
                            to={`/workspace/edit-space/${spaceDetails?.spaceId}`}
                            className='text-sm text-blue-dark font-medium'
                        >
                            Edit
                        </Link>
                    </div>
                </div>
            )}
            <SpaceHeader space={spaceDetails} spaceUrl={`spaces/view/${spaceDetails?.spaceId}`} />
            <div className='relative py-2 w-full h-full mt-32 px-4 md:px-5 md:py-10 md:mt-32'>
                <div className='flex space-x-4 items-center md:mt-5 max-w-5xl mx-auto px-5'>
                    <ProfilePicture path={profilePicture} />
                    <p className='text-sm font-semibold text-darker text-opacity-75'>{fullName}</p>
                </div>
                <div className='w-full max-w-5xl mx-auto'>
                    {loading ? (
                        <SessionSkeleton number={2} className='mt-10 px-4' />
                    ) : sessions.length === 0 ? (
                        <></>
                    ) : (
                        sessions.map((session, index) => (
                            <div className='w-full mt-10 px-4' key={index}>
                                <SpaceSession date={session.date} data={session.data} />
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default SpaceHome;
