import React from 'react';

import clsx from 'clsx';

import { Input, InputProps } from '../../basic/Input.component';

interface FormInputProps extends InputProps {
    edit: boolean;
    variant?: 'standard' | 'amount' | 'website';
}

const FormInput: React.FC<FormInputProps> = (props) => {
    const { edit, value, label, placeholder, className } = props;
    return (
        <div className='relative'>
            {edit ? (
                <>
                    <Input
                        {...props}
                        className={clsx(className, !className?.includes('w-') && 'md:max-w-[290px] w-full')}
                    />
                </>
            ) : (
                <>
                    <p className='text-normal font-medium mb-2'>{label}</p>
                    <p className='text-normal text-darker opacity-50'>{value ?? placeholder}</p>
                </>
            )}
        </div>
    );
};

export default FormInput;
