import { appInsights } from '../../AppInsights';
import { del, get, post, put } from '../../utils/network';

export type CreatePayment = {
    email: string;
    payment_method?: string;
    subscriptionType?: string;
    defaultMethod?: boolean;
};

export type UpdatePaymentMethod = {
    email: string;
    current_payment_method: string;
    new_payment_method: string;
    defaultMethod?: boolean;
};
export type PaymentIntent = {
    amount: number;
    currency: string;
    email: string;
};

export type SubscriptionTF = {
    payment_method?: string;
    type?: string;
    product_name: string;
    organisationId?: string;
    taxId?: string;
};

export class PaymentsService {
    addPaymentMethod(data: CreatePayment) {
        try {
            return post('/payments/payment_method', data);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    getAllPaymentMethods() {
        try {
            return get('/payments/payment_method/');
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    removePaymentMethod(payment_method: string) {
        try {
            return del('/payments/payment_method/' + payment_method);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    setPaymentMethodAsDefault(payment_method: string) {
        try {
            return put('/payments/payment_method/set_default/' + payment_method);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    updatePaymentMethod(data: UpdatePaymentMethod) {
        try {
            return put('/payments/payment_method', data);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    //traceflowSubscription
    createOrUpdateSubcriptionTF(data: SubscriptionTF) {
        try {
            return post('/subscriptions/aicko/expert', data);
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    getAickoSubscriptionPricings() {
        try {
            return get('/subscriptions/aicko');
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }

    async getCurrentSubscription() {
        try {
            const response = await get(`/subscriptions/current`);
            return response;
        } catch (err) {
            if (err instanceof Error) {
                appInsights.trackException({ exception: err });
            }
        }
    }
}
