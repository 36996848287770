import { Elements } from '@stripe/react-stripe-js';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { stripePromise } from '../..';
import ConfirmOrganisationPlan from '../../components/organisation/createOrganisation/ConfirmOrganisationPlan';
import CreateOrganisation from '../../components/organisation/createOrganisation/CreateOrganisation.component';
import OrgainisationTier from '../../components/organisation/createOrganisation/OrgainisationTier';
import AddPayment from '../../components/plans/AddPayment.component';

const CreateOrganisationPage = () => {
    const { path } = useRouteMatch();
    return (
        <div className='w-full px-4 md:px-0 bg-backgroundColor organisation-form'>
            <Elements stripe={stripePromise}>
                <Switch>
                    <Route exact path={[`${path}/:id`]} component={CreateOrganisation} />
                    <Route exact path={[`${path}/:id/select-tier`]} component={OrgainisationTier} />
                    <Route exact path={[`${path}/:id/:type/confirm-plan`]} component={ConfirmOrganisationPlan} />
                    <Route exact path={[`${path}/:id/add-payment`]} component={AddPayment} />
                </Switch>
            </Elements>
        </div>
    );
};

export default CreateOrganisationPage;
